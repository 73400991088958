import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { withContentStore, WithContentStoreProps } from '@app/stores'
import { Resource } from '@compass/types'

import * as NavigationComponents from './navigation-components'

export interface NavigationBaseProps {
	onClick(): void
}

export type NavigationProps = NavigationBaseProps & WithContentStoreProps & RouteComponentProps<any>

export class Navigation extends React.Component<NavigationProps> {
	private onChangeDomain = (activeNavigationDomain: string) => {
		this.props.contentStore.set({ activeNavigationDomain })
	}

	public render() {
		const { onClick } = this.props
		const { navigation, activeNavigationDomain } = this.props.contentStore.state

		const isRight =
			navigation.findIndex(resource => resource.slug === activeNavigationDomain) === 1

		return (
			<NavigationComponents.Wrapper>
				<NavigationComponents.Switch
					onChangeDomain={this.onChangeDomain}
					domains={navigation}
					activeDomain={activeNavigationDomain}
					isRight={isRight}
				/>
				<NavigationComponents.ListsWrapper>
					<NavigationComponents.Lists isRight={isRight}>
						{navigation.map((domain: Resource) => (
							<React.Fragment key={domain.id}>
								<NavigationComponents.DomainLabel>
									{domain.title}
								</NavigationComponents.DomainLabel>
								<NavigationComponents.Domain
									domain={domain}
									key={domain.slug}
									onClick={onClick}
								/>
							</React.Fragment>
						))}
					</NavigationComponents.Lists>
				</NavigationComponents.ListsWrapper>
			</NavigationComponents.Wrapper>
		)
	}
}

export default compose<NavigationProps, NavigationBaseProps>(
	withContentStore,
	withRouter,
)(Navigation)
