import * as iconset from '@app/components/svg-icons/icons'
import { domains } from '@app/config'
import http from '@app/utils/http'
import { dashCaseToCamelCase } from '@app/utils/string'
import { PageResource, Resource, ResourceTemplateType } from '@compass/types'

import { getResourcesByResourceTemplateType } from '../resource/utils'

export const getNavigationResources = (): Promise<PageResource[]> =>
	http(
		`${
			domains.api
		}/resources/page?fields=slug,order,id,parent,title,type,template,list_type,domain`,
	)

const sortByOrder = (pageA: Resource, pageB: Resource) =>
	(typeof pageA.order !== 'undefined' ? pageA.order : 999999) -
	(typeof pageB.order !== 'undefined' ? pageB.order : 999999)

export const getNavigation = (resources: Resource[]) => {
	const navigationDomains = getResourcesByResourceTemplateType(
		resources,
		ResourceTemplateType.DOMAIN_PAGE,
	)

	return navigationDomains.sort(sortByOrder).reduce(
		(navigation, domain) => [
			...navigation,
			{
				...domain,
				resources: getSectionsForDomain(domain, resources),
			},
		],
		[],
	)
}

export const getSectionsForDomain = (domain: Resource, resources: Resource[]) => {
	const navigationSections = resources.filter(resource => !!resource.domain)

	return navigationSections
		.filter(resource => resource.domain && domain.slug.includes(resource.domain))
		.sort(sortByOrder)
		.map(section => ({
			...section,
			resources: getChildResources(section, resources),
		}))
}

const getChildResources = (parent: Resource, resources: Resource[]): Resource[] => {
	return resources
		.filter(resource => parent.id === resource.parent)
		.sort(sortByOrder)
		.map(resource => ({
			...resource,
			resources: getChildResources(resource, resources),
		}))
}

export const getSectionIcon = (section: Resource): keyof typeof iconset => {
	const sectionSlug = getSectionSlugByResourceSlug(section.slug)
	return dashCaseToCamelCase(sectionSlug) as keyof typeof iconset
}

export const getSectionSlugByResourceSlug = (url: string): string => url.split('/')[1]

export const getParentResourceByPathname = (
	pathname: string = '',
	resources: Resource[],
): Resource | undefined => {
	return resources.find(resource => pathname.startsWith(resource.slug))
}

export const getParentDomain = (
	pathname: string = '',
	navigationDomains: Resource[],
): Resource | undefined => {
	return navigationDomains.find(resource => pathname.startsWith(resource.slug))
}

export const getParentSection = (
	pathname: string = '',
	navigation: Resource[],
	resources: Resource[],
): Resource | undefined => {
	const activeSection = getSections(navigation, resources).find(resource =>
		pathname.startsWith(resource.slug),
	)

	return (
		activeSection && {
			...activeSection,
			resources: getChildResources(activeSection, resources),
		}
	)
}

export const getNextResource = (
	pathname: string,
	section: Resource | undefined,
): Resource | undefined => {
	return (
		section &&
		section.resources &&
		section.resources
			.filter(resource => pathname.startsWith(resource.slug))
			.reduce((nextResource, page) => {
				if (!page.resources) {
					return undefined
				}

				if (pathname === page.slug) {
					return page.resources[0]
				} else {
					return page.resources.reduce(
						(nextSubResource: Resource, subPage: Resource, index: number) => {
							if (
								pathname === subPage.slug &&
								page.resources &&
								page.resources[index + 1]
							) {
								return page.resources[index + 1]
							} else {
								return nextSubResource
							}
						},
						nextResource,
					)
				}
			}, undefined)
	)
}
export const getPrevResource = (
	pathname: string,
	section: Resource | undefined,
): Resource | undefined => {
	return (
		section &&
		section.resources &&
		section.resources
			.filter(resource => pathname.startsWith(resource.slug))
			.reduce((prevResource, page) => {
				if (!page.resources) {
					return undefined
				}

				return page.resources.reduce(
					(prevSubResource: Resource, subPage: Resource, index: number) => {
						if (pathname === subPage.slug) {
							return (page.resources && page.resources[index - 1]) || page
						} else {
							return prevSubResource
						}
					},
					prevResource,
				)
			}, undefined)
	)
}

export const getBreadcrumbs = (pathname: string, resources: Resource[] = []): Resource[] => {
	return resources.reduce(
		(breadcrumbs, resource) => [
			...breadcrumbs,
			...(pathname.startsWith(resource.slug) ? [resource] : []),
			...getBreadcrumbs(pathname, resource.resources),
		],
		[],
	)
}

export const getDomainSections = (navigation: Resource[]): Resource[] => {
	return navigation.reduce((sections, { resources = [] }) => [...sections, ...resources], [])
}

export const getSections = (navigation: Resource[], navigationResources: Resource[]) => {
	return [
		...getResourcesByResourceTemplateType(
			navigationResources,
			ResourceTemplateType.SECTION_PAGE,
		),
		...getDomainSections(navigation),
	].filter((item, index, self) => self.findIndex(section => section.id === item.id) === index)
}
