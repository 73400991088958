import styled, { css, keyframes } from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

interface BalloonProps {
	isVisible?: boolean
}

const animation = css`
	animation: ${keyframes({
			'0%': {
				transform: 'translate(-50%, -2px)',
			},
			'50%': {
				transform: 'translate(-50%, 2px)',
			},
			'100%': {
				transform: 'translate(-50%, -2px)',
			},
		})}
		3.25s infinite;
`

export const Balloon = styled(
	styled.span<BalloonProps>(({ isVisible }) => ({
		position: 'absolute',
		top: '30%',
		whiteSpace: 'nowrap',
		left: '40%',
		display: 'block',
		textAlign: 'center',
		borderRadius: '8px',
		border: `2px ${colors.GREY_30} solid`,
		background: colors.WHITE,
		padding: `${spacing.xs}px ${spacing.sm}px`,
		transition: transition.config,
		transitionProperty: 'opacty',
		opacity: isVisible ? 1 : 0,
		...mixins.primaryFont(),
	})),
)`
	${animation}
`
