import React from 'react'
import styled from 'styled-components'

import { colors, mixins, shadows, spacing, transition } from '@styleguide'

const FallbackMessageGlam = styled.p({
	margin: `${spacing.sm}px 0`,
	...mixins.smallLabel(),
})

export const FallbackMessage: React.SFC = () => (
	<FallbackMessageGlam>No tags were found matching your query.</FallbackMessageGlam>
)

export const Item = styled.div({
	display: 'block',
	marginRight: '6px',
	fontSize: '1.4rem',
	cursor: 'pointer',
	margin: `0 ${-1 * spacing.sm}px`,
	padding: `${spacing.xs}px ${spacing.sm}px`,
	transition: transition.config,

	['&:hover']: {
		color: colors.BLACK,
		background: colors.GREY_10,
	},

	['&:not(:last-child)']: {
		borderBottom: `1px ${colors.GREY_20} solid`,
	},
})

export const Wrapper = styled.div({
	position: 'absolute',
	top: '100%',
	left: 0,
	width: '100%',
	boxShadow: shadows[5],
	background: colors.WHITE,
	padding: `0 ${spacing.sm}px`,
	opacity: 1,
	zIndex: 10,
	visibility: 'visible',
	pointerEvents: 'all',
	transform: 'translateY(0)',
	transition: transition.config,

	['&:empty']: {
		opacity: 0,
		zIndex: -1,
		visibility: 'hidden',
		pointerEvents: 'none',
		transform: 'translateY(10px)',
	},
})
