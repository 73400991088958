import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { mixins } from '@styleguide'

import { ButtonIcon } from './button-icon'
import styles from './button-styles'
import {
	ButtonProps,
	ButtonPropsForAnchorElement,
	ButtonPropsForButtonElement,
	ButtonPropsForLink,
	getButtonIconProps,
} from './button-types'

const buttonSyles = css`
	${styles.default}
	${styles.variant}
	${styles.size}
	${styles.outline}
	${styles.disabled}
	${mixins.primaryFont()}
`

const A = styled.a<ButtonPropsForAnchorElement>`
	${buttonSyles}
`
const Btn = A.withComponent('button')
const Link = styled(({ buttonSize, ...rest }) => <RouterLink {...rest} />)<ButtonPropsForLink>`
	${buttonSyles}
`

export const Button: React.SFC<
	ButtonPropsForAnchorElement | ButtonPropsForButtonElement | ButtonPropsForLink
> = props => {
	const { a, link, children, ...rest } = props
	const { icon } = rest
	let combinedChildren = children

	if (icon) {
		combinedChildren = (
			<>
				{icon && <ButtonIcon {...getButtonIconProps(icon)} />}
				{children}
			</>
		)
	}

	const ButtonComponent = a ? A : link ? Link : Btn

	return (
		<ButtonComponent
			className={rest.outline ? 'u-theme-text' : 'u-theme-background'}
			{...rest as ButtonProps}>
			<span css={{ display: 'flex', alignItems: 'center' }}>{combinedChildren}</span>
		</ButtonComponent>
	)
}
