import styled from 'styled-components'

import { mixins, spacing } from '@styleguide'

export const Content = styled.div({
	padding: `${spacing.sm}px ${spacing.xs}px`,
	width: '100%',

	...mixins.mediaStyles({
		sm: {
			width: '70%',
			padding: `0 ${spacing.md}px`,
		},
	}),
})
