import { CSSObject } from 'styled-components'

import { breakpoints } from '../breakpoints'

// Media Queries
export const media = {
	min: (bp: keyof typeof breakpoints) => `@media(min-width: ${breakpoints[bp]}px)`,
	max: (bp: keyof typeof breakpoints) => `@media(max-width: ${breakpoints[bp] - 1}px)`,
	minMax: (bpMin: keyof typeof breakpoints, bpMax: keyof typeof breakpoints) =>
		`@media (min-width: ${breakpoints[bpMin]}px) and (max-width: ${breakpoints[bpMax] - 1}px)`,
}

// Prop styles
type StyleFunction<P> = (props: P) => CSSObject
type Style<P> = CSSObject | StyleFunction<P>
type Styles<P> = { [key in keyof P]: Style<P> }

export const propStyles = <P>(styles: Styles<P>) => (props: P) =>
	Object.keys(props).map((key: keyof P) => {
		const style: Style<P> = styles[key]
		return props[key] ? (style && typeof style === 'function' ? style(props) : style) : {}
	})

// MediaStyles
type bpStyles = { [key in keyof Partial<typeof breakpoints>]: CSSObject }

export const mediaStyles = (styles: bpStyles) =>
	Object.keys(styles).reduce(
		(cssObject: CSSObject, bp: keyof typeof breakpoints) => ({
			...cssObject,
			[media.min(bp)]: styles[bp],
		}),
		{},
	)
