import { colors, spacing } from '@app/config'
import React from 'react'
import styled from 'styled-components'

import { Small } from '../small/small'

const Wrapper = styled.div({
	marginTop: spacing.md,
	padding: 15,
	borderRadius: 8,
	border: `1px ${colors.GREY_30} solid`,
})

const ImageWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	marginRight: 30,
	background: colors.WHITE,
	flex: 1,
})

const Image = styled.img({
	width: 70,
	height: 'auto',
	display: 'block',
	marginLeft: spacing.xs,
})

const ButtonWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	marginTop: 10,
})

const Button = styled.div({
	marginRight: 10,
})

const ButtonImage = styled.img({
	display: 'block',
	width: 'auto',
	height: 30,
})

const TextWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
})

export const PoweredByUniLifeTeaser = () => (
	<Wrapper>
		<ImageWrapper>
			<a href="https://uni-life.nl" target="_blank">
				<Image src="/static/unilife.png" />
			</a>
		</ImageWrapper>

		<TextWrapper>
			<Small>
				Download the Uni-Life App to see all events on your phone based on your interest.
			</Small>

			<ButtonWrapper>
				<Button>
					<a href="https://apps.apple.com/us/app/uni-life/id1320860920" target="_blank">
						<ButtonImage src="/static/badge_appstore-lrg.svg" />
					</a>
				</Button>
				<Button>
					<a
						href="https://play.google.com/store/apps/details?id=nl.appvise.lantern.unilife.android&hl=nl&gl=US&pli=1"
						target="_blank">
						<ButtonImage src="/static/Google_Play_Store_badge_EN.svg" />
					</a>
				</Button>
			</ButtonWrapper>
		</TextWrapper>
	</Wrapper>
)
