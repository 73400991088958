import React from 'react'

import { CheckboxComponents, InputComponents } from './components'

export interface CheckboxProps {
	id: string
	labelText?: React.ReactType | JSX.Element | string
	errorText?: React.ReactType | JSX.Element | string
	hasError?: boolean
	checkboxType?: 'left' | 'right'
}

export class Checkbox extends React.Component<CheckboxProps & React.HTMLProps<HTMLInputElement>> {
	public static Wrapper = CheckboxComponents.Wrapper
	public static ButtonWrapper = InputComponents.ButtonWrapper
	public static Input = InputComponents.HiddenInput
	public static Label = CheckboxComponents.Label
	public static LabelText = InputComponents.LabelText.withComponent('span')
	public static ErrorLabel = InputComponents.ErrorLabel

	public render() {
		const {
			id,
			labelText,
			errorText,
			hasError,
			checkboxType,
			disabled,
			ref: _ref,
			as: _as,
			...rest
		} = this.props
		return (
			<Checkbox.Wrapper>
				<Checkbox.ButtonWrapper>
					<Checkbox.Input id={id} type="checkbox" disabled={!!disabled} {...rest} />
					<Checkbox.Label htmlFor={id} hasError={!!hasError} checkboxType={checkboxType!}>
						<Checkbox.LabelText>{labelText}</Checkbox.LabelText>
					</Checkbox.Label>
				</Checkbox.ButtonWrapper>
				{hasError && errorText && <Checkbox.ErrorLabel>{errorText}</Checkbox.ErrorLabel>}
			</Checkbox.Wrapper>
		)
	}
}
