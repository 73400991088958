import * as React from 'react'
import { Helmet } from 'react-helmet'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { compose } from 'recompose'
import styled from 'styled-components'

import {
	A2HS,
	Footer,
	GlobalStyles,
	Grid,
	Header,
	Loader,
	SearchModal,
	Sponsors,
	Subnavigation,
	TextSelectionModal,
} from '@app/components'
import { IconNavigation } from '@app/components/icon-navigation'
import { Resource } from '@app/pages/resource'
import { withContentStore, WithContentStoreProps } from '@app/stores'
import { CompassUtils, isBrowser } from '@app/utils'
import { ResourceTemplateType } from '@compass/types'
import { mixins } from '@styleguide'

const Main = styled.main({
	position: 'relative',
	display: 'flex',
	width: '100%',

	['& > div']: {
		position: 'relative',
		width: '100%',
	},

	...mixins.mediaStyles({
		md: {
			paddingTop: 60,
		},
		lg: {
			paddingTop: 70,
		},
	}),
})

let mainElement: HTMLElement | undefined

const IconColumnWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',

	...mixins.mediaStyles({
		lg: {
			justifyContent: 'flex-end',
		},
	}),
})

let setOpenFunc: undefined | ((state: boolean) => void)

export const AppComponent: React.SFC<WithContentStoreProps> = ({ contentStore }) => {
	const { pathname, isLoading, content } = contentStore.state

	const nestedRoutes = ['city-map', 'sports', 'community']
	const section = pathname.split('/')[1]
	const isNestedRoute = nestedRoutes.includes(section)

	const transitionKey = isNestedRoute ? section : pathname

	const resource = content[pathname]
	const template = CompassUtils.resource.getResourceTemplateType(resource)
	const isMapPage = template === ResourceTemplateType.MAP_PAGE
	const isHome = pathname === '/'

	return (
		<>
			<GlobalStyles />
			<Helmet />
			<Header />
			<Subnavigation />
			{isHome && (
				<Grid.Container css={{ width: '100%' }}>
					<Grid.Row>
						<Grid.Column xs={16} css={{ position: 'relative' }}>
							<IconColumnWrapper
								css={{ [mixins.media.min('sm')]: { display: 'none' } }}>
								<IconNavigation onSearch={() => setOpenFunc && setOpenFunc(true)} />
							</IconColumnWrapper>
							<SearchModal setOpen={setOpen => (setOpenFunc = setOpen)} />
						</Grid.Column>
					</Grid.Row>
				</Grid.Container>
			)}
			<Main ref={(main: HTMLElement) => (mainElement = main)}>
				<TransitionGroup>
					<CSSTransition
						key={transitionKey}
						classNames="u-view-animation"
						onExited={() => {
							if (isBrowser) {
								window.scroll(0, 0)

								if (mainElement) {
									// tslint:disable-next-line no-unused-expression
									mainElement.offsetHeight
								}
							}
						}}
						timeout={{ enter: 1000, exit: 500 }}>
						<Resource />
					</CSSTransition>
				</TransitionGroup>
			</Main>
			{!isMapPage && <Footer />}
			{!isMapPage && <Sponsors />}
			<Loader loading={isLoading} />
			{!isHome && (
				<div css={{ [mixins.media.max('md')]: { display: 'none' } }}>
					<SearchModal />
				</div>
			)}
			<TextSelectionModal />
			<A2HS />
		</>
	)
}

export const App = compose(withContentStore)(AppComponent)
