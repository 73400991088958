import styled from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

export interface TabsItemProps {
	isActive?: boolean
	children?: any
	onClick?: any
	small?: boolean
}

export const Item = styled.button.attrs<TabsItemProps>(({ isActive }) => ({
	className: isActive ? 'u-theme-text' : undefined,
}))<TabsItemProps>(({ isActive = false, small }) => ({
	position: 'relative',
	cursor: 'pointer',
	fontSize: !small ? '1.8rem' : '1.4rem',
	width: small ? 'auto' : '50%',
	flex: small ? 1 : 'auto',
	color: colors.GREY_50,
	padding: `${spacing.xs}px 0`,
	transition: transition.config,
	transitionProperty: 'color',

	...mixins.mediaStyles({
		sm: {
			width: '25%',
		},
	}),
	...mixins.primaryFont(),

	...(isActive
		? {
				color: 'currentColor',
		  }
		: {
				['&:hover']: {
					color: colors.GREY_60,
				},
		  }),
}))
