import React from 'react'
import { Tooltip as TippyTooltip, TooltipProps } from 'react-tippy'
import styled from 'styled-components'

import 'react-tippy/dist/tippy.css'

import { colors, shadows, transition } from '@styleguide'

import { SvgIcon } from '../svg-icons'

const TooltipInfoIcon = styled(SvgIcon)({
	display: 'block',
	width: 16,
	height: 16,
	borderRadius: '50%',
	border: `1px ${colors.GREY_30} solid`,
	padding: 2,
})

interface TooltipInnerProps {
	customWidth?: number
}

export const TooltipInner = styled.div<TooltipInnerProps>(({ customWidth = 300 }) => ({
	display: 'flex',
	alignItems: 'center',
	borderRadius: 8,
	padding: `10px 15px`,
	color: colors.GREY_60,
	background: colors.WHITE,
	maxWidth: customWidth,
	// textAlign: 'center',
	fontSize: '1.4rem',
	border: `1px ${colors.GREY_20} solid`,
	boxShadow: shadows[3],
}))

const TooltipComponent = styled(TippyTooltip)({
	transitionDuration: `${transition.duration} !important`,
})

export const Tooltip: React.SFC<TooltipProps> = props => (
	<TooltipComponent {...props}>
		{props.children || <TooltipInfoIcon icon="info" fill={colors.GREY_30} />}
	</TooltipComponent>
)
