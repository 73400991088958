import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { SvgIcon } from '@app/components/svg-icons'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { CompassUtils } from '@app/utils'
import { Resource } from '@compass/types'
import { colors, mixins, spacing, transition } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
	margin: `${spacing.xs}px 0`,
})

export const SectionLink = styled(RouterLink)({
	position: 'relative',
	display: 'block',
	color: colors.WHITE,
	fontSize: '2rem',
	fontWeight: 'bold',
	marginBottom: '5px',
	...mixins.primaryFont(),
})

export const Link = styled(RouterLink)({
	color: colors.WHITE,
	fontWeight: 'bold',
	display: 'block',
	marginBottom: '5px',
	fontSize: '1.3rem',
})

export const Blocks = styled.div({
	marginTop: spacing.xs,
	marginLeft: -spacing.xs / 2,
	marginRight: -spacing.xs / 2,

	...mixins.mediaStyles({
		md: {
			display: 'flex',
			flexWrap: 'wrap',
		},
	}),
})

interface DomainOverviewBlockProps extends BlockGlamProps {
	section: Resource
}

interface BlockGlamProps {
	columns?: number
}

const BlockGlam = styled.div<BlockGlamProps>(({ columns = 4 }) => ({
	padding: `0px ${spacing.xs / 2}px`,
	overflow: 'hidden',
	position: 'relative',
	marginBottom: spacing.xs,
	zIndex: 0,

	['& a:hover']: {
		textDecoration: 'underline',
	},

	...mixins.mediaStyles({
		md: {
			width: `${100 / columns}%`,
			display: 'inline-block',
			flexGrow: 0,
			minHeight: 250,
			marginBottom: 0,
		},
	}),
}))

const BlockInner = styled.div({
	position: 'relative',
	padding: `${spacing.sm}px 24px`,
	height: '100%',
})

const BlockIconGlam = styled(SvgIcon)({
	position: 'absolute',
	display: 'block',
	width: '60%',
	height: '60%',
	opacity: 0.2,
	transition: transition.config,
	transitionProperty: 'opacity',
	bottom: '-10%',
	right: '-10%',

	['svg']: {
		width: '100%',
		height: 'auto',
	},

	['BlockGlam:hover &']: {
		opacity: 0.4,
	},
})

const BlockBackground = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	zIndex: -1,
	borderRadius: 8,
})

const SectionPage = styled(Link)({
	color: colors.WHITE,
	fontWeight: 'bold',
	display: 'flex',
	alignItems: 'center',
	marginBottom: 5,
	fontSize: '1.3rem',

	['&:before']: {
		content: '"⤑"',
		marginRight: 5,
	},
	...mixins.primaryFont(),
})

export const Block: React.SFC<DomainOverviewBlockProps> = ({ columns, section }) => {
	const sectionIcon = CompassUtils.navigation.getSectionIcon(section)
	const sectionSlug = CompassUtils.navigation.getSectionSlugByResourceSlug(section.slug)

	return (
		<BlockGlam columns={columns}>
			<BlockInner>
				<ThemeWrapper fixedTheme={sectionSlug}>
					<BlockBackground className="u-theme-background" />
				</ThemeWrapper>
				<BlockIconGlam icon={sectionIcon} />
				<SectionLink to={section.slug}>{section.title}</SectionLink>
				{section.resources &&
					section.resources.map((page: Resource) => (
						<SectionPage to={page.slug} key={page.id}>
							{page.title}
						</SectionPage>
					))}
			</BlockInner>
		</BlockGlam>
	)
}
