import React from 'react'
import styled from 'styled-components'

import { mixins, spacing } from '@styleguide'

const Image = styled.img({
	position: 'relative',
	maxHeight: '30vh',
	height: 'auto',
	width: '100%',
	overflow: 'hidden',
	objectFit: 'cover',
	display: 'block',

	...mixins.mediaStyles({
		xl: {
			marginTop: spacing.md,
		},
	}),
})

export interface FullScreenImageProps {
	src: string
	alt: string
}

export const FullScreenImage: React.SFC<FullScreenImageProps> = ({ src, alt }) => (
	<Image src={src} alt={alt} />
)
