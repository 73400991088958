import React from 'react'

import { PlusMinus } from '@app/components/plus-minus'
import { spacing } from '@styleguide'

import { FileInputComponents, InputComponents } from './components'

export interface FileInputProps {
	onPreview?: (file: FilePreview) => void
	labelText?: React.ReactType | JSX.Element | string
	errorText?: React.ReactType | JSX.Element | string
	hasError?: boolean
}

export interface FilePreview {
	data: string
	name: string
	type: string
}

export interface FileInputState {
	preview: string | undefined
}

export class FileInput extends React.Component<
	FileInputProps & React.HTMLProps<HTMLInputElement>,
	FileInputState
> {
	public static Wrapper = InputComponents.Wrapper
	public static Button = FileInputComponents.Button
	public static Label = InputComponents.LabelText
	public static InputWrapper = FileInputComponents.InputWrapper
	public static Input = FileInputComponents.Input
	public static ErrorLabel = InputComponents.ErrorLabel
	public static Preview = FileInputComponents.Preview

	public preview: HTMLImageElement

	public state: FileInputState = {
		preview: undefined,
	}

	public handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const reader = new FileReader()

		const file = e.currentTarget.files && e.currentTarget.files[0]

		if (!file) {
			return
		}

		reader.onload = () => {
			const base64Chunks = (reader.result as string).split(',')
			const base64String = base64Chunks[1] || (reader.result as string)

			this.setState(
				{
					preview: reader.result as string,
				},
				() => {
					if (this.props.onPreview) {
						this.props.onPreview({
							data: base64String,
							name: file.name,
							type: file.type,
						})
					}
				},
			)
		}

		reader.readAsDataURL(file)

		if (this.props.onChange) {
			this.props.onChange(e)
		}
	}

	public render() {
		const {
			labelText,
			errorText,
			hasError,
			ref: _ref,
			as: _as,
			value,
			onPreview,
			...rest
		} = this.props
		const { name } = rest
		const { preview } = this.state

		return (
			<FileInput.Wrapper>
				{labelText && <FileInput.Label htmlFor={name}>{labelText}</FileInput.Label>}
				<FileInput.InputWrapper>
					<FileInput.Input
						id={name}
						type="file"
						{...rest}
						onChange={this.handleOnChange}
					/>
					<FileInput.Button htmlFor={name}>
						{value ? 'Change selected file...' : 'Select a file...'}
						<PlusMinus css={{ marginLeft: spacing.xs }} />
					</FileInput.Button>
				</FileInput.InputWrapper>
				{preview && (
					<FileInput.Preview
						ref={(ref: HTMLImageElement) => (this.preview = ref)}
						src={preview}
					/>
				)}
				{hasError && errorText && <FileInput.ErrorLabel>{errorText}</FileInput.ErrorLabel>}
			</FileInput.Wrapper>
		)
	}
}
