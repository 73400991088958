import React from 'react'
import MasonryGallery from 'react-masonry-component'
import styled from 'styled-components'

import { mixins, spacing } from '@styleguide'

export const masonryGutter = spacing.sm

export const MasonryGridSizer = styled.li({
	width: '100%',
	listStyle: 'none',

	...mixins.mediaStyles({
		md: {
			width: `calc(50% - 10px)`,
		},
		lg: {
			width: `calc(${100 / 3}% - 15px)`,
		},
	}),
})

const MasonryGutterSizer = styled.div({
	width: 0,

	...mixins.mediaStyles({
		md: {
			width: masonryGutter,
		},
	}),
})

export interface MasonryProps<T> {
	items: T[]
	itemToComponent: (item: T, index: number) => JSX.Element | null
}

export class Masonry<T> extends React.Component<MasonryProps<T>> {
	public render() {
		const { items = [], itemToComponent } = this.props

		const childElements = items.map((item, index) => itemToComponent(item, index))

		return (
			<MasonryGallery
				className="c-masonry"
				elementType="ul"
				options={{
					columnWidth: '.grid-sizer',
					itemSelector: '.grid-item',
					gutter: '.gutter-sizer',
					percentPosition: true,
				}}>
				<MasonryGridSizer className="grid-sizer" />
				<MasonryGutterSizer className="gutter-sizer" />
				{childElements}
			</MasonryGallery>
		)
	}
}
