import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const health: SvgIconMap = {
	size: {
		width: 41,
		height: 52,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-33" fill={fill} fillRule="nonzero">
			<path
				d="M12.42,30.23 C5.66,30.23 0.17,24.73 0.17,17.98 L0.17,1.63 L7.81,1.63 L7.81,4.63 L3.17,4.63 L3.17,17.98 C3.17,23.08 7.32,27.23 12.42,27.23 C17.52,27.23 21.67,23.08 21.67,17.98 L21.67,4.63 L17.03,4.63 L17.03,1.63 L24.67,1.63 L24.67,17.98 C24.67,24.73 19.17,30.23 12.42,30.23 Z"
				id="Path"
			/>
			<path
				d="M33.76,30.62 C30.28,30.62 27.45,27.79 27.45,24.31 C27.45,20.83 30.28,18 33.76,18 C37.24,18 40.07,20.83 40.07,24.31 C40.07,27.79 37.24,30.62 33.76,30.62 Z M33.76,21 C31.93,21 30.45,22.49 30.45,24.31 C30.45,26.14 31.94,27.62 33.76,27.62 C35.59,27.62 37.07,26.13 37.07,24.31 C37.07,22.48 35.58,21 33.76,21 Z"
				id="Shape"
			/>
			<g id="Group" transform="translate(5.000000, 0.000000)">
				<rect id="Rectangle" x="0.47" y="0.17" width="3" height="5.72" />
				<rect id="Rectangle" x="11.36" y="0.17" width="3" height="5.72" />
			</g>
			<path
				d="M23.08,51.56 C16.33,51.56 10.84,46.07 10.84,39.32 L10.84,28.78 L13.84,28.78 L13.84,39.32 C13.84,44.42 17.99,48.56 23.08,48.56 C28.17,48.56 32.32,44.41 32.32,39.32 L32.32,29.18 L35.32,29.18 L35.32,39.32 C35.32,46.07 29.83,51.56 23.08,51.56 Z"
				id="Path"
			/>
		</g>
	),
}
