import React from 'react'
import ReactPagination from 'react-paginating'

import * as PaginationComponents from './pagination-components'

interface PaginationProps {
	itemsPerPage: number
	amountOfItems: number
	amountOfPages: number
	page: number
	updateCurrentPage(page: number): void
}

export class Pagination extends React.Component<PaginationProps> {
	public render() {
		const { amountOfItems, amountOfPages, page, updateCurrentPage } = this.props

		return amountOfPages > 1 ? (
			<ReactPagination pageCount={amountOfPages} currentPage={page} total={amountOfItems}>
				{({ pages, currentPage, getPageItemProps }) => {
					const start =
						pages.length < 6 || currentPage < 3
							? 2
							: currentPage > pages.length - 3
							? pages.length - 3
							: currentPage - 1

					const pagedItems = []

					for (let i = Math.max(2, start); i < Math.min(start + 3, pages.length); i++) {
						pagedItems.push(
							<PaginationComponents.Button
								key={i}
								isActive={currentPage === i}
								buttonProps={getPageItemProps({
									pageValue: i,
									onPageChange: updateCurrentPage,
								})}>
								{i}
							</PaginationComponents.Button>,
						)
					}

					return (
						<PaginationComponents.Wrapper>
							<PaginationComponents.Button
								isActive={currentPage === 1}
								buttonProps={getPageItemProps({
									pageValue: 1,
									onPageChange: updateCurrentPage,
								})}>
								1
							</PaginationComponents.Button>

							{start > 2 && (
								<PaginationComponents.Separator>...</PaginationComponents.Separator>
							)}

							{pagedItems}

							{page < pages.length - 2 && pages.length > 4 && (
								<PaginationComponents.Separator>...</PaginationComponents.Separator>
							)}

							{pages.length > 1 && (
								<PaginationComponents.Button
									isActive={currentPage === pages.length}
									buttonProps={getPageItemProps({
										pageValue: pages.length,
										onPageChange: updateCurrentPage,
									})}>
									{pages.length}
								</PaginationComponents.Button>
							)}
						</PaginationComponents.Wrapper>
					)
				}}
			</ReactPagination>
		) : null
	}
}
