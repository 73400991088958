import { CSSObject } from 'styled-components'

import { fonts } from '../fonts'

export const primaryFont = (): CSSObject => ({
	fontFamily: fonts.PRIMARY,
	fontWeight: 800,
	letterSpacing: '0.04rem',
	textTransform: 'lowercase',
})
