import { PageListResource, Resource } from '@compass/types'
import { getParentDomain } from './../navigation/utils'

import { getSections } from '../navigation/utils'
import { SearchResult } from './types'

export const getResultsWithNavigationContext = (
	resources: Resource[],
	navigation: Resource[],
	navigationResources: Resource[],
): SearchResult[] => {
	const sections = getSections(navigation, navigationResources)

	return resources.map(resource => {
		const section = sections.find(
			section =>
				resource.slug.startsWith(section.slug) ||
				// @ts-ignore
				(section.list_type && resource.type === section.list_type),
		)
		const domain = section ? getParentDomain(section.slug, navigation) : undefined

		return {
			resource: {
				...resource,
				...((section as PageListResource).template === 'page-list'
					? {
							slug: section ? section.slug + '?item=' + resource.id : resource.slug,
					  }
					: {}),
			},
			section,
			domain,
		}
	})
}
