import React from 'react'

import * as AccordionComponents from './accordion-components'

import { DropdownCaret } from '@app/components/dropdown-caret'

type OverflowTypes = 'hidden' | 'visible' | 'auto' | 'scroll' | 'inherit' | 'initial' | 'unset'

export interface AccordionProps {
	transitionTime?: number
	easing?: string
	defaultOpen?: boolean
	noPadding?: boolean
	open?: boolean
	headerDisabled?: boolean
	lazyRender?: boolean
	overflowWhenOpen?: OverflowTypes
	header: string | JSX.Element
	accordionPosition: number
	isActive?: boolean
	activeBackgroundColor?: string
	last: boolean
	openStyles?: any
	maxHeight?: number
	handleHeaderClick?: (accordionPosition: string | number) => void
	onOpen?: () => void
	onClose?: () => void
	onOpening?: () => void
	onClosing?: () => void
}
export interface AccordionState {
	isClosed: boolean
	shouldOpenOnNextCycle: boolean
	shouldSwitchAutoOnNextCycle: boolean
	height: number | string
	transition: string
	hasBeenOpened: boolean
	overflow: OverflowTypes
	inTransition: boolean
}

export class Accordion extends React.Component<AccordionProps, AccordionState> {
	private inner: any
	public state: AccordionState = {
		isClosed: true,
		shouldOpenOnNextCycle: false,
		shouldSwitchAutoOnNextCycle: false,
		height: 0,
		transition: `height ${this.props.transitionTime || 200}ms ${this.props.easing ||
			'lineair'}`,
		hasBeenOpened: false,
		overflow: 'hidden',
		inTransition: false,
	}

	public componentWillMount() {
		const { overflowWhenOpen = 'hidden' } = this.props
		// Defaults the dropdown to be closed
		if (this.props.open || this.props.defaultOpen) {
			this.setState({
				isClosed: false,
				height: 'auto',
				transition: 'none',
				hasBeenOpened: true,
				overflow: overflowWhenOpen,
				inTransition: false,
			})
		}
	}

	public componentDidUpdate(prevProps: AccordionProps, prevState: AccordionState) {
		const { open, onOpening, onClosing } = this.props
		if (this.state.shouldOpenOnNextCycle) {
			this.continueOpenCollapsible()
		}

		if (prevState.height === 'auto' && this.state.shouldSwitchAutoOnNextCycle === true) {
			window.setTimeout(() => {
				// Set small timeout to ensure a true re-render
				this.setState({
					height: 0,
					overflow: 'hidden',
					isClosed: true,
					shouldSwitchAutoOnNextCycle: false,
				})
			}, 50)
		}

		// If there has been a change in the open prop (controlled by accordion)
		if (prevProps.open !== open) {
			if (open === true) {
				this.openCollapsible()
				if (onOpening) {
					onOpening()
				}
			} else {
				this.closeCollapsible()
				if (onClosing) {
					onClosing()
				}
			}
		}
	}

	private closeCollapsible() {
		const { maxHeight, transitionTime = 200, easing = 'linear' } = this.props

		this.setState({
			shouldSwitchAutoOnNextCycle: true,
			height: maxHeight || this.inner.offsetHeight,
			transition: `height ${transitionTime}ms ${easing}`,
			inTransition: true,
		})
	}

	private openCollapsible() {
		this.setState({
			inTransition: true,
			shouldOpenOnNextCycle: true,
		})
	}

	private continueOpenCollapsible() {
		const { maxHeight, transitionTime = 200, easing = 'linear' } = this.props
		this.setState({
			height: maxHeight || this.inner.offsetHeight,
			transition: `height ${transitionTime}ms ${easing}`,
			isClosed: false,
			hasBeenOpened: true,
			inTransition: true,
			shouldOpenOnNextCycle: false,
		})
	}

	private handleHeaderClick(event: React.MouseEvent<HTMLDivElement>) {
		event.preventDefault()

		const {
			headerDisabled = false,
			accordionPosition,
			handleHeaderClick,
			onOpening,
			onClosing,
		} = this.props

		if (headerDisabled) {
			return
		}

		if (handleHeaderClick) {
			handleHeaderClick(accordionPosition)
		} else {
			if (this.state.isClosed === true) {
				this.openCollapsible()
				if (onOpening) {
					onOpening()
				}
			} else {
				this.closeCollapsible()
				if (onClosing) {
					onClosing()
				}
			}
		}
	}

	private handleTransitionEnd() {
		// Switch to height auto to make the container responsive
		const { overflowWhenOpen = 'hidden', onOpen, onClose } = this.props

		if (!this.state.isClosed) {
			this.setState({
				height: 'auto',
				overflow: overflowWhenOpen,
				inTransition: false,
			})
			if (onOpen) {
				onOpen()
			}
		} else {
			this.setState({ inTransition: false })
			if (onClose) {
				onClose()
			}
		}
	}

	public render() {
		const {
			lazyRender = false,
			header,
			headerDisabled,
			isActive,
			activeBackgroundColor,
			noPadding,
			openStyles,
		} = this.props
		const { hasBeenOpened, height, overflow, isClosed, inTransition, transition } = this.state

		const dropdownStyle = {
			height,
			overflow,
			transition,
			WebkitTransition: transition,
			msTransition: transition,
		}

		// Don't render children until the first opening of the Collapsible if lazy rendering is enabled
		const children =
			lazyRender && !hasBeenOpened && isClosed && !inTransition ? null : this.props.children

		return (
			<AccordionComponents.Item style={isClosed ? undefined : openStyles} isOpen={!isClosed}>
				<AccordionComponents.Header
					isDisabled={headerDisabled}
					isActive={isActive}
					activeBackgroundColor={activeBackgroundColor}
					isOpen={!isClosed}
					onClick={this.handleHeaderClick.bind(this)}>
					{header}
					<DropdownCaret
						css={{
							margin: '0 0 0 auto',
							left: '4px',
							position: 'relative',
						}}
						isActive={!isClosed}
					/>
				</AccordionComponents.Header>

				<AccordionComponents.Body
					style={dropdownStyle}
					onTransitionEnd={this.handleTransitionEnd.bind(this)}>
					<AccordionComponents.Inner
						noPadding={noPadding}
						ref={(node: HTMLDivElement) => (this.inner = node)}>
						{children}
					</AccordionComponents.Inner>
				</AccordionComponents.Body>
			</AccordionComponents.Item>
		)
	}
}
