import styled from 'styled-components'

import { Masonry as MasonryComponent } from '@app/components'
import { MediaResource } from '@compass/types'
import { spacing } from '@styleguide'

class MasonryClass extends MasonryComponent<MediaResource> {}

export const Masonry = styled(MasonryClass)({
	paddingTop: spacing.lg,
})
