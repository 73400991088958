import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { MasonryGridSizer, SvgIcon } from '@app/components'
import { ArticleResource } from '@compass/types'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'

interface ArticleProps {
	item: ArticleResource
	onClick?: (url: string) => void
}

interface ArticleState {
	isLoaded: boolean
}

const ArticleWrapper = styled(MasonryGridSizer).attrs({ className: 'c-article-wrapper grid-item' })(
	{
		position: 'relative',
		display: 'block',
		float: 'left',
		listStyle: 'none',
		borderRadius: '8px',
		overflow: 'hidden',
		marginBottom: spacing.sm,
		transition: transition.config,
		transitionProperty: 'transform, z-index',
		transformOrigin: '50% 50%',

		['&:hover']: {
			transform: 'scale(1.02)',
			zIndex: 10,
		},
	},
)

const ArticleButton = styled.a<{ isLoaded: boolean }>(({ isLoaded }) => ({
	position: 'relative',
	display: 'block',
	cursor: 'pointer',
	width: '100%',
	minHeight: isLoaded ? 0 : 250,

	['&:before']: {
		zIndex: -1,
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		boxShadow: shadows[3],
		opacity: 0,
		transition: transition.config,
		transitionProperty: 'opacity',
		content: '""',
		display: 'block',
	},

	['&:hover']: {
		['&:before']: {
			opacity: 1,
		},
	},
}))

const ArticleImage = styled.img({
	position: 'relative',
	display: 'block',
	width: '100%',
	height: 'auto',
	minHeight: 200,
	maxHeight: 350,
	objectFit: 'cover',
	overflow: 'hidden',
	zIndex: 1,
})

const ArticleTitle = styled.h3({
	fontSize: '1.8rem',
	marginBottom: 5,
})

const ArticleDate = styled.time({
	display: 'block',
})

const ArticleContent = styled.div({
	padding: spacing.sm,
	position: 'absolute',
	display: 'flex',
	alignItems: 'flex-end',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	zIndex: 3,
	fontSize: '1.4rem',
	color: colors.WHITE,

	['&:before']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		content: '""',
		display: 'block',
		zIndex: -1,
		...mixins.clothoidGradient(0, 0.6, '0, 0, 0'),
	},
})

const ArticleIconCircle = styled.span({
	position: 'relative',
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: 10,
})

const ArticleIcon = styled(SvgIcon)({
	width: 24,
	height: 24,
})

export const Article: React.SFC<ArticleProps> = props => (
	<ArticleWrapper>
		<ArticleComponent {...props} />
	</ArticleWrapper>
)

export class ArticleComponent extends React.Component<ArticleProps, ArticleState> {
	public state: ArticleState = {
		isLoaded: false,
	}

	private setLoaded = () => {
		this.setState({ isLoaded: true })
	}

	public render() {
		const {
			item: { article },
			onClick,
		} = this.props
		const { isLoaded } = this.state

		return (
			<ArticleButton
				href={article.url}
				isLoaded={isLoaded}
				onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
					if (onClick) {
						onClick(article.url)
						e.preventDefault()
					}
				}}
				target="_blank"
				rel="noopener noreferrer">
				<ArticleImage src={article.image} onLoad={this.setLoaded} />
				<ArticleContent>
					<div>
						<ArticleIconCircle className="u-theme-background">
							<ArticleIcon icon="blog" />
						</ArticleIconCircle>
						<ArticleTitle dangerouslySetInnerHTML={{ __html: article.title }} />
						{article.source.date && (
							<ArticleDate>
								{moment(article.source.date).format('dddd D MMMM YYYY [at] HH:mm')}
							</ArticleDate>
						)}
					</div>
				</ArticleContent>
			</ArticleButton>
		)
	}
}
