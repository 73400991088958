import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagesports: SvgIconMap = {
	size: {
		width: 89.576,
		height: 89.581,
	},
	Icon: () => (
		<>
			<linearGradient
				x1="73.6761409%"
				y1="13.1612621%"
				x2="9.92924146%"
				y2="94.2206836%"
				id="SVGID_1_sports">
				<stop stopColor="#97C66D" offset="0%" />
				<stop stopColor="#98D86C" offset="100%" />
			</linearGradient>
			<polygon fill="url(#SVGID_1_sports)" points="58.65,0 0.004,89.58 84.238,5.34 	" />
			<polygon fill="#98D86C" points="89.58,30.93 0,89.575 84.24,5.342 	" />
		</>
	),
}
