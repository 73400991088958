import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { colors, mixins, spacing, themeColors, transition } from '@styleguide'

export const Item = styled.li({
	position: 'relative',
	padding: '3px 0 5px',
	listStyle: 'none',

	['&:last-child > span']: {
		display: 'none',
	},
})

export const TitleLink = styled(NavLink)({
	color: colors.PRIMARY,
})

export const Top = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export const Link = styled(({ active, isSmall, ...rest }) => <NavLink {...rest} />)(
	({ active = false, isSmall = false }) => ({
		color: colors.GREY_40,
		fontSize: isSmall ? '1.2rem' : '1.5rem',
		padding: '5px 0',
		transition: transition.config,
		transitionProperty: 'color',

		['> span']: {
			marginRight: 10,
		},

		...mixins.primaryFont(),

		...(active
			? {
					color: colors.PRIMARY,
			  }
			: {
					['&:hover']: {
						color: colors.PRIMARY,
					},
			  }),
	}),
)

export const Subnavigation = styled.ul<{ isActive: boolean }>(({ isActive }) => ({
	width: '100%',
	listStyle: 'none',
	paddingLeft: 10,
	overflow: 'hidden',
	maxHeight: isActive ? 1000 : 0,
	transition: transition.config,
	transitionProperty: 'max-height',
}))
export const SubItem = styled.li({})

interface WrapperProps {
	withMediaOffset: boolean
	withIframeOffset: boolean
	withImageOffset: boolean
}

export const Wrapper = styled.nav<WrapperProps>(
	({ withMediaOffset, withIframeOffset, withImageOffset }) => ({
		position: 'relative',
		margin: spacing.xs,
		width: '100%',
		order: 1,

		...mixins.mediaStyles({
			sm: {
				margin: `0 ${spacing.md}px`,
			},
			md: {
				position: 'absolute',
				width: '20%',
				top: withIframeOffset
					? 'calc(100vh - 140px)'
					: withImageOffset
					? 'calc(30vh + 110px)'
					: withMediaOffset
					? 180
					: 110,
				left: spacing.md,
				padding: spacing.sm,
				margin: -spacing.sm,
				marginTop: -spacing.xs,
				background: colors.WHITE,
				zIndex: 1,
			},
			lg: {
				width: '25%',
			},
			xl: {
				width: '18%',
				left: spacing.lg,
				top: withIframeOffset
					? 'calc(100vh - 80px)'
					: withImageOffset
					? 'calc(30vh + 200px)'
					: withMediaOffset
					? 180
					: 135,
			},
		}),
	}),
)

export const Title = styled.h4({
	color: colors.GREY_50,
})

export const Border = styled.span({
	position: 'absolute',
	bottom: 0,
	left: 0,
	height: 1,
	width: '100%',
	backgroundColor: themeColors.default.light,
})
