import styled from 'styled-components'

import { transition } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
	overflow: 'hidden',
	transition: transition.config,
	transitionProperty: 'height',
})

export const WrapperInner = styled.div({
	position: 'relative',
	overflow: 'visible',
	transition: transition.config,
	transitionProperty: 'transform',
	whiteSpace: 'nowrap',
})
