import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagetransport: SvgIconMap = {
	size: {
		width: 238.004,
		height: 88.76,
	},
	Icon: () => (
		<>
			<path
				fill="#2B4C78"
				d="M238.004,25.33L212.678,0.003L0,44.381l230.11,0.006C230.11,36.946,233.126,30.208,238.004,25.33z"
			/>
			<linearGradient
				x1="0.00336391437%"
				y1="49.9746013%"
				x2="99.9970438%"
				y2="49.9746013%"
				id="SVGID_1_transport">
				<stop stopColor="#2B4C78" offset="0%" />
				<stop stopColor="#264560" offset="99.84%" />
			</linearGradient>
			<path
				fill="url(#SVGID_1_transport)"
				d="M230.11,44.388L230.11,44.388L0,44.381l212.679,44.383l25.326-25.321
	C233.127,58.565,230.11,51.83,230.11,44.388z"
			/>
		</>
	),
}
