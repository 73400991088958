import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagecommunity: SvgIconMap = {
	size: {
		width: 89.576,
		height: 89.581,
	},
	Icon: ({}) => (
		<>
			<linearGradient
				x1="32.6032408%"
				y1="79.0360707%"
				x2="79.7006888%"
				y2="19.0271406%"
				id="SVGID_1_community">
				<stop stopColor="#DB4612" offset="0%" />
				<stop stopColor="#D06929" offset="100%" />
			</linearGradient>
			<polygon fill="url(#SVGID_1_community)" points="30.931,89.581 89.576,0 5.342,84.241 	" />
			<polygon fill="#E84E10" points="0,58.65 89.581,0.004 5.34,84.238 	" />
		</>
	),
}
