import styled from 'styled-components'

import { transition } from '@styleguide'

export const ScrollWrapper = styled.div({
	width: '100%',
	overflow: 'hidden',
})

export const Wrapper = styled.div({
	position: 'relative',
	width: '200%',
	display: 'flex',
	transition: transition.config,
	transitionProperty: 'transform',

	['& > *']: {
		width: '50%',
	},
})
