import styled from 'styled-components'

import { colors, mixins } from '@styleguide'

export const Title = styled.h1({
	position: 'absolute',
	top: 20,
	left: 45,
	margin: 0,
	color: colors.GREY_60,
	fontSize: '3.2rem',

	['& span']: {
		fontSize: '1.8rem',
		display: 'block',
		marginTop: 5,
	},

	[mixins.media.max('md')]: {
		display: 'none',
	},
})
