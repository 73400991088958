import styled, { CSSObject } from 'styled-components'

import { mixins, spacing, transition } from '@styleguide'

export const Wrapper = styled.div.attrs({
	className: 'image-flip-card',
})({
	position: 'relative',
	width: '100%',
	height: 0,
	paddingBottom: '100%',
	perspective: '1000px',

	[mixins.media.max('md')]: {
		maxWidth: 320,
		maxHeight: 320,
		margin: `0 auto ${spacing.sm}px`,
		height: 'auto',
		paddingBottom: 0,
	},
})

export interface SideProps {
	front?: boolean
	back?: boolean
}

export const Side = styled.div<SideProps>(({ front = false, back = false }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backfaceVisibility: 'hidden',
	...(front && {
		zIndex: 2,
		transform: 'rotateY(0deg)',
	}),
	...(back && {
		transform: 'rotateY(180deg)',
	}),
}))

export const Placeholder = styled.img({
	visibility: 'hidden',
	width: '100%',
	height: 'auto',
})

export const Image = styled.figure({
	position: 'relative',
	display: 'block',
	width: '100%',
	height: '100%',
	backgroundSize: 'contain',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
})

export interface ContainerProps {
	isRotated: boolean
}

const rotatedStyles: CSSObject = {
	transform: 'rotateY(180deg)',
}

export const Container = styled.div<ContainerProps>(({ isRotated = false }) => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	padding: `0 ${spacing.sm}px 0 0`,
	transition: transition.config,
	transitionProperty: 'transform',
	transformStyle: 'preserve-3d',

	...(isRotated
		? {
				['.image-flip-card:not(:hover) &']: rotatedStyles,
		  }
		: {
				['.image-flip-card:hover &']: rotatedStyles,
		  }),
}))
