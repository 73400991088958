import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagehealth: SvgIconMap = {
	size: {
		width: 88.765,
		height: 238.006,
	},
	Icon: () => (
		<>
			<linearGradient
				x1="49.9876033%"
				y1="5.88896819%"
				x2="49.9876033%"
				y2="78.5028548%"
				id="SVGID_1_health">
				<stop stopColor="#EA992F" offset="0%" />
				<stop stopColor="#FFAD29" offset="100%" />
			</linearGradient>
			<path
				fill="url(#SVGID_1_health)"
				d="M44.384,7.896c-7.442,0.001-14.18-3.017-19.057-7.892L-0.001,25.324l44.387,212.682V7.897
		C44.384,7.896,44.384,7.896,44.384,7.896z"
			/>
			<path
				fill="#FFAD29"
				d="M44.386,7.897v230.108L88.765,25.324L63.439,0.002C58.563,4.879,51.828,7.896,44.386,7.897z"
			/>
		</>
	),
}
