import React from 'react'
import * as ReactDOM from 'react-dom'

import * as SliderComponents from './components'

interface SliderProps {
	activeSlide: number
	children?: any
}

interface SliderState {
	element: Element | Text | null
	height: number | undefined
}

export class Slider extends React.Component<SliderProps, SliderState> {
	public static Slide = SliderComponents.Slide
	public static Wrapper = SliderComponents.Wrapper
	public static WrapperInner = SliderComponents.WrapperInner

	public state: SliderState = {
		element: null,
		height: undefined,
	}

	private timeout: any

	public componentWillReceiveProps(nextProps: SliderProps) {
		if (nextProps.activeSlide !== this.props.activeSlide) {
			this.setHeight(nextProps.activeSlide)
		}
	}

	public componentDidMount() {
		const element = ReactDOM.findDOMNode(this)

		this.setState({ element })

		element!.addEventListener('transitionend', this.clearHeightAfterTransition)
	}

	private setHeight(nextActiveSlide: number) {
		if (this.props.activeSlide === nextActiveSlide) {
			return
		}

		const currentHeight = this.getHeightForSlide(this.props.activeSlide)
		const nextHeight = this.getHeightForSlide(nextActiveSlide)

		this.setState({ height: currentHeight }, () => {
			// @ts-ignore
			if (this.state.element.offsetHeight) {
				this.setState({ height: nextHeight })
			}
		})
	}

	private getHeightForSlide = (activeSlide: number) => {
		const node =
			this.state.element &&
			this.state.element.firstChild &&
			this.state.element.firstChild.childNodes[activeSlide] &&
			this.state.element.firstChild.childNodes[activeSlide].firstChild
				? this.state.element &&
				  this.state.element.firstChild &&
				  this.state.element.firstChild.childNodes[activeSlide] &&
				  this.state.element.firstChild.childNodes[activeSlide].firstChild
				: null

		// @ts-ignore
		return node.offsetHeight || undefined
	}

	private clearHeightAfterTransition = () => {
		clearTimeout(this.timeout)
		this.timeout = setTimeout(() => this.setState({ height: undefined }), 700)
	}

	public render() {
		const { activeSlide, children } = this.props
		const { height } = this.state
		const translate = -100 * activeSlide

		return (
			<Slider.Wrapper style={height ? { height: `${height}px` } : undefined}>
				<Slider.WrapperInner style={{ transform: `translateX(${translate}%)` }}>
					{React.Children.map(children, (child: any, index: number) =>
						React.cloneElement(child, {
							isActive: index === activeSlide,
						}),
					)}
				</Slider.WrapperInner>
			</Slider.Wrapper>
		)
	}
}
