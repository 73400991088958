import React from 'react'
import styled from 'styled-components'

import { TextInput } from '@app/components/form-elements'
import { Resource } from '@compass/types'
import { colors, mixins, spacing } from '@styleguide'

import { BlockProps, doesPageOrSubpagesContainQuery, queryMatchesPage } from './helpers'
import * as BlockComponents from './section-overview-block-components'

export const Title = styled.h2({
	color: colors.PRIMARY,
})

export const Wrapper = styled.div({
	position: 'relative',
	margin: `${spacing.md}px 0`,

	[mixins.media.max('sm')]: {
		display: 'none',
	},
})

export interface SearchProps {
	query: string
	onQuery: any
	section: Resource
}

const SearchInput = styled.div({
	marginTop: spacing.sm,
	...mixins.mediaStyles({
		sm: {
			maxWidth: '50%',
		},
	}),
})

export const Search: React.SFC<SearchProps> = ({ query, onQuery, section }) => {
	return (
		<SearchInput>
			<TextInput
				type="search"
				value={query}
				onChange={onQuery}
				placeholder={`Search for a topic inside the '${section.title}' section...`}
			/>
		</SearchInput>
	)
}

export const Blocks = styled.div({
	marginTop: spacing.sm,
	marginLeft: -spacing.xs / 2,
	marginRight: -spacing.xs / 2,

	...mixins.mediaStyles({
		sm: {
			display: 'flex',
			flexWrap: 'wrap',
		},
	}),
})

export const Block: React.SFC<BlockProps> = ({ page, query }) => {
	const topPageIsResult = queryMatchesPage(page.title, query)
	return doesPageOrSubpagesContainQuery(page, query) ? (
		<BlockComponents.Wrapper>
			<BlockComponents.Inner>
				<BlockComponents.Background className="u-theme-background" />
				<BlockComponents.Page to={page.slug}>{page.title}</BlockComponents.Page>
				{page.resources &&
					page.resources.map((subPage: Resource) => (
						<BlockComponents.Link
							parentAllowsAll={topPageIsResult}
							query={query}
							page={subPage}
							key={subPage.id}
						/>
					))}
			</BlockComponents.Inner>
		</BlockComponents.Wrapper>
	) : null
}
