import React from 'react'
import styled from 'styled-components'

import { colors, transition } from '@styleguide'

import { SvgIcon } from '../svg-icons'

interface ArrowProps {
	isActive: boolean
	onClick?: () => void
}

const Arrow = styled.div<ArrowProps>(({ isActive }) => ({
	transition: transition.config,
	transform: isActive ? 'rotate(180deg)' : 'rotate(90deg)',
	width: 24,
	height: 24,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: 8,
	color: isActive ? colors.GREY_40 : colors.GREY_30,
	cursor: 'pointer',
	borderRadius: 4,

	['&:hover']: {
		background: colors.GREY_10,
		color: colors.GREY_40,
	},
}))

const ArrowIcon = styled(SvgIcon)({
	width: 10,
	height: 10,
})

export const DropdownCaret: React.SFC<ArrowProps> = props => (
	<Arrow {...props}>
		<ArrowIcon icon="caret" />
	</Arrow>
)
