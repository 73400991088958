import React from 'react'
import { CSSObject } from 'styled-components'

import { InputComponents, TextInputComponents } from './components'
import { InputProps } from './components/text-input-components'

export interface TextInputProps {
	labelText?: React.ReactType | JSX.Element | string
	subText?: React.ReactType | JSX.Element | string
	errorText?: React.ReactType | JSX.Element | string
	hasError?: boolean
	textarea?: boolean
	css?: CSSObject
}

export class TextInput extends React.Component<TextInputProps & React.HTMLProps<HTMLInputElement>> {
	public static Wrapper = InputComponents.Wrapper
	public static Label = InputComponents.LabelText
	public static SubText = TextInputComponents.SubText
	public static InputWrapper = TextInputComponents.InputWrapper
	public static Input = TextInputComponents.Input
	public static Textarea = TextInputComponents.Input.withComponent('textarea')
	public static ErrorLabel = InputComponents.ErrorLabel
	public static SearchIcon = TextInputComponents.SearchIcon

	public input: HTMLInputElement

	public render() {
		const {
			labelText,
			errorText,
			hasError,
			ref: _ref,
			textarea,
			subText,
			type = 'text',
			as: _as,
			...rest
		} = this.props
		const { name } = rest

		const InputComponent = (textarea ? TextInput.Textarea : TextInput.Input) as React.SFC<
			InputProps
		>

		return (
			<TextInput.Wrapper>
				{labelText && <TextInput.Label htmlFor={name}>{labelText}</TextInput.Label>}
				{subText && <TextInput.SubText>{subText}</TextInput.SubText>}
				<TextInput.InputWrapper>
					<InputComponent
						id={name}
						textarea={textarea}
						hasError={hasError}
						type={type}
						{...rest}
					/>
					{type === 'search' && <TextInput.SearchIcon />}
				</TextInput.InputWrapper>
				{hasError && errorText && <TextInput.ErrorLabel>{errorText}</TextInput.ErrorLabel>}
			</TextInput.Wrapper>
		)
	}
}
