import React from 'react'

import { SvgIconMap } from '../types'

export const frontpageblog: SvgIconMap = {
	size: {
		width: 89.576,
		height: 89.581,
	},
	Icon: () => (
		<>
			<polygon fill="#CE0280" points="-0.002,30.93 89.578,89.576 5.338,5.342 	" />

			<linearGradient
				x1="23.7166318%"
				y1="9.69732002%"
				x2="87.4577022%"
				y2="94.6705202%"
				id="SVGID_1_media">
				<stop stopColor="#B7027B" offset="0%" />
				<stop stopColor="#CE0280" offset="100%" />
			</linearGradient>
			<polygon fill="url(#SVGID_1_media)" points="30.928,0 89.574,89.58 5.34,5.34 	" />
		</>
	),
}
