import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const play: SvgIconMap = {
	size: {
		width: 1000,
		height: 1000,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M125.3 964.5C125.3 964.5 125.3 1003.3 157.8 982L860.2 521C860.2 521 892.7 499.7 860.2 478.4L157.8 17.5C157.8 17.5 125.4-3.9 125.4 35V964.5ZM239 775.5C239 775.5 239 794.9 255.2 784.2L670.2 510.4C670.2 510.4 686.4 499.7 670.2 489L255.2 215.2C255.2 215.2 239 204.5 239 223.9L239 775.5Z"
		/>
	),
}
