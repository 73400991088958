import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const check: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<path fill={fill} d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
	),
}
