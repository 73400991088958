import { EventResource } from '@compass/types'
import moment from 'moment'

export const getEventsForDate = (calendar: EventResource[] = [], date: moment.Moment) => {
	return calendar.filter(
		(event: EventResource) =>
			!date ||
			moment(event.event.date.start).format('DD-MM-YYYY') === date.format('DD-MM-YYYY'),
	)
}
