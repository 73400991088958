import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

export const Container = styled.div<{
	backgroundColor?: keyof typeof colors
}>(({ backgroundColor }) => ({
	display: 'flex',
	flexDirection: 'column',

	backgroundColor: backgroundColor ? colors[backgroundColor] : 'transparent',
	padding: `0 ${spacing.xs}px`,

	...mixins.mediaStyles({
		sm: {
			padding: `0 ${spacing.sm}px`,
		},
	}),
}))
