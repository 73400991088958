import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { PlusMinus } from '@app/components/plus-minus'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'

export const Wrapper = styled.aside({
	position: 'relative',
	height: '50px',
	width: '100%',
	overflow: 'visible',
	margin: `${spacing.xs}px 0 ${spacing.sm}px`,
	...mixins.mediaStyles({
		sm: {
			margin: `${spacing.sm}px 0 ${spacing.md}px`,
		},
	}),
})

export interface ListProps extends React.HTMLProps<HTMLDivElement> {
	isOpen: boolean
	ref?: any
}

const ListGlam = styled.div<ListProps>(({ isOpen = false }) => ({
	position: 'relative',
	boxShadow: shadows[5],
	background: colors.WHITE,

	...(isOpen
		? {
				zIndex: 2,
				opacity: 1,
				transition: `opacity ${transition.config}`,
		  }
		: {
				zIndex: 0,
				opacity: 0,
				transition: `opacity ${transition.config}, z-index 0s ${transition.duration}`,
		  }),
}))

const ListInnerGlam = styled.div({
	padding: `${spacing.xs}px ${spacing.sm}px`,
	...mixins.mediaStyles({
		sm: {
			padding: `${spacing.sm}px ${spacing.md}px`,
		},
	}),
})

export const List: React.SFC<ListProps> = ({ as: _as, ...props }) => (
	<ListGlam {...props}>
		<ListInnerGlam>{props.children}</ListInnerGlam>
	</ListGlam>
)

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
	isOpen: boolean
	ref?: any
	css?: CSSObject
}

export const ButtonGlam = styled.button<ButtonProps>(({ isOpen = false }) => ({
	position: 'relative',
	display: 'block',
	width: '100%',
	marginTop: '10px',
	textAlign: 'left',
	padding: `5px ${spacing.sm}px`,
	boxShadow: shadows[3],
	transition: transition.config,
	transitionProperty: 'background',

	...(isOpen
		? {
				background: colors.GREY_20,
		  }
		: {
				background: colors.WHITE,

				['&:hover']: {
					background: colors.GREY_10,
				},
		  }),
}))

const ButtonIconGlam = styled.div({
	position: 'absolute',
	top: '50%',
	right: spacing.xs,
	transform: 'translateY(-50%)',
})

export const Button: React.SFC<ButtonProps> = ({ isOpen = false, as: _as, ...rest }) => (
	<ButtonGlam isOpen={isOpen} {...rest}>
		<h4>{isOpen ? 'Close' : 'Open'} filters:</h4>
		<ButtonIconGlam>
			<PlusMinus isMinus={isOpen} />
		</ButtonIconGlam>
	</ButtonGlam>
)
