import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Resource } from '@compass/types'
import { colors, mixins, spacing } from '@styleguide'

export interface ItemProps {
	resource: Resource
	index: number
	isSmall?: boolean
}

export const ItemGlam = styled.li<{ isSmall: boolean }>(({ isSmall }) => ({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'nowrap',
	position: 'relative',
	marginRight: isSmall ? 5 : spacing.xs,

	['&:not(:last-child)']: {
		['&::after']: {
			color: colors.BLACK,
			content: '"⤑"',
			marginLeft: isSmall ? 5 : spacing.xs,
		},
	},

	... isSmall ? {
		['& a']: {
			textDecoration: 'underline',
		},
	} : {
		['&:not(:last-child)']: {
			['& a']: mixins.linkUnderline(),
		},

		['&:last-child']: {
			['& a']: {
				cursor: 'default',
				color: 'currentColor',
			},
		},
	},
}))

export const LinkGlam = styled(Link)<{ isSmall: boolean }>(({ isSmall }) => ({
	color: colors.BLACK,
	whiteSpace: 'nowrap',
	fontSize: isSmall ? '1.2rem' : '1.3rem',
	... isSmall ? { lineHeight: 1.3 } : {},
}))

export const Item: React.SFC<ItemProps> = ({ resource, index, isSmall = false }) => (
	<ItemGlam itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem" isSmall={isSmall}>
		<LinkGlam itemProp="item" to={resource.slug} isSmall={isSmall}>
			<span itemProp="name">{resource.title}</span>
		</LinkGlam>
		<meta itemProp="position" content={index.toString()} />
	</ItemGlam>
)

export const List = styled.ul({
	listStyle: 'none',
	display: 'flex',
	flexWrap: 'wrap',
})

export const Wrapper = styled.nav({
	position: 'relative',
	overflow: 'auto',
	WebkitOverflowScrolling: 'touch',
})
