export enum colors {
	WHITE = '#FFFFFF',
	BLACK = '#000000',
	PRIMARY = '#ed1c24',
	PRIMARY_LIGHT = '#ed8b8f',
	GREY_10 = '#F5F5F5',
	GREY_20 = '#EBEFF1',
	GREY_30 = '#DADBDC',
	GREY_40 = '#97A2A8',
	GREY_50 = '#717D84',
	GREY_60 = '#323232',

	INFO_LIGHT = '#21A5A1',
	INFO = '#4c868c',
	INFO_DARK = '#709ea3',

	LINKTREE = '#06a34b',
	LINKTREE_LIGHT = '#04dc74',
	FACEBOOK = '#557093',
	FACEBOOK_LIGHT = '#2b4c78',
	LINKEDIN = '#0072b1',
	LINKEDIN_LIGHT = '#34b7ff',
	TWITTER = '#9dc6e8',
	TWITTER_LIGHT = '#5ba0d9',
	INSTAGRAM = '#eb99cc',
	INSTAGRAM_LIGHT = '#cc007f',
	YOUTUBE = '#e97685',
	YOUTUBE_LIGHT = '#da1b34',
	TIKTOK = '#aaaaaa',
	TIKTOK_LIGHT = '#010101',
}

export interface ThemeConfig {
	light: string
	dark: string
}

export interface ThemeColors {
	[key: string]: ThemeConfig
}

export const themeColors = {
	default: {
		light: colors.PRIMARY,
		dark: colors.PRIMARY,
	},
	info: {
		light: colors.INFO,
		dark: colors.INFO_DARK,
	},
	finances: {
		light: '#29A07E',
		dark: '#2B9370',
	},
	health: {
		light: '#FFAD29',
		dark: '#EA992F',
	},
	housing: {
		light: '#00A2DB',
		dark: '#0299C1',
	},
	transport: {
		light: '#2B4C78',
		dark: '#264560',
	},
	sports: {
		light: '#98D86C',
		dark: '#97C66D',
	},
	community: {
		light: '#E84E10',
		dark: '#D84E17',
	},
	'city-map': {
		light: '#ED1B34',
		dark: '#D81A31',
	},
	media: {
		light: '#CE0280',
		dark: '#B7027B',
	},
	blog: {
		light: '#CE0280',
		dark: '#B7027B',
	},
	events: {
		light: '#CE0280',
		dark: '#B7027B',
	},
}

export interface ThemeStyles {
	[key: string]: {
		[key: string]: string
	}
}

export const themeStyles: ThemeStyles = Object.keys(themeColors).reduce(
	(styles, theme: keyof typeof themeColors) => ({
		...styles,
		[theme]: {
			color: themeColors[theme].light,
			background: `linear-gradient(135deg, ${themeColors[theme].light}, ${
				themeColors[theme].dark
			})`,
			backgroundSimple: themeColors[theme].light,
		},
	}),
	{},
)
