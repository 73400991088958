import React from 'react'
import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

export const BoxWrapper = styled.div({
	position: 'relative',
	border: `1px ${colors.GREY_20} solid`,
	padding: spacing.xs,
	margin: `${spacing.sm}px 0 ${spacing.xs}px`,
	...mixins.mediaStyles({
		md: {
			margin: `${spacing.md}px 0 ${spacing.sm}px`,
			padding: `${spacing.md}px ${spacing.sm}px ${spacing.sm}px`,
		},
	}),
})

export const BoxLabel = styled.span({
	position: 'absolute',
	top: 0,
	transform: 'translateY(-50%)',
	left: spacing.xs,
	background: colors.WHITE,
	padding: 5,
	fontSize: '1.2rem',
	color: colors.GREY_40,
	...mixins.primaryFont(),
})

interface BoxProps {
	title: string
}

export const Box: React.SFC<BoxProps> = ({ title, children }) => (
	<BoxWrapper>
		<BoxLabel>{title}</BoxLabel>
		{children}
	</BoxWrapper>
)
