import React from 'react'

import { ContactDetails } from '@app/components/contact-details'
import { MemberResource } from '@compass/types'

import { Grid } from '@app/components/grid'
import * as MemberComponents from './member-components'

export interface MemberProps {
	member: MemberResource
}

export const Member: React.SFC<MemberProps> = ({ member }) => (
	<MemberComponents.Wrapper>
		<Grid.Row css={{ width: '100%' }}>
			<Grid.Column xs={16} md={4}>
				<MemberComponents.ImageWrapper className="u-theme-text">
					{member.image && (
						<MemberComponents.Image
							style={{ backgroundImage: `url(${member.image})` }}
						/>
					)}
				</MemberComponents.ImageWrapper>
			</Grid.Column>
			<Grid.Column xs={16} md={12}>
				<MemberComponents.Info>
					<h2 className="u-theme-text">{member.title}</h2>
					{member.content && (
						<MemberComponents.RichText
							dangerouslySetInnerHTML={{ __html: member.content }}
						/>
					)}
					<ContactDetails {...member.member} />
				</MemberComponents.Info>
			</Grid.Column>
		</Grid.Row>
	</MemberComponents.Wrapper>
)
