import React from 'react'
import styled from 'styled-components'

import { colors, mixins } from '@styleguide'

interface ListResultCountProps {
	page: number
	amountOfItems: number
}

const P = styled.p({
	fontSize: '1.4rem',
	color: colors.GREY_50,

	...mixins.mediaStyles({
		md: {
			width: 0,
			whiteSpace: 'nowrap',
		},
	}),
})

export const ResultCount: React.SFC<ListResultCountProps> = ({ page = 1, amountOfItems = 0 }) => {
	const start = Math.min(amountOfItems, (page - 1) * 20 + 1)
	const end = Math.min(amountOfItems, page * 20)
	return (
		<P>
			Showing {start} - {end} of {amountOfItems} results.
		</P>
	)
}
