import moment from 'moment'

import { Event, EventResource } from '@compass/types'
import { EventsFiltersState, Timeslots } from './types'

export const getEventsToday = (events: EventResource[] = []): EventResource[] => {
	return events.filter((event: EventResource) => {
		return isEventToday(event)
	})
}

export const getEventsThisWeek = (events: EventResource[] = []): EventResource[] => {
	return events.filter((event: EventResource) => {
		return !isEventToday(event)
	})
}

const isEventToday = (event: EventResource) => {
	const { date } = event.event
	const TODAY = new Date()
	const DATE = new Date(date.start)
	return isSameDate(TODAY, DATE)
}

const isSameDate = (a: Date, b: Date) => {
	return (
		a.getFullYear() === b.getFullYear() &&
		a.getMonth() === b.getMonth() &&
		a.getDate() === b.getDate()
	)
}

export const getTimeslotByDateTime = (date: Event['date']): Timeslots => {
	const hoursOfStartDate = parseInt(moment(date.start).format('HH'), 0)
	const diff = moment(date.end).diff(date.start, 'hours')

	if (diff > 6) {
		return Timeslots.ALL
	} else if (hoursOfStartDate >= 5 && hoursOfStartDate < 12) {
		return Timeslots.IN_THE_MORNING
	} else if (hoursOfStartDate >= 12 && hoursOfStartDate < 18) {
		return Timeslots.IN_THE_AFTERNOON
	} else if (hoursOfStartDate >= 18 && hoursOfStartDate <= 23) {
		return Timeslots.IN_THE_EVENING
	} else {
		return Timeslots.IN_THE_NIGHT
	}
}

export const getMappedEvents = (events: EventResource[] = [], filters: EventsFiltersState) => {
	return events
		.map((event: EventResource) => ({
			...event,
			event: {
				...event.event,
				timeslot: getTimeslotByDateTime(event.event.date),
			},
		}))
		.filter(
			event =>
				!filters.selectedTimeslots ||
				!filters.selectedTimeslots.length ||
				filters.selectedTimeslots.includes(event.event.timeslot),
		)
		.filter(
			event =>
				!filters.selectedCategories ||
				!filters.selectedCategories.length ||
				filters.selectedCategories.some(c =>
					event.event.categories.some(cat => cat.name === c),
				),
		)
		.filter(
			event =>
				!filters.selectedAssociations ||
				!filters.selectedAssociations.length ||
				(event.event.association &&
					filters.selectedAssociations.includes(event.event.association.name)),
		)
		.sort(
			(a, b) =>
				new Date(a.event.date.start).getTime() - new Date(b.event.date.start).getTime(),
		)
}
