import styled from 'styled-components'

import { spacing } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
	width: '100%',
	minHeight: '80vh',
	marginBottom: spacing.md,
})
