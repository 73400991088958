import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const tiktok: SvgIconMap = {
	size: {
		width: 512,
		height: 512,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M267.2,0.4C295.2,0,322.9,0.3,350.7,0c1.7,32.7,13.4,65.9,37.3,89c23.9,23.7,57.6,34.5,90.4,38.2v85.9c-30.8-1-61.7-7.4-89.6-20.7c-12.2-5.5-23.5-12.6-34.6-19.8c-0.1,62.3,0.3,124.6-0.4,186.7c-1.7,29.8-11.5,59.5-28.9,84.1c-27.9,40.9-76.3,67.6-126.1,68.4c-30.5,1.7-61-6.6-87-21.9C68.8,464.5,38.5,417.9,34.1,368c-0.5-10.7-0.7-21.3-0.3-31.7c3.8-40.6,23.9-79.4,55.1-105.8c35.3-30.8,84.8-45.4,131.2-36.8c0.4,31.6-0.8,63.2-0.8,94.8c-21.2-6.8-45.9-4.9-64.4,7.9c-13.5,8.8-23.8,22.2-29.1,37.3c-4.4,10.8-3.1,22.8-2.9,34.3c5.1,35,38.7,64.4,74.7,61.3c23.8-0.3,46.7-14.1,59.1-34.3c4-7.1,8.5-14.3,8.8-22.7c2.1-38.2,1.3-76.2,1.5-114.3C267,171.9,266.6,86.2,267.2,0.4L267.2,0.4z"
		/>
	),
}
