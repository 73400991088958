import React from 'react'
import styled from 'styled-components'

import { SvgIcon } from '@app/components'
import { InstagramPhoto } from '@compass/types'
import { colors, shadows, spacing, transition } from '@styleguide'

import { UserProfile } from './user-profile'

interface InstagramProps {
	item: InstagramPhoto
}

const InstagramWrapper = styled.li.attrs({ className: 'c-instagram-wrapper' })({
	position: 'relative',
	display: 'block',
	float: 'left',
	listStyle: 'none',
	width: 250,
	height: 250,
	marginRight: spacing.md,
	marginBottom: spacing.lg,
	transition: transition.config,
	transitionProperty: 'transform',
	transformOrigin: '50% 50%',

	['&:before']: {
		zIndex: -1,
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		background: colors.WHITE,
		boxShadow: shadows[3],
		opacity: 0,
		transition: transition.config,
		transitionProperty: 'opacity',
		content: '""',
		display: 'block',
	},

	['&:hover']: {
		transform: 'scale(1.15)',

		['&:before']: {
			opacity: 1,
		},
	},
})
const InstagramImage = styled.img({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
})

const InstagramCaption = styled.div({
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	padding: spacing.xs,
	top: 0,
	left: '100%',
	width: '100%',
	height: '100%',
	overflow: 'auto',
	WebkitOverflowScrolling: 'touch',
	background: colors.WHITE,
	transition: transition.config,
	transitionProperty: 'transform',

	['.c-instagram-wrapper:hover &']: {
		transform: 'translateX(-100%)',
	},
})

const InstagramUserProfile = styled(UserProfile)({
	position: 'absolute',
	width: '100%',
	top: 0,
	left: 0,
	padding: spacing.xs,
})

const InstagramIcon = styled(SvgIcon)({
	position: 'absolute',
	width: '70%',
	height: 'auto',
	left: '35%',
	top: '35%',
	opacity: 0.2,
})

const InstagramOverflow = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	overflow: 'hidden',
})

export const Instagram: React.SFC<InstagramProps> = ({ item }) => (
	<InstagramWrapper>
		<InstagramOverflow>
			<InstagramImage src={item.image} />
			<InstagramIcon icon="instagram" fill={colors.GREY_20} />
			<InstagramCaption>
				<InstagramUserProfile {...item.user} />
				<p>{item.caption}</p>
			</InstagramCaption>
		</InstagramOverflow>
	</InstagramWrapper>
)
