import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const eventsInTheMorning: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="Layer_1">
			<g>
				<g>
					<path
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M6.97,12.156
				c0-2.776,2.252-5.029,5.03-5.029c2.779,0,5.03,2.253,5.03,5.029"
					/>

					<line
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="4.23"
						y1="12.156"
						x2="1.042"
						y2="12.156"
					/>

					<line
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="12"
						y1="4.388"
						x2="12"
						y2="1.198"
					/>

					<line
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="19.77"
						y1="12.156"
						x2="22.958"
						y2="12.156"
					/>

					<line
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="17.494"
						y1="6.663"
						x2="19.749"
						y2="4.409"
					/>

					<line
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="6.507"
						y1="6.663"
						x2="4.252"
						y2="4.409"
					/>

					<line
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						x1="12"
						y1="14.801"
						x2="12"
						y2="22.802"
					/>
					<path
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M14.604,17.502"
					/>

					<polyline
						fill="none"
						stroke={fill}
						strokeWidth="1.6698"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						points="
				14.693,17.502 12,14.806 9.305,17.502 			"
					/>
				</g>
			</g>
		</g>
	),
}
