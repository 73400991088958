import React from 'react'
import styled from 'styled-components'

import { SvgComponentProps, SvgIcon } from '@app/components/svg-icons'

const Icon = styled(SvgIcon)({
	display: 'inline-flex',
	width: 16,
	height: 'auto',
	marginRight: 5,
})

export const ButtonIcon: React.SFC<SvgComponentProps> = props => <Icon {...props} />
