import React from 'react'

import { Grid, HelmetData, InView, Page, StaticContentSection } from '@app/components'
import { MediaFilter } from '@app/types'
import { CompassUtils } from '@app/utils'
import {
	ArticleResource,
	MediaResource,
	PageResource,
	TweetResource,
	YoutubeResource,
} from '@compass/types'

import { spacing } from '@app/config'
import * as MediaComponents from './components'

interface TemplatePageMediaProps {
	resource: PageResource
}

interface MediaState {
	filters: MediaFilter[]
	isModalOpen: boolean
	url: string | undefined
	article: ArticleResource | undefined
	media: MediaResource[]
	error?: any
	loading: boolean
	isLast: boolean
}

export class PageMedia extends React.Component<TemplatePageMediaProps, MediaState> {
	public state: MediaState = {
		// filters: ['twitter', 'article', 'youtube', 'blog'],
		filters: ['blog'],
		isModalOpen: false,
		url: undefined,
		article: undefined,
		media: [],
		loading: false,
		isLast: false,
	}

	private timeout: any

	public componentWillMount() {
		this.resetList()
	}

	public componentDidMount() {
		document.addEventListener('keyup', this.onDocumentKeyUp)

		const { filters } = this.state
		this.fetchMedia({ filters })
	}

	public componentWillUnmount() {
		document.removeEventListener('keyup', this.onDocumentKeyUp)
	}

	private async fetchMedia({
		last,
		filters,
	}: {
		last?: string | number
		filters: MediaFilter[]
	}) {
		this.setState({ loading: true })

		try {
			const data = await CompassUtils.media.fetchMedia({
				filters,
				last,
			})

			this.setState({
				...data,
				media: [...this.state.media, ...data.media],
			})
		} catch (error) {
			this.setState({ error })
		}

		this.setState({ loading: false })
	}

	private resetList() {
		this.setState({
			media: [],
			isLast: false,
		})
	}

	public refetch = () => {
		const { media = [], loading } = this.state
		const { filters } = this.state
		if (!loading) {
			this.fetchMedia({
				last: media[media.length - 1].id,
				filters,
			})
		}
	}

	// private updateFilters = ({ filters }: MediaState) => {
	// 	this.setState({ filters }, () => {
	// 		this.resetList()
	// 		this.fetchMedia({ filters })
	// 	})
	// }

	private openModal = (url: string) => {
		this.setState({
			isModalOpen: true,
			url,
		})
	}

	private showArticle = (article: ArticleResource) => {
		this.setState({
			isModalOpen: true,
			article,
		})

		window.history.replaceState({}, 'media-article', article.slug)
	}

	public closeModal = () => {
		this.setState(
			{
				isModalOpen: false,
			},
			() => {
				clearTimeout(this.timeout)
				this.timeout = setTimeout(() => {
					this.setState({
						url: undefined,
					})
				}, 300)
			},
		)
	}

	public onDocumentKeyUp = (event: KeyboardEvent) => {
		if (event.keyCode === 27) {
			this.closeModal()
		}
	}

	public render() {
		const { resource } = this.props
		const { media = [], loading, isLast } = this.state
		const { isModalOpen, url, article } = this.state

		return (
			<Page>
				<HelmetData title={resource.title} seo={resource.seo} />

				<Grid.Container>
					<Grid.Row>
						<Grid.LeftPaddedColumn xs={16} md={4} xl={3}>
							<h1 className="u-theme-text" style={{ marginTop: 30 }}>
								{resource.title}
							</h1>
							{/* <Form initialValues={{ filters }} onSubmit={this.updateFilters}>
								{() => (
									<>
										<AutoSubmit submit={this.updateFilters} />
										<Field
											name="filters"
											value="blog"
											type="checkbox"
											render={({ input }) => (
												<Toggle
													{...input}
													id="blog"
													labelText="blog posts"
												/>
											)}
										/>
										<Field
											name="filters"
											value="article"
											type="checkbox"
											render={({ input }) => (
												<Toggle
													{...input}
													id="article"
													labelText="articles"
												/>
											)}
										/>
										<Field
											name="filters"
											value="twitter"
											type="checkbox"
											render={({ input }) => (
												<Toggle
													{...input}
													id="twitter"
													labelText="tweets"
												/>
											)}
										/>
										<Field
											name="filters"
											value="youtube"
											type="checkbox"
											render={({ input }) => (
												<Toggle
													{...input}
													id="youtube"
													labelText="youtube videos"
												/>
											)}
										/>
									</>
								)}
							</Form>
							<Small css={{ marginTop: spacing.md }}>
								Want to submit your articles as a writer or organisation?
							</Small>
							<Button
								css={{ marginTop: 5 }}
								className="u-theme-background"
								buttonSize="small"
								a
								href={`mailto:${shellConfig.publicEmail}`}>
								Send us a mail
							</Button> */}
						</Grid.LeftPaddedColumn>
						<Grid.Column xs={16} md={12} xl={13}>
							<Grid.Row>
								<Grid.Column xs={16} xl={12}>
									<div css={{ marginTop: spacing.lg }}>
										<StaticContentSection resource={resource} />
									</div>
								</Grid.Column>
							</Grid.Row>

							<MediaComponents.Wrapper>
								<MediaComponents.Masonry
									items={media}
									itemToComponent={(item: MediaResource, index: number) => {
										switch (item.type) {
											case 'twitter':
												return (
													<MediaComponents.Tweet
														key={index}
														item={item as TweetResource}
													/>
												)
											// case 'instagram':
											// 	return <MediaComponents.Instagram key={index} item={item} />
											case 'youtube':
												return (
													<MediaComponents.Youtube
														key={index}
														item={item as YoutubeResource}
														onClick={this.openModal}
													/>
												)
											case 'article':
											case 'blog':
												const articleResource = item as ArticleResource
												return (
													<MediaComponents.Article
														key={index}
														item={articleResource}
														onClick={
															articleResource.article.isExternal
																? this.openModal
																: () =>
																		this.showArticle(
																			articleResource,
																		)
														}
													/>
												)

											default:
												return null
										}
									}}
								/>
								{!isLast && (
									<InView>
										{inViewProps => (
											<MediaComponents.FetchMoreTag
												{...inViewProps}
												onFetch={this.refetch}
											/>
										)}
									</InView>
								)}
								{loading && <MediaComponents.Loader />}
							</MediaComponents.Wrapper>
							<MediaComponents.MediaModal
								open={isModalOpen}
								article={article}
								url={url}
								onClose={this.closeModal}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid.Container>
			</Page>
		)
	}
}
