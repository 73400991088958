import React from 'react'
import styled from 'styled-components'

import { Small } from '@app/components/small'
import { shellConfig } from '@app/config'
import { mixins, spacing } from '@styleguide'

export const SponsorsWrapper = styled.div({
	order: 3,

	...mixins.mediaStyles({
		xl: {
			display: 'block',
			position: 'fixed',
			bottom: 25,
			left: 45,
		},
	}),

	[mixins.media.max('xl')]: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: spacing.md,
	},
})

export const SponsorsFlexBox = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',

	[mixins.media.max('xl')]: {
		justifyContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},

	[mixins.media.max('md')]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})

export const Sponsor = styled.a({
	display: 'block',
	padding: `5px 0`,

	['&:last-child']: {
		paddingBottom: 0,
	},

	['& img']: {
		width: '140px',
		height: 'auto',
	},

	...mixins.mediaStyles({
		md: {
			margin: `0 ${spacing.sm}px`,
		},
		xl: {
			margin: 0,
		},
	}),
})

export const SponsorText = styled.p({
	fontWeight: 'bold',
})

export const Sponsors: React.SFC = props =>
	!!shellConfig.sponsors.length ? (
		<SponsorsWrapper {...props}>
			<Small css={{ marginBottom: 5 }}>Official partner of:</Small>
			<SponsorsFlexBox>
				{shellConfig.sponsors.length > 0 &&
					shellConfig.sponsors.map(sponsor => (
						<Sponsor
							key={sponsor.url}
							href={sponsor.url}
							target="_blank"
							rel="noopener noreferrer">
							<img src={sponsor.logo} />
						</Sponsor>
					))}
			</SponsorsFlexBox>
		</SponsorsWrapper>
	) : null
