import React from 'react'
import styled from 'styled-components'

import { ThemeWrapper } from '@app/components/theme-wrapper'

interface PageProps {
	children: any
	minHeight?: string | null
}

const PageGlam = styled.div({
	position: 'relative',
	width: '100%',
})

export const Page: React.SFC<PageProps> = ({ children, minHeight = '60vh' }) => {
	return (
		<ThemeWrapper>
			<PageGlam css={minHeight ? { minHeight } : undefined}>{children}</PageGlam>
		</ThemeWrapper>
	)
}
