import styled from 'styled-components'

import { colors, transition } from '@styleguide'

import { LabelText as InputLabelText } from './form-input-components'

export const Wrapper = styled.div({
	position: 'relative',
})

export const Label = styled.label.attrs({ className: 'c-toggle__label' })({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	marginBottom: 5,
	color: colors.GREY_40,

	['input:disabled + &']: {
		cursor: 'initial',
	},
})

export const Toggle = styled.div({
	position: 'relative',
	display: 'flex',
	height: 12,
	width: 24,
	alignItems: 'center',
	marginRight: 10,
})

export const Bar = styled.span({
	position: 'relative',
	width: '100%',
	height: 2,
	borderRadius: 2,
	transition: transition.config,
	transitionProperty: 'background',

	['input:not(:checked) ~ .c-toggle__label &']: {
		background: `${colors.GREY_40} !important`,
	},
})

export const Circle = styled.span({
	position: 'absolute',
	zIndex: 1,
	top: 0,
	left: 0,
	transform: 'translateX(12px)',
	width: 12,
	height: 12,
	transition: transition.config,
	transitionProperty: 'transform, background',
	borderRadius: '50%',

	['input:not(:checked) ~ .c-toggle__label &']: {
		background: `${colors.GREY_40} !important`,
		transform: 'translateX(0)',
	},
})

export const LabelText = styled(InputLabelText).attrs({
	as: 'span',
})({
	fontSize: '1.4rem',
	lineHeight: 1.6,
	marginBottom: 0,
	cursor: 'pointer',
	textTransform: 'none',
})
