import React from 'react'
import styled from 'styled-components'

import { SocialMediaUserProfile } from '@compass/types'
import { colors, mixins, spacing } from '@styleguide'

const UserProfileWrapper = styled.a({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
})

const UserProfilePicture = styled.img({
	width: 48,
	height: 'auto',
	flexBasis: 48,
	flexShrink: 0,
	flexGrow: 0,
	marginRight: spacing.xs,
})

const UserProfileName = styled.span({
	display: 'block',
	...mixins.primaryFont(),
})

const UserProfileUsername = styled.span({
	display: 'block',
	color: colors.GREY_50,
	fontSize: '1.3rem',
	lineHeight: 1,
})

export const UserProfile: React.SFC<SocialMediaUserProfile> = ({
	name,
	picture,
	url,
	username,
	...rest
}) => (
	<UserProfileWrapper href={url} target="_blank" rel="noopener noreferrer" {...rest}>
		<UserProfilePicture src={picture} />
		<div>
			<UserProfileName className="u-theme-text">{name}</UserProfileName>
			<UserProfileUsername>@{username}</UserProfileUsername>
		</div>
	</UserProfileWrapper>
)
