import { MapCategoryResource, MarkerCategory, MarkerResource } from '@compass/types'

export const getMapPathFragments = (pathname: string) => {
	const [, , view, category] = pathname.split('/')

	return {
		view,
		category,
	}
}

export const getMapCategoryByType = (
	mapCategory: MapCategoryResource[] = [],
	type: string,
): MapCategoryResource | undefined =>
	mapCategory && mapCategory.find(facilityType => facilityType.slug === `/city-map/${type}/`)

export const getMarkerCategoryByCategorySlug = (
	markerCategories: MarkerCategory[] = [],
	categorySlug: string,
) => markerCategories.find(mapCategory => mapCategory.slug === categorySlug)

export const getMarkerCategories = (mapCategories: MapCategoryResource[]): MarkerCategory[] =>
	mapCategories.reduce((a, b) => [...a, ...b.categories], [])

export const fieldContains = (value: string, query: string): boolean => {
	return !!value && value.toLowerCase().indexOf(query) > -1
}

export const getMarkerCategoryByMarker = (
	marker: MarkerResource | undefined,
	facilityTypes: MapCategoryResource[],
): MarkerCategory | undefined => {
	if (!marker || !marker.categories) {
		return undefined
	}

	return getMarkerCategories(facilityTypes).find(markerCategory =>
		markerHasMarkerCategoryID(marker, markerCategory.id),
	)
}

export const markerHasOneOfMarkerCategoryIDs = (
	marker: MarkerResource,
	markerCategoryIDs: number[],
) => markerCategoryIDs.some(markerCategoryID => markerHasMarkerCategoryID(marker, markerCategoryID))

export const markerHasMarkerCategoryID = (
	marker: MarkerResource,
	markerCategoryID: number,
): boolean => marker.categories.indexOf(markerCategoryID) > -1

export const getMapCategoryByMarkerCategory = (
	markerCategory: MarkerCategory | undefined,
	mapCategories: MapCategoryResource[],
): MapCategoryResource | undefined => {
	if (!markerCategory) {
		return undefined
	}

	return mapCategories.find(
		mapCategory => !!mapCategory.categories.find(category => category.id === markerCategory.id),
	)
}
