import React from 'react'
import styled from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

export const Wrapper = styled.ul({
	position: 'relative',
	display: 'flex',
	flexWrap: 'wrap',
	listStyle: 'none',

	[mixins.media.max('md')]: {
		marginBottom: spacing.sm,
	},
})

export const PageNumber = styled.span({
	position: 'relative',
})

export const PageBackground = styled.span.attrs({
	className: 'c-pagination__page-background u-theme-background',
})({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	opacity: 0,
	borderRadius: 8,
	transition: transition.config,
	transitionProperty: 'opacity',
})

export interface ItemProps extends React.HTMLProps<HTMLLIElement> {
	isActive: boolean
	ref?: any
}

const activeButtonStyles = {
	['& .c-pagination__button']: {
		color: `${colors.WHITE} !important`,
	},
}

const rotateBackgroundStyles = {
	['& .c-pagination__page-background']: {
		transform: 'rotate(180deg)',
	},
}

const activeBackgroundStyles = {
	['& .c-pagination__page-background']: {
		opacity: 1,
	},
}

export const Item = styled.li<ItemProps>(({ isActive = false }) => ({
	['&:not(:first-child)']: {
		marginLeft: 10,
	},

	['&:hover']: {
		...activeButtonStyles,
		...activeBackgroundStyles,
	},

	['&:nth-child(even)']: {
		...rotateBackgroundStyles,
	},
	...(isActive
		? {
				...activeButtonStyles,
				...activeBackgroundStyles,
		  }
		: {}),
}))

export const ButtonWrapper = styled.button.attrs({
	className: 'c-pagination__button u-theme-text',
})({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: 40,
	height: 40,
	transition: transition.config,
	transitionProperty: 'color',
	fontWeight: 'bold',
})

export const Button: React.SFC<any> = ({ isActive, children, buttonProps }: any) => (
	<Item isActive={isActive}>
		<ButtonWrapper {...buttonProps}>
			<PageBackground />
			<PageNumber>{children}</PageNumber>
		</ButtonWrapper>
	</Item>
)

export const Separator = styled.span({
	marginLeft: spacing.xs,
	color: colors.GREY_40,
})
