import styled from 'styled-components'

import { colors, themeColors, transition } from '@app/config'

export const EventsDate = styled.span<{ isActive: boolean }>(({ isActive }) => ({
	position: 'absolute',
	top: '50%',
	marginTop: '4px',
	transform: 'translateY(-50%)',
	width: '100%',
	fontSize: '1.4rem',
	fontWeight: 'bold',
	textAlign: 'center',
	color: themeColors.events.light,
	transition: transition.config,
	transitionProperty: 'color',

	...(isActive && {
		color: colors.WHITE,
	}),
}))
