import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const eventsInTheEvening: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="Layer_1">
			<g>
				<path
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					d="M7.256,12.147
			c0-2.618,2.124-4.742,4.744-4.742s4.744,2.124,4.744,4.742"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="4.673"
					y1="12.147"
					x2="1.667"
					y2="12.147"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="12"
					y1="4.822"
					x2="12"
					y2="1.814"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="19.327"
					y1="12.147"
					x2="22.333"
					y2="12.147"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="17.181"
					y1="6.968"
					x2="19.307"
					y2="4.842"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="6.819"
					y1="6.968"
					x2="4.693"
					y2="4.842"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="12"
					y1="22.186"
					x2="12"
					y2="14.642"
				/>
				<path
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeMiterlimit="10"
					d="M14.456,19.639"
				/>

				<polyline
					fill="none"
					stroke={fill}
					strokeWidth="1.5746"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					points="
			14.54,19.639 12,22.18 9.46,19.639 		"
				/>
			</g>
		</g>
	),
}
