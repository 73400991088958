import styled from 'styled-components'

import { colors, spacing } from '@styleguide'

export interface WrapperProps {
	type: 'default' | 'aside'
}

export const Wrapper = styled.article<WrapperProps>(({ type = 'default' }) => ({
	position: 'relative',
	display: 'flex',
	flexWrap: 'wrap',
	padding: `${spacing.md}px 0`,
	borderBottom: `1px ${colors.GREY_20} solid`,

	['&:last-child']: {
		borderBottom: 'none',
	},

	...(type === 'default'
		? {
				zIndex: 'auto',
		  }
		: {
				display: 'block',
		  }),
}))
