import React from 'react'
import styled from 'styled-components'

import {
	Accordion,
	AutoSubmit,
	Checkbox,
	InView,
	MarkerTeaser,
	Sidebar as SidebarComponent,
	TextInput,
	Tooltip,
	TooltipInner,
} from '@app/components'
import { CompassUtils } from '@app/utils'
import { Collaborator, MapCategoryResource, MarkerResource } from '@compass/types'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'
import { Field, Form } from 'react-final-form'

export const MapContainer = styled.div({
	position: 'relative',
	height: 'calc(100vh - 60px)',
	display: 'flex',
	flexDirection: 'column',

	...mixins.mediaStyles({
		sm: {
			margin: 0,
			width: '100%',
			flex: 1,
			minHeight: 'calc(100vh - 130px)',
			height: 'auto',
		},
		lg: {
			minHeight: 'calc(100vh - 70px)',
			width: 'auto',
		},
	}),
})

export const FilterWrapper = styled.div({
	position: 'relative',

	['&:not(:first-child)']: {
		borderTop: `1px ${colors.GREY_20} solid`,
	},
})

export const FilterHeader = styled.button({
	position: 'relative',
	cursor: 'pointer',
	width: '100%',
	background: colors.WHITE,
	padding: `0 ${spacing.sm}px`,
	display: 'flex',
	alignItems: 'center',
	fontSize: '1.6rem',
	color: colors.GREY_50,
	transition: transition.config,
	transitionProperty: 'background',

	['& img']: {
		marginRight: spacing.xs,
		width: 48,
		height: 'auto',
		display: 'block',
	},

	['& > *:last-child']: {
		marginLeft: 'auto',
		color: colors.GREY_30,
	},

	['&:hover']: {
		background: colors.GREY_10,
	},

	...mixins.primaryFont(),
})

export const SearchFormArea = styled.div({
	padding: spacing.sm,
})

export const CategoryWrapper = styled.div({
	position: 'relative',
})

const CheckboxLabel = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export interface CategoryImageProps {
	isActive: boolean
}

export const CategoryImage = styled.img<CategoryImageProps>(({ isActive }) => ({
	width: 32,
	height: 'auto',
	display: 'block',
	marginRight: spacing.xs,
	transition: transition.config,
	transitionProperty: 'filter',
	filter: isActive ? 'brightness(0) invert(1)' : 'none',
}))

function sortBy<T>(property: keyof T) {
	return (a: T, b: T) => (a[property] > b[property] ? 1 : -1)
}

export interface MapFilterCategoryFormValues {
	categories: number[]
}

interface MarkerCategoriesFormProps {
	onUpdateCategoryFilters: (values: MapFilterCategoryFormValues) => void
	facilityTypes: MapCategoryResource[]
	categories: number[]
}

export const MarkerCategoriesForm: React.SFC<MarkerCategoriesFormProps> = ({
	onUpdateCategoryFilters,
	facilityTypes,
	categories,
}) => (
	<Form initialValues={{ categories }} onSubmit={onUpdateCategoryFilters}>
		{() => (
			<>
				<AutoSubmit submit={onUpdateCategoryFilters} />
				{facilityTypes &&
					facilityTypes.sort(sortBy('title')).map((type, index) => {
						const isActive =
							type.categories.filter(category => categories.indexOf(category.id) > -1)
								.length > 0

						return (
							<Accordion
								key={type.id}
								defaultOpen={
									type.categories.filter(
										category => categories.indexOf(category.id) > -1,
									).length > 0
								}
								isActive={isActive}
								activeBackgroundColor={type.categories[0].color}
								header={
									<>
										<CategoryImage
											src={type.icons.filter}
											isActive={isActive}
										/>
										<span
											dangerouslySetInnerHTML={{
												__html: type.title,
											}}
										/>
									</>
								}
								accordionPosition={index}
								last={index === facilityTypes.length - 1}>
								{type.categories.sort(sortBy('name')).map(category => (
									<CategoryWrapper key={category.id}>
										<Field
											name="categories"
											type="checkbox"
											value={category.id}
											render={({ input }) => (
												<Checkbox
													{...input}
													checkboxType="right"
													id={category.id.toString()}
													checked={categories.indexOf(category.id) > -1}
													labelText={
														<CheckboxLabel>
															<CategoryImage
																isActive={false}
																src={category.icons.icon}
																alt={category.name}
															/>
															<span
																dangerouslySetInnerHTML={{
																	__html: category.name,
																}}
															/>

															{category.collaborator &&
																category.collaborator.name && (
																	<div css={{ marginLeft: 10 }}>
																		<MapCategoryCollaborator
																			collaborator={
																				category.collaborator
																			}
																		/>
																	</div>
																)}
														</CheckboxLabel>
													}
												/>
											)}
										/>
									</CategoryWrapper>
								))}
							</Accordion>
						)
					})}
			</>
		)}
	</Form>
)

export interface MapFilterSearchFormValues {
	search: string
}

interface MarkerSearchFormProps {
	onUpdateSearchQuery: (values: MapFilterSearchFormValues) => void
}

export const MarkerSearchForm: React.SFC<MarkerSearchFormProps> = ({ onUpdateSearchQuery }) => (
	<Form onSubmit={onUpdateSearchQuery}>
		{() => (
			<SearchFormArea>
				<AutoSubmit submit={onUpdateSearchQuery} />
				<Field
					name="search"
					render={({ input }) => (
						<TextInput
							{...input}
							type="search"
							placeholder="Search for a facility..."
						/>
					)}
				/>
			</SearchFormArea>
		)}
	</Form>
)

export const Sidebar = styled(SidebarComponent)(({ isOpen }) => ({
	position: 'absolute',
	top: spacing.md,
	left: spacing.lg,
	width: '100vw',
	paddingRight: spacing.sm,
	margin: 0,
	paddingBottom: 0,

	[mixins.media.max('md')]: {
		left: 0,
		top: 0,
		margin: 0,
		paddingRight: 0,
		boxShadow: isOpen ? shadows[1] : 'none',
		minHeight: isOpen ? 'calc(100vh - 60px)' : 0,
		background: isOpen ? `rgba(255, 255, 255, 0.6)` : colors.WHITE,
	},

	...mixins.mediaStyles({
		md: {
			display: 'flex',
			flexDirection: 'column',
			maxWidth: 340,
		},
		lg: {
			height: 'auto !important',
			overflow: 'visible',
		},
		xl: {
			maxWidth: 400,
		},
	}),
}))

export interface MapCategoryCollaboratorProps {
	collaborator: Collaborator
}

export const MapCategoryCollaborator: React.SFC<MapCategoryCollaboratorProps> = ({
	collaborator,
}) => (
	<Tooltip
		interactive
		position="top"
		animation="fade"
		sticky
		stickyDuration={false}
		html={
			<TooltipInner>
				<span style={{ marginRight: 10 }}>Collaborating with:</span>
				<CollaboratorLink collaborator={collaborator} />
			</TooltipInner>
		}
	/>
)

const CollaboratorLink: React.SFC<{ collaborator: Collaborator }> = ({ collaborator }) => {
	const content = collaborator.logo ? (
		<img src={collaborator.logo} alt={collaborator.name} width={32} />
	) : (
		<span>{collaborator.name}</span>
	)

	const wrapper = collaborator.website ? (
		<a href={collaborator.website} target="_blank">
			{content}
		</a>
	) : (
		<div>{content}</div>
	)

	return wrapper
}

export const MarkerListTitle = styled.h4({
	padding: `0 ${spacing.sm}px`,
	color: colors.GREY_40,
})

export const InviewTrigger = styled.div({
	position: 'relative',
	top: '-35vh',
	height: 1,
})

export interface MarkerListProps {
	title?: string
	markers: MarkerResource[]
	selectedMarkers: MarkerResource[]
	scrollWrapper: Element
	facilityTypes: MapCategoryResource[]
	onMarkerClick: (marker: MarkerResource) => void
	onMarkerMouseEnter: (marker: MarkerResource) => void
	onMarkerMouseLeave: (marker: MarkerResource) => void
}

export interface MarkerListState {
	page: number
	markers: MarkerResource[]
}

export class MarkerList extends React.Component<MarkerListProps, MarkerListState> {
	public state: MarkerListState = {
		page: 0,
		markers: [],
	}

	public componentWillMount() {
		this.getList()
	}

	public componentWillReceiveProps(nextProps: MarkerListProps) {
		if (
			JSON.stringify(nextProps.markers.map(m => m.id)) !==
			JSON.stringify(this.props.markers.map(m => m.id))
		) {
			this.setState({ page: 0 }, () => this.getList(nextProps.markers))
		}
	}

	private pageUp = () => {
		this.setState({ page: this.state.page + 1 }, () => this.getList())
	}

	private getList = (markers = this.props.markers) => {
		const filtered = markers.slice(0, 20 * (this.state.page + 1))
		this.setState({ markers: filtered })
	}

	public render() {
		const {
			scrollWrapper,
			selectedMarkers,
			facilityTypes,
			title,
			onMarkerClick,
			onMarkerMouseEnter,
			onMarkerMouseLeave,
		} = this.props
		const { markers } = this.state

		return (
			<div>
				{title && <MarkerListTitle>{title}</MarkerListTitle>}
				{markers &&
					markers.map((marker, index) => (
						<MarkerTeaser
							key={index}
							marker={marker}
							isActive={selectedMarkers.some(m => m.id === marker.id)}
							category={CompassUtils.map.getMarkerCategoryByMarker(
								marker,
								facilityTypes,
							)}
							onClick={() => onMarkerClick(marker)}
							onMouseEnter={() => onMarkerMouseEnter(marker)}
							onMouseLeave={() => onMarkerMouseLeave(marker)}
						/>
					))}

				<InView scrollWrapper={scrollWrapper} onInView={this.pageUp}>
					{() => <InviewTrigger />}
				</InView>
			</div>
		)
	}
}
