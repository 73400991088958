import React from 'react'

import { colors } from '@styleguide'

import { SvgIconMap } from '../types'

const defaultFill = colors.GREY_60

export const search: SvgIconMap = {
	size: {
		width: 16,
		height: 16,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M6,-1.77635684e-15 C2.687,-1.77635684e-15 0,2.687 0,6 C0,9.313 2.687,12 6,12 C9.313,12 12,9.313 12,6 C12,2.687 9.313,-1.77635684e-15 6,-1.77635684e-15 M6,2 C8.206,2 10,3.794 10,6 C10,8.206 8.206,10 6,10 C3.794,10 2,8.206 2,6 C2,3.794 3.794,2 6,2 Z M14.414,15.828 L11,12.414 L12.414,11 L15.828,14.414 L14.414,15.828 Z"
			id="path-1"
		/>
	),
}
