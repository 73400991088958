import React from 'react'
import styled from 'styled-components'

import { ArticleResource, FeaturedContentItem } from '@compass/types'
import { colors, mixins, spacing, transition } from '@styleguide'
import {
	FeaturedBlog,
	FeaturedPage,
	FeaturedTextBlock,
	FeaturedYoutubeVideo,
} from './featured-content-item'

interface ScrollWrapperProps {
	isVisible: boolean
}

const FeaturedContentScrollWrapper = styled.div<ScrollWrapperProps>(({ isVisible }) => ({
	marginBottom: 50,

	...mixins.mediaStyles({
		md: {
			marginTop: 200,
			marginBottom: 100,
			width: 620,
		},
		lg: {
			position: 'absolute',
			marginTop: 0,
			marginBottom: 50,
			top: 70,
			right: 0,
			height: 'calc(100% - 90px)',
			transition: transition.config,
			transitionProperty: 'opacity',
			opacity: isVisible ? 0.06 : 1,

			['&::before, &::after']: {
				position: 'absolute',
				zIndex: 1,
				content: '""',
				left: 0,
				width: '100%',
				transition: transition.config,
				transitionProperty: 'opacity',
				opacity: isVisible ? 0 : 1,
			},

			['&::before']: {
				height: 50,
				top: 0,
				background: 'linear-gradient(white, rgba(255, 255, 255, 0.001))',
			},

			['&::after']: {
				height: 100,
				bottom: 0,
				background: 'linear-gradient(rgba(255, 255, 255, 0.001), white)',
				pointerEvents: 'none',
			},
		},
	}),
}))

const FeaturedContentWrapper = styled.div({
	position: 'relative',

	...mixins.mediaStyles({
		md: {
			width: '100%',
			height: '100%',
			overflowX: 'hidden',
			padding: '30px 20px 0 20px',
		},
	}),
})

const Title = styled.h3({
	color: colors.GREY_40,
	marginBottom: 5,

	...mixins.mediaStyles({
		lg: {
			marginTop: spacing.xs,
		},
	}),
})

const FeaturedContentElements = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	margin: '0 -10px',
})

const FeaturedContentItemComponents = {
	blog: FeaturedBlog,
	custom_text_block: FeaturedTextBlock,
	page: FeaturedPage,
	youtube_video: FeaturedYoutubeVideo,
}
export interface FeaturedContentProps {
	isViewingBackgroundVideo: boolean
	featuredContent?: FeaturedContentItem[]
	onClick?: (item: { url?: string; article?: ArticleResource }) => void
}

export const FeaturedContent: React.SFC<FeaturedContentProps> = ({
	isViewingBackgroundVideo,
	featuredContent = [],
	onClick,
}) => {
	return (
		<FeaturedContentScrollWrapper isVisible={isViewingBackgroundVideo}>
			<FeaturedContentWrapper>
				<Title>What's happening</Title>

				<FeaturedContentElements>
					{featuredContent.map((item, index) => {
						const Component = FeaturedContentItemComponents[item.acf_fc_layout]
						// @ts-ignore
						return <Component key={index} item={item} onClick={onClick} />
					})}
				</FeaturedContentElements>
			</FeaturedContentWrapper>
		</FeaturedContentScrollWrapper>
	)
}
