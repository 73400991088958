import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { withContentStore, WithContentStoreProps } from '@app/stores'

export class ResourceLoaderClass extends React.Component<
	RouteComponentProps<any> & WithContentStoreProps
> {
	public async componentWillReceiveProps(nextProps: RouteComponentProps<any>) {
		const { contentStore, location } = this.props

		const nestedRoutes = ['city-map', 'sports', 'community']
		const section = nextProps.location.pathname.split('/')[1]
		const isNestedRoute = nestedRoutes.includes(section)

		const pathname = isNestedRoute
			? `/${section}/`
			: nextProps.location.pathname


		if (nextProps.location.pathname !== location.pathname) {
			await contentStore.getContent(pathname)
			contentStore.setUIState(pathname)
			contentStore.set({ pathname })
		}
	}

	public render() {
		return this.props.children
	}
}

export const ResourceLoader = compose(
	withContentStore,
	withRouter,
)(ResourceLoaderClass)
