import styled from 'styled-components'

import { mixins, spacing } from '@styleguide'

export const Wrapper = styled.footer({
	position: 'relative',
	padding: `${spacing.lg}px 0`,
	width: '100%',
	order: 2,

	[mixins.media.max('sm')]: {
		display: 'none',
	},
})

export const Title = styled.h3({
	textAlign: 'center',
	marginBottom: spacing.md,
})
