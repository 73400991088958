import React from 'react'
import { hydrate } from 'react-dom'
// import GoogleAnalytics from 'react-ga'
import en from 'react-intl/locale-data/en'

import { env } from '@app/config'
import { rehydrateStores } from '@app/stores'
import { installSentry } from '@app/utils'
import { addLocaleData } from 'react-intl'

const prod = process.env.NODE_ENV === 'production'
if (prod && env.SENTRY_DSN) {
	installSentry()
}

addLocaleData([...en])

// if (env.GOOGLE_ANALYTICS_TOKEN) {
// 	GoogleAnalytics.initialize(env.GOOGLE_ANALYTICS_TOKEN)
// }

const stores = window.stores || rehydrateStores(window.__STORE_STATE__)

const App = require('./clientApp').default
hydrate(<App stores={stores} />, document.getElementById('app'))

if (process.env.NODE_ENV !== 'production') {
	if (module.hot) {
		module.hot.accept()
	}

	if (!window.stores) {
		window.stores = stores
	}
}
