import { CSSObject } from 'styled-components'

import { colors } from '../colors'

export const linkUnderline = (
	colorUnderline: string = 'currentColor',
	colorShadow: string = colors.WHITE,
	underlineHeight: number = 0.05,
	underlineOffset: number = 0.15,
): CSSObject => {
	return {
		display: 'inline',
		textDecoration: 'none',
		backgroundImage: `linear-gradient(to top, transparent, transparent ${underlineOffset}em, ${colorUnderline} ${underlineOffset}em, ${colorUnderline} ${underlineOffset +
			underlineHeight}em, transparent ${underlineOffset + underlineHeight}em)`,
		textShadow: `-1px 0 0 ${colorShadow}, 1px 0 0 ${colorShadow}, -2px 0 0 ${colorShadow}, 2px 0 0 ${colorShadow}`,
	}
}
