import styled from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

interface HeaderProps {
	isOpen?: boolean
	isActive?: boolean
	isDisabled?: boolean
	activeBackgroundColor?: string
}

export const AccordionWrapper = styled.div({
	marginBottom: 50,
})

export const Header = styled.div<HeaderProps>(
	({ isDisabled, isOpen, isActive, activeBackgroundColor }) => ({
		position: 'relative',
		width: '100%',
		padding: `0 ${spacing.sm}px`,
		display: 'flex',
		alignItems: 'center',
		fontSize: '1.6rem',
		minHeight: 48,
		transition: transition.config,
		transitionProperty: 'color, background',

		['& svg polygon']: {
			transition: transition.config,
			transitionProperty: 'fill',
		},

		['& > *:last-child']: {
			['& svg polygon']: {
				transition: transition.config,
				transitionProperty: 'fill',
			},

			...(isActive
				? {
						['&:not(:hover) svg polygon']: {
							fill: colors.WHITE,
						},
				  }
				: {}),
		},

		['&:hover']: {
			background: isActive ? activeBackgroundColor : colors.GREY_10,
		},

		...mixins.primaryFont(),
		cursor: isDisabled ? 'not-allowed' : 'pointer',
		background: isActive ? activeBackgroundColor : isOpen ? colors.GREY_10 : colors.WHITE,
		color: isActive ? colors.WHITE : colors.GREY_50,
	}),
)

interface InnerProps {
	noPadding?: boolean
}

export const Inner = styled.div<InnerProps>(({ noPadding = false }) => ({
	padding: noPadding ? 0 : `10px ${spacing.sm}px`,
}))

interface ItemProps {
	isOpen?: boolean
}

export const Item = styled.div<ItemProps>({
	position: 'relative',
	width: '100%',
	backgroundColor: colors.WHITE,
	overflow: 'hidden',

	[mixins.media.max('sm')]: {
		marginBottom: `0 !important`,
	},
})

export const Body = styled.div({
	width: '100%',
	borderBottom: `1px solid ${colors.GREY_30}`,
})
