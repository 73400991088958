import { domains } from '@app/config'
import { MediaFilter } from '@app/types'
import http from '@app/utils/http'

export interface FetchMediaOptions {
	filters: MediaFilter[]
	last: string | number | undefined
}

export const fetchMedia = ({ filters, last }: FetchMediaOptions) =>
	http(
		`${domains.api}
			/media${`?content=${filters.join(',')}`}${
			last ? `&last=${encodeURIComponent(last as string)}` : ''
		}`,
	)
