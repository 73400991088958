import styled from 'styled-components'

import { colors } from '@styleguide'

interface ModalBackgroundProps {
	isSmall: boolean
}

export const ModalBackground = styled.div<ModalBackgroundProps>(({ isSmall }) => ({
	position: 'relative',
	minHeight: 'calc(100vh - 60px)',
	background: colors.WHITE,

	...(isSmall
		? {
				width: 1024,
				margin: '0 auto',
				maxWidth: '100%',
		  }
		: {}),
}))

export const Iframe = styled.iframe({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	background: colors.WHITE,
})
