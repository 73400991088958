import * as React from 'react'
import styled from 'styled-components'

import { Ads, Grid, HelmetData, Page, StaticContentSection } from '@app/components'
import { mixins, spacing } from '@app/config'
import { EventResource } from '@compass/types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { EventDetails } from '../events/components'

interface TemplateEventProps {
	resource: EventResource
}

const EventImage = styled.img({
	position: 'relative',
	width: '100%',
	height: 'auto',
	display: 'block',
	marginTop: spacing.sm,
})

const EventsBackButton = styled(Link)({
	display: 'block',
	marginTop: 20,
	...mixins.smallLabel(),
	...mixins.primaryFont(),

	...mixins.mediaStyles({
		md: {
			marginTop: 50,
		},
		xl: {
			marginTop: 62,
		},
	}),
})

export const Event: React.SFC<TemplateEventProps> = ({ resource }) => (
	<Page>
		<div itemScope itemType="http://schema.org/Event">
			<meta itemProp="startDate" content={moment(resource.event.date.start).toISOString()} />
			<meta itemProp="image" content={resource.image} />

			<div itemProp="location" itemScope itemType="https://schema.org/Place">
				{resource.event.association && (
					<meta itemProp="name" content={resource.event.association.name} />
				)}
				<meta itemProp="address" content={resource.event.location.address} />
			</div>
			<HelmetData title={resource.title} seo={resource.seo} />
			<Grid.Container>
				<Grid.Row>
					<Grid.LeftPaddedColumn xs={16} md={4} xl={3}>
						<EventsBackButton to="/events/" className="u-theme-text">
							⬸ Back to events
						</EventsBackButton>
					</Grid.LeftPaddedColumn>
					<Grid.PaddedColumn xs={16} md={{ size: 8 }}>
						<h1 itemProp="name" className="u-theme-text" css={{ marginBottom: 10 }}>
							{resource.title}
						</h1>
						<div itemProp="description" css={{ whiteSpace: 'pre-wrap' }}>
							<StaticContentSection resource={resource} />
						</div>
					</Grid.PaddedColumn>
					<Grid.Column
						xs={16}
						md={{ size: 3, offset: 1 }}
						xl={{ size: 3, offset: 2 }}
						aside>
						{resource.image && <EventImage src={resource.image} alt={resource.title} />}
						<EventDetails event={resource.event} />
						<Ads slug={resource.slug} />
					</Grid.Column>
					{/* {resource.related_events ? (
						<Grid.Column xs={16} md={12}>
							<h2 className="u-theme-text">
								More events from
								<a target="_blank" href={resource.event.organizer.url}>
									{resource.event.organizer.name}
								</a>
							</h2>
							<Events.List events={resource.related_events} />
						</Grid.Column>
					) : null} */}
				</Grid.Row>
			</Grid.Container>
		</div>
	</Page>
)
