import React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'

import { withContentStore, WithContentStoreProps } from '@app/stores'
import { Resource } from '@compass/types'
import { colors, mixins, spacing, transition } from '@styleguide'

type TStickyArticleButtonDirection = 'prev' | 'next'

interface StickyArticleButtonBaseProps {
	direction: TStickyArticleButtonDirection
}

type StickyArticleButtonProps = StickyArticleButtonBaseProps &
	WithContentStoreProps &
	RouteComponentProps<any>

interface StickyArticleButtonState {
	texts: {
		prev: string
		next: string
	}
	article: Resource | undefined
}

interface LinkProps {
	direction: TStickyArticleButtonDirection
}

const ArticleLink = styled(({ isHidden, ...rest }) => <Link {...rest} />)<LinkProps>({
	display: 'inline-block',
	margin: `${spacing.sm}px 0`,
	color: colors.GREY_50,
	fontSize: '1.4rem',
	letterSpacing: 0,
	width: '100%',
	fontWeight: 'bold',
	transition: transition.config,
	...mixins.primaryFont(),
	...mixins.mediaStyles({
		md: {
			margin: `${spacing.md}px 0`,
		},
	}),
})

const ArticleDirection = styled.span({
	display: 'block',
	marginBottom: 5,
})

const LinkUnderline = styled.span(mixins.linkUnderline())

export class StickyArticleButton extends React.Component<
	StickyArticleButtonProps,
	StickyArticleButtonState
> {
	public state: StickyArticleButtonState = {
		texts: {
			prev: '⬸ Previous article',
			next: 'Next article ⤑',
		},
		article: this.getArticle(),
	}

	private getArticle() {
		return this.props.direction === 'next'
			? this.props.contentStore.state.nextResource
			: this.props.contentStore.state.prevResource
	}

	public render() {
		const { direction } = this.props
		const { texts } = this.state

		const article = this.getArticle()

		return article ? (
			<ArticleLink direction={direction} to={article.slug} className="u-theme-text">
				<ArticleDirection>{texts[direction]}</ArticleDirection>
				<LinkUnderline>{article.title}</LinkUnderline>
			</ArticleLink>
		) : null
	}
}

export default compose<StickyArticleButtonProps, StickyArticleButtonBaseProps>(
	withContentStore,
	withRouter,
)(StickyArticleButton)
