import { Resource } from '@compass/types'

export interface BlockProps {
	page: Resource
	query: string
	parentAllowsAll?: boolean
}

export const queryMatchesPage = (title: string, query: string): boolean => {
	return !query || title.toLowerCase().includes(query.toLowerCase())
}

export const doesPageOrSubpagesContainQuery = (page: Resource, query: string): boolean => {
	if (queryMatchesPage(page.title, query)) {
		return true
	}

	return Boolean(
		page.resources &&
			page.resources.some((subPage: Resource) => {
				return queryMatchesPage(subPage.title, query)
			}),
	)
}
