import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const cityMap: SvgIconMap = {
	size: {
		width: 34,
		height: 49,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-40" fill={fill} fillRule="nonzero">
			<path
				d="M17.11,48.98 L15.96,47.62 C15.43,47 3.05,32.2 1.01,19.95 C0.78,18.86 0.65,17.7 0.65,16.55 C0.65,7.47 8.04,0.08 17.12,0.08 C26.2,0.08 33.59,7.47 33.59,16.55 C33.59,17.68 33.47,18.84 33.22,19.98 C31.16,32.22 18.78,46.99 18.26,47.62 L17.11,48.98 Z M17.12,3.08 C9.69,3.08 3.65,9.12 3.65,16.55 C3.65,17.48 3.75,18.44 3.95,19.39 C5.52,28.79 13.99,40.29 17.11,44.26 C20.22,40.29 28.71,28.8 30.26,19.46 L30.27,19.39 C30.47,18.44 30.58,17.48 30.58,16.55 C30.58,9.12 24.54,3.08 17.12,3.08 Z"
				id="Shape"
			/>
			<path
				d="M17.12,26.78 C11.59,26.78 7.09,22.28 7.09,16.75 C7.09,11.22 11.59,6.72 17.12,6.72 C22.65,6.72 27.15,11.22 27.15,16.75 C27.15,22.28 22.65,26.78 17.12,26.78 Z M17.12,9.73 C13.24,9.73 10.09,12.88 10.09,16.76 C10.09,20.64 13.24,23.79 17.12,23.79 C21,23.79 24.15,20.64 24.15,16.76 C24.15,12.88 20.99,9.73 17.12,9.73 Z"
				id="Shape"
			/>
		</g>
	),
}
