import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const facebook: SvgIconMap = {
	size: {
		width: 16,
		height: 16,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M9.5 3h2.5v-3h-2.5c-1.93 0-3.5 1.57-3.5 3.5v1.5h-2v3h2v8h3v-8h2.5l0.5-3h-3v-1.5c0-0.271 0.229-0.5 0.5-0.5z"
		/>
	),
}
