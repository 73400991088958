import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const eventsOther: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="Layer_1">
			<g>
				<g>
					<path
						fill={fill}
						d="M12.004,2.643c5.184,0,9.4,4.217,9.4,9.4c0,5.182-4.217,9.397-9.4,9.397s-9.4-4.216-9.4-9.397
				C2.604,6.859,6.82,2.643,12.004,2.643 M12.004,0.093c-6.601,0-11.95,5.35-11.95,11.95c0,6.597,5.35,11.947,11.95,11.947
				s11.95-5.351,11.95-11.947C23.954,5.442,18.604,0.093,12.004,0.093L12.004,0.093z"
					/>
				</g>
				<g>
					<path
						fill={fill}
						d="M10.948,14.415l-0.035-0.423c-0.094-0.879,0.196-1.838,1.009-2.811c0.731-0.861,1.138-1.496,1.138-2.228
				c0-0.828-0.521-1.381-1.544-1.397c-0.585,0-1.234,0.194-1.641,0.503L9.484,7.036c0.537-0.388,1.464-0.649,2.325-0.649
				c1.867,0,2.714,1.152,2.714,2.39c0,1.103-0.617,1.9-1.398,2.826c-0.715,0.847-0.975,1.561-0.927,2.39l0.018,0.423H10.948z
				 M10.59,16.688c0-0.6,0.406-1.022,0.976-1.022c0.568,0,0.958,0.423,0.958,1.022c0,0.57-0.373,1.008-0.975,1.008
				C10.981,17.696,10.59,17.259,10.59,16.688z"
					/>
				</g>
			</g>
		</g>
	),
}
