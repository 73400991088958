import { Request } from 'express'
import { Container } from 'unstated'

export interface ModalState {
	isModalOpen: {
		textSelection: boolean
		listSubmit: boolean
	}
}

export const defaultModalState: ModalState = {
	isModalOpen: {
		textSelection: false,
		listSubmit: false,
	},
}

export class ModalStore extends Container<ModalState> {
	public constructor(initialState: ModalState = defaultModalState) {
		super()
		this.state = initialState
	}

	/**
	 *
	 * Server-side resolve flow
	 */
	public static async getInitialState(_req: Request) {
		return defaultModalState
	}

	public openModal(modal: keyof ModalState['isModalOpen']) {
		this.setState({
			isModalOpen: {
				...this.state.isModalOpen,
				[modal]: true,
			},
		})
	}

	public closeModal(modal: keyof ModalState['isModalOpen']) {
		this.setState({
			isModalOpen: {
				...this.state.isModalOpen,
				[modal]: false,
			},
		})
	}
}
