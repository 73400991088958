import { Request } from 'express'
import moment from 'moment'
import React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { Breadcrumbs, Grid, Label, RichTextContent, ShareButtons } from '@app/components'
import { env, shellConfig } from '@app/config'
import { isBrowser } from '@app/utils'
import { ArticleResource } from '@compass/types'
import { mixins, spacing } from '@styleguide'

const ArticleWrapper = styled.article({
	position: 'relative',
	padding: `${spacing.lg}px 0`,
})

export const ArticleImage = styled.img({
	position: 'relative',
	float: 'right',
	maxWidth: '50%',
	height: 'auto',
	marginLeft: spacing.sm,
	marginBottom: spacing.sm,
})

export const ArticleBackButton = styled(Link)({
	position: 'absolute',
	top: spacing.xs,
	left: spacing.md,
	cursor: 'pointer',
	...mixins.smallLabel(),
	...mixins.primaryFont(),

	...mixins.mediaStyles({
		md: {
			top: 50,
		},
		xl: {
			top: 80,
		},
	}),
})

const schemaContent = (article: ArticleResource) => ({
	'@context': 'https://schema.org',
	'@type': 'NewsArticle',
	mainEntityOfPage: {
		'@type': 'WebPage',
		'@id': 'https://google.com/article',
	},
	headline: article.article.title,
	image: [article.article.image],
	datePublished: article.article.source.date,
	author: {
		'@type': 'Person',
		name: article.article.author,
	},
	publisher: {
		'@type': 'Organisation',
		name: shellConfig.name[env.STAGE!],
		logo: {
			'@type': 'ImageObject',
			url: shellConfig.logoUrl,
		},
	},
})
export interface ArticleProps {
	article: ArticleResource
}

export const ArticleDetailComponent: React.SFC<
	ArticleProps & RouteComponentProps<any, { statusCode?: number; req: Request }>
> = ({ article, staticContext }) => {
	const host = isBrowser ? window.location.origin : staticContext!.req.get('host')

	return (
		<ArticleWrapper>
			<script type="application/ld+json">{JSON.stringify(schemaContent(article))}</script>
			<Grid.Container>
				<h2 className="u-theme-text">{article.article.title}</h2>
				{article.article.author && article.article.source.date && (
					<Label
						label={`By ${article.article.author} on ${moment(
							article.article.source.date,
						).format('dddd D MMMM YYYY [at] HH:mm')}`}
					/>
				)}
				<Breadcrumbs />
				<div>
					{article.article.image && (
						<ArticleImage src={article.article.image} alt={article.article.title} />
					)}
					{article.content && <RichTextContent content={article.content} />}
					<ShareButtons title={article.article.title} url={`${host + article.slug}`} />
				</div>
			</Grid.Container>
		</ArticleWrapper>
	)
}

export const ArticleDetail = withRouter(ArticleDetailComponent)
