import React, { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { Accordion, AutoSubmit, Checkbox, TextInput } from '@app/components'
import { CategoryWrapper } from '@app/pages/map/map-components'
import { Event } from '@compass/types'
import { spacing } from '@styleguide'

export interface CategoryFiltersFormProps {
	categories: Event['categories']
	selectedCategories: string[] | null
	onUpdateFilters: (selectedCategories: string[]) => void
}

const CheckboxLabel = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export const CategoryFiltersForm: React.SFC<CategoryFiltersFormProps> = ({
	categories,
	selectedCategories,
	onUpdateFilters,
}) => {
	const [search, setSearch] = useState('')

	const filteredCategories = useMemo(() => categories
		.filter(category => category.name.toLowerCase().includes(search.toLowerCase())),
		[categories, search]
	)

	return (
		<Form
			initialValues={{
				categories: selectedCategories,
			}}
			onSubmit={(values: any) => onUpdateFilters(values.categories)}>
			{() => (
				<>
					<AutoSubmit submit={values => onUpdateFilters(values.categories)} />
					<Accordion
						header={<span>Choose a category:</span>}
						accordionPosition={1}
						last={true}>

						<Field
							name="search"
							render={({ input }) => (
								<TextInput
									{...input}
									type="search"
									placeholder="Search for a category..."
									onChange={(event) => {
										setSearch(event.currentTarget.value)
										input.onChange(event)
									}}
								/>
							)}
						/>
						<div
							css={{
								marginBottom: 10,
								paddingRight: spacing.sm,
								marginRight: -spacing.sm,
								maxHeight: 200,
								overflow: 'auto',
							}}>

							{filteredCategories.map(category => (
								<CategoryWrapper key={category.name}>
									<Field
										name="categories"
										type="checkbox"
										value={category.name}
										render={({ input }) => (
											<Checkbox
												{...input}
												checkboxType="right"
												id={category.name}
												checked={
													!!selectedCategories &&
													selectedCategories.includes(category.name)
												}
												labelText={<CheckboxLabel>{category.name}</CheckboxLabel>}
											/>
										)}
									/>
								</CategoryWrapper>
							))}
						</div>
					</Accordion>
				</>
			)}
		</Form>
	)
}
