import React, { Suspense } from 'react'

import {
	Ads,
	Breadcrumbs,
	ContentValidators,
	FeedbackSection,
	FullScreenIframe,
	FullScreenImage,
	Grid,
	HelmetData,
	Loader,
	Member,
	Page,
	StaticContentSection,
	StickyArticleButton,
} from '@app/components'
import { isBrowser } from '@app/utils'
import { PageResource } from '@compass/types'

interface TemplatePageDefaultProps {
	resource: PageResource
}

const LazyGuidanceDocument = React.lazy(() =>
	import('@app/components/guidance-document/guidance-document'),
)

export const PageDefault: React.SFC<TemplatePageDefaultProps> = ({ resource }) => {
	return (
		<Page>
			<HelmetData title={resource.title} image={resource.image} seo={resource.seo} />
			<Grid.Container>
				<Grid.Row>
					{resource.iframe && (
						<Grid.PaddedColumn xs={16}>
							<FullScreenIframe iframe={resource.iframe} />
						</Grid.PaddedColumn>
					)}
					{resource.image && (
						<Grid.PaddedColumn xs={16}>
							<FullScreenImage src={resource.image} alt={resource.title} />
						</Grid.PaddedColumn>
					)}
					<Grid.PaddedColumn
						xs={16}
						md={{ size: 10, offset: 3 }}
						lg={{ size: 9, offset: 4 }}
						xl={{ size: 9, offset: 3 }}>
						<h1 className="u-theme-text">{resource.title}</h1>
						<Breadcrumbs />
						<StaticContentSection resource={resource} />
						{resource.relationships &&
							resource.relationships.members &&
							resource.relationships.members.map(member => (
								<Member key={member.id} member={member} />
							))}
						{resource.file && isBrowser && (
							<Suspense
								fallback={
									<Loader
										loading
										fixed={false}
										text="Loading guidance document"
									/>
								}>
								<LazyGuidanceDocument document={resource.file} />
							</Suspense>
						)}
						{resource.content_validators && (
							<ContentValidators validators={resource.content_validators} />
						)}
						<FeedbackSection />
						<Grid.Row>
							<Grid.Column xs={8}>
								<StickyArticleButton direction="prev" />
							</Grid.Column>
							<Grid.Column xs={8} css={{ textAlign: 'right' }}>
								<StickyArticleButton direction="next" />
							</Grid.Column>
						</Grid.Row>
					</Grid.PaddedColumn>
					<Grid.Column
						xs={16}
						md={{ size: 3, offset: 0 }}
						lg={{ size: 2.5, offset: 0.5 }}
						aside>
						<Ads slug={resource.slug} />
					</Grid.Column>
				</Grid.Row>
			</Grid.Container>
		</Page>
	)
}
