import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { Button } from '@app/components/button'
import { RadioButton, TextInput } from '@app/components/form-elements'
import { Grid } from '@app/components/grid'
import * as validations from '@app/utils/validation'
import { PageFeedbackFormInput } from '@compass/types'
import { spacing } from '@styleguide'

import * as SharedFormComponents from './_shared'

interface FeedbackSectionFormProps {
	onSubmit: (values: PageFeedbackFormInput) => void
}

const Div = styled.div({
	marginTop: spacing.xs,
})

export class FeedbackSectionForm extends React.Component<FeedbackSectionFormProps> {
	private onSubmit = (values: PageFeedbackFormInput) => {
		if (this.props.onSubmit) {
			this.props.onSubmit(values)
		}
	}

	public render() {
		return (
			<Form onSubmit={this.onSubmit}>
				{({ handleSubmit, form }) => (
					<SharedFormComponents.Form css={{ margin: 0 }} onSubmit={handleSubmit}>
						<Grid.Row>
							<Grid.Column xs={16} lg={8}>
								<Field
									type="radio"
									name="outcome"
									value="yes"
									render={({ input }) => (
										<RadioButton
											{...input}
											id="outcome-yes"
											isSmall
											labelText="I have found what I was looking for"
										/>
									)}
								/>
							</Grid.Column>

							<Grid.Column xs={16} lg={8}>
								<Field
									type="radio"
									name="outcome"
									value="no"
									render={({ input }) => (
										<RadioButton
											{...input}
											id="outcome-no"
											isSmall
											labelText="I have not found what I was looking for"
										/>
									)}
								/>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column xs={16} lg={8}>
								{form.getState().values.outcome === 'yes' && (
									<Div>
										<Button type="submit" buttonSize="large">
											Submit
										</Button>
									</Div>
								)}
							</Grid.Column>
							<Grid.Column xs={16} lg={8}>
								{form.getState().values.outcome === 'no' && (
									<Div>
										<Field
											name="motivation"
											render={({ input }) => (
												<TextInput
													{...input}
													textarea
													css={{ marginTop: spacing.xs }}
													labelText="Tell us which information you are missing"
												/>
											)}
										/>
										<Field
											name="email"
											validate={validations.email}
											render={({ input, meta }) => (
												<TextInput
													type="email"
													{...input}
													labelText="Your email address"
													subText="We will only use your email address to reply and help you with your issue"
													hasError={meta.touched && meta.error}
													errorText={meta.error}
												/>
											)}
										/>
										<Button type="submit" buttonSize="large">
											Submit
										</Button>
									</Div>
								)}
							</Grid.Column>
						</Grid.Row>
					</SharedFormComponents.Form>
				)}
			</Form>
		)
	}
}
