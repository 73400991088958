import styled from 'styled-components'

import { SvgIcon } from '@app/components'
import { mixins } from '@styleguide'

const LogoArrow = styled(SvgIcon)({
	backfaceVisibility: 'hidden',
	position: 'absolute',

	[mixins.media.max('sm')]: {
		display: 'none',
	},
})

export const finances = styled(LogoArrow)({
	top: 0,
	left: '50%',
	transform: 'translateX(-50%)',
	width: '17.303118%',
	height: '46.39376%',
	zIndex: 5,
})

export const health = styled(LogoArrow)({
	bottom: 0,
	left: '50%',
	transform: 'translateX(-50%)',
	width: '17.303118%',
	height: '46.39376%',
	zIndex: 5,
})

export const housing = styled(LogoArrow)({
	top: '50%',
	right: 0,
	transform: 'translateY(-50%)',
	height: '17.303118%',
	width: '46.80376%',
	zIndex: 5,
})

export const transport = styled(LogoArrow)({
	top: '50%',
	left: 0,
	transform: 'translateY(-50%)',
	height: '17.303118%',
	width: '46.39376%',
	zIndex: 5,
})

export const sports = styled(LogoArrow)({
	bottom: '50%',
	right: '50%',
	marginRight: '7.45%',
	marginBottom: '-24.8%',
	width: '17.45857699805068%',
	height: '17.30769%',
	zIndex: 4,
})

export const community = styled(LogoArrow)({
	top: '50%',
	left: '50%',
	marginLeft: '7.45%',
	marginTop: '-24.8%',
	width: '17.45857699805068%',
	height: '17.30769%',
	zIndex: 4,
})

export const cityMap = styled(LogoArrow)({
	top: '50%',
	right: '50%',
	marginRight: '7.45%',
	marginTop: '-24.8%',
	width: '17.45857699805068%',
	height: '17.30769%',
	zIndex: 4,
})

export const blog = styled(LogoArrow)({
	bottom: '50%',
	left: '50%',
	marginLeft: '7.45%',
	marginBottom: '-24.8%',
	width: '17.45857699805068%',
	height: '17.30769%',
	zIndex: 4,
})
