import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { Button } from '@app/components/button'
import { TextInput } from '@app/components/form-elements'
import { Loader } from '@app/components/loader'
import { isBrowser } from '@app/utils'
import * as validations from '@app/utils/validation'
import { FeedbackFormInput } from '@compass/types'
import { spacing } from '@styleguide'

import * as SharedFormComponents from './_shared'
import * as FeedbackFormComponents from './feedback-form-components'

const P = styled.p({
	margin: `${spacing.md}px 0`,
})

interface FeedbackFormProps {
	selection: string | undefined
	success: boolean
	submitting: boolean
	onSubmit: (values: FeedbackFormInput) => void
}

export class FeedbackForm extends React.Component<FeedbackFormProps> {
	public render() {
		const { onSubmit, selection, success, submitting } = this.props

		return (
			<FeedbackFormComponents.ScrollWrapper>
				<FeedbackFormComponents.Wrapper
					css={{ transform: success ? 'translateX(-50%)' : 'none' }}>
					<Form
						initialValues={{
							url: isBrowser ? window.location.href : undefined,
							subject: selection ? 'Feedback: ' + selection : undefined,
							action: 'feedback',
						}}
						onSubmit={onSubmit}>
						{({ handleSubmit }) => (
							<SharedFormComponents.Form onSubmit={handleSubmit}>
								<Field
									name="name"
									validate={validations.required}
									render={({ input, meta }) => (
										<TextInput
											{...input}
											labelText="Name"
											placeholder="Fill in your name"
											hasError={meta.touched && meta.error}
											errorText={meta.error}
										/>
									)}
								/>
								<Field
									name="email"
									validate={validations.email}
									render={({ input, meta }) => (
										<TextInput
											{...input}
											type="email"
											labelText="Email"
											placeholder="Fill in your email"
											hasError={meta.touched && meta.error}
											errorText={meta.error}
										/>
									)}
								/>
								<Field
									name="subject"
									validate={validations.required}
									render={({ input, meta }) => (
										<TextInput
											{...input}
											labelText="Subject"
											placeholder="Fill in a subject for your question or feedback"
											hasError={meta.touched && meta.error}
											errorText={meta.error}
										/>
									)}
								/>
								<Field
									name="message"
									validate={validations.required}
									render={({ input, meta }) => (
										<TextInput
											{...input}
											textarea
											labelText="Question / Feedback"
											placeholder="Details of your question or feedback"
											hasError={meta.touched && meta.error}
											errorText={meta.error}
										/>
									)}
								/>
								<SharedFormComponents.ButtonBar>
									<Button type="submit" buttonSize="large" disabled={submitting}>
										Submit
									</Button>
								</SharedFormComponents.ButtonBar>
							</SharedFormComponents.Form>
						)}
					</Form>
					<P aria-hidden={!success}>
						Thank you for your feedback, you're the best! We will revise our information
						as soon as possible and get back to you in case of further questions.
					</P>
					<Loader fixed={false} loading={submitting} />
				</FeedbackFormComponents.Wrapper>
			</FeedbackFormComponents.ScrollWrapper>
		)
	}
}
