import { Autocomplete } from '@app/components'
import { ListResource } from '@compass/types'

type SearchAutocompleteItemType = 'tag' | 'item'

export interface SearchAutocompleteItem {
	value: string
	label: string
	context: {
		type: SearchAutocompleteItemType
		resource?: ListResource
	}
}

export class SearchAutocomplete extends Autocomplete<SearchAutocompleteItem> {}
