import styled from 'styled-components'

import { colors, mixins, shadows, spacing, transition } from '@styleguide'

export const Wrapper = styled.div<{ open: boolean }>(({ open }) => ({
	position: 'fixed',
	top: 0,
	width: '100%',
	height: '100%',

	overflow: 'auto',
	WebkitOverflowScrolling: 'touch',
	visibility: 'hidden',
	left: '-9999px',
	opacity: 0,
	transition: `visibility 0ms ${transition.config}, left 0ms ${transition.config}, opacity ${
		transition.config
	}`,

	zIndex: 1000000,

	...(open
		? {
				visibility: 'visible',
				left: 0,
				opacity: 1,
				transitionDelay: '0ms',
		  }
		: {}),
}))

export const ScrollWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'center',

	width: '100%',
	minHeight: '100%',
})

export const Overlay = styled.div({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	opacity: 0.8,
})

export const Background = styled.div.attrs({ className: 'u-theme-background' })({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	opacity: 0.8,
	backgroundColor: colors.PRIMARY,
})

export const ModalAnimation = styled.div<{ open: boolean }>(({ open }) => ({
	position: 'relative',
	width: '100%',
	minHeight: '100vh',

	transition: transition.config,
	transitionProperty: 'transform',

	transform: open ? 'scale(1)' : 'scale(.95)',
	paddingTop: spacing.lg,
	paddingBottom: spacing.sm,
	paddingLeft: spacing.xs,
	paddingRight: spacing.xs,

	...mixins.mediaStyles({
		sm: {
			paddingTop: spacing.md,
			paddingBottom: spacing.md,
			paddingLeft: 0,
			paddingReft: 0,
		},
	}),
}))

interface CloseButtonProps {
	isWhite: boolean
}
export const CloseButton = styled.button<CloseButtonProps>(({ isWhite }) => ({
	position: 'fixed',
	cursor: 'pointer',
	top: 0,
	right: 0,
	zIndex: 100,
	padding: spacing.xs,
	color: isWhite ? `${colors.WHITE} !important` : colors.WHITE,
	...mixins.primaryFont(),
}))

export const Content = styled.div<{ align?: 'left' | 'center' | 'right' }>(
	({ align = 'center' }) => ({
		position: 'relative',
		width: 940,
		maxWidth: '100%',
		display: 'flex',
		justifyContent: 'center',
		margin: `${spacing.lg}px auto`,
		background: colors.WHITE,
		borderRadius: 20,
		padding: spacing.sm,
		textAlign: align,
		boxShadow: shadows[3],

		[' a img ']: {
			maxHeight: 50,
			maxWidth: 200,
		},

		...mixins.mediaStyles({
			md: {
				margin: `${spacing.md}px auto`,
				padding: `${spacing.lg}px 80px ${spacing.md}px`,
			},
		}),
	}),
)
