import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const youtube: SvgIconMap = {
	size: {
		width: 92,
		height: 64,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-YOUTUBE" fill={fill} fillRule="nonzero">
			<path d="M91.2,20 C91.2,9.1 82.4,0.3 71.5,0.3 L20.5,0.3 C9.6,0.3 0.8,9.1 0.8,20 L0.8,44 C0.8,54.9 9.6,63.7 20.5,63.7 L71.5,63.7 C82.4,63.7 91.2,54.9 91.2,44 L91.2,20 Z M59,33.2 L38.7,44.4 C37.8,44.9 37,44.2 37,43.2 L37,20.3 C37,19.3 37.8,18.6 38.7,19.1 L59.2,30.8 C60.1,31.4 59.9,32.7 59,33.2 Z" />
		</g>
	),
}
