import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import React from 'react'
import styled from 'styled-components'

import { SelectedMarker } from '@app/components/marker'
import { env, shellConfig } from '@app/config'
import { CompassUtils } from '@app/utils'
import { MapCategoryResource, MarkerResource } from '@compass/types'
import { transition } from '@styleguide'

const mapStyles = require('./_mapStyles.json')

interface MapProps {
	currentLocation: google.maps.LatLng | google.maps.LatLngLiteral | undefined
	markers: MapMarker[]
	selectedMarkers: MarkerResource[]
	facilityTypes: MapCategoryResource[]
	onMarkerClick?: (marker: MapMarker) => void
	onMarkerClose: (marker: MarkerResource) => void
	onMapClick?: () => void
	onMapBoundsChanged?: (markersInView: MapMarker[]) => void
}

export interface MapMarker {
	icon: string
	id: string | number
	title: string
	position: { lat: number; lng: number }
	visible: boolean
}

interface MapState {
	mapStyle: string
	mapMarkers: MapMarker[]
	zoom: number
	isInitialized: boolean
	hasMovedToCurrentLocation: boolean
	map: any
	// markerClusterer: any
	center: google.maps.LatLng | google.maps.LatLngLiteral
}

const isInsideSquare = (center: google.maps.LatLng | google.maps.LatLngLiteral) =>
	shellConfig.map.bounds.bottomLeft.lat < center.lat &&
	shellConfig.map.bounds.topRight.lat > center.lat &&
	shellConfig.map.bounds.topRight.lng > center.lng &&
	shellConfig.map.bounds.bottomLeft.lng < center.lng

const getMarkerImage = (url: string): any =>
	typeof window !== 'undefined' &&
	window.google && {
		url,
		size: undefined,
		scaledSize: new window.google.maps.Size(43, 72),
	}

// const getClusterIcon = (
// 	url: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAMAAABwfJv6AAAAV1BMVEXoREboREYAAADoREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREboREYhBdz+AAAAHXRSTlNm0QBagQZ0GxNTRkANo5CILSbEm3pNy7OpbTozvV7wBBgAAAFFSURBVDjLzZVJloMgFEUf0tgRm0STqNn/OouKjzIgZFx3qPfwz+8ARUxVOqrT51As1xqkXsucqDeJALnplDhSC9TxJOoZSWYdipVEBllRDL28idgz/c3Rm8CkqA+vewny6g5TU2xA+qv44NqDNLtYglxExAWkfIs+8FOcePrgv+KYPI/c+HN0IrurRBLFzhew2HmlxSt/WwxMWGRg6gNYm0dOfLBCYM4iC/PGzpQXJ3yi8qL6L6LJi4YKO8gBy7ZmRhuNRGbUWoyM/T2y03QwjdmJ1CgYe7mnvPvCyAX+NkF9q03pxGL2G3j2/CbO752xINM9ijuB2H1dB5DlEhRmARn8TdHAY7oH57UzONbai7oO2qlUMIO1pkgzDT2KzmxzXqujG3eQKU0O56vZtonjbPJVsKsMTlupUQyotkYah2y26Kn5AXNMDWtSbnkYAAAAAElFTkSuQmCC',
// ) => ({
// 	height: 41,
// 	url,
// 	width: 40,
// 	textColor: 'white',
// 	fontFamily: 'Larke Sans, sans-serif',
// 	textSize: 16,
// })

// const markerClustererIcon = getClusterIcon()

const MapContainer = styled.div<{ isVisible: boolean }>(({ isVisible = false }) => ({
	position: 'relative',
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
	transition: transition.config,
	transitionProperty: 'opacity',
	opacity: isVisible ? 1 : 0,
}))

export class Map extends React.Component<MapProps, MapState> {
	public state: MapState = {
		mapStyle: 'default',
		zoom: 13,
		isInitialized: false,
		map: undefined,
		mapMarkers: this.props.markers.map(marker => ({
			...marker,
			icon: getMarkerImage(marker.icon),
		})),
		// markerClusterer: undefined,
		hasMovedToCurrentLocation: false,
		center: shellConfig.map.center,
	}

	private onZoomChanged = () => {
		this.setState({ mapStyle: this.state.zoom >= 17 ? 'zoomed' : 'default' })
	}

	private setMap = (map: any) => {
		this.setState({ map })
	}

	// private setMarkerClusterer = (markerClusterer: any) => {
	// 	this.setState({ markerClusterer })
	// }

	private onTilesLoaded = () => {
		this.setState({
			isInitialized: true,
		})

		this.fetchMarkers()
	}

	private fetchMarkers(markers: MapMarker[] = this.props.markers) {
		this.setState({
			mapMarkers: markers.map(marker => ({
				...marker,
				icon: getMarkerImage(marker.icon),
			})),
		})
	}

	public setCenter(center: google.maps.LatLng | google.maps.LatLngLiteral) {
		this.setState({ center })
	}

	public setZoom(zoom: number) {
		this.setState({ zoom })
	}

	public componentWillMount() {
		if (this.props.currentLocation && isInsideSquare(this.props.currentLocation)) {
			this.handleCurrentLocation(this.props.currentLocation)
		}
	}

	public componentWillReceiveProps(nextProps: MapProps) {
		if (
			nextProps.currentLocation &&
			JSON.stringify(nextProps.currentLocation) !==
				JSON.stringify(this.props.currentLocation) &&
			isInsideSquare(nextProps.currentLocation)
		) {
			this.handleCurrentLocation(nextProps.currentLocation)
		}

		if (JSON.stringify(this.props.markers) !== JSON.stringify(nextProps.markers)) {
			this.fetchMarkers(nextProps.markers)
		}
	}

	// public componentDidUpdate(prevProps: MapProps) {
	// 	if (
	// 		this.props.markers &&
	// 		// @ts-ignore
	// 		JSON.stringify(prevProps.markers.filter(m => m.isVisible).map(m => m.id)) !==
	// 			// @ts-ignore
	// 			JSON.stringify(this.props.markers.filter(m => m.isVisible).map(m => m.id)) &&
	// 		this.state.markerClusterer
	// 	) {
	// 		this.state.markerClusterer.repaint()
	// 	}
	// }

	private handleCurrentLocation(location: google.maps.LatLng | google.maps.LatLngLiteral) {
		if (this.state.hasMovedToCurrentLocation) {
			return
		}

		this.setState(
			{
				hasMovedToCurrentLocation: true,
			},
			() => {
				this.setCenter(location)
			},
		)
	}

	// private onClusteringEnd = (markerClusterer: any) => {
	// 	const { markers, facilityTypes } = this.props
	// 	const clusters = markerClusterer.getClusters()

	// 	clusters.forEach((cluster: any) => {
	// 		const clusterMarkers = cluster.getMarkers()

	// 		const mapCategories: MapCategoryResource[] = []

	// 		clusterMarkers.forEach((m: any) => {
	// 			const marker = markers.find(propMarkers => m.title === propMarkers.title)
	// 			const markerCategory = CompassUtils.map.getMarkerCategoryByMarker(
	// 				marker,
	// 				facilityTypes,
	// 			)
	// 			const markerMapCategory = CompassUtils.map.getMapCategoryByMarkerCategory(
	// 				markerCategory,
	// 				facilityTypes,
	// 			)

	// 			if (
	// 				markerMapCategory &&
	// 				markerMapCategory &&
	// 				mapCategories.indexOf(markerMapCategory) === -1
	// 			) {
	// 				mapCategories.push(markerMapCategory)
	// 			}
	// 		})

	// 		if (mapCategories.length === 1) {
	// 			const icon = getClusterIcon(mapCategories[0].icons.cluster)
	// 			if (cluster.clusterIcon.styles[0].url !== icon.url) {
	// 				cluster.clusterIcon.styles = [icon]
	// 				cluster.updateIcon()
	// 			}
	// 		}
	// 	})
	// }

	private onDragEnd = () => {
		if (!this.state.map) {
			return
		}

		const newPos = this.state.map.getCenter().toJSON()
		this.setCenter(newPos)
	}

	private onBoundsChanged = () => {
		if (this.props.onMapBoundsChanged) {
			const bounds = this.state.map.getBounds()
			const markersInView = this.state.mapMarkers.filter(mapMarker =>
				bounds.contains(mapMarker.position),
			)
			this.props.onMapBoundsChanged(markersInView)
		}
	}

	public render() {
		const {
			facilityTypes,
			onMarkerClick,
			onMarkerClose,
			onMapClick,
			selectedMarkers,
			currentLocation,
		} = this.props

		const { mapMarkers, center, zoom = 13, mapStyle, isInitialized } = this.state

		return (
			<MapContainer isVisible={isInitialized}>
				<LoadScript googleMapsApiKey={env.GOOGLE_MAPS_API_KEY!}>
					<GoogleMap
						zoom={zoom}
						center={center}
						onTilesLoaded={this.onTilesLoaded}
						mapContainerStyle={{
							position: 'relative',
							height: '100%',
							width: '100%',
							flex: 1,
						}}
						options={{
							scaleControl: false,
							mapTypeControl: false,
							fullscreenControl: false,
							zoomControl: true,
							streetViewControl: false,
							gestureHandling: 'greedy',
							styles: mapStyles[mapStyle],
						}}
						onZoomChanged={this.onZoomChanged}
						onLoad={this.setMap}
						onClick={onMapClick}
						onBoundsChanged={this.onBoundsChanged}
						onDragEnd={this.onDragEnd}>
						{currentLocation && isInitialized && (
							<Marker
								position={currentLocation}
								icon={{
									url: '/static/current-location.svg',
									size: undefined,
									origin: new window.google.maps.Point(0, 0),
									anchor: new window.google.maps.Point(35, 35),
									scaledSize: new window.google.maps.Size(70, 70),
								}}
							/>
						)}

						{isInitialized &&
							mapMarkers.map(marker => (
								<Marker
									icon={marker.icon}
									key={marker.id}
									title={marker.title}
									position={marker.position}
									onClick={onMarkerClick && onMarkerClick.bind(this, marker)}
									visible={marker.visible}
								/>
							))}

						{selectedMarkers &&
							selectedMarkers.map((marker, index) => {
								const category = CompassUtils.map.getMarkerCategoryByMarker(
									marker,
									facilityTypes,
								)

								const mapCategory = CompassUtils.map.getMapCategoryByMarkerCategory(
									category,
									facilityTypes,
								)

								return (
									<SelectedMarker
										key={marker.id}
										marker={marker}
										index={index}
										category={category}
										mapCategory={mapCategory}
										onClose={() => onMarkerClose(marker)}
									/>
								)
							})}
					</GoogleMap>
				</LoadScript>
			</MapContainer>
		)
	}
}
