import React from 'react'

import { colors } from '@styleguide'

import * as ImageFlipCardComponents from './image-flip-card-components'

interface ImageFlipCardProps {
	front: string | undefined
	back: string | undefined
	isInView?: boolean
}

export const ImageFlipCard: React.SFC<ImageFlipCardProps> = ({ front, back, isInView }) => (
	<ImageFlipCardComponents.Wrapper>
		<ImageFlipCardComponents.Placeholder src={front} />
		<ImageFlipCardComponents.Container isRotated={!!isInView}>
			<ImageFlipCardComponents.Side front>
				<ImageFlipCardComponents.Image style={{ backgroundImage: `url(${front})` }} />
			</ImageFlipCardComponents.Side>
			<ImageFlipCardComponents.Side back css={{ backgroundColor: colors.WHITE }}>
				<ImageFlipCardComponents.Image style={{ backgroundImage: `url(${back})` }} />
			</ImageFlipCardComponents.Side>
		</ImageFlipCardComponents.Container>
	</ImageFlipCardComponents.Wrapper>
)
