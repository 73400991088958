import slugify from 'slugify'

import { domains } from '@app/config'
import { CategoryFilter, MappedTag } from '@app/types'
import http from '@app/utils/http'
import {
	InspirationGameChoice,
	InspirationGameItemMapped,
	ListResource,
	ListSubmitFormInput,
	PageListResource,
} from '@compass/types'

export const getInitiativeFilters = (resource: PageListResource): CategoryFilter[] => {
	return resource.list
		.filter(({ list_item }) => !!list_item.initiative)
		.reduce(
			(initiatives, { list_item }) => [
				...initiatives,
				{
					name: list_item.initiative as string,
					icon: list_item.initiative_icon,
				},
			],
			[],
		)
		.filter(
			(initiative, index, self) => index === self.findIndex(i => i.name === initiative.name),
		)
		.sort((a, b) => a.name.localeCompare(b.name))
}

export const getInitialInitiativeFilters = (
	resource: PageListResource,
	pathname: string,
): CategoryFilter[] => {
	const categories = getInitiativeFilters(resource)
	const presetCategory = pathname.split('/')[2]

	if (!presetCategory) {
		return []
	}

	return categories.filter(
		category =>
			presetCategory === slugify(category.name.replace(/&amp;/g, ''), { lower: true }),
	)
}

export const getCategoryFilters = (resource: PageListResource): CategoryFilter[] => {
	return resource.list
		.filter(({ categories }) => categories && categories.length > 0)
		.reduce(
			(list, { list_item, categories }) => [
				...list,
				{
					name: categories[0],
					icon: list_item.filtericon,
				},
			],
			[],
		)
		.filter((category, index, self) => index === self.findIndex(c => c.name === category.name))
		.sort((a, b) => a.name.localeCompare(b.name))
}

export const getInitialCategoryFilters = (
	resource: PageListResource,
	pathname: string,
): CategoryFilter[] => {
	const categories = getCategoryFilters(resource)
	const presetCategory = pathname.split('/')[2]

	if (!presetCategory) {
		return []
	}

	return categories.filter(
		category =>
			presetCategory === slugify(category.name.replace(/&amp;/g, ''), { lower: true }),
	)
}

export const getTagsList = (resource: PageListResource): MappedTag[] => {
	return resource.list
		.reduce((tags: string[], { tags: itemTags = [] }: ListResource): string[] => {
			return tags.concat(itemTags)
		}, [])
		.reduce((tags: MappedTag[], tag: string): MappedTag[] => {
			const existingIndex = tags.findIndex(
				(mappedTag: MappedTag): boolean => mappedTag.tag === tag,
			)
			const tagIndex = existingIndex > -1 ? existingIndex : tags.push({ tag, count: 0 }) - 1

			tags[tagIndex].count++
			return tags
		}, [])
		.sort(
			(a: MappedTag, b: MappedTag): number => {
				return b.count - a.count
			},
		)
}

export const itemHasTags = (item: ListResource, tags: string[]): boolean =>
	tags.reduce(
		(hasAllActiveFilters: boolean, tag: string) =>
			!hasAllActiveFilters ? hasAllActiveFilters : item.tags && item.tags.indexOf(tag) > -1,
		true,
	)

export const itemHasIGFilters = (item: ListResource, choices: InspirationGameChoice[] = []) => {
	const { inspiration_game = [] } = item.list_item

	return (
		choices.reduce((amountOfMatches: number, choice: InspirationGameChoice): number => {
			const questionIndex = inspiration_game.findIndex(
				(igItem: InspirationGameItemMapped) => igItem.question === choice.question,
			)
			return (
				amountOfMatches +
				(questionIndex > -1 &&
				inspiration_game[questionIndex].answers.indexOf(choice.answer) > -1
					? 1
					: 0)
			)
		}, 0) === choices.length
	)
}

export const submit = (values: ListSubmitFormInput) =>
	http(`${domains.api}/forms`, 'POST', {
		...values,
		action: 'listSubmit',
	})
