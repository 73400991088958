import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { SvgIcon, SvgIconName } from '@app/components'
import { CompassUtils } from '@app/utils'
import { Resource } from '@compass/types'
import { colors, mixins, spacing, themeColors, transition } from '@styleguide'

interface LogoProps {
	navigation: Resource[]
	activeSection: string | undefined
	isLarge: boolean
	setActiveSection: (section: string | undefined) => void
}

import { EventsDate } from './events-date'
import * as Arrows from './logo-arrows'
import * as Icons from './logo-icons'
import * as Titles from './logo-titles'

interface WrapperProps {
	isLarge: boolean
}

const LogoWrapper = styled.nav<WrapperProps>(({ isLarge }) => ({
	display: 'none',
	position: 'relative',
	marginBottom: 40,

	...mixins.mediaStyles({
		md: {
			display: 'block',
			marginTop: 160,
			width: 650,
			height: 650,
			marginBottom: 0,
		},
		lg: {
			marginTop: 0,
			width: isLarge ? '70vh' : '55vh',
			height: isLarge ? '70vh' : '55vh',
			marginRight: isLarge ? 0 : '30%', // push to left
		},
	}),
}))

const MobileDomainLabel = styled.span({
	display: 'block',
	fontSize: '2rem',
	color: colors.PRIMARY,
	margin: `${spacing.sm}px 0 ${spacing.xs}px`,
	...mixins.primaryFont(),
	...mixins.mediaStyles({
		sm: {
			display: 'none',
		},
	}),
})

const MobileLabel = styled.span({
	fontSize: '2rem',
	...mixins.mediaStyles({
		sm: {
			display: 'none',
		},
	}),
	...mixins.primaryFont(),
})

const LogoLink = styled(({ isHidden, ...rest }) => <Link {...rest} />)<{ isHidden: boolean }>(
	({ isHidden }) => ({
		display: 'flex',
		margin: `0 -${spacing.sm}px`,
		alignItems: 'center',
		color: 'white',
		padding: `${spacing.xs}px ${spacing.sm}px`,

		...mixins.mediaStyles({
			sm: {
				transition: transition.config,
				transitionProperty: 'opacity',
				background: 'transparent',
				margin: 0,
				padding: 0,

				...(isHidden
					? {
							opacity: 0.5,
					  }
					: {}),
			},
		}),
	}),
)

export class Logo extends React.Component<LogoProps> {
	private onMouseEnter(section: string) {
		this.props.setActiveSection(section)
	}

	private onMouseLeave() {
		this.props.setActiveSection(undefined)
	}

	public render() {
		const { activeSection, navigation, isLarge } = this.props
		return (
			<LogoWrapper isLarge={isLarge}>
				{navigation.map(domain => {
					return (
						<React.Fragment key={domain.title}>
							<MobileDomainLabel>{domain.title}</MobileDomainLabel>
							{domain.resources &&
								domain.resources.map(section => {
									const icon = CompassUtils.navigation.getSectionIcon(section)
									const Arrow = Arrows[icon as keyof typeof Arrows]
									const Icon = Icons[icon as keyof typeof Icons]
									const Title = Titles[icon as keyof typeof Titles]

									const frontPageIcon = `frontpage${icon}` as SvgIconName

									const theme = CompassUtils.navigation.getSectionSlugByResourceSlug(
										section.slug,
									)
									const isActive = activeSection === theme
									const themeSectionColors =
										themeColors[theme as keyof typeof themeColors]

									if (!themeSectionColors) {
										return null
									}

									return (
										<LogoLink
											css={{ backgroundColor: themeSectionColors.light }}
											key={section.slug}
											to={section.slug}
											aria-label={section.title}
											isHidden={activeSection && activeSection !== theme}
											onMouseEnter={() => this.onMouseEnter(theme)}
											onMouseLeave={() => this.onMouseLeave()}>
											<Arrow icon={frontPageIcon} />
											<Icon
												color={themeSectionColors.light}
												isActive={isActive}
												isLarge={isLarge}>
												<SvgIcon
													icon={icon}
													fill={themeSectionColors.light}
												/>
												{icon === 'events' && (
													<EventsDate isActive={isActive}>
														{new Date().getDate()}
													</EventsDate>
												)}
											</Icon>
											<Title
												color={themeSectionColors.light}
												isActive={isActive}>
												{section.title}
											</Title>
											<MobileLabel>{section.title}</MobileLabel>
										</LogoLink>
									)
								})}
						</React.Fragment>
					)
				})}
			</LogoWrapper>
		)
	}
}
