import React from 'react'
import activeHtml from 'react-active-html'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

interface RichTextContentProps {
	content: string
}

interface RichTextContentState {
	shouldRenderHtml: boolean
}

interface LinkProps {
	href: string
}

const RichTextContentGlam = styled.div.attrs({ className: 's-rich-text-content' })<
	React.HTMLProps<HTMLDivElement>
>({
	['& p, & ul, & ol']: {
		fontSize: '1.8rem',

		['&:not(:last-child)']: {
			marginBottom: spacing.sm,
		},
	},

	['& ul, & ol']: {
		paddingLeft: '24px',
	},

	['& a']: {
		color: colors.PRIMARY,
		...mixins.linkUnderline(),
	},

	['& img, & video, & .wp-video']: {
		maxWidth: '100%',
		height: 'auto',
	},
})

const componentsMap = {
	a: (props: LinkProps) => <Link {...props} to={props.href} />,
}

export class RichTextContent extends React.Component<RichTextContentProps, RichTextContentState> {
	public state: RichTextContentState = {
		shouldRenderHtml: false,
	}

	public shouldComponentUpdate(nextProps: RichTextContentProps) {
		return this.props.content !== nextProps.content
	}

	public componentDidMount() {
		this.setState({ shouldRenderHtml: true })
	}

	public render() {
		const { content } = this.props
		const { shouldRenderHtml } = this.state

		if (shouldRenderHtml) {
			const nodes = activeHtml(content, componentsMap)
			return <RichTextContentGlam>{nodes}</RichTextContentGlam>
		} else {
			return <RichTextContentGlam dangerouslySetInnerHTML={{ __html: content }} />
		}
	}
}
