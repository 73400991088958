import React from 'react'

import { colors } from '@styleguide'

import { SvgIconMap } from '../types'

const defaultFill = colors.GREY_60

export const info: SvgIconMap = {
	size: {
		width: 1000,
		height: 1000,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M621.6 0C688.6 0 722.1 45.6 722.1 97.9 722.1 163.1 663.9 223.4 588.2 223.4 524.7 223.4 487.7 185.9 489.4 124 489.4 71.8 533.4 0 621.6 0L621.6 0ZM415.4 999.9C362.6 999.9 323.8 967.4 360.8 823.8L421.4 569.3C432 528.5 433.8 512.2 421.4 512.2 405.6 512.2 336.9 540.4 296.4 568.2L270.1 524.1C398.7 414.8 546.7 350.7 610 350.7 662.8 350.7 671.8 414.3 645.3 512.2L575.8 779.7C563.5 827.1 568.8 843.4 581.1 843.4 596.9 843.4 649 823.8 700 782.9L730 823.8C604.8 951 468.3 999.9 415.4 999.9L415.4 999.9ZM415.4 999.9"
		/>
	),
}
