import React from 'react'

import { ThemeWrapper } from '@app/components/theme-wrapper'
import { ArticleComponent } from '@app/pages/media/components'
import { FeaturedBlog as FeaturedBlogInterface } from '@compass/types'

import { Full, Wrapper } from './components'

export interface FeaturedBlogProps {
	item: FeaturedBlogInterface
	onClick?: any
}

export const FeaturedBlog: React.SFC<FeaturedBlogProps> = ({ item, onClick }) => {
	return (
		<Full>
			<Wrapper css={{ padding: 0 }}>
				<ThemeWrapper fixedTheme="media">
					<ArticleComponent
						item={item.blog}
						onClick={() => onClick({ article: item.blog })}
					/>
				</ThemeWrapper>
			</Wrapper>
		</Full>
	)
}
