import React from 'react'
import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

interface LabelProps {
	label: string
}

const LabelGlam = styled.div({
	display: 'block',
	fontSize: '2rem',
	textTransform: 'lowercase',
	fontWeight: 'bold',
	color: colors.WHITE,
	...mixins.primaryFont(),

	['&:not(:last-child)']: {
		marginBottom: spacing.xs,
	},
})

export const Label: React.SFC<LabelProps> = ({ label }) => {
	return <LabelGlam className={'u-theme-text'}>{label}</LabelGlam>
}
