import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const transport: SvgIconMap = {
	size: {
		width: 54,
		height: 49,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-32" fill={fill} fillRule="nonzero">
			<path
				d="M42.07,42.67 L12.16,42.67 C8.63,42.67 5.76,39.8 5.76,36.27 L5.76,6.89 C5.76,3.36 8.63,0.49 12.16,0.49 L42.08,0.49 C45.61,0.49 48.48,3.36 48.48,6.89 L48.48,36.26 C48.48,39.8 45.6,42.67 42.07,42.67 Z M12.16,3.49 C10.28,3.49 8.76,5.02 8.76,6.89 L8.76,36.26 C8.76,38.14 10.29,39.66 12.16,39.66 L42.08,39.66 C43.96,39.66 45.48,38.13 45.48,36.26 L45.48,6.89 C45.48,5.01 43.95,3.49 42.08,3.49 L12.16,3.49 Z"
				id="Shape"
			/>
			<polygon
				id="Path"
				points="53.39 29.74 50.39 29.74 50.39 25.8 3.84 25.8 3.84 29.74 0.84 29.74 0.84 22.8 53.39 22.8"
			/>
			<rect id="Rectangle" x="6.44" y="8.65" width="41.35" height="3" />
			<rect id="Rectangle" x="25.62" y="10.45" width="3" height="12.76" />
			<g id="Group" transform="translate(12.000000, 31.000000)">
				<circle id="Oval" cx="3.15" cy="2.55" r="2.18" />
				<circle id="Oval" cx="27.09" cy="2.55" r="2.18" />
			</g>
			<g id="Group" transform="translate(8.000000, 40.000000)">
				<g>
					<path
						d="M3.36,7.7 L3.36,7.7 C1.86,7.7 0.64,6.48 0.64,4.98 L0.64,0.63 L6.08,0.63 L6.08,4.98 C6.08,6.48 4.86,7.7 3.36,7.7 Z"
						id="Path"
					/>
					<path
						d="M3.36,8.24 C1.56,8.24 0.1,6.78 0.1,4.98 L0.1,0.08 L6.63,0.08 L6.63,4.98 C6.62,6.78 5.16,8.24 3.36,8.24 Z M1.18,1.17 L1.18,4.98 C1.18,6.18 2.16,7.16 3.36,7.16 C4.56,7.16 5.54,6.18 5.54,4.98 L5.54,1.17 L1.18,1.17 Z"
						id="Shape"
					/>
				</g>
				<g transform="translate(31.000000, 0.000000)">
					<path
						d="M3.88,8.24 L3.88,8.24 C2.38,8.24 1.16,7.02 1.16,5.52 L1.16,1.17 L6.6,1.17 L6.6,5.52 C6.6,7.02 5.38,8.24 3.88,8.24 Z"
						id="Path"
					/>
					<path
						d="M3.88,8.78 C2.08,8.78 0.62,7.32 0.62,5.52 L0.62,0.62 L7.15,0.62 L7.15,5.52 C7.14,7.32 5.68,8.78 3.88,8.78 Z M1.7,1.71 L1.7,5.52 C1.7,6.72 2.68,7.7 3.88,7.7 C5.08,7.7 6.06,6.72 6.06,5.52 L6.06,1.71 L1.7,1.71 Z"
						id="Shape"
					/>
				</g>
			</g>
		</g>
	),
}
