import { OverlayView } from '@react-google-maps/api'
import React from 'react'
import Linkify from 'react-linkify'
import styled from 'styled-components'

import { MapCategoryResource, MarkerCategory, MarkerResource } from '@compass/types'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'
import { SvgIcon } from '../svg-icons'

interface MarkerTeaserProps {
	marker: MarkerResource
	isActive: boolean
	category: MarkerCategory | undefined
	onClick?: () => void
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

interface MarkerTeaserWrapperProps {
	isActive: boolean
}

export const MarkerTeaserWrapper = styled.div<MarkerTeaserWrapperProps>(({ isActive }) => ({
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	padding: `10px ${spacing.sm}px`,
	transition: transition.config,
	transitionProperty: 'background',
	background: isActive ? colors.GREY_20 : 'transparent',

	['&:hover']: {
		background: isActive ? colors.GREY_20 : colors.GREY_10,
	},
}))

export const MarkerTeaserCategoryIcon = styled.div({
	position: 'relative',
	width: 30,
	height: 'auto',
	display: 'block',
	marginRight: spacing.xs,
	flexBasis: 30,
	flexGrow: 0,
	flexShrink: 0,
})

export const MarkerTeaserCategoryIconImage = styled.img({
	height: 48,
	width: 'auto',
	display: 'block',
})

export const SelectedMarkerTeaserCategoryIconImage = styled(MarkerTeaserCategoryIconImage)({
	width: 40,
	height: 'auto',
	marginRight: spacing.xs / 2,
	filter: 'brightness(0) invert(1)',
})

export const MarkerTeaserCategoryLabel = styled.span({
	display: 'block',
	fontSize: '1.4rem',
	color: colors.GREY_40,
	lineHeight: 1.2,
	marginBottom: 3,
	...mixins.primaryFont(),
})

export const MarkerTeaserName = styled.span({
	display: 'block',
	fontSize: '1.5rem',
	lineHeight: 1.2,
	color: colors.GREY_60,
	...mixins.primaryFont(),
})

export const MarkerTeaser: React.SFC<MarkerTeaserProps> = ({
	category,
	marker,
	isActive,
	onClick,
	onMouseEnter,
	onMouseLeave,
}) => (
	<MarkerTeaserWrapper
		isActive={isActive}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		onClick={onClick}>
		{category && category.icons.marker && (
			<MarkerTeaserCategoryIcon>
				<MarkerTeaserCategoryIconImage src={category.icons.marker} />
			</MarkerTeaserCategoryIcon>
		)}
		<div>
			{category && (
				<MarkerTeaserCategoryLabel dangerouslySetInnerHTML={{ __html: category.name }} />
			)}
			<MarkerTeaserName>{marker.title}</MarkerTeaserName>
		</div>
	</MarkerTeaserWrapper>
)

interface SelectedMarkerProps {
	marker: MarkerResource
	index: number
	category: MarkerCategory | undefined
	mapCategory: MapCategoryResource | undefined
	onClose: () => void
}

export const SelectedMarkerWrapper = styled.aside({
	maxWidth: 350,
	width: `calc(100vw - 30px)`,
	boxShadow: shadows[6],
	position: 'relative',
	zIndex: 20000,
	background: colors.WHITE,
})

const SelectedMarkerTitle = styled.h1({
	fontSize: '1.8rem',
	color: colors.WHITE,
	margin: 0,
	marginRight: spacing.xs,
	flex: 1,
})

const SelectedMarkerImage = styled.img({
	display: 'block',
	width: '100%',
	marginBottom: spacing.sm,
})

const SelecterMarkerDetailWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',

	['& a']: {
		color: colors.GREY_50,
		textDecoration: 'underline',
	},

	['&:not(:last-child)']: {
		marginBottom: 5,
	},
})

const SelectedMarkerDetailValue = styled.div({
	wordBreak: 'break-word',
	fontSize: '1.4rem',
})

const SelectedMarkerCloseButton = styled.button({
	cursor: 'pointer',
	color: colors.WHITE,
	transition: transition.config,
	transitionProperty: 'color',

	['&:hover']: {
		color: colors.GREY_30,
	},
})

const SelectedMarkerHeader = styled.div({
	display: 'flex',
	alignItems: 'center',
	padding: spacing.xs / 2,
	paddingRight: spacing.xs,
})

const SelectedMarkerContent = styled.div({
	padding: `${spacing.sm}px ${spacing.xs}px`,
})

const SelectedMarkerDetailIcon = styled.div({
	width: 24,
	height: 24,
	borderRadius: '50%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexShrink: 0,
	marginRight: 10,
})

export interface SelectedMarkerDetailProps {
	label: string
	backgroundColor: string
}

export const SelectedMarkerDetail: React.SFC<SelectedMarkerDetailProps> = ({
	backgroundColor,
	label,
	children,
}) => {
	const labelText = label.toLowerCase()
	const icon =
		labelText === 'address'
			? 'marker'
			: labelText === 'website'
			? 'website'
			: labelText === 'phone'
			? 'phone'
			: labelText === 'mail'
			? 'contact'
			: labelText === 'discount'
			? 'finances'
			: undefined

	return (
		<SelecterMarkerDetailWrapper>
			{icon ? (
				<SelectedMarkerDetailIcon css={{ backgroundColor }}>
					<SvgIcon icon={icon} fill={colors.WHITE} css={{ width: 16 }} />
				</SelectedMarkerDetailIcon>
			) : (
				label
			)}
			<SelectedMarkerDetailValue>{children}</SelectedMarkerDetailValue>
		</SelecterMarkerDetailWrapper>
	)
}

const getPixelPositionOffset = (width: number, height: number) => ({
	x: -(width / 2),
	y: -(height / 2),
})

interface SelectedMarkerState {
	div: HTMLElement | null
}

export class SelectedMarker extends React.Component<SelectedMarkerProps, SelectedMarkerState> {
	public state = {
		div: null,
	}

	public listeners: any = []

	public render() {
		const { marker, index, category, mapCategory, onClose } = this.props

		return (
			<OverlayView
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
				position={marker.address}
				onLoad={() => {
					// By default, clicking on an overlay view, markers underneath are also clicked.
					// This hack disables that.
					const events = [
						'mousedown',
						'mouseup',
						'touchstart',
						'touchend',
						'click',
						'dblclick',
					]

					events.forEach(event => {
						this.listeners.push(
							window.google.maps.event.addDomListener(
								this.state.div,
								event,
								(e: any) => {
									if (
										e.target.nodeName === 'BUTTON' ||
										e.target.parentElement.nodeName === 'BUTTON' ||
										e.target.parentElement.parentElement.nodeName === 'BUTTON'
									) {
										onClose()
									}

									e.cancelBubble = true
									if (e.stopPropagation) {
										e.stopPropagation()
									}
								},
							),
						)
					})
				}}
				onUnmount={() => {
					this.listeners.forEach((listener: any) =>
						window.google.maps.event.removeListener(listener),
					)
				}}
				getPixelPositionOffset={getPixelPositionOffset}>
				<SelectedMarkerWrapper
					ref={div => {
						if (!this.state.div) {
							this.setState({ div })
						}
					}}
					css={{ zIndex: 200000 + index }}>
					{category && mapCategory && (
						<SelectedMarkerHeader css={{ backgroundColor: category.color }}>
							<SelectedMarkerTeaserCategoryIconImage
								width={40}
								height={40}
								src={category.icons.icon || mapCategory.icons.filter}
								alt={category.name}
							/>
							<SelectedMarkerTitle>{marker.title}</SelectedMarkerTitle>
							<SelectedMarkerCloseButton type="button" onClick={onClose}>
								<SvgIcon icon="cross" />
							</SelectedMarkerCloseButton>
						</SelectedMarkerHeader>
					)}
					{marker && category && (
						<SelectedMarkerContent>
							<Linkify
								componentDecorator={(href, text) => (
									<a href={href} target="_blank">
										{text}
									</a>
								)}>
								{marker.image && <SelectedMarkerImage src={marker.image} />}
								{marker.address.address && (
									<SelectedMarkerDetail
										label="Address"
										backgroundColor={category.color}>
										{marker.address.address}
									</SelectedMarkerDetail>
								)}
								{marker.details &&
									Object.entries(marker.details).map(([label, value]) => (
										<SelectedMarkerDetail
											key={label}
											label={label}
											backgroundColor={category.color}>
											{value}
										</SelectedMarkerDetail>
									))}
							</Linkify>
						</SelectedMarkerContent>
					)}
				</SelectedMarkerWrapper>
			</OverlayView>
		)
	}
}
