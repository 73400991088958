import React from 'react'
import styled from 'styled-components'

import { mixins, transition } from '@styleguide'

type VideoType = keyof typeof videos

interface BackgroundVideosProps {
	activeSection: VideoType | undefined
}

interface BackgroundVideosState {
	activeSection: VideoType | undefined
	activeSources: VideoSource[]
	loadedSources: VideoSource[]
}

enum VideoSource {
	FINANCES = 'finances',
	HOUSING = 'housing',
	HEALTH = 'health',
	TRANSPORT = 'transport',
	THE_CITY = 'city-map',
	GO_EXPLORE = 'go-explore',
}

const videos = {
	finances: VideoSource.FINANCES,
	housing: VideoSource.HOUSING,
	health: VideoSource.HEALTH,
	transport: VideoSource.TRANSPORT,
	'city-map': VideoSource.THE_CITY,
	sports: VideoSource.GO_EXPLORE,
	community: VideoSource.GO_EXPLORE,
	events: VideoSource.GO_EXPLORE,
}

const BackgroundVideosWrapper = styled.div<BackgroundVideoProps>(({ isVisible }) => ({
	position: 'absolute',
	display: 'none',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	opacity: isVisible ? 0.3 : 0,
	transition: transition.config,
	transitionProperty: 'opacity',

	['&:before']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		content: '""',
		opacity: 0.3,
		background: 'url(/static/loading-small-grayscale.gif) repeat',
	},

	...mixins.mediaStyles({
		sm: {
			display: 'block',
		},
	}),
}))

interface BackgroundVideoProps {
	isVisible: boolean
}

const BackgroundVideo = styled.video<BackgroundVideoProps>(({ isVisible }) => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	objectFit: 'cover',
	opacity: isVisible ? 1 : 0,
	transition: transition.config,
	transitionProperty: 'opacity',
	backgroundColor: 'transparent',
}))

export class BackgroundVideos extends React.Component<
	BackgroundVideosProps,
	BackgroundVideosState
> {
	public state: BackgroundVideosState = {
		activeSection: this.props.activeSection,
		loadedSources: [],
		activeSources: [],
	}

	private timeout: number
	private videos: { [key in VideoSource]?: HTMLVideoElement } = {}

	public componentWillReceiveProps({ activeSection }: BackgroundVideosProps) {
		if (!activeSection) {
			return this.setState({ activeSection })
		}

		clearTimeout(this.timeout)

		const newState = {
			activeSection,
			activeSources: [...this.state.activeSources, videos[activeSection]],
		}

		if (this.state.activeSection) {
			this.setState({ activeSection: undefined })

			this.timeout = window.setTimeout(() => {
				this.setState(newState)
			}, 500)
		} else {
			this.setState(newState)
		}
	}

	public componentDidUpdate() {
		Object.values(this.videos).forEach(video => video && video.pause())

		if (this.state.activeSection) {
			this.videos[videos[this.state.activeSection]]!.play()
		}
	}

	private createRef(video: HTMLVideoElement, source: VideoSource) {
		if (video) {
			this.videos[source] = video
			video.onloadeddata = () => {
				this.setState({
					loadedSources: [...this.state.loadedSources, source],
				})
			}
		}
	}

	public render() {
		const { activeSection, activeSources, loadedSources } = this.state

		return (
			<BackgroundVideosWrapper isVisible={!!activeSection}>
				{Object.values(VideoSource)
					.filter((source: VideoSource) => activeSources.includes(source))
					.map((source: VideoSource) => (
						<BackgroundVideo
							key={source}
							isVisible={
								!!(activeSection && videos[activeSection] === source) &&
								loadedSources.includes(source)
							}
							muted={true}
							loop={true}
							ref={(video: HTMLVideoElement) => this.createRef(video, source)}
							src={`/static/videos/${source}.mp4`}
						/>
					))}
			</BackgroundVideosWrapper>
		)
	}
}
