import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const retweet: SvgIconMap = {
	size: {
		width: 1000,
		height: 1000,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M341.2 238.4C450 238.3 697 238.3 805.8 238.4 823.4 237.6 839.8 252.5 839.9 270.4 840.2 360.6 839.8 450.9 840 541.1 888.1 541.3 936.3 541 984.4 541.3 920.4 612 855.8 682.4 791.5 752.9 788.5 758.3 784.9 753 782.2 750.2 719.1 680.3 654.9 611.4 592 541.4 640 540.9 687.9 541.4 735.9 541.2 736.4 474.9 736.3 408.7 735.9 342.5 684 342.3 494.2 342.5 442.3 342.4 408.9 307.4 374.4 273.5 341.2 238.4M204.8 240.7C208.2 234.8 212 241.7 215.1 244.3 277.9 313.8 341.8 382.4 404.3 452.2 356.2 452.7 308.2 452.1 260.1 452.5 259.9 518.6 260 584.7 260.1 650.8 312 651.3 507.8 650.8 559.7 651.1 593.1 686 627.7 719.9 660.8 755.1 551.4 755.1 298.1 755.2 188.6 755.1 170.9 755.2 155.5 739 156.2 721.4 156.2 631.7 156.3 542.1 156.2 452.4 108.1 452.2 60 452.5 11.9 452.3 75.8 381.4 140.3 311.1 204.8 240.7"
		/>
	),
}
