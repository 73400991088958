import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'
import { SvgIcon } from '../svg-icons'

export interface LoaderProps {
	loading?: boolean
	transparent?: boolean
	fixed?: boolean
	text?: string
	title?: string
}

const LoaderGlam = styled.div<LoaderProps>(
	({ loading = false, transparent = false, fixed = true }) => ({
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: transparent ? 'transparent' : 'rgba(255, 255, 255, 0.4)',
		position: fixed ? 'fixed' : 'absolute',
		zIndex: fixed ? 100000 : 'auto',

		...(loading
			? {
					opacity: 1,
					visibility: 'visible',
					transition: `opacity ${transition.config}`,
			  }
			: {
					visibility: 'hidden',
					opacity: 0,
					transition: `opacity ${transition.config}, visibility 0s ${
						transition.duration
					}`,
			  }),
	}),
)

const animation = css`
	animation: ${keyframes({
			'0%': {
				transform: 'rotate(0)',
			},
			'50%': {
				transform: 'rotate(-180deg)',
			},
			'100%': {
				transform: 'rotate(-360deg)',
			},
		})}
		2s infinite;
`

const LoaderIcon = styled(SvgIcon)`
	display: block;
	width: 64px;
	height: 64px;
	${animation}
`

const LoaderText = styled.span({
	color: colors.GREY_40,
	marginTop: spacing.xs,
	...mixins.primaryFont(),
	fontSize: '1.6rem',
})

export const Loader: React.SFC<LoaderProps> = props => {
	return (
		<LoaderGlam {...props}>
			<LoaderIcon icon="logo" />
			{props.title && <h1 className="u-theme-text">{props.title}</h1>}
			{props.text && <LoaderText>{props.text}</LoaderText>}
		</LoaderGlam>
	)
}
