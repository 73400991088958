import { CSSObject } from 'styled-components'

export const clothoidGradient = (
	alphaFrom: number = 1,
	alphaTo: number = 0,
	rgb: string = '255, 255, 255',
): CSSObject => {
	const diff = alphaFrom - alphaTo
	return {
		backgroundImage: `linear-gradient(
    rgba(${rgb}, ${alphaFrom}) 0,
    rgba(${rgb}, ${alphaFrom - diff * 0.7}) 50%,
    rgba(${rgb}, ${alphaFrom - diff * 0.85}) 65%,
    rgba(${rgb}, ${alphaFrom - diff * 0.925}) 75.5%,
    rgba(${rgb}, ${alphaFrom - diff * 0.963}) 82.85%,
    rgba(${rgb}, ${alphaFrom - diff * 0.981}) 88%,
    rgba(${rgb}, ${alphaTo}) 100%
)`,
	}
}
