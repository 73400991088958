import { debounce } from 'debounce'
import React from 'react'
import * as ReactDOM from 'react-dom'

import { isElementInViewData } from './helpers'

interface InViewProps {
	scrollWrapper?: Element
	onInView?: () => void
	children?(state: InViewState): JSX.Element
}

interface InViewState {
	isInView: boolean
	isScrolledPast: boolean
	element?: Element | Text
	scrollPosition: number
}

export class InView extends React.Component<InViewProps, InViewState> {
	public state: InViewState = {
		isScrolledPast: false,
		isInView: false,
		scrollPosition: 0,
	}
	private element: Element | Text | null

	public onScroll = debounce(this.updateScrollPosition, 50).bind(this)

	public componentDidMount() {
		this.attachScrollListener()
		this.element = ReactDOM.findDOMNode(this)
	}

	public componentWillUnmount() {
		this.detachScrollListener()
	}

	public attachScrollListener() {
		const scrollWrapper = this.props.scrollWrapper || window
		scrollWrapper.addEventListener('scroll', this.onScroll)
		window.addEventListener('resize', this.onScroll)
		this.onScroll()
	}

	public detachScrollListener() {
		const scrollWrapper = this.props.scrollWrapper || window
		scrollWrapper.addEventListener('scroll', this.onScroll)
		window.addEventListener('resize', this.onScroll)
	}
	public updateScrollPosition() {
		let scrollPosition

		if (this.props.scrollWrapper) {
			scrollPosition = this.props.scrollWrapper.scrollTop
		} else {
			scrollPosition =
				window.pageYOffset !== undefined
					? window.pageYOffset
					: ((document.documentElement ||
							document.body.parentNode ||
							document.body) as any).scrollTop
		}

		if (this.element) {
			const newState = isElementInViewData(this.element, scrollPosition)

			if (!this.state.isInView && newState.isInView && this.props.onInView) {
				this.props.onInView()
			}

			this.setState(newState)
		}
	}
	public render() {
		return this.props.children ? this.props.children(this.state) : null
	}
}
