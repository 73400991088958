import styled from 'styled-components'

import { colors, mixins, shadows, spacing } from '@styleguide'

export const Wrapper = styled.div({
	background: colors.WHITE,
	boxShadow: shadows[3],
	borderRadius: '8px',
	overflow: 'hidden',
	padding: spacing.sm,
})

export const Full = styled.div({
	width: '100%',
	padding: `0 10px`,
	margin: `10px 0`,
})

export const Half = styled.div({
	padding: `0 10px`,
	margin: `10px 0`,
	display: 'flex',

	...mixins.mediaStyles({
		md: {
			width: '50%',
		},
	}),
})

export const Content = styled.div.attrs({
	className: 's-rich-text-content',
})({
	fontSize: '1.4rem',
	color: colors.GREY_50,
	marginBottom: 15,

	['h2, h3, h4']: {
		fontSize: '1.8rem',
	},

	['& ol, & ul']: {
		listStyle: 'none',
	},
})

export const Title = styled.h4({
	marginBottom: 10,
})
