import React from 'react'

import { Small, SvgIcon } from '@app/components'
import {
	ListFilters,
	RemoveIDFilterFunction,
	RemoveInstagramFilterFunction,
	RemoveTagFilterFunction,
} from '@app/types'
import { InspirationGameChoice, InspirationGameSet, ListResource } from '@compass/types'

import * as FiltersActiveComponents from './filters-active-components'

interface ListFiltersActiveProps {
	filters: ListFilters
	onRemoveTagFilter: RemoveTagFilterFunction
	onRemoveInstagramFilter: RemoveInstagramFilterFunction
	onRemoveIDFilter: RemoveIDFilterFunction
	items: ListResource[]
	inspirationGame: InspirationGameSet[]
}

export class FiltersActive extends React.Component<ListFiltersActiveProps> {
	public static Wrapper = FiltersActiveComponents.Wrapper
	public static ActiveTag = FiltersActiveComponents.ActiveTag

	public render() {
		const { filters, inspirationGame, items } = this.props

		const filteredItem = filters.id ? items.find(i => i.id === filters.id) : undefined
		const hasFilters =
			(filters.id && filteredItem) || filters.tags.length || filters.inspirationGame.length

		return hasFilters ? (
			<FiltersActive.Wrapper>
				{filters.id && filteredItem && (
					<div>
						<Small css={{ marginBottom: 5 }}>Organisation:</Small>
						<div>
							<FiltersActive.ActiveTag
								className="u-theme-background"
								onClick={this.props.onRemoveIDFilter}>
								<span>{filteredItem.title}</span>
								<SvgIcon icon="cross" />
							</FiltersActive.ActiveTag>
						</div>
					</div>
				)}
				{filters.tags.length ? (
					<div>
						<Small css={{ marginBottom: 3 }}>Active tags:</Small>
						<div>
							{filters.tags.map((tag: string) => (
								<FiltersActive.ActiveTag
									className="u-theme-background"
									key={tag}
									onClick={() => this.props.onRemoveTagFilter(tag)}>
									<span dangerouslySetInnerHTML={{ __html: tag }} />
									<SvgIcon icon="cross" />
								</FiltersActive.ActiveTag>
							))}
						</div>
					</div>
				) : null}
				{filters.inspirationGame.length ? (
					<div>
						<Small css={{ marginBottom: 3 }}>Inspiration game preferences:</Small>
						<FiltersActiveComponents.IGTable>
							<tbody>
								{filters.inspirationGame.map((choice: InspirationGameChoice) => (
									<tr key={choice.question}>
										<th scope="row">
											<FiltersActiveComponents.Small>
												{inspirationGame[choice.question] &&
													inspirationGame[choice.question].question}
											</FiltersActiveComponents.Small>
										</th>
										<td>
											<FiltersActive.ActiveTag
												className="u-theme-background"
												onClick={() =>
													this.props.onRemoveInstagramFilter(choice)
												}>
												<span
													dangerouslySetInnerHTML={{
														__html: choice.answer,
													}}
												/>
												<SvgIcon icon="cross" />
											</FiltersActive.ActiveTag>
										</td>
									</tr>
								))}
							</tbody>
						</FiltersActiveComponents.IGTable>
					</div>
				) : null}
			</FiltersActive.Wrapper>
		) : null
	}
}
