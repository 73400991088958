import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const community: SvgIconMap = {
	size: {
		width: 46,
		height: 42,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-38" fill={fill} fillRule="nonzero">
			<path
				d="M23.13,15.4 C18.95,15.4 15.55,12 15.55,7.82 C15.55,3.64 18.95,0.24 23.13,0.24 C27.31,0.24 30.71,3.64 30.71,7.82 C30.71,12 27.3,15.4 23.13,15.4 Z M23.13,3.25 C20.61,3.25 18.55,5.3 18.55,7.83 C18.55,10.36 20.6,12.41 23.13,12.41 C25.66,12.41 27.71,10.36 27.71,7.83 C27.71,5.3 25.65,3.25 23.13,3.25 Z"
				id="Shape"
			/>
			<path
				d="M32.43,41.99 L13.82,41.99 L13.82,26.09 C13.82,20.96 17.99,16.79 23.12,16.79 C28.25,16.79 32.43,20.96 32.43,26.09 L32.43,41.99 L32.43,41.99 Z M16.82,38.99 L29.43,38.99 L29.43,26.09 C29.43,22.61 26.6,19.79 23.12,19.79 C19.64,19.79 16.82,22.62 16.82,26.09 L16.82,38.99 Z"
				id="Shape"
			/>
			<path
				d="M8.53,18.96 C4.8,18.96 1.77,15.93 1.77,12.2 C1.77,8.47 4.8,5.44 8.53,5.44 C12.26,5.44 15.29,8.47 15.29,12.2 C15.29,15.93 12.26,18.96 8.53,18.96 Z M8.53,8.44 C6.46,8.44 4.77,10.13 4.77,12.2 C4.77,14.27 6.46,15.96 8.53,15.96 C10.6,15.96 12.29,14.27 12.29,12.2 C12.29,10.13 10.61,8.44 8.53,8.44 Z"
				id="Shape"
			/>
			<path
				d="M16.79,41.99 L0.27,41.99 L0.27,28.02 C0.27,23.47 3.98,19.76 8.53,19.76 C13.08,19.76 16.79,23.47 16.79,28.02 L16.79,41.99 Z M3.27,38.99 L13.79,38.99 L13.79,28.02 C13.79,25.12 11.43,22.76 8.53,22.76 C5.63,22.76 3.27,25.12 3.27,28.02 L3.27,38.99 L3.27,38.99 Z"
				id="Shape"
			/>
			<path
				d="M37.7,18.96 C33.97,18.96 30.94,15.93 30.94,12.2 C30.94,8.47 33.97,5.44 37.7,5.44 C41.43,5.44 44.46,8.47 44.46,12.2 C44.46,15.93 41.43,18.96 37.7,18.96 Z M37.7,8.44 C35.63,8.44 33.94,10.13 33.94,12.2 C33.94,14.27 35.63,15.96 37.7,15.96 C39.77,15.96 41.46,14.27 41.46,12.2 C41.46,10.13 39.77,8.44 37.7,8.44 Z"
				id="Shape"
			/>
			<path
				d="M45.96,41.99 L29.44,41.99 L29.44,28.02 C29.44,23.47 33.15,19.76 37.7,19.76 C42.25,19.76 45.96,23.47 45.96,28.02 L45.96,41.99 Z M32.44,38.99 L42.96,38.99 L42.96,28.02 C42.96,25.12 40.6,22.76 37.7,22.76 C34.8,22.76 32.44,25.12 32.44,28.02 L32.44,38.99 Z"
				id="Shape"
			/>
		</g>
	),
}
