import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { colors, mixins, spacing, themeStyles } from '@styleguide'

import { BlockProps, queryMatchesPage } from './helpers'

export const Wrapper = styled.div.attrs({ className: 'c-section-overview-block' })({
	position: 'relative',
	padding: `0px ${spacing.xs / 2}px`,
	minHeight: '250px',
	marginBottom: spacing.xs,
	zIndex: 0,

	['&:not(:last-child)']: {
		borderBottom: `1px ${colors.WHITE} solid`,
	},

	['& a:hover']: {
		textDecoration: 'underline',
	},

	...mixins.mediaStyles({
		sm: {
			display: 'inline-block',
			flexGrow: 0,
			width: '50%',

			['&:not(:last-child)']: {
				borderBottom: 'none',

				['&:after']: {
					position: 'absolute',
					content: '""',
					display: 'block',
					top: 0,
					right: 0,
					height: '100%',
					width: '1px',
					borderRight: `1px ${colors.GREY_20} solid`,
					opacity: 0.2,
				},
			},
		},
		md: {
			width: '25%',
		},
	}),
})

export const Inner = styled.div({
	position: 'relative',
	padding: `${spacing.sm}px 24px`,
	height: '100%',
})

export const Page = styled(RouterLink)({
	display: 'block',
	color: colors.WHITE,
	fontSize: '2rem',
	fontWeight: 'bold',
	marginBottom: '5px',
	...mixins.primaryFont(),
})

const LinkGlam = styled(RouterLink)({
	color: colors.WHITE,
	fontWeight: 'bold',
	display: 'flex',
	alignItems: 'center',
	marginBottom: 5,
	fontSize: '1.3rem',

	['&:before']: {
		content: '"⤑"',
		marginRight: 5,
	},
	...mixins.primaryFont(),
})

export const Link: React.SFC<BlockProps> = ({ page, query, parentAllowsAll = false }) => {
	return queryMatchesPage(page.title, query) || parentAllowsAll ? (
		<LinkGlam to={page.slug}>{page.title}</LinkGlam>
	) : null
}

export const Background = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	zIndex: -1,
	background: themeStyles.default.background,
	borderRadius: 8,
})
