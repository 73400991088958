import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { BreadcrumbsComponent } from '@app/components/breadcrumbs'
import { TextInput } from '@app/components/form-elements'
import { Small } from '@app/components/small'
import { SvgIcon, SvgIconName } from '@app/components/svg-icons'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { CompassUtils } from '@app/utils'
import { Resource } from '@compass/types'
import { colors, mixins, shadows, spacing, themeColors, transition } from '@styleguide'

const popupContentWidth = 15
export interface InputProps {
	theme: 'light' | 'default'
}

interface ActiveProps {
	active: boolean
}

interface IsHomeProps {
	isHome: boolean
}

interface IsCenteredProps {
	isCentered: boolean
}

export const Input = styled(TextInput)<InputProps>(({ theme }) => ({
	background: colors.WHITE,

	...mixins.mediaStyles({
		md: {
			boxShadow: shadows[5],
		},
	}),

	...(theme === 'light'
		? {
				boxShadow: 'none',
				border: 0,
				borderBottom: `2px ${colors.GREY_30} solid`,

				'& path': {
					fill: colors.GREY_30,
				},

				['&:hover']: {
					borderColor: colors.GREY_40,

					'& path': {
						fill: colors.GREY_40,
					},
				},

				['&:focus']: {
					borderColor: colors.INFO,

					'& path': {
						fill: colors.INFO,
					},
				},
		  }
		: {}),
}))

const opacityKeyframes = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: 1,
	},
})

const transformKeyframes = keyframes({
	'0%': {
		transform: 'scale(0.8)',
	},
	'100%': {
		transform: 'scale(1)',
	},
})

const animation = css`
	animation: ${transformKeyframes} 0.48s cubic-bezier(0.18, 1.4, 0.4, 1) forwards,
		${opacityKeyframes} 0.24s ease-out forwards;
`

const animationHome = css`
	${animation}
	animation-delay: 1s
`

export type PopupProps = ActiveProps & IsHomeProps & IsCenteredProps

const popupComp = styled.div<PopupProps>(({ active, isHome, isCentered }) => ({
	position: 'relative',
	width: '100%',
	marginTop: 20,
	transformOrigin: '50% 0',
	zIndex: 10,

	['> :first-child']: {
		marginBottom: 0,
	},

	[mixins.media.max('md')]: {
		animation: 'none !important',
	},

	...mixins.mediaStyles({
		md: {
			position: 'absolute',
			width: `calc(100% - ${spacing.xs * 2}px)`,
			transform: 'scale(0.8)',
			opacity: 0,
			marginTop: 0,
			top: isHome ? 520 : 70 + spacing.xs,
			right: isHome ? '50%' : spacing.xs,
			marginRight: isHome ? -200 : 0,
			maxWidth: isHome ? 400 : 500,
		},
		lg: {
			visibility: active ? 'visible' : 'hidden',
			zIndex: active ? 100 : 3,
			top: isHome ? '50.5vh' : 70 + spacing.xs,
			right: isHome ? (isCentered ? '50%' : '65%') : spacing.xs,
		},
	}),
}))

// @ts-ignore
export const Popup = styled(popupComp)`
	${props => (props.active ? (props.isHome ? animationHome : animation) : '')}
`

export const Content = styled.div<ActiveProps & IsHomeProps>(({ active, isHome }) => ({
	position: 'absolute',
	padding: `10px ${popupContentWidth}px 0`,
	minHeight: 80,
	transition: isHome ? transition.config : undefined,
	opacity: active ? 1 : 0,
	background: colors.WHITE,
	borderRadius: `0 0 8px 8px`,
	boxShadow: shadows[5],
	pointerEvents: active ? 'initial' : 'none',
	width: '100%',
	zIndex: 100,
}))

// @ts-ignore
export const Title = styled(Small)({})

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

export interface SmallTeaserProps extends Omit<LinkProps, 'resource'> {
	onWhiteBackground?: boolean
	resource: Resource
	icon: SvgIconName | undefined
	theme: string
	navigation?: Resource[]
}

export const SmallTeaser: React.SFC<SmallTeaserProps> = ({
	resource,
	icon,
	theme,
	navigation,
	...rest
}) => {
	const activeTheme = (theme in themeColors ? theme : 'default') as keyof typeof themeColors

	return (
		<SmallTeaserWrapper {...rest}>
			{icon && (
				<SmallTeaserWrapperIconWrapper>
					<SmallTeaserWrapperIcon icon={icon} fill={themeColors[activeTheme].light} />
				</SmallTeaserWrapperIconWrapper>
			)}
			<SmallTeaserWrapperText>
				<ThemeWrapper fixedTheme={theme}>
					<Small className="u-theme-text-important">{resource.title}</Small>
				</ThemeWrapper>
				{navigation && (
					<BreadcrumbsComponent
						isSmall
						breadcrumbs={CompassUtils.navigation.getBreadcrumbs(
							resource.slug,
							navigation,
						)}
					/>
				)}
			</SmallTeaserWrapperText>
		</SmallTeaserWrapper>
	)
}

interface SmallTeaserWrapperProps {
	onWhiteBackground?: boolean
}

const SmallTeaserWrapper = styled(Link)<SmallTeaserWrapperProps>(({ onWhiteBackground }) => ({
	display: 'flex',
	padding: `8px ${popupContentWidth}px`,
	transition: transition.config,
	transitionProperty: 'background',
	margin: `0 -${popupContentWidth}px`,
	background: onWhiteBackground ? colors.GREY_10 : 'transparent',

	['&:hover']: {
		background: onWhiteBackground ? colors.GREY_20 : colors.GREY_10,
	},

	['&:not(:last-child)']: {
		borderBottom: `1px ${colors.GREY_20} solid`,
	},
}))

const SmallTeaserWrapperText = styled.div({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
})

const SmallTeaserWrapperIconWrapper = styled.div({
	width: 32,
	height: 32,
	flex: 1,
	flexShrink: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: spacing.xs,
})

const SmallTeaserWrapperIcon = styled(SvgIcon)({
	display: 'block',
	width: 24,
	height: 'auto',
})
