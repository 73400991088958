import React from 'react'
import styled from 'styled-components'

import { Loader } from '@app/components/loader'
import { Iframe } from '@compass/types'
import { mixins, spacing } from '@styleguide'

interface FullScreenWrapperProps {
	shownOnMobile: boolean
}

const FullScreenWrapper = styled.div<FullScreenWrapperProps>(({ shownOnMobile }) => ({
	position: 'relative',
	height: 'calc(100vh - 280px)',
	marginTop: spacing.md,
	overflow: 'hidden',

	...(!shownOnMobile
		? {
				[mixins.media.max('sm')]: {
					display: 'none',
				},
		  }
		: {}),
}))

export interface FullScreenIframeProps {
	iframe: Iframe
}

export const FullScreenIframe: React.SFC<FullScreenIframeProps> = ({ iframe }) => (
	<FullScreenWrapper shownOnMobile={iframe.shown_on_mobile}>
		<Loader loading fixed={false} />
		<iframe
			src={iframe.src}
			width="100%"
			height="100%"
			allow="fullscreen"
			frameBorder="0"
			style={{ position: 'relative' }}
		/>
	</FullScreenWrapper>
)
