import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const sports: SvgIconMap = {
	size: {
		width: 48,
		height: 48,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-35" fill={fill} fillRule="nonzero">
			<path
				d="M43.15,44.53 C41.87,44.53 40.66,44.03 39.75,43.12 L31.07,34.44 C27.34,36.68 22.97,37.5 18.63,36.74 L15.78,36.25 L39.15,12.88 L39.64,15.73 C40.34,19.79 39.66,23.93 37.72,27.51 L46.54,36.33 C47.45,37.24 47.95,38.44 47.95,39.73 C47.95,41.02 47.45,42.22 46.54,43.13 C45.64,44.03 44.43,44.53 43.15,44.53 Z M31.45,30.58 L41.88,41.01 C42.58,41.71 43.73,41.71 44.43,41.01 C44.77,40.67 44.96,40.22 44.96,39.74 C44.96,39.26 44.77,38.81 44.43,38.47 L33.94,27.97 L34.58,26.96 C36.03,24.67 36.82,22.06 36.91,19.38 L22.27,34.02 C25.18,33.93 27.99,33 30.42,31.3 L31.45,30.58 Z"
				id="Shape"
			/>
			<path
				d="M21.78,37.03 C17.08,37.03 12.42,35.21 8.92,31.71 C1.84,24.63 1.84,13.1 8.92,6.01 C16,-1.07 27.53,-1.07 34.62,6.01 C40.25,11.64 41.55,20.47 37.77,27.48 L35.13,26.06 C38.28,20.21 37.2,12.84 32.5,8.14 C26.58,2.23 16.96,2.23 11.05,8.14 C5.14,14.06 5.14,23.68 11.05,29.59 C15.92,34.46 23.48,35.44 29.42,31.97 L30.93,34.56 C28.07,36.22 24.91,37.03 21.78,37.03 Z"
				id="Path"
			/>
			<path
				d="M6.39,47.54 C4.83,47.54 3.26,46.94 2.07,45.75 C-0.31,43.37 -0.31,39.5 2.07,37.11 C4.45,34.73 8.32,34.73 10.71,37.11 C13.09,39.49 13.09,43.36 10.71,45.75 C9.51,46.94 7.95,47.54 6.39,47.54 Z M6.39,38.33 C5.59,38.33 4.8,38.63 4.19,39.24 C2.98,40.45 2.98,42.42 4.19,43.63 C5.4,44.84 7.37,44.84 8.58,43.63 C9.79,42.42 9.79,40.45 8.58,39.24 C7.98,38.63 7.18,38.33 6.39,38.33 Z"
				id="Shape"
			/>
		</g>
	),
}
