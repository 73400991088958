import truncate from 'html-truncate'
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { EventResource } from '@compass/types'
import { colors, spacing } from '@styleguide'
import { getTimeslotByDateTime } from '../helpers'
import { EventListType, Timeslots } from '../types'
import { EventDate } from './event-date'
import { RichText } from './teaser/components'
import { Timeslot } from './timeslot'

interface EventTooltipItemProps {
	event: EventResource
	type?: EventListType
}

const TooltipItemWrapper = styled.div({
	position: 'relative',
	display: 'flex',
	height: '100%',
	width: '100%',
	overflow: 'hidden',
	paddingBottom: 10,
	paddingTop: 10,
})

const TooltipItemBackgroundLink = styled(NavLink)({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'block',
})

const TooltipItemInner = styled.div({
	position: 'relative',
	width: '100%',
	height: '100%',
	display: 'flex',
})

const TooltipItemImage = styled.figure({
	position: 'relative',
	width: 128,
	height: 84,
	flexShrink: 0,

	backgroundSize: 'cover',
	backgroundPosition: 'center',

	['&:after']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		content: '""',
		background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%)',
	},
})

const TooltipItemContent = styled.div({
	padding: `0 ${spacing.sm}px`,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	overflow: 'hidden',
})

const Title = styled.h3({
	fontSize: '1.6rem',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
})

const TimeslotWrapper = styled.div({
	position: 'absolute',
	top: 180,
	left: spacing.sm,
	color: colors.WHITE,
	display: 'inline-flex',
	alignItems: 'center',
})

export const TooltipItem: React.SFC<EventTooltipItemProps> = ({ event }) => (
	<TooltipItemWrapper itemScope itemType="http://schema.org/Event">
		<meta itemProp="startDate" content={moment(event.event.date.start).toISOString()} />
		<meta itemProp="description" content={event.content} />
		<meta itemProp="image" content={event.event.images.source} />
		<TooltipItemInner>
			<TooltipItemImage css={{ backgroundImage: `url(${event.event.images.card})` }} />
			<TimeslotWrapper>
				<Timeslot timeslot={Timeslots[getTimeslotByDateTime(event.event.date)]} />
			</TimeslotWrapper>

			<TooltipItemBackgroundLink to={event.slug} />

			<TooltipItemContent>
				<div css={{ marginBottom: spacing.xs }}>
					<Title itemProp="name" className="u-theme-text">
						{event.title}
					</Title>

					{event.content && (
						<RichText
							dangerouslySetInnerHTML={{ __html: truncate(event.content, 120) }}
							css={{ marginBottom: 0 }}
						/>
					)}

					<EventDate date={event.event.date} />
				</div>
			</TooltipItemContent>
		</TooltipItemInner>
	</TooltipItemWrapper>
)
