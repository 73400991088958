import truncate from 'html-truncate'
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from '@app/components'
import { RichText } from '@app/components/member/member-components'
import { EventResource } from '@compass/types'
import { colors, shadows, spacing } from '@styleguide'
import { getTimeslotByDateTime } from '../helpers'
import { EventListType, Timeslots } from '../types'
import { EventDate } from './event-date'
import { Timeslot } from './timeslot'

interface EventTileProps {
	event: EventResource
	type?: EventListType
}

const TileWrapper = styled.div({
	position: 'relative',
	display: 'flex',
	background: colors.WHITE,
	boxShadow: shadows[7],
	borderRadius: 8,
	height: '100%',
	overflow: 'hidden',
})

const TileBackgroundLink = styled(NavLink)({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'block',
})

const TileInner = styled.div({
	position: 'relative',
	height: '100%',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
})

const TileImage = styled.figure({
	position: 'relative',
	width: '100%',
	height: 220,

	backgroundSize: 'cover',
	backgroundPosition: 'center',

	['&:after']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		content: '""',
		background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%)',
	},
})

const TileContent = styled.div({
	textAlign: 'center',
	padding: `${spacing.sm}px ${spacing.sm}px ${spacing.xs}px`,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
})

const Title = styled.h3({
	fontSize: '2rem',
	marginTop: 5,
	textAlign: 'center',
	padding: `0 ${spacing.xs}px`,
})

const TimeslotWrapper = styled.div({
	position: 'absolute',
	top: 180,
	left: spacing.sm,
	color: colors.WHITE,
	display: 'inline-flex',
	alignItems: 'center',
})

export const Tile: React.SFC<EventTileProps> = ({ event }) => (
	<TileWrapper itemScope itemType="http://schema.org/Event">
		<meta itemProp="startDate" content={moment(event.event.date.start).toISOString()} />
		<meta itemProp="description" content={event.content} />
		<meta itemProp="image" content={event.event.images.source} />
		<TileInner>
			<TileImage css={{ backgroundImage: `url(${event.event.images.card})` }} />
			<TimeslotWrapper>
				<Timeslot timeslot={Timeslots[getTimeslotByDateTime(event.event.date)]} />
			</TimeslotWrapper>

			<TileBackgroundLink to={event.slug} />

			<TileContent>
				<div css={{ marginBottom: spacing.xs }}>
					<Title itemProp="name" className="u-theme-text">
						{event.title}
					</Title>
					<EventDate date={event.event.date} />
				</div>

				{event.content && (
					<RichText dangerouslySetInnerHTML={{ __html: truncate(event.content, 200) }} />
				)}

				<div css={{ marginTop: 'auto' }}>
					<Button a href={event.event.url} target="_blank" outline buttonSize="small">
						{event.event.buttonText || 'More information'}
					</Button>
				</div>
			</TileContent>
			{event.event.association && (
				<div itemProp="location" itemScope itemType="https://schema.org/Place">
					<meta itemProp="name" content={event.event.association.name} />
					<meta itemProp="address" content={event.event.location.address} />
				</div>
			)}
		</TileInner>
	</TileWrapper>
)
