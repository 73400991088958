import { EventResource } from '@compass/types'
import { EventsState } from '../../types'

export interface EventsOverviewTabsBaseProps {
	featured: EventResource[]
	today: EventResource[]
	thisweek: EventResource[]
	calendar: EventResource[]
	updateState: (data: { state: EventsState }) => void
}

export const EVENTS_STATE_TO_INDEX_MAP = [
	EventsState.FEATURED,
	EventsState.TODAY,
	EventsState.THISWEEK,
	EventsState.CALENDAR,
]

export const getTabsItemTextByState = (
	state: EventsState,
	props: EventsOverviewTabsBaseProps,
): string => {
	switch (state) {
		case EventsState.FEATURED:
			return `Featured (${props.featured.length})`

		case EventsState.TODAY:
			return `Today (${props.today.length})`

		case EventsState.THISWEEK:
			return `This week (${props.thisweek.length})`

		case EventsState.CALENDAR:
			return `Calendar ${props.calendar.length ? `(${props.calendar.length})` : ''}`

		default:
			return ''
	}
}
