import React from 'react'
import styled from 'styled-components'

import { Small, TextInput } from '@app/components'
import * as AutocompleteComponents from '@app/components/autocomplete/autocomplete-components'
import { AddItemFilterFunction, AddTagFilterFunction, MappedTag } from '@app/types'
import { ListResource } from '@compass/types'
import { colors, mixins, spacing } from '@styleguide'

import { SearchAutocomplete, SearchAutocompleteItem } from './autocomplete'
import { FiltersActive } from './filters-active'
import { getMappedAutocompleteItems, getMappedAutocompleteTags } from './tags-search.helpers'

interface ListFiltersSearchProps {
	tagsFilters: MappedTag[]
	items: ListResource[]
	onAddTagFilter?: AddTagFilterFunction
	onAddItemFilter?: AddItemFilterFunction
}

interface ListFiltersSearchState {
	query: string
	autocompleteResults: {
		tags: SearchAutocompleteItem[]
		items: SearchAutocompleteItem[]
	}
}

const WrapperGlam = styled.div({
	position: 'relative',
	marginBottom: spacing.sm,
	marginTop: spacing.sm,
})

const OrganisationResult = styled.span({
	...mixins.primaryFont(),
})

const EmptyLabel = styled.p({
	fontSize: '1.2rem',
	color: colors.GREY_50,
})

const Wrapper = styled.div({
	marginTop: 10,
	marginBottom: 10,
})

export class Search extends React.Component<ListFiltersSearchProps, ListFiltersSearchState> {
	public static Wrapper = WrapperGlam

	public state: ListFiltersSearchState = {
		query: '',
		autocompleteResults: {
			tags: [],
			items: [],
		},
	}

	public setAutocompleteResults = (event: React.FormEvent<HTMLInputElement>) => {
		const { tagsFilters, items } = this.props

		const query = event.currentTarget.value

		const autocompleteTags = getMappedAutocompleteTags(
			tagsFilters.filter(
				(tag: MappedTag) => tag.tag && tag.tag.toLowerCase().includes(query.toLowerCase()),
			),
		)
		const autocompleteItems = getMappedAutocompleteItems(
			items.filter(
				(item: ListResource) =>
					item.title && item.title.toLowerCase().includes(query.toLowerCase()),
			),
		)

		this.setState({
			autocompleteResults: {
				tags: autocompleteTags,
				items: autocompleteItems,
			},
			query,
		})
	}

	public addTagFilter = ({ value }: SearchAutocompleteItem) => {
		if (this.props.onAddTagFilter) {
			this.props.onAddTagFilter(value)
		}

		this.setState({ query: '' })
	}

	public addItemFilter = ({ context }: SearchAutocompleteItem) => {
		if (this.props.onAddItemFilter && context && context.resource) {
			this.props.onAddItemFilter(context.resource.id)
		}

		this.setState({ query: '' })
	}

	public render() {
		const { query, autocompleteResults } = this.state

		return (
			<Search.Wrapper>
				<TextInput
					type="search"
					value={query}
					onChange={this.setAutocompleteResults}
					placeholder="Search by tag or organisation."
				/>
				<SearchAutocomplete
					query={query}
					items={{
						tags: {
							label: 'Tags',
							items: autocompleteResults.tags,
						},
						items: {
							label: 'Organisations',
							items: autocompleteResults.items,
						},
					}}
					renderChunk={chunk => {
						return (
							<>
								<AutocompleteComponents.Item
									css={{
										borderBottom: `0 !important`,
										background: colors.GREY_10,
									}}>
									<Small>{chunk.label}</Small>
								</AutocompleteComponents.Item>

								{chunk.label === 'Tags' &&
									(chunk.items.length ? (
										<Wrapper>
											{chunk.items.map(item => (
												<FiltersActive.ActiveTag
													className="u-theme-background"
													key={item.value}
													onClick={() => this.addTagFilter(item)}>
													<span
														dangerouslySetInnerHTML={{
															__html: item.label,
														}}
													/>
												</FiltersActive.ActiveTag>
											))}
										</Wrapper>
									) : (
										<Wrapper>
											<EmptyLabel>
												No tags were found based on your search.
											</EmptyLabel>
										</Wrapper>
									))}

								{chunk.label === 'Organisations' &&
									(chunk.items.length ? (
										chunk.items.map(item => (
											<AutocompleteComponents.Item
												key={item.value}
												onClick={() => this.addItemFilter(item)}>
												<OrganisationResult
													dangerouslySetInnerHTML={{ __html: item.label }}
													className="u-theme-text"
												/>
											</AutocompleteComponents.Item>
										))
									) : (
										<Wrapper>
											<EmptyLabel>
												No organisations were found based on your search.
											</EmptyLabel>
										</Wrapper>
									))}
							</>
						)
					}}
					onSelect={this.addTagFilter}
				/>
			</Search.Wrapper>
		)
	}
}
