import moment from 'moment'
import React, { useState } from 'react'

import { EventResource } from '@compass/types'

import { Tooltip, TooltipInner } from '../tooltip'
import * as CalendarComponents from './calendar-components'

export interface CalendarBaseProps {
	onSelect: any
	events: EventResource[]
	renderDayEvents: (events: EventResource[], day: moment.Moment) => any
}

export type CalendarProps = CalendarBaseProps

export const getEventsSettings = (events: EventResource[] = []) => {
	return {
		lastEventDate: getLastEventDate(events),
		datesWithEvents: getDatesWithEvents(events),
	}
}

export const getLastEventDate = (events: EventResource[] = []) => {
	return events
		.reduce((lastEventDate, event: EventResource) => {
			const eventMoment = moment(event.event.date.start)
			return eventMoment.isAfter(lastEventDate) ? eventMoment : lastEventDate
		}, moment())
		.endOf('month')
}

interface DatesWithEvents {
	[key: string]: EventResource[]
}

export const getDatesWithEvents = (events: EventResource[] = []) => {
	return events.reduce((dates: DatesWithEvents, event: EventResource) => {
		const eventMoment = moment(event.event.date.start).format('DD-MM-YYYY')
		const eventsCount = dates[eventMoment] ? [...dates[eventMoment], event] : [event]
		return {
			...dates,
			[eventMoment]: eventsCount,
		}
	}, {})
}

export const getDayCircleWidth = (datesWithEvents: DatesWithEvents, day: moment.Moment) => {
	return datesWithEvents[day.format('DD-MM-YYYY')] &&
		datesWithEvents[day.format('DD-MM-YYYY')].length
		? Math.max(32, Math.min(80, 8 * datesWithEvents[day.format('DD-MM-YYYY')].length || 0))
		: 0
}

export const renderDay = (datesWithEvents: DatesWithEvents, props: CalendarProps) => (
	day: moment.Moment,
) => {
	const width = getDayCircleWidth(datesWithEvents, day)
	const amountOfEventsForDay =
		datesWithEvents[day.format('DD-MM-YYYY')] &&
		datesWithEvents[day.format('DD-MM-YYYY')].length
	const hasEvents = amountOfEventsForDay > 0
	const [isActive, setIsActive] = useState(false)

	return (
		<Tooltip
			position="right"
			sticky
			interactive
			animation="fade"
			useContext={true}
			disabled={!hasEvents}
			// @ts-ignore
			delay={[0, 300]}
			onShow={() => setIsActive(true)}
			onHide={() => setIsActive(false)}
			html={
				<TooltipInner customWidth={600}>
					{props.renderDayEvents(datesWithEvents[day.format('DD-MM-YYYY')], day)}
				</TooltipInner>
			}>
			<CalendarComponents.Button
				hasEvents={hasEvents}
				disabled={!hasEvents}
				isActive={isActive}
				onClick={() => hasEvents && props.onSelect && props.onSelect(day)}>
				<CalendarComponents.ButtonCircle
					css={{
						width: width + 'px',
					}}
					className="u-theme-text"
				/>
				<CalendarComponents.Date hasEvents={hasEvents}>
					{day.format('D')}
				</CalendarComponents.Date>
			</CalendarComponents.Button>
		</Tooltip>
	)
}
