import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const eventsInTheAfternoon: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="Layer_1">
			<g>
				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="4.111"
					y1="12.094"
					x2="0.875"
					y2="12.094"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="12"
					y1="4.207"
					x2="12"
					y2="0.969"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="19.889"
					y1="12.094"
					x2="23.125"
					y2="12.094"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="17.577"
					y1="6.517"
					x2="19.866"
					y2="4.229"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="6.423"
					y1="6.517"
					x2="4.134"
					y2="4.229"
				/>

				<circle
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					cx="12"
					cy="12.094"
					r="5.107"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="12"
					y1="19.982"
					x2="12"
					y2="23.219"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="17.577"
					y1="17.671"
					x2="19.866"
					y2="19.96"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.6952"
					strokeLinecap="round"
					strokeMiterlimit="10"
					x1="6.423"
					y1="17.671"
					x2="4.134"
					y2="19.96"
				/>
			</g>
		</g>
	),
}
