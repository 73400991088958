import styled from 'styled-components'

import { transition } from '@styleguide'

export interface ButtonProps {
	isHidden: boolean
}

export const TimeslotButton = styled.button<ButtonProps>(({ isHidden = false }) => ({
	transition: transition.config,
	transitionProperty: 'opacity',

	...(isHidden
		? {
				opacity: 0.5,

				['&:hover']: {
					opacity: 0.8,
				},
		  }
		: {
				opacity: 1,
		  }),
}))
