import fetch from 'isomorphic-unfetch'

/**
 * Helper for doing HTTP requests. Uses fetch().
 *
 * @returns A promise with the parsed JSON from the HTTP response body
 * @throws {Error} When received a readable error from the API
 * @throws {TypeError} In case of a network error (i.e. no internect connection, proxy errors, etc.)
 * @throws {SyntaxError} When HTTP response body could not be parsed as JSON
 */
function http(
	url: string,
	method: string = 'GET',
	body?: any,
	isFileUpload: boolean = false,
): Promise<any> {
	const options: any = {
		method,
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	}

	if (body) {
		options.body = isFileUpload ? body : JSON.stringify(body)
	}

	if (isFileUpload) {
		delete options.headers['Content-Type'] // Browser sets it to multipart with a boundary automatically
	}

	return fetch(url, options).then((response: any) => {
		if (!response.ok) {
			return response.json().then((json: any) => {
				throw json
			})
		}

		const contentType = response.headers.get('content-type')
		if (contentType && contentType.indexOf('application/json') !== -1) {
			return response.json()
		} else {
			return response
		}
	})
}

export default http
