import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

export const Input = styled.input({
	display: 'none',
})

export const InputWrapper = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
})

export const Button = styled.label({
	background: colors.GREY_20,
	color: colors.GREY_60,
	textAlign: 'left',
	marginTop: 5,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	cursor: 'pointer',
	padding: `9px ${spacing.sm}px`,
	...mixins.primaryFont(),
})

export const Preview = styled.img({
	display: 'block',
	width: '100%',
	height: 'auto',
	margin: `${spacing.sm}px 0`,
})
