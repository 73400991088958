import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { withContentStore, WithContentStoreProps } from '@app/stores'
import { Resource } from '@compass/types'
import { spacing } from '@styleguide'

import * as BreadcrumbsComponents from './breadcrumbs-components'

interface BreadcrumbsState {
	breadcrumbs: Resource[]
}

class Breadcrumbs extends React.Component<
	WithContentStoreProps & RouteComponentProps<any>,
	BreadcrumbsState
> {
	public state: BreadcrumbsState = {
		breadcrumbs: this.props.contentStore.state.breadcrumbs,
	}

	public render() {
		const { breadcrumbs = [] } = this.state
		return (
			<div css={{ marginBottom: spacing.sm }}>
				<BreadcrumbsComponent breadcrumbs={breadcrumbs} />
			</div>
		)
	}
}

export const BreadcrumbsComponent: React.SFC<{ breadcrumbs: Resource[], isSmall?: boolean }> = ({ breadcrumbs, isSmall }) => (
	<BreadcrumbsComponents.Wrapper>
		<BreadcrumbsComponents.List itemScope itemType="http://schema.org/BreadcrumbList">
			{breadcrumbs.map((resource, index) => (
				<BreadcrumbsComponents.Item
					key={index}
					resource={resource}
					isSmall={isSmall}
					index={index + 1}
				/>
			))}
		</BreadcrumbsComponents.List>
	</BreadcrumbsComponents.Wrapper>
)

export default compose(
	withContentStore,
	withRouter,
)(Breadcrumbs)
