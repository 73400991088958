import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const media: SvgIconMap = {
	size: {
		width: 54,
		height: 46,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-36" fill={fill} fillRule="nonzero">
			<path
				d="M47.41,45.31 L6.82,45.31 C3.34,45.31 0.51,42.48 0.51,39 L0.51,7.22 C0.51,3.74 3.34,0.91 6.82,0.91 L47.41,0.91 C50.89,0.91 53.72,3.74 53.72,7.22 L53.72,39 C53.72,42.49 50.89,45.31 47.41,45.31 Z M6.82,3.92 C5,3.92 3.51,5.4 3.51,7.23 L3.51,39.01 C3.51,40.83 4.99,42.32 6.82,42.32 L47.41,42.32 C49.23,42.32 50.72,40.84 50.72,39.01 L50.72,7.22 C50.72,5.4 49.24,3.91 47.41,3.91 L6.82,3.91 L6.82,3.92 Z"
				id="Shape"
			/>
			<path
				d="M20.93,30.73 L20.93,15.5 L35.77,23.12 L20.93,30.73 Z M23.93,20.41 L23.93,25.82 L29.2,23.12 L23.93,20.41 Z"
				id="Shape"
			/>
			<path
				d="M27.12,38.81 C18.46,38.81 11.42,31.77 11.42,23.11 C11.42,14.45 18.46,7.41 27.12,7.41 C35.78,7.41 42.82,14.45 42.82,23.11 C42.82,31.77 35.77,38.81 27.12,38.81 Z M27.12,10.42 C20.12,10.42 14.42,16.12 14.42,23.12 C14.42,30.12 20.12,35.82 27.12,35.82 C34.12,35.82 39.82,30.12 39.82,23.12 C39.82,16.12 34.12,10.42 27.12,10.42 Z"
				id="Shape"
			/>
		</g>
	),
}
