import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagecityMap: SvgIconMap = {
	size: {
		width: 89.576,
		height: 89.581,
	},
	Icon: () => (
		<>
			<polygon fill="#ED1B34" points="89.58,58.65 0,0.005 84.24,84.239 	" />

			<linearGradient
				x1="77.0702674%"
				y1="91.3580809%"
				x2="8.64817097%"
				y2="8.6405183%"
				id="SVGID_1_events">
				<stop stopColor="#D81A31" offset="0%" />
				<stop stopColor="#F32830" offset="100%" />
			</linearGradient>
			<polygon fill="url(#SVGID_1_events)" points="58.65,89.581 0.005,0 84.238,84.241 	" />
		</>
	),
}
