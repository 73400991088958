import React from 'react'
import styled from 'styled-components'

import { transition } from '@styleguide'

export interface LoaderProps {
	isVisible: boolean
}

const LoaderGlam = styled.div<LoaderProps>(({ isVisible }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	padding: '100px 0',
	display: 'flex',
	justifyContent: 'center',
	zIndex: -1,
	opacity: 0,
	transition: `z-index 0s ${transition.duration}, opacity ${transition.config}`,
	...(isVisible
		? {
				opacity: 1,
				transition: `opacity ${transition.config}`,
		  }
		: {}),
}))

export const Loader: React.SFC<LoaderProps> = props => (
	<LoaderGlam {...props}>
		<img src="/static/loading-small.gif" />
	</LoaderGlam>
)
