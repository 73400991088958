import React from 'react'
import { compose } from 'recompose'

import { FeedbackForm } from '@app/components/forms'
import { Modal } from '@app/components/modal'
import { RichTextContent } from '@app/components/rich-text-content'
import { SvgIcon } from '@app/components/svg-icons'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { withModalStore, WithModalStoreProps } from '@app/stores'
import { CompassUtils, detectIE } from '@app/utils'
import { FeedbackFormInput } from '@compass/types'

import { shellConfig } from '@app/config'
import * as ModalComponents from '../modal/modal-components'
import * as TextSelectionModalComponents from './text-selection-modal-components'

interface TextSelectionModalState {
	selection: string | undefined
	loading: boolean
	success: boolean
	entering: boolean
	position: {
		top: number
		left: number
	}
}

class TextSelectionModalComponent extends React.Component<
	WithModalStoreProps,
	TextSelectionModalState
> {
	public state: TextSelectionModalState = {
		selection: '',
		entering: false,
		loading: false,
		success: false,
		position: {
			top: -9999,
			left: -9999,
		},
	}

	private main: Element

	public handleTextSelection = (e: MouseEvent) => {
		const ie = detectIE()
		let selectedText

		if (ie && ie < 11) {
			const { htmlText } = (document as any).selection.createRange()
			selectedText = htmlText
		} else if (ie && ie >= 11) {
			selectedText = window.getSelection().toString()
		} else {
			selectedText = window.getSelection().toString()
		}

		const entering = !this.state.selection

		this.setState({
			entering,
			selection: selectedText,
			position: selectedText
				? {
						top: e.clientY + window.pageYOffset,
						left: e.clientX,
				  }
				: {
						top: -9999,
						left: -9999,
				  },
		})
	}

	public componentDidMount() {
		this.main = document.querySelectorAll('main')[0]
		if (this.main) {
			this.main.addEventListener('mouseup', this.handleTextSelection)
		}
	}

	public componentWillUnmount() {
		if (this.main) {
			this.main.removeEventListener('mouseup', this.handleTextSelection)
		}
	}

	private open = () => {
		this.props.modalStore.openModal('textSelection')
	}

	private close = () => {
		this.setState({ selection: undefined, success: false })
		this.props.modalStore.closeModal('textSelection')
	}

	private sendFeedback = async (values: FeedbackFormInput) => {
		this.setState({ loading: true })

		try {
			const { success } = await CompassUtils.general.submitFeedback(values)

			this.setState({ success, loading: false })
		} catch (error) {
			this.setState({ loading: false })
		}
	}

	public render() {
		const { selection, entering, position, success, loading } = this.state
		const open = this.props.modalStore.state.isModalOpen.textSelection
		const { top, left } = position

		return (
			<>
				<TextSelectionModalComponents.Tooltip
					visible={!!selection}
					entering={entering}
					css={{ top, left }}>
					<TextSelectionModalComponents.FeedbackText onClick={this.open}>
						<SvgIcon icon="speech" css={{ width: 32, height: 32, marginRight: 10 }} />
						give feedback
					</TextSelectionModalComponents.FeedbackText>
				</TextSelectionModalComponents.Tooltip>
				<ThemeWrapper fixedTheme="info">
					<Modal isSmall={true} open={open} onClose={this.close}>
						<ModalComponents.Content>
							<div>
								{shellConfig.feedbackContent && (
									<RichTextContent content={shellConfig.feedbackContent} />
								)}
								<FeedbackForm
									onSubmit={this.sendFeedback}
									selection={selection}
									success={success}
									submitting={loading}
								/>
							</div>
						</ModalComponents.Content>
					</Modal>
				</ThemeWrapper>
			</>
		)
	}
}

export const TextSelectionModal = compose(withModalStore)(TextSelectionModalComponent)
