import React from 'react'
import styled from 'styled-components'

import { SvgIcon, Tooltip, TooltipInner } from '@app/components'
import { colors, transition } from '@styleguide'

export interface ViewSwitchProps {
	view: 'list' | 'calendar'
	onChange: () => void
}

const Wrapper = styled.div({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
})

const ToggleButton = styled.button<{ isActive: boolean }>({
	width: 32,
	height: 32,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
})

const ToggleBackground = styled.div<{ isActive: boolean }>(({ isActive }) => ({
	border: '1px currentColor solid',
	width: 32,
	height: 32,
	borderRadius: 4,
	position: 'absolute',
	zIndex: -1,
	left: 0,
	top: 0,
	transition: transition.config,
	transitionProperty: 'transform',

	...(isActive && { transform: 'translateX(100%)' }),
}))

const ToggleButtonIcon = styled(SvgIcon)<{ isActive: boolean }>(({ isActive }) => ({
	width: 16,
	height: 'auto',
	transition: transition.config,
	transitionProperty: 'color',

	...(isActive ? { color: 'currentColor' } : { color: colors.GREY_40 }),

	['&:hover']: {
		color: colors.GREY_60,
	},
}))

export const ViewSwitch: React.SFC<ViewSwitchProps> = ({ view, onChange }) => (
	<Wrapper>
		<Tooltip position="top" sticky html={<TooltipInner>Cards view</TooltipInner>}>
			<ToggleButton
				isActive={view === 'list'}
				onClick={() => onChange()}
				aria-label="Cards view"
				className="u-theme-text">
				<ToggleButtonIcon isActive={view === 'list'} icon="list" />
			</ToggleButton>
		</Tooltip>
		<Tooltip position="top" sticky html={<TooltipInner>Calendar view</TooltipInner>}>
			<ToggleButton
				isActive={view === 'calendar'}
				onClick={() => onChange()}
				aria-label="Calendar view"
				className="u-theme-text">
				<ToggleButtonIcon isActive={view === 'calendar'} icon="calendar" />
			</ToggleButton>
		</Tooltip>
		<ToggleBackground isActive={view === 'calendar'} className="u-theme-text" />
	</Wrapper>
)
