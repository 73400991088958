import React, { Suspense } from 'react'

import {
	Ads,
	FullScreenIframe,
	FullScreenImage,
	Grid,
	HelmetData,
	Loader,
	Page,
	SectionOverview,
	StaticContentSection,
} from '@app/components'
import { isBrowser } from '@app/utils'
import { PageResource, Resource } from '@compass/types'

interface TemplatePageSectionProps {
	resource: PageResource
	section: Resource
}

const LazyGuidanceDocument = React.lazy(() =>
	import('@app/components/guidance-document/guidance-document'),
)

export const PageSection: React.SFC<TemplatePageSectionProps> = ({ resource, section }) => {
	return (
		<Page>
			<HelmetData title={resource.title} image={resource.image} seo={resource.seo} />
			<Grid.Container>
				<Grid.Row>
					{resource.iframe && (
						<Grid.PaddedColumn xs={16}>
							<FullScreenIframe iframe={resource.iframe} />
						</Grid.PaddedColumn>
					)}
					{resource.image && (
						<Grid.PaddedColumn xs={16}>
							<FullScreenImage src={resource.image} alt={resource.title} />
						</Grid.PaddedColumn>
					)}
					<Grid.PaddedColumn
						xs={16}
						md={{ size: 10, offset: 3 }}
						lg={{ size: 9, offset: 4 }}
						xl={{ size: 9, offset: 3 }}>
						<h1 className="u-theme-text">{resource.title}</h1>
						<StaticContentSection resource={resource} />
						{resource.file && isBrowser && (
							<Suspense
								fallback={
									<Loader
										loading
										fixed={false}
										text="Loading guidance document"
									/>
								}>
								<LazyGuidanceDocument document={resource.file} />
							</Suspense>
						)}
					</Grid.PaddedColumn>
					<Grid.Column
						xs={16}
						md={{ size: 3, offset: 0 }}
						lg={{ size: 2.5, offset: 0.5 }}
						aside>
						<Ads slug={resource.slug} />
					</Grid.Column>
					<Grid.Column xs={16} xl={{ size: 9, offset: 3 }}>
						<SectionOverview section={section} />
					</Grid.Column>
				</Grid.Row>
			</Grid.Container>
		</Page>
	)
}
