import { Column, LeftPaddedColumn, PaddedColumn } from './column'
import { Container } from './container'
import { Row } from './row'

export const Grid = {
	Column,
	PaddedColumn,
	LeftPaddedColumn,
	Row,
	Container,
}
