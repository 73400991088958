import React from 'react'
import { findDOMNode } from 'react-dom'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { Loader } from '@app/components/loader'
import { env } from '@app/config/env'
import {
	withContentStore,
	WithContentStoreProps,
	withSearchStore,
	WithSearchStoreProps,
} from '@app/stores'
import { CompassUtils } from '@app/utils'
import { Resource } from '@compass/types'

import * as SearchModalComponents from './search-modal-components'

export interface SearchModalState {
	input: string
	loading: boolean
	isOpen: boolean
}

export interface SearchModalProps {
	setOpen?: (setOpen?: (state: boolean) => void) => void
}

export class SearchModalClass extends React.Component<
	SearchModalProps & WithSearchStoreProps & WithContentStoreProps & RouteComponentProps<any>,
	SearchModalState
> {
	private component: Element | Text | null

	private timeout: any

	public state: SearchModalState = {
		input: '',
		loading: false,
		isOpen: false,
	}

	public constructor(
		props: SearchModalProps &
			WithSearchStoreProps &
			WithContentStoreProps &
			RouteComponentProps<any>,
	) {
		super(props)

		if (props.setOpen) {
			props.setOpen((state: boolean) => {
				this.setOpen(state)
				;(this.component as Element).querySelectorAll('input')[0].focus()
			})
		}
	}

	private getData = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const { navigation, navigationResources } = this.props.contentStore.state
		const { value } = e.currentTarget

		this.setState({ input: value })

		clearTimeout(this.timeout)
		this.setState({ loading: false })

		if (!value) {
			return
		}

		this.setState({ loading: true })

		if (value) {
			this.timeout = setTimeout(async () => {
				await this.props.searchStore.search(value, navigation, navigationResources)
				this.setState({ loading: false })
			}, 500)
		}
	}

	public handleKeyUp = (e: KeyboardEvent) => {
		if (e.keyCode === 27) {
			;(this.component as Element).querySelectorAll('input')[0].blur()
			this.setOpen(false)
		}
	}

	public componentDidMount() {
		this.component = findDOMNode(this)
		window.addEventListener('keyup', this.handleKeyUp)
	}

	public componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKeyUp)
		document.removeEventListener('mousedown', this.onClickOutside.bind(this))
	}

	public onClickOutside(e: MouseEvent) {
		if (!this.component || !this.component.contains(e.target as Node)) {
			this.setOpen(false)
		}
	}

	public setOpen = (isOpen: boolean) => {
		this.setState({ isOpen }, () => {
			const operation = isOpen ? 'addEventListener' : 'removeEventListener'
			document[operation]('mousedown', this.onClickOutside.bind(this))
		})
	}

	public render() {
		const { searchStore, contentStore } = this.props
		const { input, isOpen, loading } = this.state
		const { results: items, isCentered } = searchStore.state
		const { pathname, navigation, navigationResources } = contentStore.state

		const isHome = pathname === '/'

		const defaultPages = env.SEARCH_DEFAULT_PAGES
			? env.SEARCH_DEFAULT_PAGES.split(',')
					.map(id => navigationResources.find(resource => resource.id === Number(id)))
					.filter((resource): resource is Resource => !!resource)
			: navigation[0].resources || []

		return (
			<SearchModalComponents.Popup
				active={isOpen || isHome}
				isHome={isHome}
				isCentered={isCentered}>
				<SearchModalComponents.Input
					value={input}
					type="search"
					placeholder="Search for a topic"
					theme="light"
					id="search-input-in-modal"
					autoComplete="off"
					onChange={this.getData}
					onFocus={() => this.setOpen(true)}
				/>
				<SearchModalComponents.Content active={isOpen} isHome={isHome}>
					{loading ? (
						<Loader loading={loading} fixed={false} css={{ margin: `20px 0` }} />
					) : input ? (
						items.length ? (
							items.map(item => (
								<SearchModalComponents.SmallTeaser
									key={item.resource.slug}
									resource={item.resource}
									to={item.resource.slug}
									navigation={navigation}
									icon={
										item.section
											? CompassUtils.navigation.getSectionIcon(item.section)
											: undefined
									}
									theme={CompassUtils.navigation.getSectionSlugByResourceSlug(
										item.resource.slug,
									)}
									onClick={() => searchStore.closeModal()}
								/>
							))
						) : (
							<SearchModalComponents.Title>
								No results found.
							</SearchModalComponents.Title>
						)
					) : (
						<>
							<SearchModalComponents.Title css={{ marginTop: 5, marginBottom: 5 }}>
								You might also be interested in:
							</SearchModalComponents.Title>
							{defaultPages.map(resource => (
								<SearchModalComponents.SmallTeaser
									key={resource.slug}
									to={resource.slug}
									resource={resource}
									navigation={navigation}
									icon={CompassUtils.navigation.getSectionIcon(resource)}
									theme={CompassUtils.navigation.getSectionSlugByResourceSlug(
										resource.slug,
									)}
									onClick={() => searchStore.closeModal()}
								/>
							))}
						</>
					)}
				</SearchModalComponents.Content>
			</SearchModalComponents.Popup>
		)
	}
}

export const SearchModal = compose<
	SearchModalProps & WithSearchStoreProps & WithContentStoreProps & RouteComponentProps<any>,
	SearchModalProps
>(
	withSearchStore,
	withContentStore,
	withRouter,
)(SearchModalClass)
