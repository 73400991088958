import merge from 'deepmerge'
import { Request } from 'express'
import idx from 'idx'
import { Container } from 'unstated'

import { CompassUtils } from '@app/utils'
import { AnyResource, Resource, ResourceTemplateType } from '@compass/types'

// Content Types
export interface Content {
	[key: string]: AnyResource
}

export interface ContentState {
	navigationResources: Resource[]
	navigation: Resource[]
	sections: Resource[]
	breadcrumbs: Resource[]
	activeNavigationDomain: string | undefined
	activeSection: Resource | undefined
	nextResource: Resource | undefined
	prevResource: Resource | undefined
	content: Content
	isLoading: boolean
	hasError: boolean
	pathname: string
}

export const defaultContentState: ContentState = {
	sections: [],
	navigationResources: [],
	navigation: [],
	breadcrumbs: [],
	activeNavigationDomain: undefined,
	activeSection: undefined,
	nextResource: undefined,
	prevResource: undefined,
	content: {},
	isLoading: false,
	hasError: false,
	pathname: '',
}

export class ContentStore extends Container<ContentState> {
	public constructor(initialState: ContentState = defaultContentState) {
		super()
		this.state = {
			...initialState,
			navigation: ContentStore.getNavigation(initialState.navigationResources),
		}
	}

	/**
	 *
	 * Server-side resolve flow
	 */
	public static async getInitialState(req: Request) {
		const pathname = req.originalUrl.split('?')[0]
		const navigationResources = await ContentStore.getNavigationResources()
		const navigation = ContentStore.getNavigation(navigationResources)
		const sections = ContentStore.getSections(navigationResources, navigation)

		const initialContentState: Partial<ContentState> = {
			navigationResources,
			sections,

			...ContentStore.getActiveState(req.originalUrl, navigation, navigationResources),
		}

		try {
			const content = await ContentStore.getEntry(pathname)

			if (content) {
				initialContentState.content = {
					[pathname]: content,
				}
			}
		} catch {
			initialContentState.hasError = true
		}

		return {
			...defaultContentState,
			...initialContentState,
			pathname,
		}
	}

	/**
	 *
	 * Client-side resolve flow
	 */
	public async getContent(path: string) {
		if (this.getCachedResource(path)) {
			return
		}

		this.set({ isLoading: true, hasError: false })

		try {
			const newEntry = await ContentStore.getEntry(path)

			if (newEntry) {
				this.set({
					content: merge(this.state.content, { [path]: newEntry }),
					isLoading: false,
				})
			} else {
				this.set({ isLoading: false, hasError: false })
			}
		} catch {
			this.set({ hasError: true, isLoading: false })
		}
	}

	public setUIState(path: string) {
		this.setState(
			ContentStore.getActiveState(
				path,
				this.state.navigation,
				this.state.navigationResources,
			),
		)
	}

	/**
	 *
	 * Fetch methods
	 */
	public static async getEntry(path: string): Promise<AnyResource | undefined> {
		const nestedRoutes = ['city-map', 'sports', 'community']
		const section = path.split('/')[1]
		const isNestedRoute = nestedRoutes.includes(section)

		if (isNestedRoute) {
			path = `/${section}`
		}

		if (!path || path === '/') {
			path = '/home/'
		}

		try {
			return CompassUtils.resource.getResource(path)
		} catch (error) {
			throw Error('No resource found')
		}
	}

	public static async getNavigationResources(): Promise<Resource[]> {
		try {
			const resources = await CompassUtils.navigation.getNavigationResources()

			if (!resources || !resources.length) {
				throw Error('No resources found!')
			}

			return resources
		} catch (error) {
			console.error('Something went wrong while fetching navigation', error)
			return []
		}
	}

	public static getNavigation(resources: Resource[]): Resource[] {
		return CompassUtils.navigation.getNavigation(resources)
	}

	public static getSections(resources: Resource[], navigation: Resource[]): Resource[] {
		function onlyUnique(value: Resource, index: number, self: Resource[]) {
			return self.findIndex(item => item.id === value.id) === index
		}

		return [
			...CompassUtils.resource.getResourcesByResourceTemplateType(
				resources,
				ResourceTemplateType.SECTION_PAGE,
			),
			...CompassUtils.navigation.getDomainSections(navigation),
		].filter(onlyUnique)
	}

	public static getActiveState(
		pathname: string,
		navigation: Resource[],
		resources: Resource[],
	): Partial<ContentState> {
		const activeSection = ContentStore.getActiveSection(pathname, navigation, resources)
		const nextResource = CompassUtils.navigation.getNextResource(pathname, activeSection)
		const prevResource = CompassUtils.navigation.getPrevResource(pathname, activeSection)
		const breadcrumbs = CompassUtils.navigation.getBreadcrumbs(pathname, navigation)

		return {
			activeNavigationDomain:
				activeSection &&
				(activeSection.domain === 'get-settled' || activeSection.domain === 'go-explore')
					? `/${activeSection.domain}/`
					: '/get-settled/',
			activeSection,
			nextResource,
			prevResource,
			breadcrumbs,
		}
	}

	public static getActiveSection(
		pathname: string,
		navigation: Resource[],
		resources: Resource[],
	) {
		return CompassUtils.navigation.getParentSection(pathname, navigation, resources)
	}

	public getCachedResource(path: string): Resource | null | undefined {
		return idx<ContentState, Resource>(
			this.state,
			(_: ContentState): Resource => _.content[path],
		)
	}

	public set(state: Partial<ContentState>) {
		return new Promise(resolve => {
			this.setState(state, resolve)
		})
	}
}
