import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const fullscreen: SvgIconMap = {
	size: {
		width: 20,
		height: 20,
	},
	Icon: ({ fill = defaultFill }) => (
		<>
			<path
				fill={fill}
				d="M1 1v6h2V3h4V1H1zm2 12H1v6h6v-2H3v-4zm14 4h-4v2h6v-6h-2v4zm0-16h-4v2h4v4h2V1h-2z"
			/>
		</>
	),
}
