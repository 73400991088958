import React from 'react'
import { Subscribe } from 'unstated'

import { SearchStore } from './search'

export interface WithSearchStoreProps {
	searchStore: SearchStore
}

export function withSearchStore<P>(
	WrappedComponent: React.ComponentType<P & WithSearchStoreProps>,
) {
	return class WithContentHoc extends React.Component<P> {
		public render() {
			return (
				<Subscribe to={[SearchStore]}>
					{(searchStore: SearchStore) => (
						<WrappedComponent {...this.props} searchStore={searchStore} />
					)}
				</Subscribe>
			)
		}
	}
}
