import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const user: SvgIconMap = {
	size: {
		width: 200,
		height: 200,
	},
	Icon: ({ fill = defaultFill }) => (
		<svg x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200" fill={fill} />
	),
}
