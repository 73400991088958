import { isBrowser } from '@app/utils/is-browser'

const serverEnv = {
	FACEBOOK_PIXEL_ID: process.env.FACEBOOK_PIXEL_ID,
	GOOGLE_ANALYTICS_TOKEN: process.env.GOOGLE_ANALYTICS_TOKEN,
	GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
	SHELL_NAME: process.env.SHELL_NAME,
	STAGE: process.env.STAGE,
	LOCAL_API_URL_OVERWRITE: process.env.LOCAL_API_URL_OVERWRITE,
	LOCAL_APP_URL_OVERWRITE: process.env.LOCAL_APP_URL_OVERWRITE,
	MEDIA_PROXY: process.env.MEDIA_PROXY,
	SENTRY_DSN: process.env.SENTRY_DSN,
	SEARCH_DEFAULT_PAGES: process.env.SEARCH_DEFAULT_PAGES,
}

export const env = isBrowser ? window.env || serverEnv : serverEnv
