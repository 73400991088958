import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const like: SvgIconMap = {
	size: {
		width: 1000,
		height: 1000,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M712.7 48C625.2 48 547.7 92.4 499.5 160.9 451.3 93.2 374.3 49.3 287.3 49.3 141.1 49.3 22.7 173 22.7 325.7 22.7 363.4 29.9 399.3 42.9 432L43.2 432.4C52.5 455.5 64.7 477 79.3 496.4L497.9 952C638.1 805.3 795.6 631.2 920.6 495.1 935.3 475.6 947.5 454.2 956.8 431.1L957 430.7C970.1 398 977.3 362.1 977.3 324.4 977.3 171.7 858.9 48 712.7 48"
		/>
	),
}
