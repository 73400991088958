import React from 'react'
import styled from 'styled-components'

import { SvgIcon } from '@app/components/svg-icons'
import { colors, spacing } from '@styleguide'

export interface InputProps {
	id?: string
	hasError?: boolean
	textarea?: boolean
	type?: string
}

export const Input = styled.input<InputProps>(({ hasError, textarea, type }) => ({
	padding: '10px 15px',
	paddingRight: type === 'search' ? `${spacing.md}px` : '15px',

	fontSize: '1.6rem',
	lineHeight: '1.5',
	color: colors.GREY_60,

	border: '1px solid',
	borderColor: colors.GREY_30,
	borderRadius: 8,
	outline: 0,

	...(textarea
		? {
				resize: 'none',
				height: '132px',
				maxWidth: '100%',
		  }
		: {}),

	...(hasError
		? {
				borderColor: colors.PRIMARY,
				['&:focus, &:hover']: {
					borderColor: colors.PRIMARY,
				},
		  }
		: {}),

	['::placeholder']: {
		color: colors.GREY_40,
	},

	['&:hover']: {
		cursor: 'pointer',
		borderColor: colors.GREY_50,
	},

	['&:focus']: {
		borderColor: colors.GREY_50,
	},

	['&:disabled']: {
		cursor: 'initial',
		borderColor: colors.GREY_30,
		color: colors.GREY_30,
	},
}))

export const SearchIconWrapper = styled.div({
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',
	right: 0,
	width: 46,
	height: 46,
	display: 'flex',
	alignItems: 'center',
	pointerEvents: 'none',
	justifyContent: 'center',
})

export const SearchIcon: React.SFC = () => (
	<SearchIconWrapper>
		<SvgIcon icon="search" />
	</SearchIconWrapper>
)

export const InputWrapper = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
})

export const SubText = styled.span({
	fontSize: '1.4rem',
	color: colors.GREY_50,
	display: 'block',
	margin: '-5px 0 5px',
})
