import React from 'react'
import styled from 'styled-components'

import { AdResource } from '@compass/types'
import { spacing } from '@styleguide'

interface AdProps {
	ad: AdResource
}

const AdLink = styled.a({})

const AdImage = styled.img({
	width: '100%',
})

export const Ad: React.SFC<AdProps> = ({ ad: { link, image } }) => (
	<AdLink href={link} target="_blank" rel="noopener noreferrer">
		<AdImage src={image} />
	</AdLink>
)

export const Wrapper = styled.div({
	marginTop: 50,
	marginBottom: spacing.sm,
})
