import debounce from 'debounce'
import { diff } from 'deep-object-diff'
import React from 'react'
import { FormSpy, FormSpyProps, FormSpyRenderProps } from 'react-final-form'

export interface AutoSubmitHandlerProps {
	submit(values: any): void
}

class AutoSubmitHandler extends React.Component<
	AutoSubmitHandlerProps & FormSpyProps & FormSpyRenderProps
> {
	public componentWillReceiveProps(
		nextProps: AutoSubmitHandlerProps & FormSpyProps & FormSpyRenderProps,
	) {
		const difference = diff(this.props.values, nextProps.values)

		if (Object.keys(difference).length) {
			this.submit()
		}
	}

	public submit = debounce(() => {
		const { values, submit } = this.props
		submit(values)
	}, 200)

	public render(): null {
		return null
	}
}

export const AutoSubmit = (autoSubmitProps: AutoSubmitHandlerProps) => (
	<FormSpy
		subscription={{ active: true, values: true }}
		render={formSpyProps => <AutoSubmitHandler {...autoSubmitProps} {...formSpyProps} />}
	/>
)
