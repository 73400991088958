import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Loader, Modal, SubmitListItemForm } from '@app/components'
import * as ModalComponents from '@app/components/modal/modal-components'
import { withModalStore, WithModalStoreProps } from '@app/stores'
import { CompassUtils } from '@app/utils'
import { ListSubmitFormInput } from '@compass/types'

import { compose } from 'recompose'
import * as ListSubmitSectionComponents from './list-submit-section-components'

interface ListSubmitSectionProps {
	type: string | undefined
}

interface ListSubmitSectionState {
	isLoading: boolean
	isSubmitted: boolean
}

export class ListSubmitSectionClass extends React.Component<
	ListSubmitSectionProps & WithModalStoreProps,
	ListSubmitSectionState
> {
	public static Wrapper = ListSubmitSectionComponents.Wrapper
	public static Content = ListSubmitSectionComponents.Content

	public state: ListSubmitSectionState = {
		isLoading: false,
		isSubmitted: false,
	}

	public sendSubmission = async (values: ListSubmitFormInput) => {
		this.setState({
			isLoading: true,
		})

		try {
			await CompassUtils.list.submit({
				...values,
				type: this.props.type,
			})

			this.setState({
				isLoading: false,
				isSubmitted: true,
			})
		} catch (error) {
			this.setState({
				isLoading: false,
			})
		}
	}

	private close = () => {
		this.props.modalStore.closeModal('listSubmit')
	}

	public render() {
		const { type } = this.props
		const open = this.props.modalStore.state.isModalOpen.listSubmit
		const { isLoading, isSubmitted } = this.state

		return (
			<Modal isSmall={true} open={open} onClose={this.close}>
				<ModalComponents.Content>
					<ListSubmitSectionComponents.Content>
						<h2 className="u-theme-text">Submit your organisation!</h2>
						<TransitionGroup>
							<CSSTransition
								key={isSubmitted.toString()}
								classNames="u-view-animation"
								timeout={{ enter: 1000, exit: 500 }}>
								<div>
									{!isSubmitted ? (
										<>
											<p>
												Fill in the form below to submit your organisation
												to our {type} database <br /> and we will review
												your request shortly.
											</p>
											<SubmitListItemForm onSubmit={this.sendSubmission} />
										</>
									) : (
										<p>
											Your submission has been sent. We will reach out to you.
										</p>
									)}
								</div>
							</CSSTransition>
						</TransitionGroup>
					</ListSubmitSectionComponents.Content>
					<Loader fixed={false} loading={isLoading} />
				</ModalComponents.Content>
			</Modal>
		)
	}
}

export const ListSubmitSection = compose<WithModalStoreProps, ListSubmitSectionProps>(
	withModalStore,
)(ListSubmitSectionClass)
