import { PublicConfig } from '../../types'

export const publicConfig: PublicConfig = {
	id: 'mymaastricht',
	domain: 'mymaastricht.nl',
	name: {
		prod: 'mymaastricht.nl',
		test: 'mycity.nl',
	},
	payoff: {
		prod: 'make maastricht yours!',
		test: 'make the city yours!',
	},
	phone: '+31 6 82 50 05 10',
	publicEmail: 'info@mymaastricht.nl',
	logoUrl: 'https://mymaastricht.nl/static/logo-img.svg',

	logos: {
		test: '/static/shell-logos/mymaastricht-test-without-text.svg',
	},

	socials: [
		{
			type: 'facebook',
			url: 'https://www.facebook.com/mymaastricht/',
		},
		{
			type: 'instagram',
			url: 'https://www.instagram.com/mymaastricht/',
		},
		{
			type: 'linkedin',
			url: 'https://www.linkedin.com/company/mymaastricht',
		},
		{
			type: 'youtube',
			url: 'https://www.youtube.com/c/stichtingmymaastricht',
		},
		{
			type: 'tiktok',
			url: 'https://www.tiktok.com/@mymaastricht',
		},
	],
	sponsors: [
		{
			url: 'https://www.gemeentemaastricht.nl/en',
			logo: '/static/stakeholder-gm.svg',
		},
		{
			url: 'https://maastrichtuniversity.nl/',
			logo: '/static/stakeholder-mu.svg',
		},
		{
			url: 'https://international.zuyd.nl/',
			logo: '/static/stakeholder-zuyd.svg',
		},
	],
	map: {
		center: { lat: 50.8513682, lng: 5.6909725 },
		bounds: {
			topLeft: {
				lat: 50.906625,
				lng: 5.632795,
			},
			topRight: {
				lat: 50.90879,
				lng: 5.782135,
			},
			bottomLeft: {
				lat: 50.803243,
				lng: 5.620081,
			},
			bottomRight: {
				lat: 50.804328,
				lng: 5.770279,
			},
		},
	},

	feedbackContent: `
		<h2>We're here to help you</h2>
		<p>
			Did you see out of date or inaccurate information? Was there a broken link?
			Please let us know by marking the corresponding text and filling out this form. We will implement the changes as soon as possible.
		</p>
		<p>
			If you need help with study-related questions, please turn directly to the <a href="https://www.maastrichtuniversity.nl/support/during-your-studies/international-students-while-maastricht/international-student-helpdesk" target="_blank" rel="noopener">International Student Helpdesk</a> (given that you are a student at UM) or to the <a href="https://www.maastrichtuniversity.nl/about-um/service-centres/student-services-centre" target="_blank" rel="noopener">Student Service Center</a>.
		</p>
		<p>
			Are you an expat? The <a href="https://expatcentremaastrichtregion.nl/" target="_blank" rel="noopener">Expat Centre Maastricht region</a> is here to help you!
		</p>
		<a
			href="https://www.maastrichtuniversity.nl/ish"
			target="_blank">
			<img src="/static/ish.jpg" />
		</a>
		<a href="https://expatcentremaastrichtregion.nl/" target="_blank" rel="noopener noreferrer">
			<img src="/static/expat-centre.png" />
		</a>
	`,

	withLogin: true,
}
