import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagehousing: SvgIconMap = {
	size: {
		width: 240.358,
		height: 88.76,
	},
	Icon: () => (
		<>
			<linearGradient
				x1="-0.00295676998%"
				y1="49.9746013%"
				x2="100.004894%"
				y2="49.9746013%"
				id="SVGID_1_housing">
				<stop stopColor="#0299C1" offset="0%" />
				<stop stopColor="#00A2DB" offset="100%" />
			</linearGradient>
			<path
				fill="url(#SVGID_1_housing)"
				d="M10.239,44.384c0,7.441-3.016,14.177-7.893,19.054L27.671,88.76l212.687-44.376H10.239z"
			/>

			<path
				fill="#00A2DB"
				d="M53.263,5.34L27.674,0h-0.002L2.347,25.327c4.877,4.877,7.893,11.614,7.892,19.057h17.465 h212.655L53.263,5.34z"
			/>
		</>
	),
}
