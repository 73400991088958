import * as React from 'react'
import styled from 'styled-components'

import {
	DropdownCaret,
	Grid,
	HelmetData,
	Page,
	PoweredByUniLifeTeaser,
	Sidebar,
	SidebarContent,
	SidebarToggle,
	ThemeWrapper,
} from '@app/components'
import { Event, EventOrganizer, EventResource, PageResource } from '@compass/types'

import * as EventsComponents from './components'

import { CompassUtils } from '@app/utils'
import moment from 'moment'
import { getMappedEvents } from './helpers'
import { EventsFiltersState, EventsState, Timeslots } from './types'

import { mixins, spacing } from '@app/config'
import { AssociationFiltersForm } from './components/association-filters-form'
import { CategoryFiltersForm } from './components/categories-filter-form'
import { TimeslotFiltersForm } from './components/timeslot/filters-form'
import { ViewSwitch } from './components/view-switch'

interface EventsBaseProps {
	resource: PageResource
}

interface EventsBaseState {
	calendar: EventResource[]
	categories: Event['categories']
	associations: EventOrganizer[]
	filters: EventsFiltersState
	state: EventsState
	loading: boolean
	error?: any
	date: any
	isMobileOpen: boolean
	view: 'list' | 'calendar'
}

type EventsProps = EventsBaseProps

const EventsTitle = styled.h1({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginTop: spacing.sm,

	...mixins.mediaStyles({
		md: {
			marginTop: 40,
			marginBottom: -10,
			height: 40,

			['h1']: {
				lineHeight: 1,
			},
		},
	}),
})

export class PageEvents extends React.Component<EventsProps, EventsBaseState> {
	public state: EventsBaseState = {
		calendar: [],
		associations: [],
		categories: [],
		filters: {
			selectedTimeslots: null,
			selectedCategories: null,
			selectedAssociations: null,
		},
		state: EventsState.FEATURED,
		loading: true,
		date: null,
		isMobileOpen: false,
		view: 'list',
	}

	public static getDerivedStateFromProps(_props: EventsProps, state: EventsBaseState) {
		return {
			categories: CompassUtils.events.getCategories(state.calendar),
			associations: CompassUtils.events.getAssociations(state.calendar),
		}
	}

	public componentDidMount() {
		if (!this.state.calendar.length) {
			this.getEvents()
		}
	}

	private async getEvents() {
		this.setState({ loading: true })

		try {
			const calendar = await CompassUtils.events.fetchEvents()
			this.setState({ calendar, error: null })
		} catch (error) {
			this.setState({ error })
		} finally {
			this.setState({ loading: false })
		}
	}

	public updateState(state: EventsBaseState) {
		this.setState(state)
	}

	private toggleSidebar = () => {
		this.setState({
			isMobileOpen: !this.state.isMobileOpen,
		})
	}

	public onUpdateTimeslotFilters = (selectedTimeslots: Timeslots[]) => {
		this.setState({
			filters: {
				...this.state.filters,
				selectedTimeslots,
			},
		})
	}

	public onUpdateCategoryFilters = (selectedCategories: string[]) => {
		this.setState({
			filters: {
				...this.state.filters,
				selectedCategories,
			},
		})
	}

	public onUpdateAssociationFilters = (selectedAssociations: string[]) => {
		this.setState({
			filters: {
				...this.state.filters,
				selectedAssociations,
			},
		})
	}

	public toggleView = () => {
		const view = this.state.view === 'list' ? 'calendar' : 'list'
		this.setState({ view })
	}

	public render() {
		const { resource } = this.props
		const {
			filters,
			calendar,
			associations,
			categories,
			loading,
			isMobileOpen,
			view,
		} = this.state

		const mappedEvents = getMappedEvents(calendar, filters)
		const now = moment()

		const today = mappedEvents.filter(event =>
			moment(event.event.date.start).isSame(now, 'day'),
		)
		const week = mappedEvents.filter(
			event =>
				now.isBefore(event.event.date.start) &&
				moment(event.event.date.start).isSame(now, 'isoWeek') &&
				!today.some(e => e.id === event.id),
		)
		const month = mappedEvents.filter(
			event =>
				now.isBefore(event.event.date.start) &&
				moment(event.event.date.start).isSame(now, 'month') &&
				!today.some(e => e.id === event.id) &&
				!week.some(e => e.id === event.id),
		)
		const ongoing = mappedEvents.filter(
			event =>
				now.isBetween(moment(event.event.date.start), moment(event.event.date.end)) &&
				!today.some(e => e.id === event.id) &&
				!week.some(e => e.id === event.id),
		)
		const later = mappedEvents.filter(
			event =>
				!today.some(e => e.id === event.id) &&
				!week.some(e => e.id === event.id) &&
				!month.some(e => e.id === event.id),
		)

		const eventProps = {
			today,
			week,
			month,
			ongoing,
			later,
			calendar: mappedEvents,
		}

		return (
			<Page>
				<HelmetData title={resource.title} seo={resource.seo} />

				<ThemeWrapper fixedTheme="events">
					<Grid.Container css={{ overflow: 'hidden' }}>
						<Grid.Row>
							<Grid.LeftPaddedColumn xs={16} md={4} xl={3}>
								<EventsTitle>
									<h1 className="u-theme-text">{resource.title}</h1>

									<ViewSwitch view={view} onChange={this.toggleView} />
								</EventsTitle>

								<Sidebar css={{ height: 'auto' }} isOpen={isMobileOpen}>
									<SidebarToggle
										isOpen={isMobileOpen}
										onClick={this.toggleSidebar}>
										<DropdownCaret isActive={isMobileOpen} />
										Options
									</SidebarToggle>
									<SidebarContent>
										<CategoryFiltersForm
											categories={categories}
											selectedCategories={filters.selectedCategories}
											onUpdateFilters={this.onUpdateCategoryFilters}
										/>
										<TimeslotFiltersForm
											selectedTimeslots={filters.selectedTimeslots}
											onUpdateFilters={this.onUpdateTimeslotFilters}
										/>
										<AssociationFiltersForm
											associations={associations}
											selectedAssociations={filters.selectedAssociations}
											onUpdateFilters={this.onUpdateAssociationFilters}
										/>

										<PoweredByUniLifeTeaser />
									</SidebarContent>
								</Sidebar>
							</Grid.LeftPaddedColumn>
							<Grid.PaddedColumn xs={16} md={{ size: 12 }} xl={{ size: 13 }}>
								<EventsComponents.Wrapper>
									{view === 'list' && (
										<>
											<EventsComponents.List
												filters={filters}
												events={eventProps.today}
												isLoading={loading}
												title="Today"
											/>

											<EventsComponents.List
												filters={filters}
												events={eventProps.week}
												isLoading={loading}
												title="This week"
											/>

											<EventsComponents.List
												filters={filters}
												events={eventProps.month}
												isLoading={loading}
												title="This month"
											/>

											<EventsComponents.List
												filters={filters}
												events={eventProps.ongoing}
												isLoading={loading}
												title="Ongoing events"
											/>

											<EventsComponents.List
												filters={filters}
												events={eventProps.later}
												isLoading={loading}
												title="Later"
											/>
										</>
									)}

									{view === 'calendar' && (
										<>
											<h3
												css={{
													marginBottom: spacing.sm,
													marginTop: 14,
												}}
												className="u-theme-text">
												Calendar
											</h3>
											<EventsComponents.Calendar
												{...this.state}
												calendar={eventProps.calendar}
												renderDayEvents={(events, day) =>
													events ? (
														<div css={{ width: '100%' }}>
															<ThemeWrapper fixedTheme="events">
																<EventsComponents.List
																	isLoading={false}
																	title={`Event${
																		events.length > 1 ? 's' : ''
																	} on ${day.format(
																		'dddd D MMMM YYYY',
																	)}:`}
																	filters={filters}
																	events={events}
																	view="tooltip"
																/>
															</ThemeWrapper>
														</div>
													) : null
												}
												selectDate={({ date }) => this.setState({ date })}
											/>
										</>
									)}
								</EventsComponents.Wrapper>
							</Grid.PaddedColumn>
						</Grid.Row>
					</Grid.Container>
				</ThemeWrapper>
			</Page>
		)
	}
}
