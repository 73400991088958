import moment from 'moment'
import React from 'react'
import ReactCalendar from 'tt-react-calendar'

import * as CalendarComponents from './calendar-components'
import { CalendarProps, getEventsSettings, renderDay } from './helpers'

export const Calendar: React.SFC<CalendarProps> = props => {
	const { events } = props
	const eventsSettings = getEventsSettings(events)

	return (
		<CalendarComponents.Wrapper>
			<ReactCalendar
				dayAbbrevs={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
				dayHeaderStyle={ReactCalendar.DayHeaderStyles.InEveryMonth}
				monthHeaderFormat="MMMM YYYY"
				firstRenderedDay={moment().startOf('month')}
				monthClassName="tt-month"
				monthHeaderClassName="tt-month-header u-theme-text"
				lastRenderedDay={eventsSettings.lastEventDate}
				renderDay={renderDay(eventsSettings.datesWithEvents, props)}
			/>
		</CalendarComponents.Wrapper>
	)
}
