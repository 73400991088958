import React, { Suspense } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import { FlexibleContentItem } from '@compass/types/types/flexible-content'

import { Loader } from '@app/components/loader'
import * as SearchModalComponents from '@app/components/modals/search-modal-components'
import { RichTextContent } from '@app/components/rich-text-content'
import { Small } from '@app/components/small'
import { withContentStore, WithContentStoreProps } from '@app/stores'
import { CompassUtils, isBrowser } from '@app/utils'
import { spacing } from '@styleguide'

export interface FlexibleContentProps {
	content: FlexibleContentItem[]
}

const LazyGuidanceDocument = React.lazy(() =>
	import('@app/components/guidance-document/guidance-document'),
)

const FlexibleContentSection = styled.div({
	marginBottom: spacing.lg,
	clear: 'both',

	['&:first-child']: {
		marginTop: spacing.lg,
	},
})

const IframeWrapper = styled.div({
	position: 'relative',
	width: '100%',
	height: '0',
	paddingBottom: '56.25%',

	['iframe']: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
})

interface FlexibleContentImageProps {
	isFloated?: boolean
}

const FlexibleContentImage = styled.figure<FlexibleContentImageProps>(({ isFloated }) => ({
	margin: `0 auto`,
	display: 'block',
	width: 800,
	maxWidth: '100%',

	['img']: {
		display: 'block',
		width: '100%',
		height: 'auto',
	},

	['figcaption']: {
		display: 'block',
		marginTop: 10,
	},

	...(isFloated
		? {
				float: 'right',
				marginLeft: spacing.sm,
				marginRight: 0,
				marginBottom: spacing.sm,
				width: 400,
		  }
		: {}),
}))

const AnchorIframe = styled.iframe({
	width: '100%',
	height: 160,

	['@media (min-width: 1024px) and (max-width: 1313px)']: {
		height: 98,
	},

	['@media (max-width: 808px)']: {
		height: 98,
	},

	['@media (min-width: 1366px) and (max-width: 1437px)']: {
		height: 98,
	},
})

export class FlexibleContentClass extends React.Component<
	FlexibleContentProps & WithContentStoreProps
> {
	public render() {
		const { content, contentStore } = this.props
		const { navigation } = contentStore.state

		return content.length ? (
			<div>
				{content.map((item, index) => {
					switch (item.acf_fc_layout) {
						case 'text':
							return (
								<FlexibleContentSection key={index}>
									{item.image && (
										<FlexibleContentImage isFloated={true}>
											<img src={item.image} />
											{item.caption && (
												<figcaption>
													<Small css={{ fontSize: '1.4rem' }}>
														{item.caption}
													</Small>
												</figcaption>
											)}
										</FlexibleContentImage>
									)}
									<RichTextContent content={item.content} />
								</FlexibleContentSection>
							)
						case 'image':
							return (
								<FlexibleContentSection key={index}>
									<FlexibleContentImage>
										<img src={item.image} />
										{item.caption && (
											<figcaption>
												<Small>{item.caption}</Small>
											</figcaption>
										)}
									</FlexibleContentImage>
								</FlexibleContentSection>
							)
						case 'oembed':
							return (
								<FlexibleContentSection key={index}>
									<IframeWrapper dangerouslySetInnerHTML={{ __html: item.url }} />
								</FlexibleContentSection>
							)
						case 'anchor':
							return (
								<FlexibleContentSection key={index}>
									<AnchorIframe src={item.url} frameBorder="0" scrolling="no" />
								</FlexibleContentSection>
							)
						case 'guidance_document':
							return (
								<FlexibleContentSection key={index}>
									{isBrowser ? (
										<Suspense
											fallback={
												<Loader
													loading
													fixed={false}
													text="Loading guidance document"
												/>
											}>
											<LazyGuidanceDocument
												document={{
													src: item.file,
													caption: 'Download guidance document',
												}}
											/>
										</Suspense>
									) : null}
								</FlexibleContentSection>
							)
						case 'page_link':
							return (
								<FlexibleContentSection key={index}>
									{item.header && (
										<Small css={{ marginBottom: 10 }}>{item.header}</Small>
									)}
									{item.page.map(resource => (
										<SearchModalComponents.SmallTeaser
											onWhiteBackground={true}
											key={resource.slug}
											to={resource.slug}
											resource={resource}
											navigation={navigation}
											icon={CompassUtils.navigation.getSectionIcon(resource)}
											theme={CompassUtils.navigation.getSectionSlugByResourceSlug(
												resource.slug,
											)}
											css={{ margin: 0 }}
										/>
									))}
								</FlexibleContentSection>
							)
						default:
							return null
					}
				})}
			</div>
		) : null
	}
}

export const FlexibleContent = compose<WithContentStoreProps, FlexibleContentProps>(
	withContentStore,
)(FlexibleContentClass)
