import React from 'react'

import { Calendar as CalendarComponent } from '@app/components'
import { EventResource } from '@compass/types'

import moment from 'moment'
import * as CalendarComponents from './components'

interface EventsCalendarProps {
	loading: boolean
	error?: any
	selectDate: ({ date }: { date: Date }) => void
	calendar: EventResource[]
	renderDayEvents?: (Event: EventResource[], day: moment.Moment) => any
}

export default class extends React.Component<EventsCalendarProps> {
	public render() {
		const { loading = false, calendar = [], error, selectDate, renderDayEvents } = this.props
		return (
			<>
				<CalendarComponents.Item isVisible={!loading && calendar.length > 0}>
					<CalendarComponent
						events={calendar}
						onSelect={(date: Date) => selectDate({ date })}
						renderDayEvents={renderDayEvents!}
					/>
				</CalendarComponents.Item>
				<CalendarComponents.Item isVisible={!loading && error}>
					<p>An error occured while loading the calendar.</p>
				</CalendarComponents.Item>
			</>
		)
	}
}
