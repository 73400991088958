import styled from 'styled-components'

import {
	IconNavigation as IconNavigationComponent,
	Sponsors as SponsorsComponent,
} from '@app/components'
import { mixins, spacing } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
})

export const Section = styled.section({
	position: 'relative',
	padding: spacing.sm,
	marginBottom: spacing.lg,

	...mixins.mediaStyles({
		md: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',

			['&:first-of-type']: {
				marginTop: -70,
			},
		},
		lg: {
			padding: 0,
			minHeight: '100vh',
		},
	}),
})

export const Sponsors = styled(SponsorsComponent)({
	[mixins.media.min('lg')]: {
		display: 'block',
		position: 'absolute',
		bottom: spacing.sm,
		left: 0,
	},
})

export const IconNavigation = styled(IconNavigationComponent)({
	position: 'absolute',
	top: '20px !important',
	right: '20px !important',
	marginTop: '0 !important',

	[mixins.media.max('sm')]: {
		display: 'none',
	},
})
