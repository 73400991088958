import { MappedTag } from '@app/types'
import { ListResource } from '@compass/types'

import { SearchAutocompleteItem } from './autocomplete'

export const getMappedAutocompleteTags = (tags: MappedTag[]): SearchAutocompleteItem[] => {
	return tags
		.map(
			(tag: MappedTag): SearchAutocompleteItem => ({
				value: tag.tag,
				label: `${tag.tag} (<span>${tag.count}</span>)`,
				context: {
					type: 'tag',
				},
			}),
		)
		.slice(0, 5)
}

export const getMappedAutocompleteItems = (tags: ListResource[]): SearchAutocompleteItem[] => {
	return tags
		.map(
			(item: ListResource): SearchAutocompleteItem => ({
				value: String(item.id),
				label: item.title,
				context: {
					type: 'item',
					resource: item,
				},
			}),
		)
		.sort((a, b) => a.label.localeCompare(b.label))
		.slice(0, 5)
}
