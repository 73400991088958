import React from 'react'
import { Subscribe } from 'unstated'

import { ModalStore } from './modal'

export interface WithModalStoreProps {
	modalStore: ModalStore
}

export function withModalStore<P>(WrappedComponent: React.ComponentType<P & WithModalStoreProps>) {
	return class WithContentHoc extends React.Component<P> {
		public render() {
			return (
				<Subscribe to={[ModalStore]}>
					{(modalStore: ModalStore) => (
						<WrappedComponent {...this.props} modalStore={modalStore} />
					)}
				</Subscribe>
			)
		}
	}
}
