import React from 'react'
import styled from 'styled-components'

import { shellConfig } from '@app/config'
import { isBrowser, isMobile } from '@app/utils'
import { colors, shadows, spacing, transition } from '@styleguide'

import { Button } from '../button'
import { SvgIcon } from '../svg-icons'

export interface A2HSState {
	isVisible: boolean
	isClosed: boolean
}

const Wrapper = styled.aside<{ isVisible: boolean }>(({ isVisible }) => ({
	position: 'fixed',
	bottom: 0,
	left: 0,
	width: '100%',
	background: colors.WHITE,
	boxShadow: shadows[4],
	borderTop: `1px ${colors.GREY_30} solid`,
	zIndex: 1000000,
	padding: spacing.xs,
	transform: isVisible ? 'none' : 'translateY(100%)',
	transition: transition.config,
	display: 'flex',
	alignItems: 'center',
}))

const CloseButton = styled.button({
	flex: 1,
	marginLeft: spacing.xs,
	height: 16,
})

export class A2HS extends React.Component<{}, A2HSState> {
	public state: A2HSState = {
		isVisible: false,
		isClosed: false,
	}

	public componentDidMount() {
		setTimeout(() => {
			// @ts-ignore
			if (window.installEvent && sessionStorage.getItem('a2hs-closed') !== '1') {
				this.setState({ isVisible: true })
			}
		}, 1000)

		window.addEventListener('appinstalled', this.close)
	}

	private close = () => {
		this.setState({ isClosed: true })
		sessionStorage.setItem('a2hs-closed', '1')
	}

	public render() {
		const { isVisible, isClosed } = this.state

		if (!isBrowser || !isMobile()) {
			return null
		}

		return (
			<Wrapper isVisible={isVisible && !isClosed}>
				<Button
					css={{ width: '100%' }}
					className="u-theme-background"
					buttonSize="small"
					// @ts-ignore
					onClick={() => window.installEvent.prompt()}>
					Add {shellConfig.domain} to home screen
				</Button>
				<CloseButton type="button" onClick={this.close}>
					<SvgIcon icon="cross" />
				</CloseButton>
			</Wrapper>
		)
	}
}
