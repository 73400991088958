import { PublicConfig } from '../types'

import { publicConfig as anytown } from '../config/anytown/public'
import { publicConfig as mymaastricht } from '../config/mymaastricht/public'

const config = {
	mymaastricht,
	anytown,
}

export const getPublicShellConfig = (shell: string | undefined): PublicConfig => {
	if (!shell) {
		throw new Error('Shell is not set')
	}

	const publicConfig = config[shell as keyof typeof config]

	if (!publicConfig) {
		throw new Error('Invalid shell')
	}

	return publicConfig
}
