import React from 'react'
import styled from 'styled-components'

import { FlexibleContent } from '@app/components/flexible-content'
import { RichTextContent } from '@app/components/rich-text-content'
import { Resource } from '@compass/types'

interface StaticContentSectionProps {
	resource: Resource
}

const StaticContentSectionGlam = styled.article({
	position: 'relative',

	['& iframe']: {
		maxWidth: '100%',
	},
})

export const StaticContentSection: React.SFC<StaticContentSectionProps> = ({ resource }) => (
	<StaticContentSectionGlam>
		{resource.content && <RichTextContent content={resource.content} />}
		{resource.flexible_content && <FlexibleContent content={resource.flexible_content} />}
	</StaticContentSectionGlam>
)
