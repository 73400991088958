import { domains } from '@app/config'
import http from '@app/utils/http'
import { PageFeedbackFormInput, PageResource, Resource, ResourceTemplateType } from '@compass/types'

export const getResource = (path: string) =>
	http(`${domains.api}/resource/${path.replace(/^\/|\/$/g, '')}`)

export const search = (query: string) => http(`${domains.api}/search?query=${query}&limit=5`)

export const getAds = (slug: string) =>
	http(`${domains.api}/partners/${slug.replace(/^\/|\/$/g, '')}`)

export const submitFeedback = (values: PageFeedbackFormInput) =>
	http(`${domains.api}/forms`, 'POST', {
		...values,
		action: 'pageFeedback',
		url: window.location.href,
	})

export const getResourceTemplateType = (resource: Resource): ResourceTemplateType | undefined => {
	if (!resource) {
		return undefined
	}

	if (resource.type === 'page') {
		const { template } = resource as PageResource
		switch (template) {
			case 'page-domain':
				return ResourceTemplateType.DOMAIN_PAGE

			case 'page-events':
				return ResourceTemplateType.EVENTS_PAGE

			case 'page-maps':
				return ResourceTemplateType.MAP_PAGE

			case 'page-media':
				return ResourceTemplateType.MEDIA_PAGE

			case 'page-list':
				return ResourceTemplateType.LIST_PAGE

			case 'page-section':
				return ResourceTemplateType.SECTION_PAGE

			case 'page-home':
				return ResourceTemplateType.HOME_PAGE

			default:
				return ResourceTemplateType.DEFAULT_PAGE
		}
	} else {
		switch (resource.type) {
			case 'event':
				return ResourceTemplateType.EVENT_PAGE

			case 'blog':
				return ResourceTemplateType.ARTICLE_PAGE

			case 'redirect':
				return ResourceTemplateType.REDIRECT

			default:
				return undefined
		}
	}
}

export const getResourcesByResourceTemplateType = (
	resources: Resource[],
	type: ResourceTemplateType,
): Resource[] => resources.filter(resource => getResourceTemplateType(resource) === type)
