import React from 'react'

import { CompassUtils } from '@app/utils'
import { AdResource } from '@compass/types'

import * as AdsComponents from './ads-components'

interface AdsProps {
	slug: string
}

interface AdsState {
	ads: AdResource[]
}

export class Ads extends React.Component<AdsProps, AdsState> {
	public state: AdsState = {
		ads: [],
	}

	public async componentDidMount() {
		const { slug } = this.props

		try {
			const ads = await CompassUtils.resource.getAds(slug)
			this.setState({ ads })
		} catch {
			//
		}
	}

	public render() {
		const { ads = [] } = this.state

		return (
			<AdsComponents.Wrapper>
				{ads.map(
					(ad, index) =>
						ad.link &&
						ad.image && <AdsComponents.Ad ad={ad} key={ad.id + '-' + index} />,
				)}
			</AdsComponents.Wrapper>
		)
	}
}
