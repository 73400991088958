import styled from 'styled-components'

export const Validators = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
})

export const Validator = styled.a({
	display: 'block',
})

export const ValidatorLogo = styled.img({
	display: 'block',
	maxHeight: 100,
	width: 'auto',
	maxWidth: '100%',
})
