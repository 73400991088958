import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const phone: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<>
			<path
				fill={fill}
				d="M18 0H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 22H6v-3h12zm0-5H6V5h12zM6 3V2h12v1z"
				fillRule="evenodd"
			/>
			<circle fill={fill} cx="12" cy="20.57" r="1" />
		</>
	),
}
