import styled from 'styled-components'

import { transition } from '@styleguide'

interface PlusMinusProps {
	isMinus?: boolean
}

export const PlusMinus = styled.span<PlusMinusProps>(({ isMinus }) => ({
	position: 'relative',
	display: 'block',
	width: '24px',
	height: '24px',

	['&:before']: {
		position: 'absolute',
		top: 0,
		left: '50%',
		transform: 'translateX(-50%)',
		content: '""',
		borderLeft: '2px currentColor solid',
		height: '100%',
		transition: transition.config,

		...(isMinus
			? {
					transform: 'translateX(-50%) rotate(270deg)',
					opacity: 0,
			  }
			: {}),
	},

	['&:after']: {
		position: 'absolute',
		top: '50%',
		left: 0,
		transform: 'translateY(-50%)',
		content: '""',
		borderTop: ' 2px currentColor solid',
		width: '100%',
		transition: transition.config,

		...(isMinus
			? {
					transform: 'translateY(-50%) rotate(180deg)',
			  }
			: {}),
	},
}))
