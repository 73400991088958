import styled from 'styled-components'

import { colors, mixins } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',

	['&:not(:last-child)']: {
		marginBottom: 20,
	},
})

export const ButtonWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export const HiddenInput = styled.input.attrs({})({
	display: 'none',
})

export const LabelText = styled.label({
	fontSize: '1.4rem',
	lineHeight: '1.8',
	color: colors.GREY_40,
	marginBottom: 5,
	display: 'block',
	...mixins.primaryFont(),
})

export const ErrorLabel = styled.label({
	fontSize: '1.4rem',
	lineHeight: '1.43',
	color: colors.PRIMARY,

	marginTop: 5,
})
