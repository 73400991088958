import React from 'react'

import { SvgIconMap } from '../types'

export const logo: SvgIconMap = {
	size: {
		width: 197,
		height: 197,
	},
	Icon: () => (
		<>
			<defs>
				<linearGradient
					x1="23.7166318%"
					y1="9.69732002%"
					x2="87.4577022%"
					y2="94.6705202%"
					id="linearGradient-1">
					<stop stopColor="#B7027B" offset="0%" />
					<stop stopColor="#CE0280" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="32.6032408%"
					y1="79.0360707%"
					x2="79.7006888%"
					y2="19.0271406%"
					id="linearGradient-2">
					<stop stopColor="#DB4612" offset="0%" />
					<stop stopColor="#D06929" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="77.0702674%"
					y1="91.3580809%"
					x2="8.64817097%"
					y2="8.6405183%"
					id="linearGradient-3">
					<stop stopColor="#D81A31" offset="0%" />
					<stop stopColor="#F32830" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="73.6761409%"
					y1="13.1612621%"
					x2="9.92924146%"
					y2="94.2206836%"
					id="linearGradient-4">
					<stop stopColor="#97C66D" offset="0%" />
					<stop stopColor="#98D86C" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="-0.00295676998%"
					y1="49.9746013%"
					x2="100.004894%"
					y2="49.9746013%"
					id="linearGradient-5">
					<stop stopColor="#0299C1" offset="0%" />
					<stop stopColor="#00A2DB" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="49.9876033%"
					y1="5.88896819%"
					x2="49.9876033%"
					y2="78.5028548%"
					id="linearGradient-6">
					<stop stopColor="#EA992F" offset="0%" />
					<stop stopColor="#FFAD29" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="49.984463%"
					y1="87.2942196%"
					x2="49.7194887%"
					y2="18.4824141%"
					id="linearGradient-7">
					<stop stopColor="#2B9370" offset="0.1550099%" />
					<stop stopColor="#29A07E" offset="100%" />
				</linearGradient>
				<linearGradient
					x1="0.00336391437%"
					y1="49.9746013%"
					x2="99.9970438%"
					y2="49.9746013%"
					id="linearGradient-8">
					<stop stopColor="#2B4C78" offset="0%" />
					<stop stopColor="#264560" offset="99.84%" />
				</linearGradient>
			</defs>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Group" fillRule="nonzero">
					<polygon
						id="Path"
						fill="#ED1B34"
						points="41.58 41.57 98.68 78.96 78.96 98.68"
					/>
					<polygon
						id="Path"
						fill="#E84E10"
						points="155.77 41.57 118.39 98.68 98.68 78.96"
					/>
					<polygon
						id="Path"
						fill="#573B86"
						points="155.77 155.77 98.68 118.39 118.39 98.68"
					/>
					<polygon
						id="Path"
						fill="#CE0280"
						points="155.77 155.77 98.68 118.39 118.39 98.68"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-1)"
						points="155.77 155.77 108.53 108.53 118.39 98.68"
					/>
					<polygon
						id="Path"
						fill="#98D86C"
						points="41.58 155.77 78.96 98.68 98.68 118.39"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-2)"
						points="155.77 41.57 118.39 98.68 108.53 88.82"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-3)"
						points="41.58 41.57 88.82 88.82 78.96 98.68"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-4)"
						points="41.54 155.74 79 98.69 88.84 108.56"
					/>
					<polyline
						id="Path"
						fill="#00A2DB"
						points="196.76 98.68 115.61 115.61 98.68 98.68 115.61 81.74 196.76 98.68"
					/>
					<polygon
						id="Path"
						fill="#FFAD29"
						points="98.68 196.76 81.74 115.61 98.68 98.68 115.61 115.61"
					/>
					<polygon
						id="Path"
						fill="#29A07E"
						points="98.68 0.59 115.61 81.74 98.68 98.68 81.74 81.74"
					/>
					<polygon
						id="Path"
						fill="#2B4C78"
						points="0.58 98.68 81.74 81.74 98.68 98.68 81.74 115.61"
					/>
					<polyline
						id="Path"
						fill="url(#linearGradient-5)"
						points="196.76 98.68 115.61 115.61 98.68 98.68 196.76 98.68"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-6)"
						points="98.68 196.76 81.74 115.61 98.68 98.68"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-7)"
						points="98.68 0.59 115.61 81.74 98.68 98.68"
					/>
					<polygon
						id="Path"
						fill="url(#linearGradient-8)"
						points="0.58 98.68 98.68 98.68 81.74 115.61"
					/>
					<path
						d="M108.96,98.68 C108.96,104.36 104.35,108.96 98.68,108.96 C93,108.96 88.4,104.36 88.4,98.68 C88.4,93 93,88.39 98.68,88.39 C104.35,88.39 108.96,92.99 108.96,98.68 Z"
						id="Path"
						fill="#FFFFFF"
					/>
				</g>
			</g>
		</>
	),
}
