import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { Accordion, AutoSubmit, Checkbox } from '@app/components'
import { CategoryWrapper } from '@app/pages/map/map-components'
import { EventOrganizer } from '@compass/types'

export interface AssociationFiltersFormProps {
	associations: EventOrganizer[]
	selectedAssociations: string[] | null
	onUpdateFilters: (selectedAssociation: string[]) => void
}

const CheckboxLabel = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export const AssociationFiltersForm: React.SFC<AssociationFiltersFormProps> = ({
	associations,
	selectedAssociations,
	onUpdateFilters,
}) => (
	<Form
		initialValues={{
			associations: selectedAssociations,
		}}
		onSubmit={(values: any) => onUpdateFilters(values.associations)}>
		{() => (
			<>
				<AutoSubmit submit={values => onUpdateFilters(values.associations)} />
				<Accordion
					header={<span>Choose an association:</span>}
					accordionPosition={1}
					last={true}>
					{associations.map(association => (
						<CategoryWrapper key={association.name}>
							<Field
								name="associations"
								type="checkbox"
								value={association.name}
								render={({ input }) => (
									<Checkbox
										{...input}
										checkboxType="right"
										id={association.name}
										checked={
											!!selectedAssociations &&
											selectedAssociations.includes(association.name)
										}
										labelText={
											<CheckboxLabel>{association.name}</CheckboxLabel>
										}
									/>
								)}
							/>
						</CategoryWrapper>
					))}
				</Accordion>
			</>
		)}
	</Form>
)
