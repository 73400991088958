import React from 'react'
import styled, { CSSObject } from 'styled-components'

import { colors, mixins, shadows, spacing, transition } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',

	['& .tt-cal-dayHeaders, & .tt-cal-week']: {
		display: 'flex',
		justifyContent: 'space-between',
	},

	['& .tt-cal-dayHeaders']: {
		marginBottom: spacing.sm,
		color: colors.GREY_50,
		...mixins.primaryFont(),
	},

	['& .tt-cal-columnHeader, & .tt-cal-dummyDay, & .tt-cal-day']: {
		width: '60px',
		textAlign: 'center',
	},

	['& .tt-month']: {
		borderBottom: `1px ${colors.GREY_20} solid`,
		paddingBottom: spacing.sm,

		[mixins.media.min('xl')]: {
			width: '50%',
			padding: spacing.md,

			['&:nth-child(2n + 1)']: {
				borderRight: `1px ${colors.GREY_20} solid`,
			},
		},
	},

	['& .tt-month-header']: {
		position: 'relative',
		margin: `${spacing.md}px 0 ${spacing.sm}px`,

		[mixins.media.min('xl')]: {
			margin: `${spacing.xs}px 0 ${spacing.sm}px`,
		},
	},

	...mixins.mediaStyles({
		xl: {
			margin: -spacing.md,

			['> div']: {
				display: 'flex',
				flexWrap: 'wrap',
				marginBottom: 2
			},
		},
	}),
})

interface DateProps {
	hasEvents: boolean
}

export const Date = styled.span<DateProps>(({ hasEvents }) => ({
	position: 'relative',
	transition: transition.config,
	transitionProperty: 'color',
	...mixins.primaryFont(),

	...(hasEvents ? { color: colors.WHITE } : {}),
}))

interface ButtonProps {
	hasEvents: boolean
	isActive: boolean
}

const activeStyles =  {
	transform: 'scale(1.2)',
	zIndex: 1,

	['&::before']: {
		opacity: 1,
	},
}

export const Button = styled.button<ButtonProps>(({ hasEvents, isActive }) => ({
	position: 'relative',
	padding: `${spacing.sm}px 0`,
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'default',
	zIndex: 0,
	transition: `transform ${transition.config}, z-index ${transition.duration} linear`,

	['&:before']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		content: '""',
		boxShadow: shadows[2],
		opacity: 0,
		transition: transition.config,
		transitionProperty: 'opacity',
	},

	...(hasEvents
		? {
				cursor: 'pointer',

				['&:hover']: activeStyles
		  }
		: {}),

	... isActive && activeStyles
}))

interface ButtonCircleProps extends React.HTMLProps<HTMLButtonElement> {
	ref?: any
	css?: CSSObject
}

export const ButtonCircleGlam = styled.span({
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	transition: transition.config,
	transitionProperty: 'width',
})

export const ButtonCircleInnerGlam = styled.span({
	position: 'relative',
	display: 'block',
	maxWidth: '80px',
	height: 0,
	paddingTop: '100%',
	borderRadius: '50%',
	background: 'currentColor',
	maxHeight: '20px',
})

export const ButtonCircle: React.SFC<ButtonCircleProps> = ({ as: _as, ...props }) => (
	<ButtonCircleGlam {...props}>
		<ButtonCircleInnerGlam />
	</ButtonCircleGlam>
)
