import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import styled from 'styled-components'

import { withContentStore, WithContentStoreProps } from '@app/stores'
import { CompassUtils } from '@app/utils'
import { themeColors, themeStyles } from '@styleguide'

const setNewThemeByProps = (props: ThemeWrapperProps): string => {
	if (props.fixedTheme) {
		return props.fixedTheme
	}

	const { activeSection } = props.contentStore.state
	const theme =
		activeSection && CompassUtils.navigation.getSectionSlugByResourceSlug(activeSection.slug)

	if (theme && themeStyles[theme]) {
		return theme
	}

	return 'default'
}

interface ThemeWrapperBaseProps {
	children: any
	update?: boolean
	fixedTheme?: string
}

type ThemeWrapperProps = ThemeWrapperBaseProps & WithContentStoreProps & RouteComponentProps<any>

interface ThemeWrapperState {
	theme: string
}

const Theme = styled.div(({ t }: { t: string }) =>
	t && themeStyles[t]
		? {
				[' .u-theme-text']: {
					color: themeStyles[t].color,
				},
				[' > .u-theme-text-important']: {
					color: `${themeStyles[t].color} !important`,
				},
				[' .u-theme-text-hover:hover']: {
					color: themeStyles[t].color,
				},
				[' .u-theme-background']: {
					background: themeStyles[t].background,
				},
				[' .u-theme-background-simple']: {
					background: themeStyles[t].backgroundSimple,
				},
				[' [class*="s-rich-text-content"] ']: {
					['& h1, & h2, & h3, & h4, & h5, & h6']: {
						color: themeColors[t as keyof typeof themeColors].light,
					},
					['& a']: {
						color: themeColors[t as keyof typeof themeColors].dark,
					},
				},
		  }
		: {},
)

export class ThemeWrapper extends React.Component<ThemeWrapperProps, ThemeWrapperState> {
	constructor(props: ThemeWrapperProps) {
		super(props)

		this.state = {
			theme: setNewThemeByProps(props),
		}
	}
	public componentWillReceiveProps(props: ThemeWrapperProps) {
		if (props.update) {
			this.setState({
				theme: setNewThemeByProps(props),
			})
		}
	}
	public render() {
		const { children } = this.props
		const { theme } = this.state

		return <Theme t={theme}>{children}</Theme>
	}
}

export default compose<ThemeWrapperProps, ThemeWrapperBaseProps>(
	withRouter,
	withContentStore,
)(ThemeWrapper)
