import styled from 'styled-components'

import { colors, mixins, shadows, spacing, transition } from '@styleguide'

interface TooltipProps {
	visible: boolean
	entering: boolean
}

export const Tooltip = styled.div<TooltipProps>(({ entering = false, visible = false }) => ({
	position: 'absolute',
	padding: spacing.xs,
	background: colors.GREY_60,
	borderRadius: 5,
	display: 'flex',
	marginBottom: spacing.xs,
	zIndex: 10,
	color: colors.WHITE,
	boxShadow: shadows[4],
	opacity: visible ? 1 : 0,
	transform: visible
		? `translate(-50%, -${100 + spacing.md}%) scale(1)`
		: `translate(-50%, -${100 + spacing.md}%) scale(0)`,
	transition: visible
		? entering
			? `transform ${transition.config}, opacity ${transition.config}`
			: transition.config
		: `transform ${transition.config}, opacity ${transition.config},
            left 0s ${transition.duration}, top 0s ${transition.duration}`,
}))

interface ButtonProps {
	backgroundColors: {
		default: keyof typeof colors
		hover: keyof typeof colors
	}
}

export const Button = styled.a<ButtonProps>(({ backgroundColors }) => ({
	width: 32,
	height: 32,
	margin: '0 5px',
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	transition: transition.config,
	transitionProperty: 'background',
	background: colors[backgroundColors.default],

	['&:hover']: {
		background: colors[backgroundColors.hover],
	},

	['& svg']: {
		width: 16,
		height: 16,
	},
}))

export const FeedbackText = styled.button({
	display: 'flex',
	alignItems: 'center',
	fontSize: '1.8rem',
	color: colors.WHITE,
	...mixins.primaryFont(),
})
