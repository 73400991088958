import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { MasonryGridSizer, SvgIcon } from '@app/components'
import { YoutubeResource } from '@compass/types'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'

interface YoutubeProps {
	item: YoutubeResource
	onClick(url: string): void
}

const YoutubeThumbnail = styled.img({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	overflow: 'hidden',
})

const YoutubeWrapper = styled(MasonryGridSizer)({
	position: 'relative',
	display: 'flex',
	float: 'left',
	listStyle: 'none',
	marginBottom: spacing.sm,
	alignItems: 'center',
	justifyContent: 'center',
	transition: transition.config,
	transitionProperty: 'transform, z-index',
	transformOrigin: '50% 50%',

	['&:before']: {
		zIndex: -1,
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		boxShadow: shadows[6],
		opacity: 0,
		transition: transition.config,
		transitionProperty: 'opacity',
		content: '""',
		display: 'block',
	},

	['&:hover']: {
		transform: 'scale(1.05)',
		zIndex: 10,

		['&:before']: {
			opacity: 1,
		},
	},
})

const YoutubeThumbnailWrapper = styled.div({
	position: 'relative',
	height: 0,
	width: '100%',
	paddingBottom: '54%',
})

const YoutubeTitle = styled.h3({
	fontSize: '1.8rem',
	marginBottom: 5,
})

const YoutubeDate = styled.time({
	display: 'block',
})

const YoutubeContent = styled.div({
	padding: spacing.sm,
	position: 'absolute',
	display: 'flex',
	alignItems: 'flex-end',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	zIndex: 3,
	fontSize: '1.4rem',
	color: colors.WHITE,
	cursor: 'pointer',

	['&:before']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		content: '""',
		display: 'block',
		zIndex: -1,
		...mixins.clothoidGradient(0, 0.6, '0, 0, 0'),
	},
})

const YoutubeSourceName = styled.span({
	fontStyle: 'italic',
	fontWeight: 'bold',
})

const YoutubeIconCircle = styled.span({
	position: 'relative',
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: 10,
})

const YoutubeIcon = styled(SvgIcon)({
	width: 24,
	height: 24,
})

export const Youtube: React.SFC<YoutubeProps> = props => (
	<YoutubeWrapper className="grid-item">
		<YoutubeComponent {...props} />
	</YoutubeWrapper>
)

export const YoutubeComponent: React.SFC<YoutubeProps> = ({ item, onClick }) => (
	<YoutubeThumbnailWrapper>
		<YoutubeThumbnail src={`//img.youtube.com/vi/${item.id}/sddefault.jpg`} />
		<YoutubeContent
			onClick={() =>
				item && onClick(`https://youtube.com/embed/${item.id}?autoplay=1&mute=1`)
			}>
			<div>
				<YoutubeIconCircle className="u-theme-background">
					<YoutubeIcon icon="play" />
				</YoutubeIconCircle>
				<YoutubeTitle dangerouslySetInnerHTML={{ __html: item.video.title }} />
				{item.video.date && (
					<YoutubeDate>
						{moment(item.video.date).format('dddd D MMMM YYYY [at] HH:mm')}
					</YoutubeDate>
				)}
				{item.video.user && (
					<div>
						Video by <YoutubeSourceName>{item.video.user.name}</YoutubeSourceName>
					</div>
				)}
			</div>
		</YoutubeContent>
	</YoutubeThumbnailWrapper>
)
