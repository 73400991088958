import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import styled, { StyledComponent } from 'styled-components'

import { isBrowser, isMobile } from '@app/utils'
import { spacing } from '@styleguide'

import { Button } from '../button'
import { Small } from '../small'

const ShareButtonsWrapper = styled.div({
	margin: `${spacing.sm}px 0 0`,
})

const ShareButton = styled(Button)({
	margin: `0 ${spacing.xs}px ${spacing.xs}px 0`,
}) as StyledComponent<'button', any, any>

export interface ShareButtonProps {
	url?: string
	title?: string
}

export const ShareButtons = (props: ShareButtonProps) => {
	const url = props.url || (isBrowser ? window.location.href : '')
	const title = props.title || (isBrowser ? document.title : '')

	const isMobileDevice = isMobile()

	const [hasCopied, setHasCopied] = useState(false)

	return (
		<ShareButtonsWrapper>
			<Small css={{ marginBottom: 5 }}>Share this page on:</Small>

			{isMobileDevice && (
				<ShareButton
					type="button"
					icon="facebook"
					variant="facebook"
					buttonSize="medium"
					onClick={() =>
						window.open(
							`fb-messenger://share/?link=${encodeURIComponent(
								url,
							)}&app_id=1584748168428518`,
							'twitter-share-dialog',
							'left=20,top=20,width=750,height=500,toolbar=1,resizable=0',
						)
					}>
					Messenger
				</ShareButton>
			)}
			{/* {isMobileDevice && <ShareButton
				type="button"
				icon="instagram"
				variant="instagram"
				buttonSize="medium"
				onClick={() => window.open(`instagram://sharesheet/?text=${encodeURIComponent(title + ' - ' + url)}`)}>
				Instagram
			</ShareButton>} */}
			<ShareButton
				type="button"
				icon="twitter"
				variant="twitter"
				buttonSize="medium"
				onClick={() =>
					window.open(
						`https://twitter.com/intent/tweet?text=${encodeURIComponent(
							title,
						)}&url=${encodeURIComponent(url)}`,
						'twitter-share-dialog',
						'left=20,top=20,width=750,height=500,toolbar=1,resizable=0',
					)
				}>
				Twitter
			</ShareButton>
			<ShareButton
				a
				icon="whatsapp"
				variant="whatsapp"
				buttonSize="medium"
				href={`https://wa.me/?text=${encodeURIComponent(title + ' - ' + url)}`}>
				Whatsapp
			</ShareButton>
			<ShareButton
				type="button"
				icon={hasCopied ? 'check' : undefined}
				variant="grey"
				buttonSize="medium"
				onClick={() => {
					copy(url)
					setHasCopied(true)
				}}>
				{hasCopied ? 'Copied link' : 'Copy link'}
			</ShareButton>
		</ShareButtonsWrapper>
	)
}
