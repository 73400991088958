import { domains } from '@app/config'
import http from '@app/utils/http'
import { Event, EventOrganizer, EventResource } from '@compass/types'

export const fetchEvents = () =>
	http(`${domains.api}/resources/event?fields=id,title,slug,image,event,content`)

export const getCategories = (events: EventResource[]) => {
	return events
		.map(event => event.event.categories)
		.reduce((a, b) => [...a, ...b], [])
		.reduce(
			(eventsCategories, category) => {
				if (!eventsCategories.some(c => c.name === category.name)) {
					eventsCategories.push(category)
				}

				return eventsCategories
			},
			[] as Event['categories'],
		)
		.sort((a, b) => a.name.localeCompare(b.name))
}

export const getAssociations = (events: EventResource[]) => {
	return events
		.map(event => event.event.association)
		.reduce(
			(eventsAssociations, association) => {
				if (!association) {
					return eventsAssociations
				}

				if (!eventsAssociations.some(c => c.name === association.name)) {
					eventsAssociations.push(association)
				}

				return eventsAssociations
			},
			[] as EventOrganizer[],
		)
		.sort((a, b) => a.name.localeCompare(b.name))
}
