import React from 'react'

import { InputComponents, ToggleComponents } from './components'

export interface ToggleProps {
	id: string
	labelText?: React.ReactType | JSX.Element | string
	errorText?: React.ReactType | JSX.Element | string
	hasError?: boolean
}

export class Toggle extends React.Component<ToggleProps & React.HTMLProps<HTMLInputElement>> {
	public static Wrapper = ToggleComponents.Wrapper
	public static ButtonWrapper = InputComponents.ButtonWrapper
	public static Input = InputComponents.HiddenInput
	public static Label = ToggleComponents.Label
	public static LabelText = InputComponents.LabelText.withComponent('span')
	public static ErrorLabel = InputComponents.ErrorLabel

	public render() {
		const {
			id,
			labelText,
			errorText,
			hasError,
			disabled,
			ref: _ref,
			as: _as,
			...rest
		} = this.props
		return (
			<Toggle.Wrapper>
				<Toggle.ButtonWrapper>
					<Toggle.Input id={id} type="checkbox" disabled={!!disabled} {...rest} />
					<Toggle.Label htmlFor={id}>
						<ToggleComponents.Toggle>
							<ToggleComponents.Bar className="u-theme-background-simple" />
							<ToggleComponents.Circle className="u-theme-background-simple" />
						</ToggleComponents.Toggle>
						<ToggleComponents.LabelText>{labelText}</ToggleComponents.LabelText>
					</Toggle.Label>
				</Toggle.ButtonWrapper>
				{hasError && errorText && <Toggle.ErrorLabel>{errorText}</Toggle.ErrorLabel>}
			</Toggle.Wrapper>
		)
	}
}
