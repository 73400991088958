import React from 'react'
import styled from 'styled-components'

import { Small, Tag } from '@app/components'
import { AddTagFilterFunction, MappedTag } from '@app/types'
import { ListResource } from '@compass/types'
import { spacing } from '@styleguide'

const TagsListWrapper = styled.div({
	marginTop: spacing.sm,
})

interface ListFiltersTagsListProps {
	filteredList: ListResource[]
	tagsFilters: MappedTag[]
	tags?: string[]
	onAddTagFilter: AddTagFilterFunction
}

interface ListFiltersTagsListState {
	tagsList: MappedTag[]
}

export class TagsList extends React.Component<ListFiltersTagsListProps, ListFiltersTagsListState> {
	public state: ListFiltersTagsListState = {
		tagsList: this.getTagsChunks(this.props.tagsFilters, this.props.filteredList),
	}

	public getTagsChunks(tags: MappedTag[] = [], filteredList: ListResource[] = []) {
		return tags
			.map((tag: MappedTag) => ({
				...tag,
				count: filteredList.reduce(
					(amount: number, resource: ListResource) =>
						resource.tags && resource.tags.indexOf(tag.tag) > -1 ? amount + 1 : amount,
					0,
				),
			}))
			.filter((tag: MappedTag) => tag.count > 0)
			.sort((a: MappedTag, b: MappedTag) =>
				a.tag.toLowerCase() > b.tag.toLowerCase() ? 1 : -1,
			)
	}

	public componentWillReceiveProps(newProps: ListFiltersTagsListProps) {
		this.setState({
			tagsList: this.getTagsChunks(newProps.tagsFilters, newProps.filteredList),
		})
	}

	public render() {
		return (
			<TagsListWrapper>
				<Small css={{ marginBottom: 5 }}>Available tags:</Small>
				{this.state.tagsList.map(({ tag, count }: MappedTag) => (
					<Tag
						key={tag}
						onClick={() => this.props.onAddTagFilter(tag)}
						dangerouslySetInnerHTML={{ __html: `${tag} (${count})` }}
					/>
				))}
			</TagsListWrapper>
		)
	}
}
