import idx from 'idx'
import React from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { FAQ } from '@app/components'
import { ContentStore, withContentStore, WithContentStoreProps } from '@app/stores'
import { CompassUtils } from '@app/utils'
import {
	AnyResource,
	HomepageResource,
	PageListResource,
	PageMapResource,
	PageResource,
	RedirectResource,
	Resource as ResourceInterface,
	ResourceTemplateType,
} from '@compass/types'

import * as Templates from './index'

type ResourceProps = RouteComponentProps<any, { statusCode?: number; url?: string }> &
	WithContentStoreProps

interface ResourceState {
	path: string
}

export class ResourceClass extends React.Component<ResourceProps> {
	public state: ResourceState = {
		path: this.props.contentStore.state.pathname,
	}

	private render404() {
		return <Templates.Error404 />
	}

	private renderFaq(resource: AnyResource) {
		const relationships = (resource as PageResource).relationships

		if (!relationships || !relationships.faq) {
			return []
		}

		return <FAQ faq={relationships.faq} />
	}

	private renderTemplate(resource: AnyResource, section: ResourceInterface | null | undefined) {
		const template = CompassUtils.resource.getResourceTemplateType(resource)

		switch (template) {
			case ResourceTemplateType.EVENTS_PAGE:
				return <Templates.PageEvents resource={resource as PageResource} />

			case ResourceTemplateType.MEDIA_PAGE:
				return <Templates.PageMedia resource={resource as PageResource} />

			case ResourceTemplateType.MAP_PAGE:
				return <Templates.Map resource={resource as PageMapResource} />

			case ResourceTemplateType.LIST_PAGE:
				return <Templates.PageList resource={resource as PageListResource} />

			case ResourceTemplateType.HOME_PAGE:
				return this.state.path === '/home/' ? (
					<Redirect to="/" />
				) : (
					<Templates.Home resource={resource as HomepageResource} />
				)

			case ResourceTemplateType.SECTION_PAGE:
				return (
					<Templates.PageSection resource={resource as PageResource} section={section!} />
				)

			case ResourceTemplateType.DEFAULT_PAGE:
				return <Templates.PageDefault resource={resource as PageResource} />

			case ResourceTemplateType.ARTICLE_PAGE:
				// @ts-ignore
				return <Templates.Article resource={resource} />

			case ResourceTemplateType.EVENT_PAGE:
				// @ts-ignore
				return <Templates.Event resource={resource} />

			case ResourceTemplateType.REDIRECT: {
				const { redirect } = resource as RedirectResource

				if (this.props.staticContext) {
					this.props.staticContext.url = redirect
				}

				return redirect ? <Redirect to={redirect} /> : <Templates.Error404 />
			}

			default:
				return this.render404()
		}
	}

	private getCurrentResource() {
		const { contentStore } = this.props
		const { path } = this.state

		return idx(contentStore, _ => _.state.content[path])
	}

	public render() {
		const resource = this.getCurrentResource()
		const activeSection =
			resource &&
			ContentStore.getActiveSection(
				resource.slug,
				this.props.contentStore.state.navigation,
				this.props.contentStore.state.navigationResources,
			)

		if (resource) {
			return (
				<>
					{this.renderTemplate(resource, activeSection)}
					{this.renderFaq(resource)}
				</>
			)
		} else {
			return this.render404()
		}
	}
}

export const Resource = compose(
	withRouter,
	withContentStore,
)(ResourceClass)
