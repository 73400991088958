import styled from 'styled-components'

import { mixins } from '@styleguide'

export const Images = styled.div({
	position: 'relative',
	width: '100%',
	minHeight: '1px',

	['& img']: {
		display: 'block',
		maxWidth: '100%',
		height: 'auto',
	},

	...mixins.mediaStyles({
		sm: {
			width: '30%',
		},
	}),
})
