import { EventResource } from '@compass/types'
import moment from 'moment'
import React from 'react'

import * as EventDetailsComponents from './components'

interface EventsDetailsProps {
	event: EventResource['event']
}

export class EventDetails extends React.Component<EventsDetailsProps> {
	public static Block = EventDetailsComponents.Block
	public static Wrapper = EventDetailsComponents.Wrapper

	public render() {
		const { event } = this.props

		return (
			<EventDetails.Wrapper>
				<EventDetails.Block className="u-theme-background">
					{moment(event.date.start).format('dddd D MMMM YYYY [at] HH:mm')}
				</EventDetails.Block>
				{event.location && (
					<EventDetails.Block className="u-theme-background">
						{event.location.address}
					</EventDetails.Block>
				)}
				{event.association && (
					<EventDetails.Block className="u-theme-background">
						Hosted by:
						<a
							target="_blank"
							href={event.association.website}
							rel="noopener noreferrer"
							css={{ display: 'block', color: 'inherit', textDecoration: 'underline' }}>
							{event.association.name}
						</a>
					</EventDetails.Block>
				)}
			</EventDetails.Wrapper>
		)
	}
}
