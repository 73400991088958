import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { Accordion, AutoSubmit, Checkbox } from '@app/components'
import { Timeslots } from '@app/pages/events/types'
import { CategoryWrapper } from '@app/pages/map/map-components'

import { Timeslot } from './button-text'

export interface TimeslotFiltersFormProps {
	selectedTimeslots: Timeslots[] | null
	onUpdateFilters: (selectedTimeslots: Timeslots[]) => void
}

const CheckboxLabel = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export const TimeslotFiltersForm: React.SFC<TimeslotFiltersFormProps> = ({
	selectedTimeslots,
	onUpdateFilters,
}) => (
	<Form
		initialValues={{
			timeslots: selectedTimeslots,
		}}
		onSubmit={(values: any) => onUpdateFilters(values.timeslots)}>
		{() => (
			<>
				<AutoSubmit submit={values => onUpdateFilters(values.timeslots)} />
				<Accordion
					header={<span>Choose a timeslot:</span>}
					accordionPosition={1}
					last={true}>
					{Object.values(Timeslots).map(timeslot => (
						<CategoryWrapper key={timeslot}>
							<Field
								name="timeslots"
								type="checkbox"
								value={timeslot}
								render={({ input }) => (
									<Checkbox
										{...input}
										checkboxType="right"
										id={timeslot}
										checked={
											!!selectedTimeslots &&
											selectedTimeslots.includes(timeslot)
										}
										labelText={
											<CheckboxLabel>
												<Timeslot timeslot={timeslot} iconSize={20} />
											</CheckboxLabel>
										}
									/>
								)}
							/>
						</CategoryWrapper>
					))}
				</Accordion>
			</>
		)}
	</Form>
)
