import styled from 'styled-components'

import { transition } from '@styleguide'

export const Background = styled.div({
	position: 'absolute',
	display: 'block',
	top: 0,
	left: 0,
	height: 'calc(100% + 3px)',
	color: 'transparent',
	border: `3px currentColor solid`,
	transition: transition.config,
	transitionProperty: 'transform',
})
