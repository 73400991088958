import styled from 'styled-components'

import { transition } from '@styleguide'

export interface ItemProps {
	isVisible?: boolean
}

export const Item = styled.div<ItemProps>(({ isVisible = false }) => ({
	zIndex: -1,

	['&:last-child']: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		textAlign: 'center',
		padding: '100px 0',
	},
	...(isVisible
		? {
				opacity: 1,
				transition: `opacity ${transition.config}`,
		  }
		: {
				opacity: 0,
				transition: `z-index 0s ${transition.duration}, opacity ${transition.config}`,
		  }),
}))
