import React from 'react'

import * as TabsComponents from './components'

interface TabsProps {
	activeItem?: number
	onChange(index: number): void
}

interface TabsState {
	activeItem: number
}

export class Tabs extends React.Component<TabsProps, TabsState> {
	public static Item = TabsComponents.Item
	public static Background = TabsComponents.Background
	public static Wrapper = TabsComponents.Wrapper

	constructor(props: TabsProps) {
		super(props)
		this.state = {
			activeItem: this.props.activeItem || 0,
		}
	}

	public componentWillReceiveProps(nextProps: TabsProps) {
		if (
			typeof nextProps.activeItem === 'number' &&
			nextProps.activeItem !== this.props.activeItem
		) {
			this.setState({ activeItem: nextProps.activeItem })
		}
	}

	public setActiveItem = (index: number) => () => {
		this.setState({
			activeItem: index,
		})
		if (!this.props.onChange) {
			return
		}
		this.props.onChange(index)
	}

	public render() {
		const { children } = this.props
		const { activeItem } = this.state
		const itemWidth = 100 / React.Children.count(children)
		return (
			<Tabs.Wrapper>
				<Tabs.Background
					className="u-theme-text"
					css={{
						width: `${itemWidth}%`,
						transform: `translateX(${activeItem * 100}%)`,
					}}
				/>
				{React.Children.map(children, (child, index) =>
					React.cloneElement(child as any, {
						isActive: activeItem === index,
						onClick: this.setActiveItem(index),
					}),
				)}
			</Tabs.Wrapper>
		)
	}
}
