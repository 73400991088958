// tslint:disable:interface -name max-classes-per-file
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { compose } from 'recompose'

import { DropdownCaret } from '@app/components/dropdown-caret'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { withContentStore, WithContentStoreProps } from '@app/stores'
import { Resource, ResourceTemplateType } from '@compass/types'

import { CompassUtils } from '@app/utils'
import * as SubnavigationComponents from './subnavigation-components'

interface SubnavigationState {
	activeSection: Resource | undefined
	pathname: string
	toggled: string[]
}

export class Subnavigation extends React.Component<
	RouteComponentProps<any> & WithContentStoreProps,
	SubnavigationState
> {
	public render() {
		const { pathname, content, activeSection } = this.props.contentStore.state

		const resource = content[pathname]

		if (
			!activeSection ||
			!activeSection.resources ||
			!activeSection.resources.length ||
			!resource ||
			resource.slug !== pathname
		) {
			return null
		}

		const template = resource && CompassUtils.resource.getResourceTemplateType(resource)

		return (
			<SubnavigationComponents.Wrapper
				withMediaOffset={template === ResourceTemplateType.MEDIA_PAGE}
				withImageOffset={!!resource.image}
				withIframeOffset={!!resource.iframe}>
				<TransitionGroup>
					<CSSTransition
						key={
							activeSection
								? activeSection.slug
								: resource && resource.slug === pathname
								? resource.slug
								: pathname
						}
						classNames="u-view-animation"
						timeout={{ enter: 1000, exit: 500 }}>
						<SubnavigationComponent
							pathname={pathname}
							activeSection={activeSection}
							resource={resource}
						/>
					</CSSTransition>
				</TransitionGroup>
			</SubnavigationComponents.Wrapper>
		)
	}
}

interface SubnavProps {
	resource: Resource
	activeSection: Resource | undefined
	pathname: string
}

class SubnavigationComponent extends React.Component<SubnavProps, SubnavigationState> {
	public state: SubnavigationState = {
		activeSection: this.props.activeSection,
		pathname: this.props.pathname,
		toggled: this.getNextResource(this.props.resource)
			? [this.getNextResource(this.props.resource)]
			: [],
	}

	private toggle = (pageSlug: string) => {
		if (this.state.toggled.includes(pageSlug)) {
			return this.setState({ toggled: this.state.toggled.filter(page => page !== pageSlug) })
		} else {
			return this.setState({ toggled: [...this.state.toggled, pageSlug] })
		}
	}

	public componentWillReceiveProps(nextProps: SubnavProps) {
		if (nextProps.resource) {
			const nextResourceSlug = this.getNextResource(nextProps.resource)
			if (nextResourceSlug && !this.state.toggled.includes(nextResourceSlug)) {
				this.setState({ toggled: [...this.state.toggled, nextResourceSlug] })
			}
		}
	}

	private getNextResource(resource: Resource) {
		const parts = resource.slug.split('/')

		if (parts.length >= 5) {
			parts.splice(parts.length - 2, 1)
			return parts.join('/')
		}

		return resource.slug
	}

	public render() {
		const { activeSection, toggled } = this.state
		const { resource, pathname } = this.props

		if (
			!activeSection ||
			!activeSection.resources ||
			!activeSection.resources.length ||
			!resource ||
			resource.slug !== pathname
		) {
			return null
		}

		return (
			<ThemeWrapper>
				<SubnavigationComponents.Title>
					In '
					<SubnavigationComponents.TitleLink
						to={activeSection.slug}
						className="u-theme-text">
						{activeSection.title}
					</SubnavigationComponents.TitleLink>
					':
				</SubnavigationComponents.Title>
				<ul>
					{activeSection.resources &&
						activeSection.resources.length > 0 &&
						activeSection.resources.map((page: Resource) => {
							const isActive = toggled.includes(page.slug)
							return (
								<SubnavigationComponents.Item key={page.id}>
									<SubnavigationComponents.Top>
										<DropdownCaret
											isActive={isActive}
											onClick={() => this.toggle(page.slug)}
										/>
										<SubnavigationComponents.Link
											active={isActive}
											className={
												isActive ? 'u-theme-text' : 'u-theme-text-hover'
											}
											to={page.slug}>
											<span>{page.title}</span>
										</SubnavigationComponents.Link>
									</SubnavigationComponents.Top>
									<SubnavigationComponents.Subnavigation isActive={isActive}>
										{page.resources &&
											page.resources.length > 0 &&
											page.resources.map(subPage => {
												const isSubActive = resource.slug.startsWith(
													subPage.slug,
												)
												return (
													<SubnavigationComponents.SubItem
														key={subPage.id}>
														<SubnavigationComponents.Link
															isSmall
															active={isSubActive}
															className={
																isSubActive
																	? 'u-theme-text'
																	: 'u-theme-text-hover'
															}
															to={subPage.slug}>
															<span>⤑</span>
															<span>{subPage.title}</span>
														</SubnavigationComponents.Link>
													</SubnavigationComponents.SubItem>
												)
											})}
									</SubnavigationComponents.Subnavigation>
									<SubnavigationComponents.Border className="u-theme-background" />
								</SubnavigationComponents.Item>
							)
						})}
				</ul>
			</ThemeWrapper>
		)
	}
}

export default compose(
	withRouter,
	withContentStore,
)(Subnavigation)
