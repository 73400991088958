import React from 'react'
import { JsonLd } from 'react-schemaorg'
import { FAQPage } from 'schema-dts'

import { isBrowser } from '@app/utils'
import { Resource } from '@compass/types'

export interface FaqProps {
	faq: Resource[]
}

const toText = (html: string | undefined) => {
	if (!isBrowser) {
		return html
	}

	const div = document.createElement('div')
	div.innerHTML = html || ''
	return div.textContent || div.innerText || ''
}

export const FAQ: React.SFC<FaqProps> = ({ faq }) => (
	<JsonLd<FAQPage>
		item={{
			'@context': 'https://schema.org',
			'@type': 'FAQPage',
			mainEntity: faq.map(item => ({
				'@type': 'Question',
				name: item.title,
				acceptedAnswer: {
					'@type': 'Answer',
					text: toText(item.content),
				},
			})),
		}}
	/>
)
