import React from 'react'
import styled from 'styled-components'

interface SlideProps {
	isActive?: boolean
	children?: any
}

export const SlideComponent = styled.div<SlideProps>(({ isActive = false }) => ({
	position: 'relative',
	display: 'inline-block',
	width: '100%',
	verticalAlign: 'top',
	whiteSpace: 'normal',

	...(isActive
		? {
				opacity: 1,
				maxHeight: '10000000px',
				overflow: 'visible',
				transition: 'opacity 700ms ease-in-out',
		  }
		: {
				opacity: 0,
				maxHeight: '1px',
				overflow: 'hidden',
				transition: 'opacity 700ms ease-in-out, max-height 0s 700ms',
		  }),
}))

const SlideHeightWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
})

export const Slide: React.SFC<SlideProps> = props => (
	<SlideComponent {...props}>
		<SlideHeightWrapper>{props.children}</SlideHeightWrapper>
	</SlideComponent>
)
