import styled from 'styled-components'

import { colors } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
})

export const Label = styled.label<{
	hasError: boolean
	checkboxType: 'left' | 'right'
}>(({ hasError, checkboxType = 'left' }) => ({
	position: 'relative',
	display: 'flex',
	cursor: 'pointer',
	width: '100%',
	flexDirection: checkboxType === 'left' ? 'row' : 'row-reverse',

	['input:disabled + &']: {
		cursor: 'initial',
	},

	['&:before']: {
		content: '""',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 6,
		width: 16,
		height: 16,
		flexShrink: 0,

		border: `1px solid ${colors.GREY_50}`,
		borderRadius: 2,

		marginLeft: checkboxType === 'left' ? 0 : 'auto',
		marginRight: checkboxType === 'left' ? 11 : '0',

		...(hasError
			? {
					['input + &']: {
						border: `1px solid ${colors.PRIMARY}`,
					},
			  }
			: {}),

		['input:disabled + &']: {
			border: `1px solid ${colors.GREY_20}`,
		},

		['input:checked + &']: {
			borderColor: colors.GREY_40,
			background: colors.GREY_40,
		},
	},

	['&::after']: {
		display: 'block',
		content: '""',
		border: '2px currentColor solid',
		borderLeft: 0,
		borderTop: 0,
		height: 10,
		right: 5,
		opacity: 0,
		position: 'absolute',
		top: 7,
		transform: 'rotate(45deg)',
		width: 6,

		['input:checked + &']: {
			opacity: 1,
			color: colors.WHITE,
		},
	},

	['&:hover']: {
		['&:after']: {
			['input:not(:disabled) + &']: {
				opacity: 0.5,
			},
			['input:checked + &']: {
				opacity: 1,
			},
		},
	},
}))
