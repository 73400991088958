import { Request } from 'express'
import { Container } from 'unstated'

import { CompassUtils } from '@app/utils'
import { SearchResult } from '@app/utils/compass/search'
import { Resource } from '@compass/types'

export interface SearchState {
	loading: boolean
	results: SearchResult[]
	isSearchOpen: boolean
	isCentered: boolean
	error?: any
}

export const defaultSearchState: SearchState = {
	loading: false,
	isSearchOpen: false,
	isCentered: false,
	results: [],
}

export class SearchStore extends Container<SearchState> {
	public constructor(initialState: SearchState = defaultSearchState) {
		super()
		this.state = initialState
	}

	/**
	 *
	 * Server-side resolve flow
	 */
	public static async getInitialState(_req: Request) {
		return defaultSearchState
	}

	public async search(query: string, navigation: Resource[], navigationResources: Resource[]) {
		this.setState({ loading: true })

		try {
			const resources = await CompassUtils.resource.search(query)
			this.setState({
				results: CompassUtils.search.getResultsWithNavigationContext(
					resources,
					navigation,
					navigationResources,
				),
			})
		} catch (error) {
			this.setState({ error })
		}

		this.setState({ loading: false })
	}

	public openModal() {
		this.setState({ isSearchOpen: true })
	}

	public closeModal() {
		this.setState({ isSearchOpen: false })
	}
}
