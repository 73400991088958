import React from 'react'

import { themeColors } from '@styleguide'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const document: SvgIconMap = {
	size: {
		width: 32,
		height: 32,
	},
	Icon: ({ fill = defaultFill }) => (
		<g fillRule="nonzero" fill="none">
			<path
				fill={themeColors.default.light}
				d="M8.089 14.765l-4.041-3.136-4.04 3.136V0h8.08z"
			/>
			<path
				d="M30.34 7.5h-4.105c-1.609 0-2.915-1.158-2.915-2.585V1.267h1.068V4.91c0 .902.83 1.638 1.847 1.638h4.106v.954z"
				fill={fill}
			/>
			<path
				d="M27.96 32H6.429c-1.61 0-2.915-1.158-2.915-2.586V13.3h1.068v16.115c0 .903.83 1.639 1.847 1.639h21.524c1.017 0 1.847-.736 1.847-1.639V7.22l-5.91-5.472H9.221V.794h15.131l6.516 6.041v22.58c.007 1.427-1.3 2.585-2.908 2.585z"
				fill={fill}
			/>
			<path
				fill={fill}
				d="M6.775 18.054h20.766v1H6.775zM6.775 20.493h14.777v1H6.775zM6.775 22.925h9.734v1H6.775zM6.775 25.363h20.016v1H6.775zM6.775 27.802H25.16v1H6.775z"
			/>
			<path
				fill={themeColors.default.light}
				d="M11.819 13.37v1.67h-.83v-4.416h1.905c.548 0 .981.128 1.292.378.31.25.469.582.469.992 0 .416-.16.748-.47.998-.31.25-.743.371-1.291.371h-1.075v.007zm0-.59h1.075c.31 0 .541-.07.693-.217a.747.747 0 0 0 .23-.557c0-.224-.079-.41-.23-.563-.152-.147-.39-.224-.693-.224h-1.075v1.562zM15.65 15.046V10.63h1.645c.628 0 1.148.173 1.537.525.397.352.592.8.592 1.35v.673c0 .55-.195.998-.592 1.35-.397.346-.909.518-1.537.518H15.65zm.837-3.827v3.232h.816c.396 0 .714-.121.952-.358s.354-.544.354-.922v-.678c0-.371-.116-.679-.354-.915-.238-.237-.548-.359-.952-.359h-.816zM23.392 13.165h-2.005v1.881h-.83V10.63h3.182v.59h-2.352v1.35h2.005z"
			/>
		</g>
	),
}
