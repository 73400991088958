import React from 'react'
import styled from 'styled-components'

import {
	Accordion,
	AutoSubmit,
	Checkbox,
	RichTextContent,
	Tooltip,
	TooltipInner,
} from '@app/components'
import { CategoryFilter } from '@app/types'
import { spacing, transition } from '@styleguide'
import { Field, Form } from 'react-final-form'

const CategoryWrapper = styled.div({
	position: 'relative',
})

const CheckboxLabel = styled.div({
	display: 'flex',
	alignItems: 'center',
})

export interface CategoryImageProps {
	isActive: boolean
}

export const CategoryImage = styled.img<CategoryImageProps>(({ isActive }) => ({
	width: 32,
	height: 'auto',
	display: 'block',
	marginRight: 10,
	transition: transition.config,
	transitionProperty: 'filter',
	filter: isActive ? 'brightness(0) invert(1)' : 'none',
}))

function sortBy<T>(property: keyof T) {
	return (a: T, b: T) => (a[property] > b[property] ? 1 : -1)
}

const tooltipForCategory = {
	'UM SPORTS': `UM Sports is the sports department for students, employees, and alumni of Maastricht University. A membership is required, you can find the tariffs <a href="https://www.maastrichtuniversity.nl/support/sports/memberships/rates" target="_blank">here</a>.`,
	'student sports associations': `If you’re looking for team spirit and competition, consider joining a student sports association (SSA)! Note that SSAs charge a membership fee on top of a UM Sports membership.`,
	'local sports organisation': `These are all the private, non-university-related options for sports in the city.`,
}

export interface ListFilterCategoryFormValues {
	categories: number[]
}

interface ListFiltersFormProps {
	initiatives: CategoryFilter[]
	selectedInitiatives: CategoryFilter[]
	selectedCategories: CategoryFilter[]
	categories: CategoryFilter[]
	onUpdateFilters: (initiatives: string[], categories: string[]) => void
}

export const ListCategoriesForm: React.SFC<ListFiltersFormProps> = ({
	initiatives,
	selectedInitiatives,
	categories,
	selectedCategories,
	onUpdateFilters,
}) => (
	<Form
		initialValues={{
			categories: selectedCategories.map(({ name }) => name),
			initiatives: selectedInitiatives.map(({ name }) => name),
		}}
		onSubmit={(values: any) => onUpdateFilters(values.initiatives, values.categories)}>
		{() => (
			<>
				<AutoSubmit
					submit={values => onUpdateFilters(values.initiatives, values.categories)}
				/>
				<Accordion
					header={<span>Organised by:</span>}
					accordionPosition={0}
					last={false}
					defaultOpen={true}
					openStyles={{
						marginBottom: spacing.xs,
					}}>
					{initiatives.sort(sortBy('name')).map(category => (
						<CategoryWrapper key={category.name}>
							<Field
								name="initiatives"
								type="checkbox"
								value={category.name}
								render={({ input }) => (
									<Checkbox
										{...input}
										checkboxType="right"
										id={category.name}
										checked={selectedInitiatives.some(
											i => i.name === category.name,
										)}
										labelText={
											<CheckboxLabel>
												{category.icon && (
													<CategoryImage
														isActive={false}
														src={category.icon}
														alt={category.name}
													/>
												)}

												{tooltipForCategory[
													category.name as keyof typeof tooltipForCategory
												] && (
													<Tooltip
														interactive
														position="right"
														sticky
														stickyDuration={false}
														html={
															<TooltipInner>
																<RichTextContent
																	content={
																		tooltipForCategory[
																			category.name as keyof typeof tooltipForCategory
																		]
																	}
																/>
															</TooltipInner>
														}
													/>
												)}

												<span
													dangerouslySetInnerHTML={{
														__html: category.name,
													}}
													css={{ marginLeft: 10 }}
												/>
											</CheckboxLabel>
										}
									/>
								)}
							/>
						</CategoryWrapper>
					))}
				</Accordion>
				<Accordion
					header={<span>Choose a category:</span>}
					accordionPosition={1}
					last={true}>
					{categories.sort(sortBy('name')).map(category => (
						<CategoryWrapper key={category.name}>
							<Field
								name="categories"
								type="checkbox"
								value={category.name}
								render={({ input }) => (
									<Checkbox
										{...input}
										checkboxType="right"
										id={category.name}
										checked={selectedCategories.some(
											c => c.name === category.name,
										)}
										labelText={
											<CheckboxLabel>
												<CategoryImage
													isActive={false}
													src={category.icon}
													alt={category.name}
												/>

												<span
													dangerouslySetInnerHTML={{
														__html: category.name,
													}}
												/>
											</CheckboxLabel>
										}
									/>
								)}
							/>
						</CategoryWrapper>
					))}
				</Accordion>
			</>
		)}
	</Form>
)
