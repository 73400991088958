import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const contact: SvgIconMap = {
	size: {
		width: 1000,
		height: 1000,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M894.3 155.7H105.7C47.7 155.7 0.2 203.2 0.2 261.3V738.7C0.2 796.8 47.7 844.3 105.7 844.3H894.3C952.3 844.3 999.8 796.8 999.8 738.7V261.3C999.8 203.2 952.3 155.7 894.3 155.7ZM752.3 230.2L500 450.5 247.6 230.2H752.3ZM925.3 738.7C925.3 755.6 911.1 769.8 894.3 769.8H105.7C88.9 769.8 74.7 755.6 74.7 738.7V261.3C74.7 244.4 88.9 230.2 105.7 230.2H134.4L475.5 528C482.6 534.2 491.3 537.2 500 537.2S517.5 534.2 524.5 528L865.6 230.2H894.3C911.1 230.2 925.3 244.4 925.3 261.3V738.7Z"
		/>
	),
}
