import styled from 'styled-components'

import { Tag } from '@app/components'
import { colors, spacing } from '@styleguide'

export const ActiveTag = styled(Tag)({
	['& svg']: {
		marginLeft: 8,
		height: '10px',
		width: '10px',
	},

	['& path']: {
		fill: colors.WHITE,
	},
})

export const Small = styled.p({
	fontSize: '1.4rem',
})

export const IGTable = styled.table({
	border: 'none',
	padding: 0,

	['& tr, & td, & th']: {
		border: 'none',
		verticalAlign: 'top',
	},

	['& th']: {
		fontWeight: 'normal',
		textAlign: 'left',
		padding: '5px 5px 5px 0',
	},

	['& td']: {
		textAlign: 'right',
		padding: '5px 0 5px 5px',
	},
})

export const Wrapper = styled.div({
	marginTop: spacing.sm,

	['> div']: {
		marginBottom: spacing.sm,
	},
})
