import React from 'react'
import styled, { CSSObject } from 'styled-components'

import * as icons from './icons'
import { SvgComponentProps } from './types'

const Svg = styled.svg({})
// Looks up the correct component in the icons dir
export const SvgIcon: React.SFC<SvgComponentProps> = props => {
	const { icon, css, fill, ...rest } = props
	const Icon = icons[icon]

	const composedStyle: CSSObject = {
		display: 'inline-block',
		...css,
	}

	if (Icon && Icon.Icon) {
		return (
			<Svg
				css={composedStyle}
				width={Icon.size.width}
				height={Icon.size.height}
				viewBox={`0 0 ${Icon.size.width} ${Icon.size.height}`}
				{...rest}>
				<Icon.Icon fill={fill} />
			</Svg>
		)
	} else {
		return null
	}
}
