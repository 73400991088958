import truncate from 'html-truncate'
import React from 'react'

import { Button } from '@app/components/button'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { FeaturedTextBlock as FeaturedTextBlockInterface } from '@compass/types'

import { Content, Half, Title, Wrapper } from './components'

export interface FeaturedTextBlockProps {
	item: FeaturedTextBlockInterface
}

export const FeaturedTextBlock: React.SFC<FeaturedTextBlockProps> = ({ item }) => {
	return (
		<Half>
			<ThemeWrapper fixedTheme="info">
				<Wrapper
					css={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}>
					<Title className="u-theme-text">{item.title}</Title>
					<Content dangerouslySetInnerHTML={{ __html: truncate(item.content, 300) }} />
					{item.call_to_action && (
						<div css={{ marginTop: 'auto' }}>
							<Button
								a
								href={item.call_to_action.link}
								buttonSize="small"
								target={
									item.call_to_action.open_link_in_new_tab ? '_blank' : undefined
								}>
								{item.call_to_action.text}
							</Button>
						</div>
					)}
				</Wrapper>
			</ThemeWrapper>
		</Half>
	)
}
