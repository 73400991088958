import { PublicConfig } from '../../types'

export const publicConfig: PublicConfig = {
	id: 'anytown',
	domain: 'mymaastricht.nl',
	name: {
		test: 'myanytown.nl',
	},
	payoff: {
		test: 'where anytown yours!',
	},
	phone: '+31 6 123456789',
	publicEmail: 'info@mymaastricht.nl',
	logoUrl: '/static/logo-img.svg',

	logos: {
		test: '/static/shell-logos/mymaastricht-test-without-text.svg',
	},
	socials: [
		{
			type: 'facebook',
			url: 'https://www.facebook.com/mymaastricht/',
		},
	],
	sponsors: [],
	map: {
		center: { lat: 51.587022, lng: 4.770722 },
		bounds: {
			topLeft: {
				lat: 51.626471,
				lng: 4.6682,
			},
			topRight: {
				lat: 51.6228673,
				lng: 4.844716,
			},
			bottomLeft: {
				lat: 51.540469,
				lng: 4.683355,
			},
			bottomRight: {
				lat: 51.538529,
				lng: 4.8556306,
			},
		},
	},

	withLogin: true,
}
