import { domains } from '@app/config'
import http from '@app/utils/http'
import { FeedbackFormInput } from '@compass/types'

import { SitemapURL } from './types'

export const getSitemap = (): Promise<SitemapURL[]> => http(`${domains.api}/sitemap`)

export const submitFeedback = (values: FeedbackFormInput) =>
	http(`${domains.api}/forms`, 'POST', {
		...values,
		action: 'feedback',
	})
