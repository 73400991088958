import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const finances: SvgIconMap = {
	size: {
		width: 50,
		height: 50,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-37" fill={fill} fillRule="nonzero">
			<path
				d="M25.12,49.45 C11.7,49.45 0.79,38.53 0.79,25.12 C0.79,11.71 11.7,0.78 25.12,0.78 C38.54,0.78 49.45,11.7 49.45,25.11 C49.45,38.52 38.53,49.45 25.12,49.45 Z M25.12,3.78 C13.36,3.78 3.79,13.35 3.79,25.11 C3.79,36.87 13.36,46.44 25.12,46.44 C36.88,46.44 46.45,36.87 46.45,25.11 C46.45,13.35 36.88,3.78 25.12,3.78 Z"
				id="Shape"
			/>
			<g id="Group" transform="translate(11.000000, 12.000000)">
				<path
					d="M14.03,25.41 C7.25,25.41 1.74,19.9 1.74,13.12 C1.74,6.34 7.25,0.83 14.03,0.83 C16.99,0.83 19.85,1.91 22.1,3.86 L20.13,6.12 C18.43,4.64 16.27,3.83 14.04,3.83 C8.92,3.83 4.75,8 4.75,13.12 C4.75,18.24 8.92,22.41 14.04,22.41 C16.28,22.41 18.44,21.6 20.13,20.12 L22.1,22.38 C19.85,24.33 16.99,25.41 14.03,25.41 Z"
					id="Path"
				/>
				<g transform="translate(0.000000, 9.000000)" id="Rectangle">
					<rect x="0.01" y="0.07" width="15.22" height="3" />
					<rect x="0.01" y="5.16" width="15.22" height="3" />
				</g>
			</g>
		</g>
	),
}
