import React from 'react'
import styled from 'styled-components'

import { Resource } from '@compass/types'
import { colors, mixins, spacing, transition } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
	height: 70,
	background: colors.WHITE,
	marginRight: spacing.xs,

	[mixins.media.max('md')]: {
		display: 'none',
	},
})

export const Toggles = styled.div({
	position: 'relative',
})

interface ToggleButtonProps {
	isActive: boolean
}

const ToggleButton = styled.button.attrs({ className: 'c-navigation-switch__toggle' })<
	ToggleButtonProps
>(({ isActive = false }) => ({
	position: 'relative',
	display: 'block',
	width: '100%',
	height: 35,
	lineHeight: 1,
	padding: `0 ${spacing.xs}px`,
	color: isActive ? colors.BLACK : colors.GREY_40,
	fontWeight: 700,
	letterSpacing: 0,
	fontSize: '1.4rem',
	border: 0,
	background: 'transparent',
	transition: transition.config,
	transitionProperty: 'color, font-size',
	whiteSpace: 'nowrap',

	...mixins.primaryFont(),
}))

export const ToggleArrow = styled.span<ToggleButtonProps>(({ isActive }) => ({
	marginLeft: 10,
	opacity: isActive ? 1 : 0,
	transition: transition.config,
	transitionProperty: 'opacity',
}))

interface ToggleProps {
	domain: Resource
	onChangeDomain: (domain: string) => void
	activeDomain: string | undefined
}

export const Toggle: React.SFC<ToggleProps> = ({ domain, onChangeDomain, activeDomain }) => {
	const isActive = Boolean(activeDomain && activeDomain === domain.slug)

	return (
		<ToggleButton isActive={isActive} onClick={() => onChangeDomain(domain.slug)}>
			{domain.title}
			<ToggleArrow isActive={isActive}>⤑</ToggleArrow>
		</ToggleButton>
	)
}

interface BackgroundProps {
	isRight: boolean
}

export const Background = styled.div<BackgroundProps>(({ isRight = false }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '50%',
	background: colors.WHITE,
	borderRadius: 8,
	border: `1px ${colors.BLACK} solid`,
	transition: transition.config,
	transitionProperty: 'transform',
	transform: isRight ? 'translateY(100%)' : 'none',
}))
