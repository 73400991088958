import React from 'react'
import { Router } from 'react-router-dom'
import { Provider as StoreProvider } from 'unstated'

import { ResourceLoader, ScrollToTop, VirtualPageview } from '@app/components'
import { history } from '@app/utils'

import { App } from '@app/bootstrap/shared'

export default ({ stores }: { stores: any }) => (
	<StoreProvider inject={stores}>
		<Router history={history}>
			<ScrollToTop>
				<VirtualPageview>
					<ResourceLoader>
						<App />
					</ResourceLoader>
				</VirtualPageview>
			</ScrollToTop>
		</Router>
	</StoreProvider>
)
