import { env } from '@app/config'
import Raven, { RavenOptions } from 'raven-js'

export const installSentry = () => {
	if (env.SENTRY_DSN) {
		Raven.config(env.SENTRY_DSN).install()
	}
}

/* Use this exception logger to fail gracefully */
export const logException = (
	ex: Error,
	options?: RavenOptions | undefined,
	logToConsole: boolean = true,
) => {
	Raven.captureException(ex, options)

	/* tslint:disable-next-line:no-unused-expression */
	logToConsole && console && console.error && console.error(ex)
}
