import React from 'react'

import { Grid, HelmetData, Page } from '@app/components'
import { ArticleResource } from '@compass/types'

import { ArticleBackButton, ArticleDetail } from './article-components'

interface TemplateArticleProps {
	resource: ArticleResource
}

export const Article: React.SFC<TemplateArticleProps> = ({ resource }) => {
	return (
		<Page>
			<HelmetData title={resource.title} seo={resource.seo} />
			<Grid.Row centered>
				<Grid.PaddedColumn
					xs={{ size: 16 }}
					md={{ size: 12, offset: 1.5 }}
					lg={{ size: 8 }}>
					<ArticleDetail article={resource} />
				</Grid.PaddedColumn>
			</Grid.Row>
			<ArticleBackButton to="/info/blog" className="u-theme-text">
				⬸ Back to blog
			</ArticleBackButton>
		</Page>
	)
}
