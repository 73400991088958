export const getElementOffsetTop = (element: any): number => {
	if (!element) {
		return 0
	}
	return element.offsetTop + getElementOffsetTop(element.offsetParent)
}

export const isElementInViewData = (element: any, scrollPosition: number): any => {
	const offsetTop = getElementOffsetTop(element)

	return {
		isInView:
			// scrolled in view
			scrollPosition + window.innerHeight * 0.65 > offsetTop &&
			// not already scrolled out of view
			scrollPosition + window.innerHeight * 0.35 <= offsetTop + element.offsetHeight,
		isScrolledPast:
			// scrolled in view
			scrollPosition + window.innerHeight * 0.65 > offsetTop,
		scrollPosition,
	}
}
