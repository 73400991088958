import React from 'react'
import GoogleAnalytics from 'react-ga'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { env } from '@app/config'

export class VirtualPageviewClass extends React.Component<RouteComponentProps<any>> {
	public componentDidMount() {
		this.fireUserIdTag()
	}

	public componentDidUpdate(prevProps: RouteComponentProps<any>) {
		if (this.props.location !== prevProps.location) {
			this.fireUserIdTag()
		}
	}

	private fireUserIdTag() {
		const page = this.props.location.pathname
		GoogleAnalytics.set({ page })
		GoogleAnalytics.pageview(page)

		if (env.FACEBOOK_PIXEL_ID && window.fbq) {
			window.fbq('track', 'PageView')
		}
	}

	public render() {
		return this.props.children
	}
}

export const VirtualPageview = compose(withRouter)(VirtualPageviewClass)
