import React from 'react'

import * as ModalComponents from './modal-components'

export interface ModalProps {
	open: boolean
	isSmall?: boolean
	onClose?: () => void
}

const ESC_KEY = 27

export class Modal extends React.Component<ModalProps> {
	public componentDidMount() {
		window.addEventListener('keyup', this.handleKeyUp)
		if (this.props.open) {
			document.documentElement.classList.add('no-scroll')
		}
	}

	public componentWillReceiveProps({ open }: ModalProps) {
		if (typeof window !== 'undefined') {
			if (open) {
				document.documentElement.classList.add('no-scroll')
			} else {
				document.documentElement.classList.remove('no-scroll')
			}
		}
	}

	public componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKeyUp)
		document.documentElement.classList.remove('no-scroll')
	}

	public handleKeyUp = (e: KeyboardEvent) => {
		const { onClose } = this.props
		if (e.keyCode === ESC_KEY && onClose) {
			onClose()
		}
	}

	public onClose = (_e: React.MouseEvent<any>) => {
		const { onClose } = this.props
		if (onClose) {
			onClose()
		}
	}

	public render() {
		const { children, open, isSmall } = this.props

		return (
			<ModalComponents.Wrapper open={open}>
				<ModalComponents.ScrollWrapper>
					<ModalComponents.Background onClick={this.onClose} />
					<ModalComponents.ModalAnimation open={open}>
						<ModalComponents.Overlay onClick={this.onClose} />
						{children && (
							<ModalComponents.CloseButton
								className={isSmall ? '' : 'u-theme-text'}
								isWhite={!!isSmall}
								onClick={this.onClose}>
								Close
							</ModalComponents.CloseButton>
						)}
						{children}
					</ModalComponents.ModalAnimation>
				</ModalComponents.ScrollWrapper>
			</ModalComponents.Wrapper>
		)
	}
}
