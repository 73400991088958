export const isBrowser = typeof window !== 'undefined'

export const detectIE = () => {
	if (!isBrowser) {
		return false
	}

	const ua = window.navigator.userAgent

	let version

	const msie = ua.indexOf('MSIE ')
	if (msie > 0) {
		// IE 10 or older => return version number
		version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
	}

	const trident = ua.indexOf('Trident/')
	if (trident > 0) {
		// IE 11 => return version number
		const rv = ua.indexOf('rv:')
		version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
	}

	const edge = ua.indexOf('Edge/')
	if (edge > 0) {
		// IE 12 => return version number
		version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
	}

	return version
}

export const isMobile = () => {
	return (
		isBrowser &&
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	)
}
