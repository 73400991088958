export interface EventsFiltersState {
	selectedTimeslots: Timeslots[] | null
	selectedCategories: string[] | null
	selectedAssociations: string[] | null
}

export enum EventsState {
	FEATURED = 'FEATURED',
	TODAY = 'TODAY',
	THISWEEK = 'THISWEEK',
	CALENDAR = 'CALENDAR',
}

export enum Timeslots {
	ALL = 'ALL',
	IN_THE_AFTERNOON = 'IN_THE_AFTERNOON',
	IN_THE_EVENING = 'IN_THE_EVENING',
	IN_THE_MORNING = 'IN_THE_MORNING',
	IN_THE_NIGHT = 'IN_THE_NIGHT',
}

export enum TimeslotsTexts {
	ALL = 'All day',
	IN_THE_AFTERNOON = 'In the afternoon',
	IN_THE_EVENING = 'In the evening',
	IN_THE_MORNING = 'In the morning',
	IN_THE_NIGHT = 'In the night',
}

export const timeslotIcons = {
	ALL: 'eventsAll',
	IN_THE_AFTERNOON: 'eventsInTheAfternoon',
	IN_THE_EVENING: 'eventsInTheEvening',
	IN_THE_MORNING: 'eventsInTheMorning',
	IN_THE_NIGHT: 'eventsInTheNight',
}

export type EventListType = 'default' | 'aside'
