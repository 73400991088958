import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Grid, HelmetData, Page, RichTextContent } from '@app/components'

const Error404Template: React.SFC<RouteComponentProps<any>> = ({ staticContext }) => {
	if (staticContext) {
		staticContext.statusCode = 404
	}
	return (
		<Page>
			<HelmetData title={'404 - Page not found!'} seo={{ robots: 'noindex, follow' }} />
			<Grid.Container>
				<Grid.Row>
					<Grid.PaddedColumn
						xs={16}
						md={{ size: 8, offset: 4 }}
						xl={{ size: 9, offset: 3 }}>
						<h1 className="u-theme-text">404 - Page not found!</h1>
						<RichTextContent
							content={`
                        	<p>It seems that the page you tried to access does not exist. Please try again via the navigation or contact us about this issue!</p>
                        `}
						/>
					</Grid.PaddedColumn>
				</Grid.Row>
			</Grid.Container>
		</Page>
	)
}

export const Error404 = withRouter(Error404Template)
