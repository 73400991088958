import styled from 'styled-components'

import { mixins, spacing } from '@styleguide'

export const Row = styled.div<{
	centered?: boolean
}>(({ centered }) => ({
	display: 'flex',
	flexWrap: 'wrap',

	justifyContent: centered ? 'center' : 'flex-start',

	margin: `0 -${spacing.xs / 3}px`,

	...mixins.mediaStyles({
		sm: {
			margin: `0 -${spacing.sm / 2}px`,
		},
		lg: {
			margin: `0 -${spacing.sm}px`,
		},
	}),
}))
