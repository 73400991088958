import React from 'react'
import { compose } from 'recompose'

import { DomainOverview } from '@app/components/domain-overview'
import { Grid } from '@app/components/grid'
import { withContentStore, WithContentStoreProps } from '@app/stores'

import * as FooterComponents from './footer-components'

export const Footer: React.SFC<WithContentStoreProps> = ({
	contentStore: {
		state: { navigation },
	},
}) => (
	<FooterComponents.Wrapper>
		<Grid.Container>
			<Grid.Row>
				<Grid.Column xs={16} xl={{ size: 9, offset: 3 }}>
					{navigation.length > 0 && (
						<>
							<FooterComponents.Title>Quickly go to</FooterComponents.Title>
							{navigation.map(domain => (
								<DomainOverview key={domain.id} domain={domain} />
							))}
						</>
					)}
				</Grid.Column>
			</Grid.Row>
		</Grid.Container>
	</FooterComponents.Wrapper>
)

export default compose(withContentStore)(Footer)
