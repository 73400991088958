import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const housing: SvgIconMap = {
	size: {
		width: 56,
		height: 50,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="ICONS-MYMAASTRICHT-39" fill={fill} fillRule="nonzero">
			<path
				d="M41.17,49.58 L15.07,49.58 C12.23,49.58 9.91,47.27 9.91,44.42 L9.91,22.71 L13.08,22.71 L13.08,44.42 C13.08,45.52 13.97,46.41 15.07,46.41 L41.17,46.41 C42.27,46.41 43.16,45.52 43.16,44.42 L43.16,22.71 L46.33,22.71 L46.33,44.42 C46.32,47.26 44.01,49.58 41.17,49.58 Z"
				id="Path"
			/>
			<polygon
				id="Path"
				points="34.26 48.03 31.1 48.03 31.1 33.94 25.13 33.94 25.13 48.03 21.97 48.03 21.97 30.78 34.26 30.78"
			/>
			<polygon
				id="Path"
				points="13.08 16.15 9.92 16.15 9.92 3.6 21.38 3.6 21.38 8.92 18.22 8.92 18.22 6.77 13.08 6.77"
			/>
			<path
				d="M5.73,29.47 L0.27,22.72 L28.12,0.16 L55.97,22.72 L50.5,29.47 L28.11,11.34 L5.73,29.47 Z M28.11,7.27 L50.03,25.02 L51.51,23.19 L28.11,4.24 L4.72,23.19 L6.2,25.02 L28.11,7.27 Z"
				id="Shape"
			/>
		</g>
	),
}
