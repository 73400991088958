import styled from 'styled-components'

import { colors, spacing } from '@styleguide'

export const Wrapper = styled.article({
	position: 'relative',
	display: 'flex',
	marginTop: spacing.xs,
})

export const ImageWrapper = styled.div({
	position: 'relative',
	flexShrink: 0,
	width: '100%',
	padding: '6px',
	border: `2px currentColor solid`,
})

export const Info = styled.div({})

export const Image = styled.figure({
	width: '100%',
	height: 0,
	paddingTop: '100%',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	display: 'block',
})

export const RichText = styled.div({
	fontSize: '1.4rem',
	marginBottom: spacing.sm,
	color: colors.GREY_50,
})
