export const shadows = [
	'0 0 0 0 rgba(0, 0, 0, 0)',
	'0 1px 3px 0 rgba(74, 74, 74, 0.48)',
	'0 1px 5px 0 rgba(0, 0, 0, 0.15)',
	'1px 3px 5px 0 rgba(0, 0, 0, 0.15)',
	'2px 12px 30px -4px rgba(0, 0, 0, 0.1)',
	'0 3px 50px -5px rgba(74, 74, 74, 0.3)',
	'10px 10px 50px -5px rgba(32, 32, 32, 0.6)',
	'1px 3px 10px 0px rgb(0 0 0 / 15%)',
]
