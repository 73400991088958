import * as events from './events'
import * as general from './general'
import * as list from './list'
import * as map from './map'
import * as media from './media'
import * as navigation from './navigation'
import * as resource from './resource'
import * as search from './search'

export const CompassUtils = { list, events, map, media, general, navigation, resource, search }
