import React from 'react'
import styled from 'styled-components'

import { Loader as LoaderComponent } from '@app/components'

const LoaderWrapper = styled.div({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '100px 0',
})

export const Loader: React.SFC = () => (
	<LoaderWrapper>
		<LoaderComponent transparent fixed={false} loading />
	</LoaderWrapper>
)
