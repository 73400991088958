import styled from 'styled-components'

import { MapButton as Button } from '@app/components/map'
import { mixins, spacing } from '@styleguide'

export const MapButton = styled(Button)({
	position: 'absolute',
	bottom: 65,
	right: spacing.xs,

	...mixins.mediaStyles({
		sm: {
			bottom: spacing.xs,
		},
	}),
})
