import React from 'react'

import { Modal } from '@app/components'
import * as ModalComponents from '@app/components/modal/modal-components'
import { EventResource } from '@compass/types'

import { EventsFiltersState } from '../../types'
import { List } from '../list'
import * as SelectionComponents from './components'
import { getEventsForDate } from './helpers'

interface EventsCalendarModalProps {
	date: any
	calendar: EventResource[]
	filters: EventsFiltersState
	deselectDate: () => void
}

interface EventsCalendarModalState {
	date: any
	events: EventResource[]
}

export default class Selection extends React.Component<
	EventsCalendarModalProps,
	EventsCalendarModalState
> {
	public static Inner = SelectionComponents.Inner

	constructor(props: EventsCalendarModalProps) {
		super(props)
		this.state = {
			date: props.date,
			events: getEventsForDate(props.calendar, props.date),
		}
	}
	public componentWillReceiveProps(newProps: EventsCalendarModalProps) {
		const { date, calendar } = newProps
		if (date) {
			this.setState({
				date,
				events: getEventsForDate(calendar, date),
			})
		}
	}
	public render() {
		const { date, deselectDate, filters } = this.props
		const { date: stateDate, events = [] } = this.state
		return (
			<Modal open={!!date} isSmall onClose={deselectDate}>
				{stateDate && (
					<ModalComponents.Content>
						<div>
							<Selection.Inner>
								<List
									isLoading={false}
									title={`${events.length} event${
										events.length > 1 ? 's' : ''
									} on ${stateDate.format('dddd D MMMM YYYY')}:`}
									filters={filters}
									events={events}
									view="tooltip"
								/>
							</Selection.Inner>
						</div>
					</ModalComponents.Content>
				)}
			</Modal>
		)
	}
}
