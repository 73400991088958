import { Request } from 'express'

import { ContentStore } from './content'
import { ModalStore } from './modal'
import { SearchStore } from './search'

// It seems like TS has an issue with an array of different classes that inherit from the same base class
// When we map over them and initialise them, TS complains, but it is valid JS (not sure why)
const stores: any[] = [ContentStore, SearchStore, ModalStore]

// Stores can have a 'getInitialState' method which will execute serverside with the request as param
// This allows you to initialize the state serverside before sending it to the client.
// Can be useful for fetching content outside of graphql
export const initializeStores = async (req: Request) => {
	const state = await Promise.all(
		stores.map(s => (s.getInitialState ? s.getInitialState(req) : Promise.resolve(undefined))),
	)
	return {
		state,
		instances: stores.map((s, i) => {
			const initialState = state[i] ? state[i] : undefined
			return new s(initialState)
		}),
	}
}

export const rehydrateStores = (state: any[]) =>
	stores.map((s, i) => {
		// we should check for the state, because if we pass it directly null might be passed which will fail in the constructor
		const initialState = state[i] ? state[i] : undefined
		return new s(initialState)
	})

export * from './content'
export * from './modal'
export * from './search'
