import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'

import { DomainOverview, HelmetData } from '@app/components'
import { env, mixins, shellConfig } from '@app/config'
import {
	withContentStore,
	WithContentStoreProps,
	withSearchStore,
	WithSearchStoreProps,
} from '@app/stores'
import { ArticleResource, HomepageResource } from '@compass/types'

import * as MediaComponents from '../media/components'
import * as HomepageComponents from './components/index'

interface HomeState {
	activeSection: string | undefined
	isModalOpen: boolean
	url: string | undefined
	article: ArticleResource | undefined
}

interface HomeBaseProps {
	resource: HomepageResource
}

type HomeProps = WithContentStoreProps & HomeBaseProps

const MobileWrapper = styled.div({
	[mixins.media.min('md')]: {
		display: 'none',
	},
})

class HomeContainer extends React.Component<WithSearchStoreProps & HomeProps, HomeState> {
	public state: HomeState = {
		activeSection: undefined,
		isModalOpen: false,
		url: undefined,
		article: undefined,
	}

	private timeout: any

	private handleUpdateActiveSection = (activeSection: string) => {
		this.setState({ activeSection })
	}

	public componentWillMount() {
		if (!this.props.resource.featured_content || !this.props.resource.featured_content.length) {
			this.props.searchStore.setState({ isCentered: true })
		}
	}

	public closeModal = () => {
		this.setState(
			{
				isModalOpen: false,
			},
			() => {
				clearTimeout(this.timeout)
				this.timeout = setTimeout(() => {
					this.setState({
						url: undefined,
						article: undefined,
					})
				}, 300)
			},
		)
	}

	public openMediaItem: HomepageComponents.FeaturedContentProps['onClick'] = ({
		url,
		article,
	}) => {
		this.setState({ isModalOpen: true })

		if (url) {
			this.setState({ url })
		}
		if (article) {
			this.setState({ article })
		}
	}

	public render() {
		const { resource } = this.props
		const { navigation } = this.props.contentStore.state
		const { activeSection, isModalOpen, url, article } = this.state

		return (
			<HomepageComponents.Wrapper>
				<HelmetData
					title={'Make Maastricht Yours!'}
					seo={{
						description:
							'All the information you need to live, study and work in Maastricht. We cover personal finances, housing, health care and transport. Make Maastricht Yours!',
					}}
				/>

				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: `
                {
                    "@context": "http://schema.org",
                    "@type": "Organisation",
                    "url": "${shellConfig.name[env.STAGE!]}",
                    "email": "${shellConfig.publicEmail}",
                    "logo": "${shellConfig.logoUrl}",
                    "sameAs" : [
                        ${shellConfig.socials.map(social => `"${social.url}"`).join(',\n')}
                    ],
                    "contactPoint" : [
                        {
                            "@type" : "ContactPoint",
                            "telephone" : "${shellConfig.phone}",
                            "contactType" : "sales"
                        }
                    ]
                }`,
					}}
				/>
				<HomepageComponents.Section>
					<HomepageComponents.Title>
						{shellConfig.name[env.STAGE!]}{' '}
						{shellConfig.payoff[env.STAGE!] && (
							<span>{shellConfig.payoff[env.STAGE!]}</span>
						)}
					</HomepageComponents.Title>
					{/* <HomepageComponents.BackgroundVideos activeSection={activeSection as any} /> */}

					{navigation.length > 0 && (
						<MobileWrapper css={{ marginBottom: 40 }}>
							{navigation.map(domain => (
								<DomainOverview key={domain.id} domain={domain} />
							))}
						</MobileWrapper>
					)}

					<HomepageComponents.Logo
						navigation={navigation}
						activeSection={activeSection}
						setActiveSection={this.handleUpdateActiveSection}
						isLarge={
							!(resource.featured_content && resource.featured_content.length > 0)
						}
					/>

					{resource.featured_content && resource.featured_content.length > 0 && (
						<HomepageComponents.FeaturedContent
							isViewingBackgroundVideo={!!activeSection}
							featuredContent={resource.featured_content}
							onClick={this.openMediaItem}
						/>
					)}

					<HomepageComponents.IconNavigation />
				</HomepageComponents.Section>

				<MediaComponents.MediaModal
					open={isModalOpen}
					article={article}
					url={url}
					onClose={this.closeModal}
				/>
			</HomepageComponents.Wrapper>
		)
	}
}

export const Home = compose<HomeProps, HomeBaseProps>(
	withSearchStore,
	withContentStore,
)(HomeContainer)
