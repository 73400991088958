import styled from 'styled-components'

import { colors, mixins, transition } from '@styleguide'

export const Tag = styled.button({
	cursor: 'pointer',
	...mixins.primaryFont(),
	fontSize: 11,
	padding: `8px 10px`,
	borderRadius: 12,
	marginRight: 7,
	marginBottom: 7,
	display: 'inline-flex',
	alignItems: 'center',
	transition: transition.config,

	['&:not(.u-theme-background)']: {
		background: colors.GREY_20,
		color: colors.GREY_40,

		['&:hover']: {
			background: colors.GREY_30,
			color: colors.GREY_60,
		},
	},

	['&.u-theme-background']: {
		color: colors.WHITE,
	},
})
