import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

const scrollDown = css`
	animation: ${keyframes({
			'0%': {
				transform: 'translate(-50%, 0)',
			},
			'70%': {
				transform: 'translate(-50%, 100%)',
			},
			'90%': {
				transform: 'translate(-50%, 100%)',
			},
			'100%': {
				transform: 'translate(-50%, 0)',
			},
		})}
		1.2s infinite;
`

const fading = css`
	animation: ${keyframes({
			'0%': {
				opacity: 1,
			},
			'50%': {
				opacity: 0.3,
			},
			'100%': {
				opacity: 1,
			},
		})}
		1.2s infinite;
`

export const Wrapper = styled.div({
	position: 'relative',
	marginBottom: spacing.md,
})

export const Mouse = styled(
	styled.div({
		width: 20,
		height: 32,
		borderRadius: 10,
		border: `2px solid ${colors.GREY_30}`,

		['&:before']: {
			content: '""',
			width: 3,
			height: 5,
			background: colors.GREY_30,
			borderRadius: '50%',
			position: 'absolute',
			top: 10,
			left: '50%',
		},
	}),
)`
	&:before {
		${scrollDown}
	}
`

export const Arrow = styled(
	styled.div({
		position: 'absolute',
		left: '50%',
		transform: 'translateX(-50%)',
		backgroundColor: colors.GREY_40,
		width: 2,
		height: 2,
		animationIterationCount: 'infinite',

		['&:last-child']: {
			width: 0,
			height: 0,
			backgroundColor: 'transparent',
			borderLeft: '4px solid transparent',
			borderRight: '4px solid transparent',
			borderTop: `3px solid ${colors.GREY_40}`,
		},

		...Array.from(Array(3).keys()).reduce(
			(o, i) => ({
				...o,
				[`&:nth-child(${i + 1})`]: {
					animationDelay: `${i * 0.4 - 0.4}s`,
					bottom: `${-6 * i - 4}px`,
				},
			}),
			{},
		),
	}),
)`
	${fading}
`

export const ScrollAnimationWrapper = styled.div({
	display: 'inline-flex',
	alignItems: 'flex-end',

	[mixins.media.max('sm')]: {
		display: 'none',
	},
})

export const ScrollAnimation: React.SFC = props => (
	<ScrollAnimationWrapper>
		<Wrapper {...props}>
			<Mouse>
				<Arrow />
				<Arrow />
				<Arrow />
			</Mouse>
		</Wrapper>
	</ScrollAnimationWrapper>
)
