import React from 'react'

import { SvgIconMap } from '../types'

export const frontpagefinances: SvgIconMap = {
	size: {
		width: 88.765,
		height: 238.007,
	},
	Icon: () => (
		<>
			<linearGradient
				x1="49.984463%"
				y1="87.2942196%"
				x2="49.7194887%"
				y2="18.4824141%"
				id="SVGID_1_finances">
				<stop stopColor="#2B9370" offset="0.1550099%" />
				<stop stopColor="#29A07E" offset="100%" />
			</linearGradient>
			<path
				fill="#29A07E"
				d="M44.383,230.115C44.383,230.115,44.383,230.115,44.383,230.115L44.384,0L-0.002,212.681
	l25.328,25.327C30.203,233.131,36.939,230.115,44.383,230.115z"
			/>
			<path
				fill="url(#SVGID_1_finances)"
				d="M63.438,238.007l25.326-25.325L44.384,0v230.115C51.827,230.115,58.563,233.131,63.438,238.007z"
			/>
		</>
	),
}
