import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'

import { Event } from '@compass/types'

export interface EventDateProps {
	date: Event['date']
}

export const EventDateElement = styled.span({
	fontSize: '1.4rem',
	textTransform: 'uppercase',
	fontWeight: 'bold',
})

const dateFormat = 'ddd, D MMM'
const timeFormat = 'HH:mm'
const dateTimeFormat = `${dateFormat} ${timeFormat}`

export const EventDate: React.SFC<EventDateProps> = ({ date }) => {
	const start = moment(date.start)
	const end = moment(date.end)

	const isSame = date.start === date.end
	const endFormat = start.isSame(date.end, 'day') ? timeFormat : dateTimeFormat

	return (
		<EventDateElement className="u-theme-text">
			{start.format(dateTimeFormat)}
			{!isSame && ` - ${end.format(endFormat)}`}
		</EventDateElement>
	)
}
