import React from 'react'

import { Box } from '@app/components/box'
import { ContentValidator } from '@compass/types'

import * as ContentValidatorsComponents from './content-validators-components'

interface ContentValidatorsProps {
	validators: ContentValidator[]
}

export const ContentValidators: React.SFC<ContentValidatorsProps> = ({ validators }) => (
	<Box title="Proofread and validated by">
		<ContentValidatorsComponents.Validators>
			{validators.map(validator => (
				<ContentValidatorsComponents.Validator
					href={validator.link}
					key={validator.name}
					target="_blank"
					rel="noopener noreferrer">
					<ContentValidatorsComponents.ValidatorLogo
						src={validator.logo}
						alt={validator.name}
					/>
				</ContentValidatorsComponents.Validator>
			))}
		</ContentValidatorsComponents.Validators>
	</Box>
)
