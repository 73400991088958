import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const blog: SvgIconMap = {
	size: {
		width: 64,
		height: 64,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M61.746,9.737c-0.889,0-1.729,0.011-2.569,0.031v-2.85h-0.936c-18.179,0-24.275,4.35-26.24,6.697
				c-1.967-2.347-8.065-6.697-26.243-6.697H4.821v2.85c-0.837-0.02-1.677-0.031-2.567-0.031H0.132v41.244h2.123
				c22.427,0,27.007,5.14,27.657,6.1h4.213c0.708-1.003,5.376-6.1,27.621-6.1h2.122V9.737H61.746z M30.6,49.12
				c-3.141-2.31-9.704-4.986-23.434-5.166V9.275c19.223,0.25,22.939,5.516,23.434,6.375V49.12z M56.837,43.954
				c-13.732,0.18-20.3,2.856-23.436,5.166V15.662c0.535-0.939,4.351-6.136,23.436-6.386V43.954z M56.837,43.954"
		/>
	),
}
