import { env } from '@app/config/env'
import { getPublicShellConfig } from '@compass/config/src/utils/get-public-shell-config'

export const shellConfig = getPublicShellConfig(env.SHELL_NAME)

const apiPrefix = `//${env.STAGE === 'prod' ? '' : `${env.STAGE}-`}api.`
const appPrefix = `//${env.STAGE === 'prod' ? '' : `${env.STAGE}.`}`

export const domains = {
	api: env.LOCAL_API_URL_OVERWRITE || apiPrefix + shellConfig.domain,
	app: env.LOCAL_APP_URL_OVERWRITE || appPrefix + shellConfig.domain,
}
