import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { env, shellConfig } from '@app/config'
import { colors, mixins, spacing } from '@styleguide'

const LogoGlam = styled(({ isSmall, ...rest }) => <Link {...rest} />)<LogoProps>(({ isSmall }) => ({
	position: 'relative',
	display: 'inline-flex',
	alignItems: 'center',
	color: colors.BLACK,
	transition: '150ms ease-out',
	transitionProperty: 'transform',
	transformOrigin: '0 0',

	...mixins.mediaStyles({
		md: {
			margin: 0,
			padding: `${spacing.xs}px 0`,
		},
		xl: {
			transform: isSmall ? 'scale(0.70)' : 'translateY(15px)',
		},
		xxl: {
			transformOrigin: '0 0',
			transform: isSmall ? 'scale(0.64)' : 'translateY(15px)',
		},
	}),
}))

const LogoImageGlam = styled.img<LogoProps>({
	position: 'relative',
	display: 'block',
	width: 40,
	height: 'auto',

	...mixins.mediaStyles({
		xl: {
			width: 70,
		},
		xxl: {
			width: 80,
		},
	}),
})

const LogoTextGlam = styled.div({
	paddingLeft: 7,
	fontSize: '1.4rem',
	lineHeight: 1,
	whiteSpace: 'nowrap',

	...mixins.mediaStyles({
		xl: {
			paddingLeft: spacing.xs,
			lineHeight: 1.2,
		},
		xxl: {
			paddingLeft: spacing.sm,
			fontSize: '2rem',
		},
	}),
})

const LogoTextName = styled.strong({
	...mixins.primaryFont(),
	fontSize: '1.6rem',
	lineHeight: 1.4,

	...mixins.mediaStyles({
		xxl: {
			fontSize: '2.4rem',
		},
	}),
})

interface LogoProps {
	isSmall?: boolean
}

const logoSource = (env.STAGE && shellConfig.logos[env.STAGE]) || '/static/logo-without-text.svg'

export const Logo: React.SFC<LogoProps> = props => (
	<LogoGlam isSmall={props.isSmall} to="/">
		<LogoImageGlam src={logoSource} width="497px" height="112px" />
		<LogoTextGlam>
			<LogoTextName>{shellConfig.name[env.STAGE!]}</LogoTextName>
			<br />
			{shellConfig.payoff[env.STAGE!]}
		</LogoTextGlam>
	</LogoGlam>
)
