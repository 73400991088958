import debounce from 'debounce'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import {
	Accordion,
	DropdownCaret,
	Grid,
	HelmetData,
	Loader,
	Map as MapComponent,
	MapMarker,
	Page,
	SidebarToggle,
	ThemeWrapper,
} from '@app/components'
import { Error404 } from '@app/pages/error/error-404'
import { CompassUtils, isMobile } from '@app/utils'
import {
	MapCategoryResource,
	MarkerCategory,
	MarkerResource,
	PageMapResource,
} from '@compass/types'
import { spacing } from '@styleguide'

import * as MapComponents from './map-components'

interface MapBaseProps {
	resource: PageMapResource
}

type MapProps = MapBaseProps & RouteComponentProps<any>

type MapViewType = 'default' | 'marker'

interface MapState {
	currentLocation: google.maps.LatLng | google.maps.LatLngLiteral | undefined
	categories: number[]
	search: string
	markers: MarkerResource[]
	mapMarkers: MapMarker[]
	autocompleteMarkers: MarkerResource[]
	selectedMarkers: MarkerResource[]
	markersInView: MapMarker[]
	hoveredMarker: MarkerResource | undefined
	defaultMarkerCategory: MarkerCategory | undefined
	defaultMapCategory: MapCategoryResource | undefined
	view: MapViewType
	isMobileOpen: boolean
	error: boolean
	sidebar?: Element
	inViewElement?: Element
	inViewElementSearch?: Element
}

export class MapClass extends React.PureComponent<MapProps, MapState> {
	public state: MapState = {
		categories: [],
		currentLocation: undefined,
		search: '',
		markers: [],
		mapMarkers: [],
		autocompleteMarkers: [],
		defaultMarkerCategory: undefined,
		defaultMapCategory: undefined,
		view: 'default',
		selectedMarkers: [],
		markersInView: [],
		hoveredMarker: undefined,
		isMobileOpen: false,
		error: false,
		sidebar: undefined,
		inViewElement: undefined,
		inViewElementSearch: undefined,
	}

	public componentDidCatch() {
		this.setState({ error: true })
	}

	public componentWillMount() {
		this.updateMarkers()
		this.setMapView(this.props.location.pathname)
	}

	public async componentDidMount() {
		try {
			const position = await this.getUserCenter()
			this.setCurrentLocation(position)
		} catch {
			this.setCurrentLocation(undefined)
		}
	}

	public componentWillReceiveProps(props: MapProps) {
		if (props.location.pathname !== this.props.location.pathname) {
			this.setMapView(props.location.pathname)
		}
	}

	private getUserCenter(): Promise<Position> {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 10000 })
		})
	}

	private setCurrentLocation(position: Position | undefined) {
		this.setState({
			currentLocation: position
				? {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
				  }
				: undefined,
		})
	}

	private setMapView(pathname: string) {
		const { view: viewSlug, category } = CompassUtils.map.getMapPathFragments(pathname)
		const view = this.getView(viewSlug)

		this.setState({ view })

		if (viewSlug) {
			this.setDefaultMapCategory(viewSlug, category)
		}
	}

	private getView(slug: string): MapViewType {
		switch (slug) {
			case 'marker':
				return slug

			default:
				return 'default'
		}
	}

	private setDefaultMapCategory(type: string, categorySlug: string) {
		const { facilityTypes = [] } = this.props.resource
		const defaultMapCategory = CompassUtils.map.getMapCategoryByType(facilityTypes, type)

		if (!defaultMapCategory) {
			return
		}

		this.setState(
			{
				defaultMapCategory,
				categories: defaultMapCategory.categories.map(({ id }) => id),
			},
			() => this.updateMarkers(),
		)

		if (!categorySlug) {
			return
		}

		const defaultMarkerCategory = CompassUtils.map.getMarkerCategoryByCategorySlug(
			defaultMapCategory.categories,
			categorySlug,
		)

		if (!defaultMarkerCategory) {
			return
		}

		this.setState(
			{
				defaultMarkerCategory,
				categories: [defaultMarkerCategory.id],
			},
			() => this.updateMarkers(),
		)
	}

	private toggleSidebar = () => {
		this.setState({
			isMobileOpen: !this.state.isMobileOpen,
		})
	}

	private addSelectedMarker = (mapMarker: MapMarker | MarkerResource) => {
		const marker = this.state.markers.find(m => m.id === mapMarker.id)
		if (!marker) {
			return
		}

		if (isMobile()) {
			return this.setState({ selectedMarkers: [marker] })
		}

		const selectedMarkers = this.state.selectedMarkers.some(m => m.id === marker.id)
			? this.state.selectedMarkers
			: [...this.state.selectedMarkers, marker]
		this.setState({ selectedMarkers })
	}

	private removeSelectedMarker = (marker: MapMarker | MarkerResource) => {
		const selectedMarkers = this.state.selectedMarkers.filter(m => m.id !== marker.id)
		this.setState({ selectedMarkers })
	}

	private toggleSelectedMarker = (mapMarker: MapMarker | MarkerResource) => {
		if (this.state.selectedMarkers.some(m => m.id === mapMarker.id)) {
			this.removeSelectedMarker(mapMarker)
		} else {
			this.addSelectedMarker(mapMarker)
		}
	}

	private onMarkerMouseEnter = (hoveredMarker: MarkerResource) => {
		this.setState({ hoveredMarker })
	}

	private onMarkerMouseLeave = () => {
		this.setState({ hoveredMarker: undefined })
	}

	private onUpdateCategoryFilters = (values: MapComponents.MapFilterCategoryFormValues) => {
		this.setState(values, () => this.updateMarkers())
	}

	private onUpdateSearchQuery = (values: MapComponents.MapFilterSearchFormValues) => {
		this.setState(values, () => this.updateAutocompleteMarkers())
	}

	private updateAutocompleteMarkers() {
		const { search } = this.state

		const lowerCaseSearch = search.toLowerCase()

		const markerCategoryIDs = CompassUtils.map
			.getMarkerCategories(this.props.resource.facilityTypes)
			.filter(markerCategory =>
				CompassUtils.map.fieldContains(markerCategory.name, lowerCaseSearch),
			)
			.map(markerCategory => markerCategory.id)

		this.setState({
			autocompleteMarkers:
				this.props.resource.markers && search
					? this.props.resource.markers
							.filter(
								marker =>
									CompassUtils.map.fieldContains(marker.title, lowerCaseSearch) ||
									CompassUtils.map.markerHasOneOfMarkerCategoryIDs(
										marker,
										markerCategoryIDs,
									),
							)
							.filter((_marker, index) => index < 10)
					: [],
		})
	}

	private updateMarkers() {
		const { categories } = this.state

		const markers = this.props.resource.markers || []

		const mapMarkers = markers
			.map(marker => {
				const markerCategory = CompassUtils.map.getMarkerCategoryByMarker(
					marker,
					this.props.resource.facilityTypes,
				)

				const icon = markerCategory ? markerCategory.icons.marker : ''

				return {
					icon,
					id: marker.id,
					title: marker.title,
					position: marker.address,
					visible:
						!categories.length ||
						CompassUtils.map.markerHasOneOfMarkerCategoryIDs(marker, categories),
				}
			})
			.filter(
				marker =>
					marker.icon && marker.position && marker.position.lat && marker.position.lng,
			)

		this.setState({
			markers,
			mapMarkers,
		})
	}

	private isInvalidUrl(): boolean {
		const { pathname } = this.props.location
		const { facilityTypes, slug } = this.props.resource
		const { view, category } = CompassUtils.map.getMapPathFragments(pathname)

		if (!view) {
			return false
		}

		const defaultMapCategory =
			facilityTypes &&
			facilityTypes.find(facilityType => facilityType.slug === `${slug}${view}/`)

		if (!defaultMapCategory) {
			return true
		}

		if (!category) {
			return false
		}

		const defaultMarkerCategory = defaultMapCategory.categories.find(
			mapCategory => mapCategory.slug === category,
		)

		return !defaultMarkerCategory
	}

	private onMapBoundsChanged = debounce((markersInView: MapMarker[]) => {
		this.setState({ markersInView })
	}, 1000)

	public render() {
		if (this.isInvalidUrl()) {
			return <Error404 />
		}

		const { resource } = this.props
		const { facilityTypes = [] } = this.props.resource
		const {
			currentLocation,
			autocompleteMarkers,
			categories,
			markers,
			hoveredMarker,
			isMobileOpen,
			error,
			search,
			mapMarkers,
			markersInView,
		} = this.state

		if (error) {
			return (
				<Page>
					<HelmetData title={resource.title} seo={resource.seo} />
					<Grid.Container>
						<p>Something went wrong</p>
					</Grid.Container>
				</Page>
			)
		}

		// @ts-ignore
		const filteredMarkers = mapMarkers
			.filter(m => m.visible)
			.map(m => markers.find(marker => m.id === marker.id)!)

		const filteredMarkersInView = markersInView
			.map(m => mapMarkers.find(marker => m.id === marker.id)!)
			.filter(m => m && m.visible)
			.map(m => markers.find(marker => m.id === marker.id)!)
			.filter(Boolean) as MarkerResource[]

		const selectedMarkers = [
			...this.state.selectedMarkers,
			...(hoveredMarker && !this.state.selectedMarkers.some(m => m.id === hoveredMarker.id)
				? [hoveredMarker]
				: []),
		]

		return (
			<Page>
				<ThemeWrapper>
					<HelmetData title={resource.title} seo={resource.seo} />
					<MapComponents.MapContainer>
						<MapComponents.Sidebar
							ref={(sidebarElement: any) =>
								this.setState({ sidebar: sidebarElement })
							}
							isOpen={isMobileOpen}>
							<SidebarToggle isOpen={isMobileOpen} onClick={this.toggleSidebar}>
								<DropdownCaret isActive={isMobileOpen} />
								What are you looking for?
							</SidebarToggle>
							<Accordion
								noPadding
								accordionPosition={1}
								header="Find by category"
								last={false}
								defaultOpen>
								<MapComponents.MarkerCategoriesForm
									onUpdateCategoryFilters={this.onUpdateCategoryFilters}
									facilityTypes={facilityTypes}
									categories={categories}
								/>
							</Accordion>
							<div css={{ marginTop: spacing.xs }}>
								<Accordion
									noPadding
									accordionPosition={2}
									header="Find by search"
									last={false}>
									<div
										ref={(inViewElementSearch: any) =>
											this.setState({ inViewElementSearch })
										}
										css={{
											paddingBottom: spacing.xs,
											maxHeight: 400,
											overflow: 'auto',
										}}>
										<MapComponents.MarkerSearchForm
											onUpdateSearchQuery={this.onUpdateSearchQuery}
										/>
										{this.state.inViewElementSearch && (
											<MapComponents.MarkerList
												scrollWrapper={this.state.inViewElementSearch}
												title={
													search ? `Results for "${search}"` : undefined
												}
												markers={autocompleteMarkers}
												selectedMarkers={selectedMarkers}
												facilityTypes={facilityTypes}
												onMarkerClick={this.toggleSelectedMarker}
												onMarkerMouseEnter={this.onMarkerMouseEnter}
												onMarkerMouseLeave={this.onMarkerMouseLeave}
											/>
										)}
									</div>
								</Accordion>
							</div>

							<div css={{ marginTop: spacing.xs }}>
								<Accordion
									noPadding
									accordionPosition={3}
									header="Browse facilities in view"
									last={true}>
									<div
										ref={(inViewElement: any) =>
											this.setState({ inViewElement })
										}
										css={{
											maxHeight: 400,
											overflow: 'auto',
										}}>
										{this.state.inViewElement && (
											<MapComponents.MarkerList
												scrollWrapper={this.state.inViewElement}
												markers={filteredMarkersInView}
												selectedMarkers={selectedMarkers}
												facilityTypes={facilityTypes}
												onMarkerClick={this.toggleSelectedMarker}
												onMarkerMouseEnter={this.onMarkerMouseEnter}
												onMarkerMouseLeave={this.onMarkerMouseLeave}
											/>
										)}
									</div>
								</Accordion>
							</div>
						</MapComponents.Sidebar>
						<Loader
							transparent
							fixed={false}
							loading
							title="The city map"
							text="Loading - please wait"
						/>
						<MapComponent
							currentLocation={currentLocation}
							markers={mapMarkers}
							selectedMarkers={selectedMarkers}
							facilityTypes={facilityTypes}
							onMarkerClick={this.addSelectedMarker}
							onMarkerClose={this.removeSelectedMarker}
							onMapBoundsChanged={this.onMapBoundsChanged}
						/>
					</MapComponents.MapContainer>
				</ThemeWrapper>
			</Page>
		)
	}
}

export const Map = compose<MapProps, MapBaseProps>(withRouter)(MapClass)
