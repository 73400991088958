import { Request } from 'express'
import React from 'react'
import Helmet from 'react-helmet'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { env, shellConfig } from '@app/config'
import { isBrowser } from '@app/utils'
import { SEOData } from '@compass/types'

interface HelmetDataProps {
	title: string
	seo?: SEOData
	image?: string
}

export const HelmetData: React.SFC<
	HelmetDataProps & RouteComponentProps<any, { statusCode?: number; req: Request }>
> = ({ staticContext, title, image, seo }) => {
	let hostname
	let currentUrl

	if (isBrowser) {
		hostname = window.location.origin
		currentUrl = window.location.href
	} else {
		const { req } = staticContext!

		hostname = req.protocol + '://' + req.get('host')
		currentUrl = hostname + req.originalUrl
	}

	const formattedImage = (seo && seo.image) || image || '/attachment/2020/11/mymaastricht.png'
	const formattedTwitterImage =
		(seo && seo.twitter_image) || (seo && seo.image) || image || '/static/logo-without-text.svg'

	const canonical = (seo && seo.canonical) || currentUrl
	const robots = (seo && seo.robots) || 'index, follow'
	const description = seo && seo.description

	const formattedTitle = `${title ? title + ' | ' : ''}${shellConfig.name[env.STAGE!]}`

	return (
		<Helmet>
			<title>{formattedTitle}</title>
			{description && <meta name="description" content={description} />}
			<link rel="canonical" href={canonical} />
			<meta name="robots" content={robots} />
			<meta property="og:locale" content="en" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={formattedTitle} />
			{description && <meta property="og:description" content={description} />}
			<meta property="og:url" content={currentUrl} />
			<meta
				property="og:site_name"
				content={`${shellConfig.name[env.STAGE!]} - ${shellConfig.payoff[env.STAGE!]}`}
			/>
			<meta property="og:image" content={formattedImage} />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />

			<meta name="twitter:card" content="summary_large_image" />
			{description && <meta name="twitter:description" content={description} />}
			<meta name="twitter:title" content={formattedTitle} />
			<meta name="twitter:site" content={currentUrl} />
			<meta name="twitter:image" content={formattedTwitterImage} />
			<meta name="twitter:creator" content="@maastrichtmy" />
			<link
				rel="apple-touch-icon"
				sizes="180x180"
				href={hostname + '/static/favicons/apple-touch-icon.png'}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="32x32"
				href={hostname + '/static/favicons/favicon-32x32.png'}
			/>
			<link
				rel="icon"
				type="image/png"
				sizes="16x16"
				href={hostname + '/static/favicons/favicon-16x16.png'}
			/>
			<link rel="manifest" href={hostname + '/site.webmanifest'} />
			<link
				rel="mask-icon"
				href={hostname + '/static/favicons/safari-pinned-tab.svg'}
				color="#5bbad5"
			/>
			<meta name="msapplication-TileColor" content="#ffffff" />
			<meta name="theme-color" content="#ffffff" />
		</Helmet>
	)
}

export default withRouter(HelmetData)
