import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const caret: SvgIconMap = {
	size: {
		width: 100,
		height: 100,
	},
	Icon: ({ fill = defaultFill }) => <polygon fill={fill} points="5.9,88.2 50,11.8 94.1,88.2 " />,
}
