import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

export interface WrapperProps {
	isActive: boolean
}

const activeBackgroundStyles = {
	['& .c-navigation-section__background']: {
		opacity: 1,
	},
}

const activeLinkTextStyles = {
	['& .c-navigation-section__link-text']: {
		color: `${colors.WHITE} !important`,
	},
}

const activeSubmenuStyles = {
	['& .c-navigation-section__submenu']: {
		maxHeight: '500px',
	},
}

export const Wrapper = styled.li<WrapperProps>(({ isActive = false }) => ({
	position: 'relative',
	display: 'block',
	textAlign: 'left',

	['&:hover']: {
		...activeBackgroundStyles,
		...activeLinkTextStyles,
	},

	[mixins.media.max('sm')]: {
		margin: `0 -24px`,
	},

	[mixins.media.max('md')]: {
		padding: `0 24px`,
	},

	...(isActive
		? {
				...activeBackgroundStyles,
				...activeLinkTextStyles,
				...activeSubmenuStyles,
		  }
		: {
				['& .c-navigation-section__link svg']: {
					['& [fill]:not([fill="none"]), &b[style*="fill:#FFFFFF"]']: {
						transition: transition.config,
						transitionProperty: 'fill',
					},

					['& [stroke]:not([stroke="none"]), & [style*="stroke:#FFFFFF"]']: {
						transition: transition.config,
						transitionProperty: 'stroke',
					},
				},
				['&:not(:hover)']: {
					['& .c-navigation-section__link svg']: {
						['& [fill]:not([fill="none"]), &b[style*="fill:#FFFFFF"]']: {
							fill: 'currentColor !important',
						},

						['& [stroke]:not([stroke="none"]), & [style*="stroke:#FFFFFF"]']: {
							stroke: 'currentColor !important',
						},
					},
					['& .c-navigation-section__events-date']: {
						color: `currentColor !important`,
					},
				},
		  }),
}))

export const Submenu = styled.ul.attrs({ className: 'c-navigation-section__submenu' })({
	position: 'relative',
	maxHeight: 0,
	overflow: 'hidden',
	transition: transition.config,
	transitionProperty: 'max-height',
})

interface SubmenuItemProps {
	isActive: boolean
}

const activeLinkStyles = {
	['& .c-navigation-section__submenu-item-link']: {
		background: 'rgba(255, 255, 255, 0.3)',
	},
}

export const SubmenuItem = styled.li.attrs({
	className: 'c-navigation-section__submenu-item',
})<SubmenuItemProps>(({ isActive = false }) => ({
	['&:hover']: {
		...activeLinkStyles,
	},
	['&:last-child']: {
		marginBottom: spacing.xs,
	},

	...(isActive ? activeLinkStyles : {}),
}))

export const SubmenuItemLink = styled(RouterLink).attrs({
	className: 'c-navigation-section__submenu-item-link',
})({
	padding: '3px 20px 7px',
	display: 'block',
	fontWeight: 'bold',
	fontSize: '14px',
	letterSpacing: 0,
	color: colors.WHITE,
	transition: transition.config,
	transitionProperty: 'background',
	...mixins.primaryFont(),
})

export const Link = styled(RouterLink).attrs({
	className: 'c-navigation-section__link u-theme-text',
})({
	position: 'relative',
	height: 70,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	textDecoration: 'none',
	transition: transition.config,
	transitionProperty: 'color',

	['& svg']: {
		width: 32,
		height: 32,
	},

	...mixins.mediaStyles({
		md: {
			padding: '0 2rem',

			['& svg']: {
				width: 24,
				height: 24,
			},
		},
		xl: {
			padding: '0 2.5rem',

			['& svg']: {
				width: 32,
				height: 32,
			},
		},
	}),
})

export const LinkText = styled.h4.attrs({
	className: 'c-navigation-section__link-text',
})({
	position: 'relative',
	height: '24px',
	lineHeight: '27px',

	[mixins.media.minMax('md', 'lg')]: {
		fontSize: '1.5rem',
	},
})

export const IconWrapper = styled.div({
	position: 'relative',
	marginRight: 10,
	display: 'flex',
	alignItems: 'center',

	['@media (min-width: 1367px)']: {
		marginRight: spacing.xs,
	},
})

export const EventsDate = styled.span.attrs({
	className: 'c-navigation-section__events-date',
})({
	position: 'absolute',
	top: '50%',
	marginTop: '4px',
	transform: 'translateY(-50%)',
	width: '100%',
	fontSize: '1.2rem',
	fontWeight: 'bold',
	textAlign: 'center',
	color: colors.WHITE,
	transition: transition.config,
	transitionProperty: 'color',
})

export const Background = styled.span.attrs({
	className: 'c-navigation-section__background u-theme-background',
})({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	opacity: 0,
	transition: transition.config,
	transitionProperty: 'opacity',
})
