import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { SvgIcon } from '@app/components/svg-icons'
import { colors, mixins, spacing } from '@styleguide'

const Button = styled(Link)({
	background: colors.PRIMARY,
	padding: `5px ${spacing.sm}px`,
	borderRadius: 20,
	display: 'flex',
	alignItems: 'center',
})

const Text = styled.span({
	color: colors.WHITE,
	marginLeft: 5,
	...mixins.primaryFont(),
})

export const MapButton: React.SFC = props => (
	<Button to="/map/" {...props}>
		<SvgIcon icon="marker" />
		<Text>map</Text>
	</Button>
)
