import styled from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

interface SidebarProps {
	isOpen: boolean
}

export const Sidebar = styled.aside<SidebarProps>(({ isOpen = false }) => ({
	position: 'relative',
	zIndex: 1,
	overflow: 'hidden',
	marginBottom: spacing.sm,

	...mixins.mediaStyles({
		md: {
			marginTop: spacing.md,
		},
		lg: {
			overflow: 'visible',
		},
	}),

	[mixins.media.max('md')]: {
		transition: transition.config,
		transitionProperty: 'max-height',
		maxHeight: isOpen ? 2000 : 60,
		margin: `0 -${spacing.sm}px`,
	},
}))

export const SidebarContent = styled.div({
	[mixins.media.max('md')]: {
		padding: `0 ${spacing.sm}px`,
	},
})

export const SidebarToggle = styled.button<{ isOpen: boolean }>(({ isOpen }) => ({
	position: 'relative',
	cursor: 'pointer',
	padding: `0 ${spacing.sm}px`,
	height: 60,
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	backgroundColor: isOpen ? colors.GREY_20 : 'transparent',
	transition: transition.config,
	transitionProperty: 'background',
	...mixins.primaryFont(),
	...mixins.mediaStyles({
		md: {
			display: 'none',
		},
	}),
}))
