import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

export const Content = styled.div({
	maxWidth: '720px',
	width: '100%',

	['& p']: {
		marginTop: spacing.sm,
	},
})

export const Wrapper = styled.section({
	position: 'relative',
	border: `1px ${colors.GREY_30} solid`,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	padding: `${spacing.xs}px ${spacing.sm}px`,

	...mixins.mediaStyles({
		sm: {
			padding: `${spacing.lg}px ${spacing.sm}px`,
		},
	}),
})
