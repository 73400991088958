import styled from 'styled-components'

import { colors, spacing } from '@styleguide'

export const Label = styled.label<{ hasError: boolean; isSmall: boolean }>(
	({ hasError, isSmall }) => ({
		position: 'relative',
		display: 'flex',
		cursor: 'pointer',

		['input:disabled + &']: {
			cursor: 'initial',
		},

		['&:before']: {
			content: '""',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: 28,
			height: 28,
			marginRight: spacing.xs,
			flexShrink: 0,

			border: `1px solid ${colors.GREY_50}`,
			borderRadius: '50%',

			['input:disabled + &']: {
				border: `1px solid ${colors.GREY_20}`,
			},

			...(hasError
				? {
						['input + &']: {
							border: `1px solid ${colors.PRIMARY}`,
						},
				  }
				: {}),

			...(isSmall
				? {
						marginTop: 4,
						width: 20,
						height: 20,
				  }
				: {}),
		},

		['&:after']: {
			content: '""',
			position: 'absolute',
			display: 'block',
			top: 6,
			left: 6,
			width: 16,
			height: 16,

			borderRadius: '50%',
			backgroundColor: colors.GREY_40,

			opacity: 0,
			transform: 'scale(.5)',

			transition: 'all 100ms ease-out',

			['input:checked + &']: {
				opacity: 1,
				transform: 'scale(1)',
			},

			...(isSmall
				? {
						marginTop: 4,
						top: 3,
						left: 3,
						width: 14,
						height: 14,
				  }
				: {}),
		},

		['&:hover']: {
			['&:after']: {
				['input:not(:disabled) + &']: {
					opacity: 0.5,
					transform: 'scale(1)',
				},
				['input:checked + &']: {
					opacity: 1,
					transform: 'scale(1)',
				},
			},
		},
	}),
)
