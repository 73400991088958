import React from 'react'
import { Subscribe } from 'unstated'

import { ContentStore } from './content'

export interface WithContentStoreProps {
	contentStore: ContentStore
}

export function withContentStore<P>(
	WrappedComponent: React.ComponentType<P & WithContentStoreProps>,
) {
	return class WithContentHoc extends React.Component<P> {
		public render() {
			return (
				<Subscribe to={[ContentStore]}>
					{(contentStore: ContentStore) => (
						<WrappedComponent {...this.props} contentStore={contentStore} />
					)}
				</Subscribe>
			)
		}
	}
}
