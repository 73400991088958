import React from 'react'
import styled from 'styled-components'

import { GridItemWrapper } from '@app/pages/list'
import { EventResource } from '@compass/types'
import { colors, spacing } from '@styleguide'

import { EventListType, EventsFiltersState } from '../../types'
import { Fallback } from '../fallback'
import { Tile } from '../tile'
import { TooltipItem } from '../tooltip-item'

const ItemWrapper = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	margin: `0 -${spacing.xs}px ${spacing.xxl}px`,
})

interface EventsListProps {
	events: EventResource[]
	isLoading?: boolean
	type?: EventListType
	transition?: number
	view?: 'tooltip' | 'tile'
	filters: EventsFiltersState
	emptyState?: string
	title?: string
}

export default class extends React.Component<EventsListProps> {
	private renderTiles() {
		const { events = [], type, view } = this.props

		return (
			<ItemWrapper css={{ ...(view === 'tooltip' ? { marginBottom: 0 } : {}) }}>
				{events.map((event: EventResource) => (
					<GridItemWrapper
						css={{
							...(view === 'tooltip'
								? {
										width: '100% !important',
										marginBottom: 0,
										['&:not(:last-child)']: {
											borderBottom: `1px ${colors.GREY_20} solid }`,
										},
								  }
								: {}),
						}}
						key={event.id}>
						{view !== 'tooltip' ? (
							<Tile event={event} type={type} />
						) : (
							<TooltipItem event={event} />
						)}
					</GridItemWrapper>
				))}
			</ItemWrapper>
		)
	}

	public render() {
		const { emptyState, isLoading, title, view } = this.props

		if (!this.props.events.length && !emptyState) {
			return null
		}

		return (
			<div>
				<h3
					css={{
						marginBottom: spacing.sm,
						marginTop: 14,
						...(view === 'tooltip' && { fontSize: '1.8rem' }),
					}}
					className="u-theme-text">
					{title}
				</h3>
				{this.props.events.length ? (
					this.renderTiles()
				) : !isLoading ? (
					<Fallback>
						{emptyState || 'No events were found mathching your query.'}
					</Fallback>
				) : null}
			</div>
		)
	}
}
