import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const marker: SvgIconMap = {
	size: {
		width: 16,
		height: 16,
	},
	Icon: ({ fill = defaultFill }) => (
		<path
			fill={fill}
			d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 11 5 11s5-6 5-11c0-2.761-2.239-5-5-5zM8 8c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
		/>
	),
}
