import styled from 'styled-components'

import { colors } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
	display: 'flex',
	color: colors.PRIMARY,
	borderBottom: `3px ${colors.GREY_20} solid`,
	flexWrap: 'wrap',
})
