import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const eventsInTheNight: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="Layer_1">
			<g>
				<path
					fill="none"
					stroke={fill}
					strokeWidth="2.0864"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					d="
			M9.856,6.438c0-1.602,0.358-3.117,0.988-4.482C5.981,2.984,2.332,7.3,2.332,12.469c0,5.938,4.811,10.75,10.748,10.75
			c4.336,0,8.061-2.571,9.762-6.268c-0.722,0.153-1.469,0.236-2.235,0.236C14.669,17.188,9.856,12.375,9.856,6.438z"
				/>
				<g>
					<path
						fill="none"
						stroke={fill}
						strokeWidth="2.0864"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="
				M16,23.204"
					/>
				</g>
			</g>
		</g>
	),
}
