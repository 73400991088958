import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = 'currentColor'

export const eventsAll: SvgIconMap = {
	size: {
		width: 24,
		height: 24,
	},
	Icon: ({ fill = defaultFill }) => (
		<g id="Layer_1">
			<g>
				<g>
					<polyline
						fill="none"
						stroke={fill}
						strokeWidth="1.9413"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						points="
				10.875,1.638 14.448,4.487 10.938,7.272 			"
					/>

					<path
						fill="none"
						stroke={fill}
						strokeWidth="1.9413"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						d="
				M19.64,8.245c1.394,2.109,1.905,4.785,1.185,7.416c-1.323,4.846-6.326,7.701-11.172,6.375c-4.848-1.324-7.703-6.326-6.378-11.174
				C4.6,6.017,9.601,3.163,14.448,4.487"
					/>
				</g>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.9413"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="12.043"
					y1="22.362"
					x2="12.043"
					y2="18.54"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.9413"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="2.95"
					y1="13.077"
					x2="6.771"
					y2="13.077"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.9413"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="21.15"
					y1="13.077"
					x2="17.327"
					y2="13.077"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.9413"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="7.33"
					y1="8.362"
					x2="5.719"
					y2="6.753"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.9413"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="18.606"
					y1="19.642"
					x2="16.996"
					y2="18.03"
				/>

				<line
					fill="none"
					stroke={fill}
					strokeWidth="1.9413"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="5.511"
					y1="19.61"
					x2="7.119"
					y2="17.999"
				/>
			</g>
		</g>
	),
}
