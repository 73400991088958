import React from 'react'
import styled from 'styled-components'

import { Resource } from '@compass/types'
import { colors, spacing } from '@styleguide'

import * as DomainOverviewComponents from './domain-overview-components'

interface DomainOverviewProps {
	domain: Resource
	title?: string
}

const H2 = styled.h2({
	color: colors.PRIMARY,
	marginBottom: spacing.md,
})

export const DomainOverview: React.SFC<DomainOverviewProps> = ({ domain, title, ...rest }) => (
	<DomainOverviewComponents.Wrapper {...rest}>
		{title && <H2>{title}:</H2>}
		<DomainOverviewComponents.Blocks>
			{domain &&
				domain.resources &&
				domain.resources.length > 0 &&
				domain.resources.map((section: Resource) => (
					<DomainOverviewComponents.Block section={section} key={section.slug} />
				))}
		</DomainOverviewComponents.Blocks>
	</DomainOverviewComponents.Wrapper>
)
