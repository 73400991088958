import truncate from 'html-truncate'
import React from 'react'

import { Button } from '@app/components/button'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { CompassUtils } from '@app/utils'
import { FeaturedPage as FeaturedPageInterface } from '@compass/types'

import { Content, Half, Title, Wrapper } from './components'

export interface FeaturedPageProps {
	item: FeaturedPageInterface
}

export const FeaturedPage: React.SFC<FeaturedPageProps> = ({ item }) => {
	const theme = CompassUtils.navigation.getSectionSlugByResourceSlug(item.page.slug)

	return (
		<Half>
			<Wrapper>
				<ThemeWrapper fixedTheme={theme}>
					<Title className="u-theme-text">{item.page.title}</Title>
					<Content
						dangerouslySetInnerHTML={{ __html: truncate(item.page.content, 250) }}
					/>
					<Button link to={item.page.slug} buttonSize="small">
						Read more
					</Button>
				</ThemeWrapper>
			</Wrapper>
		</Half>
	)
}
