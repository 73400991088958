import React from 'react'
import styled from 'styled-components'

interface FetchMoreTagProps {
	onFetch(): void
	isInView: boolean
	isScrolledPast: boolean
}

interface FetchMoreTagState {
	isInView: boolean
	isScrolledPast: boolean
}

const FetchMoreTagDiv = styled.div({
	position: 'absolute',
	bottom: 200,
	left: 0,
	width: '100%',
})

export class FetchMoreTag extends React.Component<FetchMoreTagProps, FetchMoreTagState> {
	public state: FetchMoreTagState = {
		isInView: false,
		isScrolledPast: false,
	}

	public componentWillReceiveProps({ isInView, isScrolledPast }: FetchMoreTagProps) {
		if (this.state.isInView === isInView && this.state.isScrolledPast === isScrolledPast) {
			return
		}

		this.setState(
			{ isInView, isScrolledPast },
			() => (this.state.isInView || this.state.isScrolledPast) && this.props.onFetch(),
		)
	}

	public render() {
		return <FetchMoreTagDiv />
	}
}
