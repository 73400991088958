import styled from 'styled-components'

import { colors, mixins, spacing } from '@styleguide'

export const Block = styled.div({
	...mixins.primaryFont(),
	marginBottom: spacing.sm,
	padding: spacing.sm,
	color: colors.WHITE,
	borderRadius: 8,
})
