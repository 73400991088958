import React from 'react'

import { ThemeWrapper } from '@app/components/theme-wrapper'
import { YoutubeComponent } from '@app/pages/media/components'
import { FeaturedYoutubeVideo as FeaturedYoutubeVideoInterface } from '@compass/types'

import { Full, Wrapper } from './components'

export interface FeaturedYoutubeVideoProps {
	item: FeaturedYoutubeVideoInterface
	onClick?: any
}

const getIdFromUrl = (url: string) => {
	const split = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return split[2] !== undefined ? split[2].split(/[^0-9a-z_\-]/i)[0] : split[0]
}

export const FeaturedYoutubeVideo: React.SFC<FeaturedYoutubeVideoProps> = ({ item, onClick }) => {
	return (
		<Full>
			<Wrapper css={{ padding: 0 }}>
				<ThemeWrapper fixedTheme="media">
					<YoutubeComponent
						item={{
							id: getIdFromUrl(item.url),
							// @ts-ignore
							video: {
								title: item.title,
							},
						}}
						onClick={url => onClick({ url })}
					/>
				</ThemeWrapper>
			</Wrapper>
		</Full>
	)
}
