import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { SvgIcon } from '@app/components'
import { Loader } from '@app/components/loader'
import { Modal } from '@app/components/modal'
import * as ModalComponents from '@app/components/modal/modal-components'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { mixins, spacing } from '@app/config'
import * as ArticleComponents from '@app/pages/article/article-components'
import { ArticleResource } from '@compass/types'

import { Iframe } from './modal'

const ViewInFullWrapper = styled(RouterLink)({
	position: 'absolute',
	top: spacing.xs,
	right: spacing.xs,
	...mixins.smallLabel(),
	...mixins.primaryFont(),
})

export interface MediaModalProps {
	open: boolean
	onClose: () => void
	article: ArticleResource | undefined
	url: string | undefined
}

export const MediaModal: React.SFC<MediaModalProps> = ({ open, onClose, article, url }) => (
	<ThemeWrapper fixedTheme="media">
		<Modal open={open} onClose={onClose} isSmall={Boolean(article && !url)}>
			<ModalComponents.Content align="left">
				{article && !url && (
					<ViewInFullWrapper to={article.slug}>
						View in full
						<SvgIcon icon="fullscreen" />
					</ViewInFullWrapper>
				)}
				<div css={{ maxWidth: '100%' }}>
					{url && (
						<>
							<Loader transparent fixed={false} loading />
							<Iframe src={url} frameBorder="0" />
						</>
					)}
					{article && !url && <ArticleComponents.ArticleDetail article={article} />}
				</div>
			</ModalComponents.Content>
		</Modal>
	</ThemeWrapper>
)
