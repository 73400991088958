import React, { FC } from 'react'
import styled from 'styled-components'

import { Small } from '@app/components/small'
import { colors } from '@app/config'

interface CharacterCounterProps {
    value: string
    max: number
}

const Wrapper = styled.div({
    position: 'relative'
})

interface CounterProps {
    isInvalid: boolean
}

const Counter = styled(Small)<CounterProps>(({ isInvalid }) => ({
    position: 'absolute',
    top: -12,
    right: 10,
    color: isInvalid ? colors.PRIMARY : ''
}))

export const CharacterCounter: FC<CharacterCounterProps> = ({ value, max, children }) => (
    <Wrapper>
        {children}
        <Counter isInvalid={value.length > max}>
            {value.length}/{max}
        </Counter>
    </Wrapper>
)
