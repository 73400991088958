import React from 'react'

import { Resource } from '@compass/types'

import * as SectionOverviewComponents from './section-overview-components'

interface SectionOverviewProps {
	section: Resource
}

interface SectionOverviewState {
	query: string
}

export class SectionOverview extends React.Component<SectionOverviewProps, SectionOverviewState> {
	public state: SectionOverviewState = {
		query: '',
	}

	public query = (event: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			query: event.currentTarget.value,
		})
	}

	public render() {
		const { query } = this.state
		const { section: activeSection } = this.props

		return activeSection ? (
			<SectionOverviewComponents.Wrapper>
				<SectionOverviewComponents.Title className="u-theme-text">
					In the '{activeSection.title}' section:
				</SectionOverviewComponents.Title>
				<SectionOverviewComponents.Search
					section={activeSection}
					query={query}
					onQuery={this.query}
				/>
				<SectionOverviewComponents.Blocks>
					{activeSection &&
						activeSection.resources &&
						activeSection.resources.map((page: Resource) => (
							<SectionOverviewComponents.Block
								query={query}
								page={page}
								key={page.id}
							/>
						))}
				</SectionOverviewComponents.Blocks>
			</SectionOverviewComponents.Wrapper>
		) : null
	}
}
