import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#ffffff'

export const events: SvgIconMap = {
	size: {
		width: 46,
		height: 46,
	},
	Icon: ({ fill = defaultFill }) => (
		<g>
            <g>
                <path fill="none" d="M5.751,36.561c0,0.974,0.797,1.771,1.771,1.771h30.955c0.975,0,1.771-0.797,1.771-1.771V15.46H5.751V36.561z"
                    />
                <path fill={fill} d="M12.204,11.292c1.203,0,2.178-0.975,2.178-2.177V6.35V4.095c0-1.203-0.975-2.178-2.178-2.178
                    s-2.177,0.975-2.177,2.178V6.35v2.765C10.027,10.317,11.001,11.292,12.204,11.292z"/>
                <path fill={fill} d="M33.796,11.292c1.202,0,2.178-0.975,2.178-2.177V6.35V4.095c0-1.203-0.976-2.178-2.178-2.178
                    c-1.203,0-2.178,0.975-2.178,2.178V6.35v2.765C31.618,10.317,32.593,11.292,33.796,11.292z"/>
                <path fill={fill} d="M42.052,6.35H37.76v2.765c0,2.186-1.777,3.964-3.964,3.964s-3.965-1.778-3.965-3.964V6.35H16.169v2.765
                    c0,2.186-1.779,3.964-3.965,3.964S8.24,11.301,8.24,9.115V6.35H3.948C1.771,6.35,0,8.122,0,10.298v29.835
                    c0,2.178,1.771,3.948,3.948,3.948h38.104c2.177,0,3.948-1.771,3.948-3.948V10.298C46,8.122,44.229,6.35,42.052,6.35z
                    M40.249,36.561c0,0.974-0.797,1.771-1.771,1.771H7.522c-0.974,0-1.771-0.797-1.771-1.771V15.46h34.498V36.561z"/>
            </g>
        </g>
	),
}
