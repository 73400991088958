import styled from 'styled-components'

import { colors, spacing } from '@styleguide'

export const Form = styled.form({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	textAlign: 'left',
	margin: `${spacing.md}px 0`,
	flex: 1,
})

export const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
})

export const ButtonBar = styled.div({
	borderTop: `1px ${colors.GREY_30} solid`,
	paddingTop: spacing.xs,
	display: 'flex',
	justifyContent: 'flex-end',
})
