import React from 'react'

import * as AutocompleteComponents from './autocomplete-components'

interface ChunkedItems<T> {
	label: string
	items: T[]
}

interface AutocompleteProps<T extends Record<string, any>> {
	query: string
	items: T[] | Record<string, ChunkedItems<T>>
	onSelect(item: T): void
	render?: (item: T) => JSX.Element | string | null
	renderChunk?: (chunk: ChunkedItems<T>) => JSX.Element | string | null
}

export class Autocomplete<T extends Record<string, any>> extends React.Component<
	AutocompleteProps<T>
> {
	private getContent() {
		const { items, render, renderChunk, query } = this.props

		if (!query) {
			return null
		}

		if (Array.isArray(items)) {
			return items.map((item, index) => (
				<AutocompleteComponents.Item key={index} onClick={() => this.props.onSelect(item)}>
					{render ? render(item) : item.toString()}
				</AutocompleteComponents.Item>
			))
		}

		return Object.values(items).map(chunk =>
			renderChunk ? (
				renderChunk(chunk)
			) : (
				<React.Fragment key={chunk.label}>
					<h2>{chunk.label}</h2>
					{chunk.items.map((item, index) => (
						<AutocompleteComponents.Item
							key={index}
							onClick={() => this.props.onSelect(item)}>
							{render ? render(item) : item.toString()}
						</AutocompleteComponents.Item>
					))}
				</React.Fragment>
			),
		)
	}
	public render() {
		const content = this.getContent()

		return <AutocompleteComponents.Wrapper>{content}</AutocompleteComponents.Wrapper>
	}
}
