import styled from 'styled-components'

import { colors, mixins, transition } from '@styleguide'

interface LogoTitleProps {
	color: string
	isActive: boolean
}

const LogoTitle = styled.h3<LogoTitleProps>(({ color, isActive }) => ({
	position: 'absolute',
	textShadow: `
            -1px -1px 0 ${colors.WHITE},
            1px -1px 0 ${colors.WHITE},
            -1px 1px 0 ${colors.WHITE},
            1px 1px 0 ${colors.WHITE},
            -2px -2px 0 ${colors.WHITE},
            2px -2px 0 ${colors.WHITE},
            -2px 2px 0 ${colors.WHITE},
            2px 2px 0 ${colors.WHITE},
            -2px -1px 0 ${colors.WHITE},
            2px -1px 0 ${colors.WHITE},
            -2px 1px 0 ${colors.WHITE},
            2px 1px 0 ${colors.WHITE},
            -1px -2px 0 ${colors.WHITE},
            1px -2px 0 ${colors.WHITE},
            -1px 2px 0 ${colors.WHITE},
            1px 2px 0 ${colors.WHITE}`,
	display: 'flex',
	justifyContent: 'center',
	whiteSpace: 'nowrap',
	width: 0,
	zIndex: 5,
	transition: transition.config,
	transitionProperty: 'opacity, transform',
	transformOrigin: '50% 50%',
	color,
	opacity: isActive ? 1 : 0,
	transitionTimingFunction: isActive ? 'cubic-bezier(.75,-0.5,0,1.75)' : undefined,
	transform: `scale(${isActive ? 1 : 0})`,

	[mixins.media.max('sm')]: {
		display: 'none',
	},
}))

export const finances = styled(LogoTitle)(({ isActive }) => ({
	top: '5%',
	left: '50%',
	transform: `translateX(-50%) scale(${isActive ? 1 : 0})`,
}))

export const health = styled(LogoTitle)(({ isActive }) => ({
	bottom: '10%',
	left: '50%',
	transform: `translateX(-50%) scale(${isActive ? 1 : 0})`,
}))

export const housing = styled(LogoTitle)(({ isActive }) => ({
	top: '50%',
	right: '5%',
	transform: `translateY(-50%) scale(${isActive ? 1 : 0})`,
}))

export const transport = styled(LogoTitle)(({ isActive }) => ({
	top: '50%',
	left: '5%',
	justifyContent: 'flex-start',
	transform: `translateY(-50%) scale(${isActive ? 1 : 0})`,
}))

export const sports = styled(LogoTitle)({
	bottom: '50%',
	right: '50%',
	marginRight: '18%',
	marginBottom: '-22%',
})

export const community = styled(LogoTitle)({
	top: '50%',
	left: '50%',
	marginLeft: '18%',
	marginTop: '-22%',
})

export const cityMap = styled(LogoTitle)({
	top: '50%',
	right: '50%',
	marginRight: '18%',
	marginTop: '-22%',
})

export const blog = styled(LogoTitle)({
	bottom: '50%',
	left: '50%',
	marginLeft: '18%',
	marginBottom: '-22%',
})
