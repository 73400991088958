import truncate from 'html-truncate'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, RichTextContent } from '@app/components'

import { EventResource } from '@compass/types'
import { getTimeslotByDateTime } from '../../helpers'
import { EventListType } from '../../types'
import { TimeslotButtonText } from '../timeslot'
import * as TeaserComponents from './components'

interface EventTeaserProps {
	event: EventResource
	type?: EventListType
}

export class Teaser extends React.Component<EventTeaserProps> {
	public static Wrapper = TeaserComponents.Wrapper
	public static Images = TeaserComponents.Images
	public static Label = TeaserComponents.Label
	public static Content = TeaserComponents.Content
	public static RichText = TeaserComponents.RichText

	public render() {
		const { event, type = 'default' } = this.props

		return (
			<Teaser.Wrapper type={type}>
				<Teaser.Images>
					{event.event.images.card ? (
						<Link to={event.slug}>
							<img src={event.event.images.card} />
						</Link>
					) : null}
					<Teaser.Label>
						<TimeslotButtonText timeslot={getTimeslotByDateTime(event.event.date)} />
					</Teaser.Label>
				</Teaser.Images>
				<Teaser.Content>
					<h3 className="u-margin-bottom--xs">
						<Link className="u-theme-text" to={event.slug}>
							{event.title}
						</Link>
					</h3>
					<div className="u-margin-bottom--xs">
						{moment(event.event.date.start).format('dddd D MMMM YYYY - HH:mm:ss')}
					</div>
					{event.content ? (
						<Teaser.RichText>
							<RichTextContent content={truncate(event.content, 500)} />
						</Teaser.RichText>
					) : null}
					<Button link to={event.slug}>
						read more ⤑
					</Button>
				</Teaser.Content>
			</Teaser.Wrapper>
		)
	}
}
