import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Box } from '@app/components/box'
import { FeedbackSectionForm } from '@app/components/forms'
import { Loader } from '@app/components/loader'
import { CompassUtils } from '@app/utils'
import { PageFeedbackFormInput } from '@compass/types'

interface FeedbackSectionState {
	isLoading: boolean
	isSubmitted: boolean
}

export class FeedbackSection extends React.Component<{}, FeedbackSectionState> {
	public state = {
		isLoading: false,
		isSubmitted: false,
	}

	private sendSubmission = async (values: PageFeedbackFormInput) => {
		this.setState({
			isLoading: true,
		})

		try {
			await CompassUtils.resource.submitFeedback(values)
			this.setState({
				isLoading: false,
				isSubmitted: true,
			})
		} catch (error) {
			this.setState({
				isLoading: false,
			})
		}
	}

	public render() {
		const { isLoading, isSubmitted } = this.state

		return (
			<Box title="Give feedback">
				<TransitionGroup>
					<CSSTransition
						key={isSubmitted.toString()}
						classNames="u-view-animation"
						timeout={{ enter: 1000, exit: 500 }}>
						<div>
							{!isSubmitted ? (
								<FeedbackSectionForm onSubmit={this.sendSubmission} />
							) : (
								<p>Thank you for your valuable feedback.</p>
							)}
						</div>
					</CSSTransition>
				</TransitionGroup>
				<Loader fixed={false} loading={isLoading} />
			</Box>
		)
	}
}
