import React from 'react'
import styled from 'styled-components'

import { SvgIcon } from '@app/components'
import * as iconset from '@app/components/svg-icons/icons'
import { timeslotIcons, Timeslots, TimeslotsTexts } from '@app/pages/events/types'
import { colors, mixins, spacing } from '@styleguide'

interface EventTimeSlotLabelProps {
	timeslot: Timeslots
	iconSize?: number
}

const ButtonTextGlam = styled.span({
	...mixins.primaryFont(),
	display: 'inline-flex',
	alignItems: 'center',
	padding: `5px ${spacing.xs}px`,
	marginBottom: spacing.xs,
	color: colors.WHITE,

	['svg']: {
		marginRight: spacing.xs,
		height: '24px',
		width: 'auto',
	},
})

const Text = styled.span({
	fontSize: '1.4rem',
	fontWeight: 'bold',
})

export const Timeslot: React.SFC<EventTimeSlotLabelProps> = ({ timeslot, iconSize = 24 }) => (
	<>
		<SvgIcon
			css={{ marginRight: 10, width: iconSize, height: 'auto' }}
			icon={timeslotIcons[timeslot] as keyof typeof iconset}
		/>
		<Text>{TimeslotsTexts[timeslot]}</Text>
	</>
)

export const TimeslotButtonText: React.SFC<EventTimeSlotLabelProps> = ({ timeslot }) => {
	return (
		<ButtonTextGlam className="u-theme-background">
			<Timeslot timeslot={timeslot} />
		</ButtonTextGlam>
	)
}
