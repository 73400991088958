import React from 'react'
import styled from 'styled-components'

import { Column } from '@app/components/grid/column'
import { Row } from '@app/components/grid/row'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'

export interface HeaderProps {
	isHidden?: boolean
	isScrolling?: boolean
}

const defaultTransition = `all ${transition.config}, transform ${transition.duration} ${
	transition.duration
}`

export const Wrapper = styled.header<HeaderProps>(({ isHidden = false, isScrolling = false }) => ({
	position: 'relative',
	height: 60,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	background: colors.WHITE,
	transition: defaultTransition,
	zIndex: 101,

	...mixins.mediaStyles({
		md: {
			position: 'fixed',
			height: 'auto',
			top: 0,
			left: 0,
			boxShadow: isScrolling ? shadows[3] : 'none',
			transform: isHidden ? 'translateY(-110px)' : 'none',
			opacity: isHidden ? 0 : 1,
			transition: isHidden
				? `transform ${transition.duration} ${transition.easing}, opacity ${
						transition.duration
				  } ${transition.easing}`
				: defaultTransition,
		},
	}),
}))

export const NavigationColumn = styled(Column)<{ isOpen: boolean; isHome: boolean }>(
	({ isOpen = false, isHome = false }) => ({
		[mixins.media.max('md')]: {
			background: colors.WHITE,
			width: '100%',
			overflow: 'hidden',
			padding: '60px 5px 0 5px',
			transition: transition.config,
			transitionProperty: 'max-height',
			maxHeight: isOpen ? '1000px' : isHome ? 0 : 0,

			'> div': {
				position: 'relative',
				background: colors.WHITE,
			},
		},

		...mixins.mediaStyles({
			lg: {
				padding: `0 ${spacing.sm}px`,
			},
		}),
	}),
)

export const LogoColumn = styled(Column)({
	[mixins.media.min('xl')]: {
		paddingLeft: spacing.lg,
	},

	[mixins.media.max('lg')]: {
		paddingLeft: '15px !important',
		paddingRight: '0 !important',
	},

	[mixins.media.max('md')]: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		padding: `10px !important`,
		display: 'flex',
		alignItems: 'center',
		zIndex: 101,
		boxShadow: shadows[2],
		background: colors.WHITE,
	},
})

// @ts-ignore
export const NavigationRow = styled(Row)({
	...mixins.mediaStyles({
		sm: {
			margin: `0 -${spacing.sm}px`,
		},
	}),

	[mixins.media.max('md')]: {
		justifyContent: 'flex-end',
		flexDirection: 'column-reverse',
	},
})

export interface SubnavigationProps {
	isSmall?: boolean
	isHidden?: boolean
}

export const Subnavigation = styled('div')<SubnavigationProps>(
	({ isSmall = false, isHidden = false }) => ({
		position: 'absolute',
		top: 0,
		left: '100%',
		height: '100%',
		zIndex: -1,
		opacity: isHidden ? 0 : 1,
		pointerEvents: isHidden ? 'none' : 'auto',
		transitionProperty: 'transform, opacity',
		...(isSmall
			? {
					transform: 'translateX(-500px)',
					transition: transition.config,
			  }
			: {
					transform: 'none',
					transition: `transform ${transition.duration} ${transition.config}, opacity ${
						transition.config
					}`,
			  }),
	}),
)

export const ScrollWrapperGlam = styled.div({
	position: 'relative',
	width: '100%',
	height: '100%',
	overflow: 'hidden',

	...mixins.mediaStyles({
		lg: {
			overflow: 'visible',
		},
	}),
})

export const ScrollWrapperInnerGlam = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: 70,

	[mixins.media.max('md')]: {
		minHeight: isOpen ? '-webkit-fill-available' : 0,
		width: '100%',
		overflow: 'auto',
		['-webkit-overflow-scrolling']: 'touch',
		position: 'fixed',

		['&::after']: isOpen
			? {
					position: 'fixed',
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					zIndex: -1,
					content: '""',
					display: 'block',
					background: 'rgba(255, 255, 255, 0.8)',
					pointerEvents: 'none',
			  }
			: {},
	},
}))

export const ScrollWrapper: React.SFC<{ isOpen: boolean }> = ({ children, isOpen }) => (
	<ScrollWrapperGlam>
		<ScrollWrapperInnerGlam isOpen={isOpen}>{children}</ScrollWrapperInnerGlam>
	</ScrollWrapperGlam>
)

const ToggleButton = styled.button({
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',
	width: 48,
	height: 48,
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	margin: `0 -8px`,
	padding: 8,
	textAlign: 'center',
	cursor: 'pointer',
	right: spacing.xs,
	...mixins.mediaStyles({
		md: {
			boxShadow: shadows[2],
			display: 'none',
		},
	}),
})

export const MobileMenu = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
	[mixins.media.max('md')]: {
		position: 'fixed',
		top: 0,
		paddingTop: 55,
		paddingBottom: 10,
		left: '-100vw',
		width: '100%',
		height: '100%',
		background: colors.WHITE,
		transition: transition.config,
		transitionProperty: 'transform',
		transform: isOpen ? 'translateX(100%)' : 'none',
	},
}))

const SliceWrapper = styled.div({
	width: 26,
	height: 21,
	display: 'inline-flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
})

const Slice = styled.span<{ isOpen: boolean }>(({ isOpen }) => ({
	width: '100%',
	display: 'block',
	height: 3,
	backgroundColor: colors.GREY_30,
	transition: transition.config,

	...(isOpen
		? {
				['&:first-child']: {
					transform: 'rotate(45deg) translate(7px, 6px)',
				},
				['&:nth-child(2)']: {
					opacity: 0,
				},
				['&:last-child']: {
					transform: 'rotate(-45deg) translate(6px, -6px)',
				},
		  }
		: {}),
}))

export const Toggle: React.SFC<React.HTMLAttributes<HTMLButtonElement> & { isOpen: boolean }> = ({
	isOpen,
	...rest
}) => (
	<ToggleButton {...rest}>
		<SliceWrapper>
			<Slice isOpen={isOpen} />
			<Slice isOpen={isOpen} />
			<Slice isOpen={isOpen} />
		</SliceWrapper>
	</ToggleButton>
)
