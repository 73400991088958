import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { MasonryGridSizer, RichTextContent, SvgIcon } from '@app/components'
import { TweetResource } from '@compass/types'
import { colors, mixins, shadows, spacing, transition } from '@styleguide'

import { UserProfile } from './user-profile'

interface TweetProps {
	item: TweetResource
}

const TweetWrapper = styled(MasonryGridSizer)({
	position: 'relative',
	display: 'block',
	float: 'left',
	listStyle: 'none',
	marginBottom: spacing.sm,
	backgroundColor: colors.WHITE,
	transition: transition.config,
	transitionProperty: 'transform, z-index',
	transformOrigin: '50% 50%',
	border: `1px ${colors.GREY_30} solid`,
	padding: spacing.xs,
	zIndex: 0,

	['&:before']: {
		zIndex: -1,
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		boxShadow: shadows[6],
		opacity: 0,
		transition: transition.config,
		transitionProperty: 'opacity',
		content: '""',
		display: 'block',
	},

	['&:hover']: {
		transform: 'scale(1.05)',
		zIndex: 10,

		['&:before']: {
			opacity: 1,
		},
	},
})

const TweetContent = styled.div({
	paddingLeft: 48 + spacing.xs,
})

const TweetText = styled.div({
	fontSize: '1.6rem',
	marginTop: spacing.xs,
})

const TweetData = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	marginTop: spacing.xs,
})

const TweetDataItem = styled.div({
	display: 'flex',
	alignitems: 'center',
	marginRight: spacing.sm,
})

const TweetDataItemIcon = styled(SvgIcon)({
	width: 24,
	height: 24,
	marginRight: 10,
})

const TweetDataItemText = styled.span({
	fontSize: '2rem',
	lineHeight: 1.3,
	...mixins.primaryFont(),
})

const TweetDate = styled.div({
	color: colors.GREY_50,
	fontSize: '1.3rem',
	marginTop: spacing.xs,
})

const TweetBackground = styled(SvgIcon)<{ className: string }>({
	position: 'absolute',
	opacity: 0.1,
	top: '45%',
	transform: 'translateY(-50%)',
	right: '5%',
	width: '40%',
	height: 'auto',
})

export const Tweet: React.SFC<TweetProps> = ({ item: { tweet } }) => (
	<TweetWrapper className="grid-item">
		<TweetBackground className="u-theme-text" icon="twitter" />
		<UserProfile {...tweet.user} />
		<TweetContent>
			<TweetText>
				<RichTextContent
					content={tweet.text.replace(
						/#(\S*)/g,
						'<a href="https://twitter.com/hashtag/$1">#$1</a>',
					)}
				/>
			</TweetText>
			<TweetData className="u-theme-text">
				<TweetDataItem>
					<TweetDataItemIcon icon="retweet" />
					<TweetDataItemText>{tweet.retweets}</TweetDataItemText>
				</TweetDataItem>
				<TweetDataItem>
					<TweetDataItemIcon icon="like" />
					<TweetDataItemText>{tweet.likes}</TweetDataItemText>
				</TweetDataItem>
			</TweetData>
			<TweetDate>{moment(tweet.date).format('dddd D MMMM YYYY [at] HH:mm')}</TweetDate>
		</TweetContent>
	</TweetWrapper>
)
