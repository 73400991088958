import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { SvgIcon } from '@app/components/svg-icons'
import { ThemeWrapper } from '@app/components/theme-wrapper'
import { CompassUtils } from '@app/utils'
import { Resource } from '@compass/types'
import { colors, mixins, spacing, transition } from '@styleguide'

import * as SectionComponents from './navigation-section-components'
import * as SwitchComponents from './navigation-switch-components'

export const Wrapper = styled.div<{ isOpen?: boolean }>({
	position: 'relative',
	display: 'block',
	width: '100%',
	alignItems: 'center',
	...mixins.mediaStyles({
		md: {
			display: 'inline-flex',
		},
		lg: {
			marginLeft: -149,
			width: 'calc(100% + 149px)',
		},
		xl: {
			marginLeft: 0,
			width: '100%',
		},
	}),
})

interface ListsProps {
	isRight?: boolean
}

export const Lists = styled.div<ListsProps>(({ isRight = false }) => ({
	width: '100%',
	transition: `transform ${transition.config}`,

	...(isRight
		? {
				[mixins.media.min('md')]: {
					transform: 'translateY(-50%)',
				},
		  }
		: {}),
}))

export const ListsWrapper = styled.div({
	width: '100%',
	...mixins.mediaStyles({
		md: {
			position: 'relative',
			width: '100%',
			height: 70,
			overflow: 'hidden',
		},
	}),
})

interface NavigationDomainProps {
	domain: Resource
	onClick(): void
}

const DomainGlam = styled.ul({
	flex: 1,
	padding: 0,
	margin: 0,
	listStyle: 'none',
	width: '100%',

	...mixins.mediaStyles({
		md: {
			overflow: 'hidden',
			display: 'flex',
		},
	}),
})

export const Domain: React.SFC<NavigationDomainProps> = ({ domain, onClick }) => (
	<DomainGlam key={domain.slug}>
		{domain &&
			domain.resources &&
			domain.resources.length > 0 &&
			domain.resources.map((section: Resource) => (
				<Section section={section} key={section.slug} onClick={onClick} />
			))}
	</DomainGlam>
)

export const DomainLabel = styled.span({
	display: 'block',
	fontSize: '2rem',
	color: colors.BLACK,
	textAlign: 'center',
	margin: `${spacing.sm}px 0 ${spacing.xs}px`,
	...mixins.primaryFont(),
	...mixins.mediaStyles({
		md: {
			display: 'none',
		},
	}),
})

interface NavigationSwitchProps {
	domains: Resource[]
	activeDomain: string | undefined
	isRight: boolean
	onChangeDomain: (activeNavigationDomain: string) => void
}

export const Switch: React.SFC<NavigationSwitchProps> = ({
	domains,
	activeDomain,
	onChangeDomain,
	isRight,
}) => {
	return (
		<SwitchComponents.Wrapper>
			<SwitchComponents.Background isRight={isRight} />
			<SwitchComponents.Toggles>
				{domains.map((domain: Resource) => (
					<SwitchComponents.Toggle
						domain={domain}
						activeDomain={activeDomain}
						onChangeDomain={onChangeDomain}
						key={domain.slug}
					/>
				))}
			</SwitchComponents.Toggles>
		</SwitchComponents.Wrapper>
	)
}

interface NavigationSectionBaseProps {
	section: Resource
	key: string
	onClick(): void
}

type NavigationSectionProps = NavigationSectionBaseProps & RouteComponentProps<any>

export const SectionComponent: React.SFC<NavigationSectionProps> = ({
	section,
	location: { pathname },
	onClick,
}) => {
	const sectionIcon = CompassUtils.navigation.getSectionIcon(section)
	const route = section.slug
	const isActive = pathname !== '/' && pathname.startsWith(route)

	return (
		<SectionComponents.Wrapper isActive={isActive}>
			<ThemeWrapper
				fixedTheme={CompassUtils.navigation.getSectionSlugByResourceSlug(section.slug)}>
				<SectionComponents.Background />
				<SectionComponents.Link to={route} onClick={onClick}>
					<SectionComponents.IconWrapper>
						<SvgIcon icon={sectionIcon} />
						{sectionIcon === 'events' ? (
							<SectionComponents.EventsDate>
								{new Date().getDate()}
							</SectionComponents.EventsDate>
						) : null}
					</SectionComponents.IconWrapper>
					<SectionComponents.LinkText>{section.title}</SectionComponents.LinkText>
				</SectionComponents.Link>
				{/* <SectionComponents.Submenu>
                    {section.pages.map((page: Resource) => (
                        <SectionComponents.SubmenuItem
                            key={page.id}
                            isActive={pathname.startsWith(page.slug)}>
                            <SectionComponents.SubmenuItemLink to={page.slug}>
                                {page.title}
                            </SectionComponents.SubmenuItemLink>
                        </SectionComponents.SubmenuItem>
                    ))}
                </SectionComponents.Submenu> */}
			</ThemeWrapper>
		</SectionComponents.Wrapper>
	)
}

export const Section = withRouter(SectionComponent)
