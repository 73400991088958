import React from 'react'

import { InputComponents, RadioButtonComponents } from './components'

export interface RadioButtonProps {
	id: string
	labelText?: React.ReactType | JSX.Element | string
	errorText?: React.ReactType | JSX.Element | string
	hasError?: boolean
	isSmall?: boolean
}

export class RadioButton extends React.Component<
	RadioButtonProps & React.HTMLProps<HTMLInputElement>
> {
	public static Wrapper = InputComponents.Wrapper
	public static ButtonWrapper = InputComponents.ButtonWrapper
	public static Input = InputComponents.HiddenInput
	public static Label = RadioButtonComponents.Label
	public static LabelText = InputComponents.LabelText.withComponent('span')
	public static ErrorLabel = InputComponents.ErrorLabel

	public render() {
		const {
			id,
			labelText,
			errorText,
			hasError,
			isSmall = false,
			disabled,
			ref: _ref,
			as: _as,
			...rest
		} = this.props

		return (
			<RadioButton.Wrapper css={{ marginBottom: `0 !important` }}>
				<RadioButton.ButtonWrapper>
					<RadioButton.Input id={id} type="radio" disabled={!!disabled} {...rest} />
					<RadioButton.Label htmlFor={id} hasError={!!hasError} isSmall={isSmall}>
						<RadioButton.LabelText>{labelText}</RadioButton.LabelText>
					</RadioButton.Label>
				</RadioButton.ButtonWrapper>
				{hasError && errorText && (
					<RadioButton.ErrorLabel>{errorText}</RadioButton.ErrorLabel>
				)}
			</RadioButton.Wrapper>
		)
	}
}
