import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { colors, mixins, spacing, transition } from '@styleguide'

export const Wrapper = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'row-reverse',
	zIndex: 1,

	[mixins.media.max('md')]: {
		marginTop: spacing.sm,
		justifyContent: 'center',
		flexWrap: 'wrap',
		padding: 0,
		marginLeft: -10,
		marginRight: -10,
	},

	[mixins.media.minMax('md', 'lg')]: {
		position: 'absolute',
		top: '100%',
		marginTop: 20,
		right: 30,
	},

	['@media (min-width: 1250px) and (max-width: 1365px)']: {
		top: 0,
	},

	...mixins.mediaStyles({
		md: {
			opacity: 0.75,
			transition: transition.config,
			transitionProperty: 'opacity',
			transitionDelay: '300ms',

			['&:hover']: {
				opacity: 1,
				transitionDelay: '0ms',
			},
		},
	}),
})

interface LinkProps {
	backgroundColors: {
		default: string
		hover: string
	}
}

const linkColorStyles = ({ backgroundColors }: LinkProps) => ({
	width: 36,
	height: 36,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// @ts-ignore
	background: colors[backgroundColors.default] || backgroundColors.default,
	border: `2px ${colors.WHITE} solid`,

	['& svg']: {
		width: 16,
		height: 16,
	},

	...mixins.mediaStyles({
		md: {
			transition: `background ${transition.config}`,

			['&:hover']: {
				// @ts-ignore
				background: colors[backgroundColors.hover] || backgroundColors.hover,
			},

			[`${Wrapper}:hover &`]: {
				transition: `background ${transition.config}`,
			},
		},
	}),
})

export const Link = styled.a<LinkProps>(linkColorStyles)

export const RouterLink = styled(({ backgroundColors, ...rest }) => <NavLink {...rest} />)(
	linkColorStyles,
)
