export function chunk<T>(arr: T[], chunkSize: number): T[][] {
	return arr.reduce(
		(prevVal: any, _currVal: any, currIndx: number, array: T[]) =>
			!(currIndx % chunkSize)
				? prevVal.concat([array.slice(currIndx, currIndx + chunkSize)])
				: prevVal,
		[],
	)
}

export function shuffle(array: any[]) {
	let currentIndex = array.length
	let temporaryValue
	let randomIndex

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex -= 1

		// And swap it with the current element.
		temporaryValue = array[currentIndex]
		array[currentIndex] = array[randomIndex]
		array[randomIndex] = temporaryValue
	}

	return array
}
