import { createGlobalStyle } from 'styled-components'

import { normalize } from './normalize'

import { colors, fonts, spacing, transition } from '@styleguide'

export const GlobalStyles = createGlobalStyle`
	${normalize}

	*, *::before, *:after {
		box-sizing: border-box;
		margin: 0;
		outline: 0;
		padding: 0;
	}

	html, body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-width: 320px;
		min-height: 100%;
		-webkit-text-size-adjust: 100%;
	}

	html {
		font-family: 'Nunito', 'Helvetica', 'Arial', sans-serif;
		font-size: 10px;
		color: ${colors.BLACK};
		background-color: #FEFEFE;
		height: 100%;
	}

	html.no-scroll {
		overflow: hidden;
	}

	@media (max-width: 767px) {
        html.no-scroll-on-mobile {
            overflow: hidden
        }
    }

	body {
		font-size: 1.8rem;
		line-height: 1.56;
		width: 100%;
		overflow-x: hidden;
	}

	button {
		position: relative;
		display: inline-block;
		cursor: pointer;
		user-select: none;
		text-decoration: none;
		vertical-align: top;
		background: transparent;
		border: 0;
	}

	fieldset {
		border: 0;
	}

	ol {
		padding-left: ${spacing.xs}px;
	}

	figure {
		margin: 0;
	}

	[type="text"], [type="email"], [type="tel"], [type="number"], [type="search"], textarea {
		color: ${colors.GREY_50};
		display: block;
		font-family: inherit;
		font-size: 1.6rem;
		height: 45px;
		line-height: 1.2rem;
		padding: 0 15px;
		width: 100%;
		-webkit-appearance: none;
		border: 1px ${colors.GREY_40} solid;
		box-shadow: none;
		background-color: ${colors.WHITE};
		font-family: ${fonts.SECONDARY};
		font-weight: 800;

		&::placeholder {
			color: ${colors.GREY_40};
		}
	}

	h1 {
		font-weight: bold;
		line-height: 1.3;
		font-size: 4.8rem;
		margin: 0;
		color: ${colors.PRIMARY};
		font-family: ${fonts.PRIMARY};
		font-weight: 800;
		letter-spacing: 0.04rem;
		text-transform: lowercase;

		@media (max-width: 768px) {
			font-size: 3.2rem;
		}
	}

	h2 {
		font-weight: bold;
		line-height: 1.3;
		font-size: 3.6rem;
		margin-bottom: ${spacing.xs}px;
		font-family: ${fonts.PRIMARY};
		font-weight: 800;
		letter-spacing: 0.04rem;
		text-transform: lowercase;

		@media (max-width: 768px) {
			font-size: 2.4rem;
		}
	}

	h3 {
		font-weight: bold;
		line-height: 1.3;
		font-size: 2.8rem;
		margin: 0;
		font-family: ${fonts.PRIMARY};
		font-weight: 800;
		letter-spacing: 0.04rem;
		text-transform: lowercase;

		@media (max-width: 768px) {
			font-size: 2rem;
		}
	}

	h4 {
		font-weight: bold;
		line-height: 1.3;
		font-size: 2rem;
		margin: 0;
		font-family: ${fonts.PRIMARY};
		font-weight: 800;
		letter-spacing: 0.04rem;
		text-transform: lowercase;

		@media (max-width: 768px) {
			font-size: 1.8rem;
		}
	}

	a {
		cursor: pointer;
		text-overflow: ellipsis;
		text-decoration: none;
	}

	#app {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	.u-view-animation-enter {
		position: absolute;
		opacity: 0;
		top: 0;
		width: 100%;
		transform: translateY(10px);
	}

	.u-view-animation-enter:last-child {
		position: static;
	}

	.u-view-animation-enter.u-view-animation-enter-active {
		opacity: 1;
		transform: none;
		transition: all 500ms 500ms ${transition.easing};
	}

	.u-view-animation-exit {
		position: relative;
		width: 100%;
		opacity: 1;
	}

	.u-view-animation-exit.u-view-animation-exit-active {
		opacity: 0;
		transition: all 500ms ${transition.easing};
	}

	.u-list-delay-animation-enter {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.u-list-delay-animation-exit {
		position: relative;
		opacity: 1;
	}

	.u-list-delay-animation-exit.u-list-delay-animation-exit-active {
		opacity: 0.01;
		transition: all 500ms ${transition.easing};
	}

	.u-list-animation-enter {
		position: relative;
		opacity: 0;
	}

	.u-list-animation-enter.u-list-animation-enter-active {
		opacity: 1;
		transition: all 500ms 500ms ${transition.easing};
	}

	.u-list-animation-exit {
		position: absolute;
		width: 100%;
		top: 0;
		opacity: 1;
	}

	.u-list-animation-exit.u-list-animation-exit-active {
		opacity: 0;
		transition: all 500ms ${transition.easing};
	}

	.gm-style img {
		max-height: none !important;
	}

	.cluster img {
		width: 40px;
		height: auto;
	}
`
