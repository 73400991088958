import React from 'react'
import styled from 'styled-components'

import * as IconNavigationComponents from '@app/components//icon-navigation/icon-navigation-components'
import { ContactDetailsProps, icons } from '@app/components/contact-details'
import { TextInput, TextInputProps } from '@app/components/form-elements'
import { SvgIcon } from '@app/components/svg-icons'
import { colors, spacing } from '@styleguide'

interface ContactTextInputProps extends React.HTMLProps<HTMLInputElement>, TextInputProps {
	type: keyof ContactDetailsProps
}

const InputWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	margin: '10px 0',
	width: '350px',
	maxWidth: '100%',

	['& svg']: {
		display: 'block',
		width: 24,
		height: 24,
	},
})

export const ContactTextInput: React.SFC<ContactTextInputProps> = ({
	type,
	ref: _ref,
	...rest
}) => {
	const hoverBackground = type.toUpperCase() as keyof typeof colors
	const defaultBackground = (type.toUpperCase() + '_LIGHT') as keyof typeof colors

	const hoverBackgroundColor = colors[hoverBackground] ? hoverBackground : 'INFO'
	const defaultBackgroundColor = colors[defaultBackground] ? defaultBackground : 'INFO_LIGHT'

	return (
		<InputWrapper>
			<IconNavigationComponents.Link
				backgroundColors={{
					default: defaultBackgroundColor,
					hover: hoverBackgroundColor,
				}}
				css={{ margin: `0 ${spacing.xs}px 0 0 !important`, border: 0 }}>
				<SvgIcon icon={icons[type]} fill={colors.WHITE} />
			</IconNavigationComponents.Link>
			<div css={{ flex: 1 }}>
				<TextInput {...rest} />
			</div>
		</InputWrapper>
	)
}
